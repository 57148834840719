
export const ShippingByTypeAndMaterial = /* GraphQL */ `
  query ShippingByTypeAndMaterial(
    $type: String!
    $materialID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivingAndShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ShippingByTypeAndMaterial(
      type: $type
      materialID: $materialID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        no @client
        timeStamp

        date
        number
        itemNumber
        qty
        warehouseCode
        type
        poID
        po {
          id
          no @client
          number
          itemNumber
          pi {
            id
            poID
          }
          customer {
            id
            abbr @client
            name
            alias
          }
        }
        materialID
        material {
          id
          mfr
          mpn
          unit
          desc
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        courierID
        trackingNumber
        warehouseStaffID
        driverID
        creatorID
        definerID
        billingStatus
        createdAt
        updatedAt
        invoice {
          items {
            id
            number
            itemNumber
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const deleteReceivingAndShipping = /* GraphQL */ `
  mutation DeleteReceivingAndShipping(
    $input: DeleteReceivingAndShippingInput!
    $condition: ModelReceivingAndShippingConditionInput
  ) {
    deleteReceivingAndShipping(input: $input, condition: $condition) {
      id
      date
      number
      qty

      __typename
    }
  }
`;

export const getReceivingAndShipping = /* GraphQL */ `
  query GetReceivingAndShipping($id: ID!) {
    getReceivingAndShipping(id: $id) {
      id
      date
      timeStamp

      number
      itemNumber
      qty
      warehouseCode
      type
      poID
      po {
        id
        number
        itemNumber
        pi {
          id
          poID
        }

        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID

        __typename
      }
      materialID
      material {
        id
        definerID
        mpn
        mfr
        unit
      }
      materialEntityRelationID
      materialEntityRelation {
        id
        entityID
        materialID
        code
        statusCode

        __typename
      }
      courierID
      trackingNumber
      warehouseStaffID
      driverID
      creatorID
      definerID
      billingStatus
      invoice {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
