import { Box, TextField, Stack, Link, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import LoadingButton from "@mui/lab/LoadingButton";

export default function ResetPassword({
  loading,
  setAuthType,
  action,
  setEmail,
  email,
  setPassword,
  password,
  confirmPassword,
  setConfirmPassword,
  sx,
  clearAlert,
}) {
  const label = "Reset Password";

  return (
    <Box sx={sx}>
      <LockOutlinedIcon fontSize="large" color="secondary" />
      <Typography variant="h5">{label}</Typography>
      <Box component="form" onSubmit={action.onSubmit} width="100%">
        <TextField
          margin="normal"
          required
          fullWidth
          disabled
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus={email ? false : true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Retype Password"
          type="password"
          id="confirmPassword"
          // autoComplete="current-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <LoadingButton
          loading={loading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirm
        </LoadingButton>
      </Box>
      <Stack width="90%" direction="row" justifyContent="space-between">
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            clearAlert();
            setAuthType("signIn");
          }}
        >
          {"Back to Sign in"}
        </Link>
      </Stack>
    </Box>
  );
}
