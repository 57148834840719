import { onCreate } from "../../../function/onCreate";
import { createPO, fragment } from "./query";

export default async function onCreateSPO({ client, values }) {
  const {
    date,
    number,
    itemNumber,
    materialID,
    salesID,
    buyerID,
    vendorID,
    customerID,
    definerID,
    importAgentID,
    paymentAgentID,
    materialEntityRelationID,
    paymentTerms,
    incoTerms,
    forwarderID,
    CRD,
    price,
    qty,
    openQty,
    priceCur,
    version,
    statusCode,
    billingStatus,
    paymentStatus,
  } = values;

  const input = {
    date,
    number,
    itemNumber,
    materialEntityRelationID,
    materialID,
    paymentAgentID,
    importAgentID,
    salesID,
    buyerID,
    vendorID,
    customerID,
    definerID,
    billingStatus,
    paymentStatus,
    paymentTerms,
    incoTerms,
    forwarderID,
    CRD,
    price,
    qty,
    openQty,
    priceCur,
    version,
    statusCode,
  };

  // console.log({ input });

  // debugger;

  const { data: createOfferData, errors: createOfferErrors } = await onCreate({
    input,
    mutation: createPO,
    fragment,
    updateCacheFields: [""],
    client,
    refetchQueries: ["POByDefinerID"],
  });
  console.log({ createOfferData, createOfferErrors });
}
