import { onDelete } from "../../../function/onDelete";
import {  deleteMaterialEntityRelation } from "./query";

export default async function onDeleteRelation({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }
}

async function deleteSingleItem(item, client) {
  const { id } = item || {};

  await onDelete({
    input: { id },
    mutation: deleteMaterialEntityRelation,
    // updateCacheFields: ["ThirdPartyUserRelationByThirdPartyID"],
    client,
  });
}
