import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import {
  FormikField,
  FormikSelect,
  FormikCheckbox,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { entityType } from "../../../../../settings/formFieldEnum";

export default function Step4Relations({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
  setActiveStep,
}) {
  // const { step, id } = values;

  // useEffect(() => {
  //   if (step !== 1) {
  //     console.log({ step });
  //     setFieldValue("step", 1);
  //   }
  //   setErrors({});
  // }, [setFieldValue, setErrors, step]);

  // useEffect(() => {
  //   if (!id) {
  //     setFieldValue("id", uuidv4());
  //   }
  // }, [id, setFieldValue]);

  return (
    <Stack width={1200} spacing={2}>
      <FormikRadioGroup
        label="Does this entity have relations with other entities?"
        name="hasRelations"
        selections={[
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ]}
        onChange={(e) => {
          if (e === "false") {
            setActiveStep((prev) => prev + 1);
          }
        }}
      />
      {/* <FormikCheckbox
        label="Does this entity have specified payment terms?"
        name="hasSpecifiedPaymentTerms"
      /> */}

      {/* <FormikCheckbox
        label="Does this entity have specified inco terms?"
        name="hasSpecifiedIncoTerms"
      /> */}
    </Stack>
  );
}
