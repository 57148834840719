import { useApolloClient } from "@apollo/client";
import { Hub } from "aws-amplify";
import * as Yup from "yup";
import { Stack, Typography, Button } from "@mui/material";
import FormikForm, {
  FormikField,
  FormikSelect,
} from "../../../../component/formikForm";
import onDeleteEntity from "../onDelete";
import onUpdate from "../onUpdate";
import IncoTerms from "./IncoTerms";
import {
  entityType,
  purePaymentTerms as paymentTermsOptions,
} from "../../../../settings/formFieldEnum";

const FormArray = [
  {
    Item: IncoTerms,
    name: "incoTerms",
    // width: 1200,
    bgcolor: "#e3f2fd",
    btnColor: "#3f51b5",
    size: "md",
    hideLabel: false,
  },
];

const validationSchema = Yup.object().shape({
  // .required("Please enter a alias"),
});

export default function Item({ data, onClose }) {
  const client = useApolloClient();

  const onDeleteBtnClick = async () => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      await onDeleteEntity({ values: data, client });
      onClose();
    }
  };

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    });
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={FormArray}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onDelete={onDeleteBtnClick}
      onSubmit={onSubmit}
    />
  );
}

const EditFields = ({ values }) => {
  const { isEditing } = values || [];

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormikField
          name="name"
          label="Name"
          width={360}
          disabled={!isEditing}
        />
        <FormikField
          name="alias"
          label="Alias"
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          name="address"
          label="Address"
          width={360}
          disabled={!isEditing}
        />
        <FormikField
          name="phoneNumber"
          label="phoneNumber"
          width={180}
          disabled={!isEditing}
        />
      </Stack>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikSelect
          label="Business Type"
          name="businessType"
          labelKey="label"
          valueKey="value"
          multiple
          options={entityType}
          disabled={!isEditing}
        />
        <FormikField
          name="alias"
          label="Company alias"
          width={180}
          disabled={!isEditing}
        />
        <FormikField
          name="statusCode"
          required
          label="Status Code"
          width={100}
          disabled={!isEditing}
        />
      </Stack>
      <Stack direction="row" spacing={5}>
        <FormikSelect
          label="PaymentTerms"
          name="paymentTerms"
          multiple
          width={600}
          options={paymentTermsOptions}
          disabled={!isEditing}
          // required
          // labelKey="label"
          // valueKey="value"
        />
      </Stack>
    </Stack>
  );
};
