import { useMemo } from "react";
import { Stack, Typography, TextField } from "@mui/material";
import SimpleTable from "../../../../../component/SimpleTable";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import { useEffect } from "react";
import { vatRate } from "../../../../../settings/formFieldEnum";

export default function Step1Review({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const { category } = values;

  if (category === "DISCOUNTING") {
    return <DiscountingItems values={values} setFieldValue={setFieldValue} />;
  } else if (["VAT", "PI", "RECEIPT"].includes(category)) {
    return <ItemsWithPO values={values} />;
  } else if (["SERVICE", "OTHERS"].includes(category)) {
    return <ServiceItem values={values} />;
  }
}

function ItemsWithPO({ values }) {
  const { vendor, number, category, date, items } = values;

  const invoiceItems = items
    .map((x) => x.subItems)
    .flat()
    ?.map(
      (
        {
          poID,
          itemNumber,
          billingQty,
          price,
          priceCur,
          desc,
          // paymentDueDate,
          shippingID,
        },
        i
      ) => {
        return {
          poID,
          number,
          itemNumber: itemNumber || i + 1,
          qty: billingQty,
          price,
          currency: priceCur,
          desc,
          shippingID,
        };
      }
    )
    ?.filter((x) => x.qty > 0);

  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">Billing Date: {date}</Typography>
        <Typography variant="h6">Category: {category}</Typography>
        <Typography variant="h6">Number: {number}</Typography>
        <Typography variant="h6">Vendor: {vendor?.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">
          Total: {invoiceItems?.[0]?.currency}{" "}
          {invoiceItems
            ?.reduce((a, v) => a + (v.qty * v.price || 0), 0)
            .toFixed(3) * 1}
        </Typography>
        {invoiceItems?.[0]?.currency === "CNY" && (
          <Typography>
            Total including VAT:
            {(
              invoiceItems?.reduce((a, v) => a + (v.qty * v.price || 0), 0) *
              (1 + vatRate)
            )?.toFixed(2)}{" "}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6"></Typography>
      </Stack>
      {items?.map(({ hideSubArray, material, po, qty, subItems }, index) => (
        <Stack key={`${po.materialID}${po.number}`} bgcolor="#fff">
          <Stack direction="row" spacing={3}>
            <Typography variant="h6">
              Material: {po.material?.mfr} {po.material?.mpn}
            </Typography>

            <Typography variant="h6">PO: {po.number}</Typography>
            <Typography variant="h6">
              QUANTITY: {subItems?.reduce((a, v) => a + (v.billingQty || 0), 0)}
            </Typography>
            <Typography variant="h6">
              TOTAL AMOUNT:{" "}
              {subItems
                ?.reduce((a, v) => a + (v.billingQty || 0) * v.price, 0)
                .toFixed(3) * 1}
            </Typography>
          </Stack>
          {subItems.map(
            ({ itemNumber, qty, openQty, price, priceCur, billingQty }) => (
              <Stack key={itemNumber} direction="row" spacing={2}>
                <Typography variant="h6">Item Number: {itemNumber}</Typography>

                <Typography variant="h6">
                  billingQty: {billingQty || 0}
                </Typography>
                <Typography variant="h6">
                  Price: {priceCur} {price}
                </Typography>
                <Typography variant="h6">
                  Amount: {(price * billingQty).toFixed(3) * 1 || 0}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
      ))}
    </Stack>
  );
}

function DiscountingItems({ values, setFieldValue }) {
  const { items } = values;

  const invoices = items?.map((x) => x.discountingInvoices).flat();

  invoices.sort((a, b) => (a?.id > b?.id ? 1 : -1));

  // console.log({ invoices })

  return (
    <Stack spacing={5}>
      {invoices.map((x, i) => {
        // console.log({ xxxxxxxx: x })
        return (
          <SingleItem
            key={x.id}
            item={x}
            items={items}
            setFieldValue={setFieldValue}
          />
        );
      })}
    </Stack>
  );
}

function SingleItem({ item, items, setFieldValue }) {
  return (
    <Stack>
      <Typography variant="h6">
        Desc: {item.desc}
        {item.price}
      </Typography>
      <Typography variant="h6">id: {item.id}</Typography>
      <TextField
        // disabled={index !== 0 && applyEAUToAll}
        label="Number"
        onChange={(e) => {
          setFieldValue(
            "items",
            items.map(({ discountingInvoices }) => {
              let itemFound = discountingInvoices.find(
                (x) => x?.id === item?.id
              );
              console.log({
                discountingInvoices,
                item,
                itemFound,
              });

              // itemFound = { ...itemFound }
              // itemFound.number = e.target.value

              return {
                discountingInvoices: [
                  ...discountingInvoices.filter((x) => x?.id !== item?.id),
                  { ...itemFound, number: e.target.value },
                ],
              };
            })
          );
        }}
        value={item.number || ""}
        width={240}
        required
      />
    </Stack>
  );
}

function ServiceItem({ values }) {
  const { vendor, number, category, date, items } = values;
  const currency = items?.[0]?.currency;
  const taxRate = items?.[0]?.taxRate;

  console.log({ items });

  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">Billing Date: {date}</Typography>
        <Typography variant="h6">Category: {category}</Typography>
        <Typography variant="h6">Number: {number}</Typography>
        <Typography variant="h6">Vendor: {vendor?.name || vendor}</Typography>
      </Stack>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">
          Total: {currency}{" "}
          {items?.reduce((a, v) => a + (v.qty * v.price || 0), 0).toFixed(3) *
            1}
        </Typography>
        {taxRate > 0 && (
          <Stack direction="row" spacing={3}>
            <Typography>Tax Rate: {taxRate}%</Typography>
            <Typography>
              Total including TAX:
              {(
                items?.reduce((a, v) => a + (v.qty * v.price || 0), 0) *
                (1 + taxRate / 100)
              )?.toFixed(2)}{" "}
            </Typography>
          </Stack>
        )}
      </Stack>
      {/* <Stack direction="row" spacing={3}>
        <Typography variant="h6"></Typography>
      </Stack> */}
      {items?.map(
        (
          { hideSubArray, desc, descDetails, price, currency, taxRate, qty },
          i
        ) => (
          <Stack key={i} bgcolor="#fff">
            <Stack direction="row" spacing={3}>
              <Typography variant="h6">
                desc: {descDetails ? `${desc}-${descDetails}` : desc}
              </Typography>
              <Typography variant="h6">
                price: {currency}
                {price}
              </Typography>
              <Typography variant="h6">qty: {qty}</Typography>
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
}
