import { useEffect, useMemo, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
} from "../../../../../component/formikForm";
import {
  relationsByDefiner,
  getEntityUserRelation,
  POByCustomerAndOpenQty,
} from "./query";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export default function Step0ChooseItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const client = useApolloClient();
  const { definerID, incoTermsOptions, customer, vendor } = values;

  const { data: relationsByDefinerData } = useQuery(gql(relationsByDefiner), {
    variables: { definerID, relation: { eq: "CUSTOMER-SUPPLIER" } },
    skip: !definerID,
  });

  const relationItems = relationsByDefinerData?.relationsByDefiner?.items;

  const customerOptions = useMemo(
    () =>
      filterOutSameItemInArray(
        relationItems?.map((x) => ({
          name: x.entity1.abbr,
          id: x.entity1.id,
        })),
        "id"
      ),
    [relationItems]
  );

  const vendorOptions = useMemo(
    () =>
      relationItems
        ?.filter((x) => x.entity1ID === customer?.id)
        ?.map((x) => ({
          name: x.entity2.abbr,
          id: x.entity2.id,
        })),
    [relationItems, customer?.id]
  );

  useEffect(() => {
    async function func() {
      if (!relationItems?.length > 0) return;
      let options = relationItems
        ?.filter(
          (x) => x.entity1ID === customer?.id && x.entity2ID === vendor?.id
        )
        ?.map((x) => x.incoTerms)
        ?.flat();

      options = await Promise.all(
        options?.map(async (x) => {
          const { data } = await client.query({
            query: gql(getEntityUserRelation),
            variables: { id: x.entityUserRelationID },
          });

          const { user, entity } = data?.getEntityUserRelation || {};
          const { name, phoneNumber, address, email } = user || {};
          const { name: entityName } = entity || {};
          return {
            terms: x.terms,
            entityName,
            contactName: name,
            phoneNumber,
            address,
            email,
          };
        })
      );

      setFieldValue("incoTermsOptions", options);

      if (options?.length === 1) {
        setFieldValue("incoTerms", options[0]);
      }
    }

    func();
  }, [relationItems, customer?.id, client, vendor?.id, setFieldValue]);

  useEffect(() => {
    if (vendorOptions?.length === 1) {
      setFieldValue("vendor", vendorOptions[0]);
    }
  }, [vendorOptions, setFieldValue]);

  const { data: POByCustomerAndOpenQtyData } = useQuery(
    gql(POByCustomerAndOpenQty),
    {
      variables: {
        customerID: customer?.id,
        openQty: { gt: 0 },
        filter: {
          and: [
            { vendorID: { eq: vendor?.id } },
            { definerID: { eq: definerID } },
          ],
        },
      },
      skip: !customer?.id,
    }
  );

  useEffect(() => {
    if (POByCustomerAndOpenQtyData?.POByCustomerAndOpenQty?.items?.length > 0) {
      setFieldValue(
        "POOptions",
        POByCustomerAndOpenQtyData?.POByCustomerAndOpenQty?.items
      );
    }
  }, [POByCustomerAndOpenQtyData, setFieldValue]);

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="date"
          label="Date"
          type="date"
          width={180}
          required
        />
        <FormikField name="number" label="Number" width={200} required />

        <FormikAutocomplete
          name="customer"
          label="Customer"
          options={customerOptions}
          getOptionLabel={(option) => option.name}
          required
          getPrimaryText={(option) => option.name}
          // getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={300}
          onChange={() => {
            setFieldValue("shippingItems", [{ findBy: "code" }]);

            setFieldValue("vendor", null);
            setFieldValue("incoTerms", null);
          }}
          getOptionDisabled={null}
        />

        <FormikAutocomplete
          name="vendor"
          label="Vendor"
          options={vendorOptions}
          getOptionLabel={(option) => option.name}
          required
          getPrimaryText={(option) => option.name}
          // getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={300}
          onChange={() => {
            // setFieldValue("shippingItems", [{ findBy: "code" }]);
            // setFieldValue("vendor", null);
            // setFieldValue("incoTerms", null);
          }}
          getOptionDisabled={null}
        />
        <FormikAutocomplete
          name="incoTerms"
          label="Incoterms"
          options={incoTermsOptions}
          getOptionLabel={(option) => option.terms}
          required
          getPrimaryText={(option) => option.terms}
          getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={600}
          // onChange={() => {
          //   setFieldValue("POItems", [{ itemNumber: "00010", findBy: "code" }]);

          //   setFieldValue("vendor", null);
          //   setFieldValue("incoTerms", null);
          //   setFieldValue("paymentTerms", null);
          // }}
          getOptionDisabled={null}
        />
      </Stack>
    </Stack>
  );
}
