import { useQuery, gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SimpleTable from "../../../../../component/SimpleTable";
import { ShippingByNumber } from "../../query";
import TrackingInfo from "./TrackingInfo";
import GeneratePackingBtn from "./GeneratePackingBtn";
import PGShippingDocBtn from "./PGShippingDocBtn";

export default function Packing({ number, definerID }) {
  const { data } = useQuery(gql(ShippingByNumber), {
    variables: { number },
    skip: !number,
  });

  if (!data) return null;

  const invoiceHeaders = [
    "number",
    "itemNumber",
    "code",
    "desc",
    "unit",
    "date",
    "category",
    "poNumber",
    "qty",
    "price",
    "currency",
  ];

  const packingHeaders = [
    "poNumber",
    "ctnFrom",
    "ctnTo",
    "gcas",
    "mfr",
    "mpn",
    "coo",
    "qty",
    "unit",
    "l",
    "w",
    "h",
    "gw",
    "nw",
    "dimensionUnit",
    "weightUnit",
  ];

  const shippingItems = data?.ShippingByNumber?.items?.map((item, i) => {
    return {
      id: item?.id,
      poNumber: item?.po?.no,
      itemNumber: item?.itemNumber,
      qty: item?.qty,
      coo: item?.coo,
      mpn: item?.material?.mpn,
      mfr: item?.material?.mfr,
      unit: item?.material?.unit,
      code: item?.materialEntityRelation?.code,
      poID: item?.po?.id,
      materialID: item?.material?.id,
      number: item?.itemNumber * 1 === 1 ? number : "",
      specifications: item?.material?.specifications,
      trackingNumber: item?.trackingNumber,
      courierID: item?.courierID,
      po: item?.po,
      packingDetails: item.packingDetails,
    };
  });

  console.log({ shippingItems });

  const invoices = data?.ShippingByNumber?.items
    ?.map((x) =>
      x.invoice.items.map((y) => ({
        ...y,
        po: x.po,
        materialEntityRelation: x.materialEntityRelation,
        material: x.material,
      }))
    )
    .flat()
    .map((x) => ({
      id: x.id,
      date: x.date,
      category: x.category,
      number: x.number,
      itemNumber: x.itemNumber,
      code: x.materialEntityRelation?.code || "N/A",
      desc: x?.desc || x.material?.mpn,
      poNumber: x.po?.no,
      qty: x.qty,
      price: x.price,
      currency: x.currency,
      unit: x.material?.unit,
    }));

  console.log({ invoices });

  const metaLine = data?.ShippingByNumber?.items?.find(
    (x) => x.itemNumber * 1 === 1
  );

  const { date, packingDetails, courier, trackingNumber } = metaLine || {};
  const carrier = courier?.abbr;

  // console.log({ metaLine, date, packingDetails, courier, trackingNumber });

  // const { packingDetails, carrier, trackingNumber } =
  //   data?.ShippingByNumber?.items?.find((x) => x.itemNumber * 1 === 1) || {};

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ minHeight: 120 }}
      bgcolor="#f1f1f1"
      borderRadius={1}
      padding={2}
    >
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ alignSelf: "center" }}>
            Packing And Invoicing
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            {/* <Typography variant='body2' sx={{ alignSelf: 'center' }}>
              TOTAL: {shippingItems?.reduce((a, v) => a + (v.qty || 0), 0)}
            </Typography> */}
            <TrackingInfo shippingItems={shippingItems} definerID={definerID} />
          </Stack>
        </Stack>
        <Typography variant="h5">Invoicing</Typography>
        <SimpleTable headers={invoiceHeaders} rows={invoices} />
        <Typography variant="h5">Packing</Typography>
        <SimpleTable headers={packingHeaders} rows={packingDetails} />

        <Stack justifyContent="center" direction="row" spacing={2}>
          <GeneratePackingBtn
            shippingItems={shippingItems}
            packingDetails={packingDetails}
          />
          <PGShippingDocBtn
            shippingNumber={number}
            shippingItems={shippingItems}
            packingDetails={packingDetails}
            invoices={invoices}
            date={date}
            carrier={carrier}
            trackingNumber={trackingNumber}
            type="prev"
          />
          <PGShippingDocBtn
            shippingNumber={number}
            shippingItems={shippingItems}
            packingDetails={packingDetails}
            invoices={invoices}
            date={date}
            carrier={carrier}
            trackingNumber={trackingNumber}
            type="to Agent"
          />
          <PGShippingDocBtn
            shippingNumber={number}
            shippingItems={shippingItems}
            packingDetails={packingDetails}
            invoices={invoices}
            date={date}
            carrier={carrier}
            trackingNumber={trackingNumber}
            type="to Customer"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
