import { gql } from '@apollo/client'
import mergeArrayAndCombineQty from '../../lib/mergeArrayAndCombineQty'

const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      category
      type
      number
      itemNumber
      date
      price
      qty
      amount @client
      currency
      note
      desc
      poID

      paymentStatus
      deductionDate
      definerID
      payments {
        items {
          id
          amount
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`

const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      category
      type
      paymentStatus
      createdAt
      updatedAt
      __typename
    }
  }
`

export default async function updateInvoicePaymentStatusFromArray({
  client,
  arr,
  type,
}) {
  // console.log({ arr });

  if (type !== 'deduct' && type !== 'add')
    return alert('type must be deduct or add')

  if (arr.some((x) => !x.amount || !x.invoiceID))
    return alert('please make sure all items have amount and invoiceID')

  const congregatedItems = mergeArrayAndCombineQty(
    arr,
    ['invoiceID'],
    ['paidAmount'],
  )

  await Promise.all(
    congregatedItems.map(async (x) => {
      const { invoiceID, amount } = x
      if (!invoiceID) return
      // console.log({ amount, invoiceID })
      // debugger
      const { data } = await client.query({
        query: gql(getInvoice),
        variables: { id: invoiceID },
        fetchPolicy: 'network-only',
      })

      if (!data.getInvoice) return
      
      const { amount: invoiceAmount } = data?.getInvoice

      const prevPaidAmount =
        data?.getInvoice?.payments?.items?.reduce((a, v) => a + v.amount, 0) ||
        0

      let paymentStatus

      const accumulatedPaidAmount =
        type === 'add' ? prevPaidAmount + amount : prevPaidAmount - amount

      // console.log({
      //   accumulatedPaidAmount,
      //   prevPaidAmount,
      //   amount,
      //   invoiceAmount,
      // });
      // debugger;

      if (Math.abs(accumulatedPaidAmount - invoiceAmount) < 1) {
        paymentStatus = 'PAID'
      } else if (accumulatedPaidAmount > invoiceAmount) {
        paymentStatus = 'OVERPAID'
      } else if (accumulatedPaidAmount === 0) {
        paymentStatus = 'UNPAID'
      } else {
        paymentStatus = 'PARTIAL'
      }

      // console.log({ prevPaidAmount, paidAmount, amount });

      const res = await client.mutate({
        mutation: gql(updateInvoice),
        variables: {
          input: {
            id: invoiceID,
            paymentStatus,
          },
        },
      })
      // console.log({ res });
      // debugger;
    }),
  )
}
