export const invoiceByType = /* GraphQL */ `
  query InvoiceByType(
    $type: String!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByType(
      type: $type
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        taxRate

        issuerID
        issuer {
          id
          name
          alias
          abbr @client
        }
        statusCode
        type
        no @client
        number
        itemNumber
        date
        price
        qty
        currency
        note
        desc
        poID
        amount @client
        forwarderPOID
        forwarderPO {
          id
          number
          itemNumber
          no @client
        }
        po {
          id
          no @client
          number
          itemNumber
          customer {
            id
            name
            alias
            abbr @client
          }
          vendor {
            id
            name
            alias
            abbr @client
          }
          materialEntityRelation {
            id
            code
          }
          material {
            id
            mfr
            mpn
            unit
            importDutyCN
          }
        }

        paymentDueDate
        billingEntityID
        billingEntity {
          id
          name
          alias
          abbr @client
        }
        shippingID
        paymentStatus
        deductionDate
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      category
      type
      taxRate
      number
      itemNumber
      date
      price
      qty
      currency
      note
      desc
      poID
      po {
        id
        no @client
        number
        itemNumber
        price
        qty
        date
        customer {
          id
          name
          alias
          abbr @client
        }
        vendor {
          id
          name
          alias
          abbr @client
        }
        materialEntityRelation {
          id
          code
        }
        material {
          id
          mfr
          mpn
          unit
        }
      }

      paymentDueDate
      billingEntityID

      shippingID

      paymentStatus
      deductionDate
      definerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
