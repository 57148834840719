import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../../component/formikForm";
import { currencies } from "../../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";

export default function POItemSubArrayCRD({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <FormikField
          name={`[${arrayFieldName}][${index}][CRD]`}
          label="CRD"
          required
          type="date"
        />

        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="Qty"
          type="number"
        />

        <FormikArrayRemoveItemButton
          disabled={index === 0 ? true : false}
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
