import { useEffect, useMemo } from "react";
import {
  Stack,
  Chip,
  InputAdornment,
  TextField,
  MenuItem,
} from "@mui/material";
import { currencies } from "../../../../../settings/formFieldEnum";
import { FormikCheckbox } from "../../../../../component/formikForm";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export default function Form1TP({ setFieldValue, values }) {
  const { inquiries, step } = values;

  const parts = useMemo(
    () =>
      filterOutSameItemInArray(inquiries.map((x) => x.material)?.flat(), "id"),
    [inquiries]
  );

  // console.log({ inquiries, parts });

  useEffect(() => {
    if (step !== 1) {
      setFieldValue("step", 1);
    }
  }, [setFieldValue, step]);

  return (
    <Stack>
      {parts?.length > 1 && (
        <FormikCheckbox
          label="apply first line's EAU and application to all items"
          name="applyEAUToAll"
        />
      )}
      <Stack spacing={5}>
        {parts?.map((item, index) => {
          return (
            <ItemByMPN
              key={item.id}
              item={item}
              index={index}
              setFieldValue={setFieldValue}
              values={values}
              parts={parts}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

const ItemByMPN = ({ item, index, setFieldValue, values, parts }) => {
  const { inquiries, applyEAUToAll } = values;

  useEffect(() => {
    if (
      applyEAUToAll &&
      index !== 0 &&
      (item.EAU !== parts?.[0]?.EAU ||
        item.application !== parts?.[0]?.application)
    ) {
      setFieldValue(
        "inquiries",
        inquiries.map((x) => {
          const material = x.material.map((y) => ({
            ...y,
            EAU: parts[0]?.EAU,
            application: parts[0]?.application,
          }));

          return { ...x, material };
        })
      );
    }
  }, [item, index, setFieldValue, applyEAUToAll, parts, inquiries]);

  return (
    <Stack spacing={2}>
      <Chip
        siza="small"
        label={`${item.code || ""} ${item?.mfr}-${item.mpn}`}
        color="primary"
        variant="contained"
        clickable
      />

      <Stack direction="row" spacing={4} alignItems="flex-start">
        <TextField
          disabled={index !== 0 && applyEAUToAll}
          label="EAU"
          onChange={(e) => {
            setFieldValue(
              "inquiries",
              inquiries.map((x) => {
                const material = x.material.map((y) => ({
                  ...y,
                  EAU: applyEAUToAll
                    ? e.target.value
                    : item?.id === y?.id
                    ? e.target.value
                    : y.EAU,
                }));

                return { ...x, material };
              })
            );
          }}
          value={item.EAU || ""}
          width={160}
          // required
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">
                {item.unit || "pcs"}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          disabled={index !== 0 && applyEAUToAll}
          label="Application"
          onChange={(e) => {
            setFieldValue(
              "inquiries",
              inquiries.map((x) => {
                const material = x.material.map((y) => ({
                  ...y,
                  application: applyEAUToAll
                    ? e.target.value
                    : item?.id === y?.id
                    ? e.target.value
                    : y.application,
                }));

                return { ...x, material };
              })
            );
          }}
          value={item.application || ""}
          width={240}
          // required
        />
        <TextField
          // disabled={index !== 0 && applyEAUToAll}
          label="TP"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          onChange={(e) => {
            setFieldValue(
              "inquiries",
              inquiries.map((x) => {
                const material = x.material.map((y) => ({
                  ...y,
                  TP: item?.id === y?.id ? e.target.value : y.TP,
                }));

                return { ...x, material };
              })
            );
          }}
          value={item.TP || ""}
          // width={60}
          sx={{ width: 90 }}
          // required
        />
        <TextField
          // disabled={index !== 0 && applyEAUToAll}
          label="TPCurrency"
          select
          // defaultValue="CNY"
          onChange={(e) => {
            setFieldValue(
              "inquiries",
              inquiries.map((x) => {
                const material = x.material.map((y) => {
                  return {
                    ...y,
                    TPCurrency:
                      item?.id === y?.id ? e.target.value : y.TPCurrency,
                  };
                });

                return { ...x, material };
              })
            );
          }}
          value={item.TPCurrency || ""}
          sx={{ width: 120 }}
          // required
        >
          {currencies.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
};
