import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import groupArrayofObjectsByKeyAndGenerateArr from "../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";

export default function Summary({ setFieldValue, values }) {
  let { POItem, groupedForSubmit } = values;

  const groupedByVendor = useMemo(() =>
    // filterOutSameItemInArray(POItem.map((x) => x.material)?.flat(), "id"),
    {
      const grouped = groupArrayofObjectsByKeyAndGenerateArr(
        filterOutSameItemInArray(
          POItem.map((x) => ({ ...x, materialID: x.material?.id })),
          "materialID"
        ),
        "vendorID"
      );

      return grouped;
    }, [POItem]);

  // console.log({ groupedByVendor });

  useEffect(() => {
    const numberFromDate = Date.now().toString().slice(1, 9);
    const date = new Date().toISOString("en-US").slice(0, 10);

    let arr = groupedByVendor.map(({ vendorID, items }, i) => {
      const number = (numberFromDate * 1 + i).toString().padStart(8, "0");
      const {
        buyer,
        vendorCode,
        customer,
        sales,
        vendor,
        forwarder,
        // forwarderContact,
        incoTerms,
        paymentTerms,
        emailDelivery,
        emailRecipients,
      } = items[0];

      return items.map((item, j) => {
        const { CRD, material, currency, price } = item || {};
        const { id: materialID, relationID, mpn, mfr } = material || {};

        return CRD?.map(({ CRD, qty }) => {
          return {
            date,
            number,
            emailDelivery,
            emailRecipients,
            // itemNumber: ((i + 1) * 10).toString().padStart(5, "0"),
            materialID,
            materialEntityRelationID: relationID,
            salesID: sales?.id,
            buyerID: buyer?.id,
            vendorID,
            customerID: customer.id,
            vendorCode,
            paymentTerms,
            incoTerms,
            forwarderID: forwarder?.id,
            CRD,
            price,
            qty,
            openQty: qty,
            priceCur: currency,
          };
        });
      });
    });

    arr = arr.flat(2);
    arr = groupArrayofObjectsByKeyAndGenerateArr(arr, "vendorID");
    arr = arr.map(({ vendorID, items }) => {
      return items.map((item, j) => ({
        ...item,
        itemNumber: ((j + 1) * 10).toString().padStart(5, "0"),
      }));
    });

    console.log({ arr });

    setFieldValue("groupedForSubmit", arr);
  }, [setFieldValue, groupedByVendor]);

  console.log({ groupedForSubmit });
  console.log({ groupedByVendor });

  // return null;

  return (
    <Stack spacing={5} alignItems="flex-start">
      <Typography sx={{ alignSelf: "center" }} variant="h5">
        Summary
      </Typography>

      {/* <Button
        variant="contained"
        onClick={async () => {
          const groupedForEmail = await Promise.all(
            groupedForSubmit.map(async (x) => {
              const { data: getVendorData } = await client.query({
                query: gql(getEntity),
                variables: { id: x[0].vendorID },
              });
              const {
                name: vendorName,
                address: vendorAddress,
                phoneNumber: vendorPhoneNumber,
              } = getVendorData?.getEntity || {};

              const { data: getCustomerData } = await client.query({
                query: gql(getEntity),
                variables: { id: x[0].customerID },
              });
              const {
                name: customerName,
                address: customerAddress,
                phoneNumber: customerPhoneNumber,
              } = getCustomerData?.getEntity || {};

              const { data: getSalesData } = await client.query({
                query: gql(getUser),
                variables: { id: x[0].salesID },
              });
              const {
                fn: salesFn,
                ln: salesLn,
                email: salesEmail,
                phoneNumber: salesPhoneNumber,
              } = getSalesData?.getUser;

              const { data: getBuyerData } = await client.query({
                query: gql(getUser),
                variables: { id: x[0].buyerID },
              });
              const {
                fn: buyerFn,
                ln: buyerLn,
                email: buyerEmail,
                phoneNumber: buyerPhoneNumber,
              } = getBuyerData?.getUser || {};

              const { data: entityUserDataForReceiving } = await client.query({
                query: gql(getEntityUserRelation),
                variables: { id: x[0].incoTerms?.entityUserRelationID },
              });

              const {
                fn: receiverFn,
                ln: receiverLn,
                phoneNumber: receiverPhoneNumber,
                address: receiverAddress,
              } = entityUserDataForReceiving?.getEntityUserRelation?.user;

              const { name: receiverCompanyName } =
                entityUserDataForReceiving?.getEntityUserRelation?.entity;

              let forwarderName;

              if (x[0].forwarderID) {
                const { data: getForwarderData } = await client.query({
                  query: gql(getEntity),
                  variables: { id: x[0].forwarderID },
                });
                forwarderName = getForwarderData?.getEntity?.name;
              }

              return {
                date: x[0].date,
                number: x[0].number,
                emailDelivery: x[0].emailDelivery,
                // vendorID: x[0].vendorID,
                vendorCode: x[0].vendorCode,
                vendorName,
                vendorAddress,
                vendorPhoneNumber,
                // salesID: x[0].salesID,
                salesFn,
                salesLn,
                salesEmail,
                salesPhoneNumber,
                // salesAddress: sales?.address,
                // buyerID: x[0].buyerID,
                buyerFn,
                buyerLn,
                buyerEmail,
                buyerPhoneNumber,
                // buyerAddress: buyer?.address,
                // customerID: x[0].customerID,
                customerName,
                customerAddress,
                customerPhoneNumber,

                receiverCompanyName,
                receiverFn,
                receiverLn,
                receiverPhoneNumber,
                receiverAddress,

                // forwarderID: x[0].forwarderID,
                forwarderName,
                paymentTerms: x[0].paymentTerms,
                incoTerms: x[0].incoTerms?.terms,
                items: await Promise.all(
                  x.map(async (item) => {
                    const { data: getMaterialData } = await client.query({
                      query: gql(getMaterial),
                      variables: { id: item.materialID },
                    });
                    const { mfr, mpn, unit, mpq } =
                      getMaterialData?.getMaterial || {};

                    let code;
                    if (item.materialEntityRelationID) {
                      const { data: getMaterialEntityRelationData } =
                        await client.query({
                          query: gql(getMaterialEntityRelation),
                          variables: { id: item.materialEntityRelationID },
                        });
                      code =
                        getMaterialEntityRelationData?.getMaterialEntityRelation
                          ?.code;
                    }

                    return {
                      itemNumber: item.itemNumber,
                      materialID: item.materialID,
                      materialEntityRelationID: item.materialEntityRelationID,
                      qty: item.qty,
                      price: item.price,
                      priceCur: item.priceCur,
                      CRD: item.CRD,
                      openQty: item.openQty,
                      mfr,
                      mpn,
                      unit,
                      mpq,
                      code,
                    };
                  })
                ),
              };
            })
          );

          console.log({ groupedForEmail });
        }}
      >
        Grouped For Email
      </Button> */}
      {groupedByVendor?.map(({ vendorID, items }) => {
        const {
          buyer,
          vendorCode,
          customer,
          sales,
          vendor,
          forwarder,
          // forwarderContact,
          incoTerms,
          paymentTerms,
          emailDelivery,
          emailRecipients,
        } = items[0];
        return (
          <Stack key={vendorID} spacing={2}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6">
                VENDOR:{vendor?.name} CODE: {vendorCode}{" "}
              </Typography>
              <Typography variant="h6">
                Ordering Entity:
                {customer.alias}
              </Typography>
              <Typography variant="h6">
                Email Delivery:
                {emailDelivery?.toString()}
              </Typography>
            </Stack>
            <Stack direction="row">
              {emailRecipients && (
                <>
                  <Typography>Email Recipient:</Typography>
                  {emailRecipients.map(({ name, email }) => (
                    <Typography variant="body2" key={name}>
                      {name} {email}
                    </Typography>
                  ))}
                </>
              )}
            </Stack>

            <Stack direction="row" spacing={2}></Stack>
            <Typography variant="p"> Buyer: {buyer?.name} </Typography>
            <Typography variant="p">Sales: {sales?.name}</Typography>
            <Typography variant="p"> incoTerms: {incoTerms?.terms} </Typography>
            <Typography variant="p">
              receiver: {incoTerms?.entityUserRelationID}
            </Typography>

            <Typography variant="p">
              paymentTerms:
              {paymentTerms}
            </Typography>
            <Typography variant="p"> forwarder: {forwarder?.name} </Typography>

            <Stack spacing={1}>
              {items.map((y) => {
                const { CRD, material, currency, price } = y || {};
                const { id: materialID, mpn, mfr } = material || {};

                return (
                  <Stack key={materialID}>
                    {CRD?.map((z, i) => {
                      const { CRD, qty } = z;
                      return (
                        <Stack key={i} direction="row" spacing={2}>
                          <Typography variant="h6">
                            {mfr} {mpn}
                          </Typography>
                          <Typography variant="h6">
                            {currency} {price}
                          </Typography>
                          <Typography variant="h6"> QTY: {qty}</Typography>
                          <Typography variant="h6">CRD: {CRD}</Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
