// export {default as about} from './about'
// export {default as test} from './test'
// export {default as home} from './home'
// export {default as demo} from './demo'
// export {default as plans} from './plans'
// export {default as page404} from './404'

import about from './about'
import test from './test'
import home from './home'
import demo from './demo'
import plans from './plans'
import page404 from './404'

const modules = [ home, page404]

export default modules