import { useCallback, useState } from "react";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step0ChooseItems from "./Step0ChooseInvoices";
import Step2Review from "./Step2Review";
import Step1EditItems from "./Step1EditItems";
import { useNavigate, useParams } from "react-router-dom";
import { onCreatePaymentsFromInvoices, onCreatePayment } from "../../onCreate";
import { onCreateInvoiceItem } from "../../../billing/onCreate";
import PaymentItem from "../../../outbound_payments/components/CreatePaymentStepForm/component/InvoicePaymentItem";
import BackdropLoader from "../../../../../component/BackdropLoader";
import { vatRate } from "../../../../../settings/formFieldEnum";

// import * as ReactDOMServer from "react-dom/server";

const validationSchema = Yup.object().shape({
  // findBy: Yup.string().required(),
  // parts: Yup.array()
  //   .when("findBy", {
  //     is: (value) => {
  //       return value === "byPart";
  //     },
  //     then: Yup.array()
  //       .of(Yup.object())
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 1,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           targetPrice: Yup.number(),
  //           currency: Yup.string(),
  //           // EAU: Yup.number().required("Please enter the EAU"),
  //           // application: Yup.string().required("Please enter the application"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 2,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           vendors: Yup.array()
  //             .min(1, "Please assign at least one vendor")
  //             .required("Please select at least one vendor"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   }),
  // materials: Yup.array().when("selectionType", {
  //   is: (value) => {
  //     return value === "byMaterial";
  //   },
  //   then: Yup.array()
  //     .of(Yup.object())
  //     .min(1, "Please select at lease one material")
  //     .required("Please select materials"),
  // }),
  // RFQByVendor: Yup.array().of(
  //   Yup.object({
  //     contact: Yup.object().required("Please select contact person"),
  //   })
  // ),
});

const labelOfSumbit = "CREATE PAYMENT";

export default function CreateShippingForm() {
  const { entityID } = useParams();

  const initialValues = {
    // shippingItems: [{ findBy: "code" }],
    definerID: entityID,
    date: new Date().toISOString("en-US").slice(0, 10),
    disableAddButton: true,
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Invoices",
      form: Step0ChooseItems,

      isOptional: false,
      fields: ["materials", "parts", "findBy"],
      narrow: false,
    },
    {
      step: 1,
      label: "Edit and Review",
      form: Step1EditItems,
      isOptional: true,
      fields: [""],
      formArray: [
        {
          Item: PaymentItem,
          name: "invoiceItems",
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
        },
      ],
    },
    // {
    //   step: 2,
    //   label: "Review",
    //   form: Step2Review,
    //   isOptional: false,
    //   fields: [""],
    // },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);

      const {
        date,
        definerID,
        invoiceItems,
        discounting,
        discountingProvider,
        days,
        invoices,
      } = values;

      await onCreatePaymentsFromInvoices({
        client,
        values: { invoiceItems, definerID, date },
        type: "INBOUND",
      });

      if (discounting) {
        const calculatedInvoices = invoices.map((x, i) => {
          const amount = (x.amount * (1 + vatRate))?.toFixed(3) * 1;
          const discounting =
            (
              ((amount * discountingProvider?.rate || 0) / 360) *
              (days || 0)
            ).toFixed(3) * 1;

          const net = (amount - discounting)?.toFixed(3) * 1;

          return { ...x, amount, discounting, net };
        });

        await Promise.all(
          calculatedInvoices?.map(async (x) => {
            const invoiceID = uuidv4();

            const res = await onCreateInvoiceItem({
              client,
              values: {
                id: invoiceID,
                type: "INBOUND",
                category: "DISCOUNTING",
                number: "TBD",
                itemNumber: 1,
                price: x.discounting,
                qty: 1,
                desc: `${x.number} discounting`,
                statusCode: "100",
                definerID,
                paymentStatus: "PAID",
                issuerID: discountingProvider?.id,
              },
            });

            const res2 = await onCreatePayment({
              client,
              values: {
                date,
                invoiceID,
                type: "OUTBOUND",
                amount: x.discounting,
                desc: `${x.number} discounting`,
                payeeID: discountingProvider?.id,
                currency: "CNY",
                definerID,
              },
            });
          })
        );
      }

      setIsLoading(false);

      navigate(-1);
    },
    [navigate]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
