import { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Button from "@mui/material/Button";

export default function BackdropoLoader({ isLoading, minLoadingTime = 2000 }) {
  const startTime = useRef(Date.now());

  const [open, setOpen] = useState(isLoading);

  useEffect(() => {
    let timeoutID;

    if (isLoading) {
      if (!open) {
        setOpen(true);
      }
    } else if (Date.now() > startTime.current + minLoadingTime) {
      setOpen(false);
    } else {
      timeoutID = setTimeout(
        () => setOpen(false),
        minLoadingTime - (Date.now() - startTime.current)
      );
    }

    return () => {
      clearTimeout(timeoutID);
    };
  }, [isLoading, open, minLoadingTime]);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
