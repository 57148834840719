import { useState } from "react";
import { Hub } from "aws-amplify";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import FileZone from "../../../component/FileZone";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import Item from "./components/Item";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import CreateForm from "./components/CreateForm";
import ChartView from "../../../component/ChartView";

import { getPO, POByDefinerID } from "./query";
import { POByNumber } from "../SPOs/query";
import onDeletePO from "./onDelete";
import { POStatusCode, vatRate } from "../../../settings/formFieldEnum";
import { userEntityRelationVar } from "../../../client/cache";
import ViewByMaterial from "./components/ViewByMaterial";
import Calibrating from "./components/Calibrating";

function ItemView({ lineData: { id }, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getPO), {
    variables: { id },
  });
  data = data?.getPO;

  if (!data) return null;

  const storagePath = `${entityID}/CPO/${data?.number}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={data} onClose={onClose} />
        <ChartView
          currentID={id}
          query={POByNumber}
          variables={{ number: data.number }}
        />
        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const [isViewByMaterial, setIsViewByMaterial] = useState(false);
  const [isCalibrating, setIsCalibrating] = useState(false);

  const isAdmin = userRole?.includes("admin");
  const { subsidiaryIDs } = useReactiveVar(userEntityRelationVar);

  const IDs = [entityID, ...subsidiaryIDs];

  const queryVariables = {
    definerID: entityID,
    filter: {
      or: IDs.map((id) => ({
        vendorID: { eq: id },
      })),
    },
  };
  if (isViewByMaterial) {
    return <ViewByMaterial setIsViewByMaterial={setIsViewByMaterial} />;
  }
  if (isCalibrating) {
    return (
      <Calibrating
        entityID={entityID}
        vendorIDs={IDs}
        setIsCalibrating={setIsCalibrating}
      />
    );
  }

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "sales"],
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin", "sales"],
    },
    {
      field: "number",
      headerName: "Number",
      width: 180,
      userRole: ["admin", "sales"],
    },
    {
      field: "itemNumber",
      headerName: "Item",
      width: 90,
      userRole: ["admin", "sales"],
    },

    {
      field: "vendor",
      headerName: "Vendor",
      width: 180,
      userRole: ["admin", "sales"],
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      userRole: ["admin", "sales"],
    },

    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "sales"],
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "sales"],
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      userRole: ["admin", "sales"],
    },
    {
      field: "openQty",
      headerName: "Open Qty",
      width: 150,
      userRole: ["admin", "sales"],
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      userRole: ["admin", "sales"],
    },
    {
      field: "priceCur",
      headerName: "Currency",
      width: 100,
      userRole: ["admin", "sales"],
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      userRole: ["admin", "sales"],
    },
    {
      field: "CRD",
      headerName: "CRD",
      width: 140,
      userRole: ["admin", "sales"],
    },
    {
      field: "statusCode",
      headerName: "statusCode",
      width: 140,
      userRole: ["admin", "sales"],
      renderCell: (params) => {
        const label = POStatusCode.find((x) => x.value === params.value)?.label;
        return <Typography>{label}</Typography>;
      },
    },
    {
      field: "incoTerms",
      headerName: "IncoTerms",
      width: 200,
      userRole: ["admin", "sales"],
      renderCell: ({ row, ...props }) => {
        return (
          <Stack direction="row" spacing={2}>
            <Typography>{row?.incoTerms.terms}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "paymentTerms",
      headerName: "PaymentTerms",
      width: 200,
      userRole: ["admin", "sales"],
    },
    {
      field: "billingStatus",
      headerName: "billingStatus",
      width: 140,
      userRole: ["admin", "sales"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeletePO({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
    {
      label: "Batch Update",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        Hub.dispatch("dialogMonitor", {
          event: "batchUpdate",
          data: {
            selectionModel,
            setSelectionModel,
            attrs: [
              "price",
              "priceCur",
              "paymentTerms",
              "materialEntityRelationID",
              "statusCode",
            ],
            model: "CPO",
          },
        });
        // await onDeletePO({
        //   selectionModel,
        //   setSelectionModel,
        //   client,
        // });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = ({ items }) => {
    return items.map((x) => {
      return {
        ...x,
        openQty: x.openQty.toFixed(3) * 1,
        customer: x.customer?.alias || x.customer?.name,
        vendor: x.vendor?.alias || x.vendor?.name,
        code: x.materialEntityRelation?.code,
        mfr: x.material?.mfr,
        mpn: x.material?.mpn,
      };
    });
  };

  const toolbarFieldAddon = [
    {
      comp: null,
      element: (
        <Button
          onClick={() => {
            setIsCalibrating(true);
          }}
          variant="contained"
        >
          CALIBRATING
        </Button>
      ),
      userRole: ["admin"],
    },
    {
      comp: null,
      element: (
        <Button
          onClick={() => {
            setIsViewByMaterial(true);
          }}
          variant="contained"
        >
          VIEW BY MATERIAL
        </Button>
      ),
      userRole: ["admin", "sales"],
    },
  ];

  const filteredToolbarFieldAddon = getFilteredItemsByUserRole(
    toolbarFieldAddon,
    userRole
  );

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber > b.itemNumber ? -1 : 1));
    arr.sort((a, b) => (a.date < b.date ? 1 : -1));
    return arr;
  };

  const filterAttrs = [
    { label: "date", value: "date", type: "date" },
    { label: "number", value: "number", type: "string" },

    {
      label: "code",
      value: "code",
      type: "string",
    },
    { label: "mfr", value: "mfr", type: "string" },
    { label: "mpn", value: "mpn", type: "string" },
    { label: "openQty", value: "openQty", type: "number" },

    { label: "customer", value: "customer", type: "string" },
  ];

  return (
    <DataGridComp
      query={POByDefinerID}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={isAdmin ? ItemView : null}
      checkboxSelection={isAdmin ? true : null}
      userRole={userRole}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
      toolbarFieldAddon={filteredToolbarFieldAddon}
    />
  );
}

const CPOs = {
  path: "cpos",

  sideNav: {
    group: 3,
    order: 30,
    label: "Incoming POs",
    entityType: ["200", "300"],
    allowedRole: ["admin", "sales"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["admin", "sales"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default CPOs;

function ToolbarInsert({ selectionModel }) {
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          QTY (ALL/OPEN):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel.reduce((a, v) => a + (v?.qty || 0), 0).toFixed(3) * 1}
          /{" "}
          {selectionModel
            .reduce((a, v) => a + (v?.openQty || 0), 0)
            .toFixed(3) * 1}
        </Typography>
      </Stack>

      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          VALUE (ALL/OPEN):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel
            .reduce((a, v) => a + (v?.qty * v?.price || 0), 0)
            .toFixed(3) * 1}{" "}
          /
          {selectionModel
            .reduce((a, v) => a + (v?.openQty * v?.price || 0), 0)
            .toFixed(3) * 1}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          W.VAT (ALL/OPEN):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {(
            selectionModel
              .reduce((a, v) => a + (v?.qty * v?.price || 0), 0)
              .toFixed(3) *
            (1 + vatRate)
          ).toFixed(2)}
          /{" "}
          {(
            selectionModel
              .reduce((a, v) => a + (v?.openQty * v?.price || 0), 0)
              .toFixed(3) *
            (1 + vatRate)
          ).toFixed(2)}
        </Typography>
      </Stack>
    </Stack>
  );
}
