const groupArrayofObjectsByKeyAndGenerateObj = (arr, property) => {
  return arr.reduce((acc, obj) => {
    const key = obj[property]?.value || obj[property]?.label || obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export default groupArrayofObjectsByKeyAndGenerateObj
