import { useCallback,useState } from "react";
import { API } from "aws-amplify";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import StepForm from "../../../../../component/formikForm/StepForm";
import Form0SelectItem from "./Form0SelectItem";
import Form1TP from "./Form1TP";
import Form2Vendor from "./Form2Vendor";
import Form3Contact from "./Form3Contact";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import { onCreateInquiry } from "../../onCreate";
import InquiryItem from "./Array/InquiryItem";
import BackdropLoader from "../../../../../component/BackdropLoader";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import groupArrayofObjectsByKeyAndGenerateArr from "../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";

const validationSchema = Yup.object().shape({
  inquiryValidDays: Yup.number().min(1).required("Required"),
  step: Yup.number().required(),
  inquiries: Yup.array()
    .when("step", {
      is: (value) => value === 0,
      then: Yup.array().of(
        Yup.object().shape({
          material: Yup.array()
            .of(
              Yup.object().shape({
                vendors: Yup.array(),
              })
            )
            .min(1, "Please select at least one material")
            .required("Please select material")
            .nullable(),
        })
      ),
    })
    .when("step", {
      is: (value) => value === 2,
      then: Yup.array().of(
        Yup.object().shape({
          material: Yup.array()
            .of(
              Yup.object().shape({
                vendors: Yup.array()
                  .min(1, "Please select at least one vendor")
                  .required("Please select vendor")
                  .nullable(),
              })
            )
            .min(1, "Please select at least one material")
            .required("Please select material")
            .nullable(),
        })
      ),
    })
    .when("step", {
      is: (value) => value === 3,
      then: Yup.array().of(
        Yup.object().shape({
          material: Yup.array()
            .of(
              Yup.object().shape({
                vendors: Yup.array()
                  .of(
                    Yup.object().shape({
                      contacts: Yup.array()
                        .min(1, "Please select at least one contact")
                        .required("Please select contact")
                        .nullable(),
                    })
                  )

                  .min(1, "Please select at least one vendor")
                  .required("Please select vendor")
                  .nullable(),
              })
            )
            .min(1, "Please select at least one material")
            .required("Please select material")
            .nullable(),
        })
      ),
    })
    .required("at least one item"),

  // inquiries: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       material: Yup.array()
  //         .of(
  //           Yup.object().shape({
  //             vendors: Yup.array(),
  //           })
  //         )
  //         .min(1, "Please select at least one material")
  //         .required("Please select material")
  //         .nullable(),
  //     })
  //   )
  //   .required("at least one item"),
});

const labelOfSumbit = "SEND INQUIRY";

export default function CreateInquiryStepForm() {
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);
  // }, []);

  const { entityID } = useParams();

  const initialValues = {
    inquiries: [{}],
    inquiryValidDays: 7,
    applyEAUToAll: true,
    definerID: entityID,
    disableMultiple: false,
    isEditing: true,
  };

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Material",
      form: Form0SelectItem,
      formArray: [
        {
          Item: InquiryItem,
          name: "inquiries",
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
        },
      ],

      isOptional: false,
      fields: ["inquiryValidDays", "inquiries"],
    },
    {
      step: 1,
      label: "TP and Currency",
      form: Form1TP,
      isOptional: true,
      fields: [],
    },
    {
      step: 2,
      label: "Select Vendor",
      form: Form2Vendor,
      isOptional: false,
      fields: ["inquiries"],
    },
    {
      step: 3,
      label: "Select Contact",
      form: Form3Contact,
      isOptional: false,
      fields: ["inquiries"],
    },
    {
      step: 4,
      label: "Summary",
      form: Summary,
      isOptional: false,
      fields: [],
    },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);
      const { inquiryValidDays, inquiries } = values;

      const parts = filterOutSameItemInArray(
        inquiries.map((x) => x.material)?.flat(),
        "id"
      );

      // const byVendorRelation = parts
      //   .map((x) => {
      //     const { id: partID, code, relationID, materialID, mfr, mpn } = x;

      //     return x.vendors.map((y) => {
      //       const {
      //         id: relationsID,
      //         relation,
      //         entity1,
      //         entity2,

      //         vendorCode,
      //         contacts,
      //       } = y;

      //       return {
      //         part: { id: partID, code, relationID, materialID, mfr, mpn },
      //         vendorRelation: {

      //           customerID:entity1.id,
      //           customerName:entity1.name,
      //           customerAlias:entity1.alias,
      //           vendorID:entity2.id,
      //           vendorName:entity2.name,
      //           vendorAlias:entity2.alias,
      //           vendorCode,
      //           vendorContacts:contacts,
      //         },
      //       };
      //     });
      //   })
      //   .flat();

      const byContact = parts
        .map((part) => {
          const {
            id: partID,
            EAU,
            application,
            TP,
            TPCurrency,
            code,
            relationID,
            materialID,
            mfr,
            mpn,
          } = part;

          return part.vendors.map((vendorRelation) => {
            const {
              id: relationsID,
              relation,
              entity1,
              entity2,

              vendorCode,
            } = vendorRelation;

            return vendorRelation.contacts.map((contact) => {
              const { id, name, sub, fn, ln, email, entityID } = contact;
              return {
                contactID: id,
                contact: { id, name, fn, ln, email, entityID, sub },
                part: {
                  id: partID,
                  code,
                  relationID,
                  materialID,
                  mfr,
                  mpn,
                  EAU,
                  application,
                  TP,
                  TPCurrency,
                  inquiryID: uuidv4,
                },
                vendorRelation: {
                  customerID: entity1.id,
                  customerName: entity1.name,
                  customerAlias: entity1.alias,
                  vendorID: entity2.id,
                  vendorName: entity2.name,
                  vendorAlias: entity2.alias,
                  vendorCode,
                },
              };
            });
          });
        })
        .flat(2);

      const number = Date.now().toString().slice(1, 9);

      const groupByContact = groupArrayofObjectsByKeyAndGenerateArr(
        byContact,
        "contactID"
      );

      groupByContact.forEach((x, i) => {
        x.number = (number * 1 + i).toString().padStart(8, "0");
        x.inquiryValidDays = inquiryValidDays;
      });

      // console.log({ parts, byContact, groupByContact });

      await Promise.all(
        groupByContact.map(async (x, i) => {
          await Promise.all(
            x.items?.map(async (item, i) => {
              const values = {
                id: item.part.inquiryID,
                date: new Date().toISOString("en-US")?.substring(0, 10),
                number: x.number,
                itemNumber: ((i + 1) * 10).toString().padStart(5, "0"),
                materialID: item.part.materialID || item.part.id,
                materialEntityRelationID: item.part.relationID,
                salesID: item.contactID,
                vendorID: item.vendorRelation.vendorID,
                customerID: item.vendorRelation.customerID,
                definerID: entityID,
                TP: item.part.TP || undefined,
                TPCurrency: item.part.TPCurrency,
                EAU: item.part.EAU,
                application: item.part.application,
                inquiryValidDays,
              };

              await onCreateInquiry({ values, client });
            })
          );
        })
      );

      // debugger;

      const res = await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "SEND_RFQ",
          data: groupByContact,
        },
      });
      console.log({ res });
      setIsLoading(false);

      navigate(-1);
    },
    [navigate, entityID]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} minLoadingTime={1000} />
    </>
  );
}
