import { useQuery, gql } from "@apollo/client";
import { Box, Stack, Typography, Button } from "@mui/material";
import BackdropoLoader from "../../../../../component/BackdropLoader";
import SimpleTable from "../../../../../component/SimpleTable";

const POByDefinerID = /* GraphQL */ `
  query POByDefinerID(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByDefinerID(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount @client
        statusCode
        number
        itemNumber
        no @client
        date
        offerID
        customerID
        customer {
          id
          name
          alias
          abbr @client
        }
        vendorID
        vendor {
          id
          name
          alias
          abbr @client
        }
        definerID
        materialID
        material {
          id
          mfr
          mpn
        }
        buyerID
        salesID
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        shippings {
          items {
            id
            qty
          }
        }
        qty
        openQty
        price
        priceCur
        paymentTerms

        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function Calibrating({ entityID, vendorIDs, setIsCalibrating }) {
  const { data, loading } = useQuery(gql(POByDefinerID), {
    variables: {
      definerID: entityID,
      filter: {
        and: {
          statusCode: { ne: "900" },
          or: vendorIDs.map((id) => ({
            vendorID: { eq: id },
          })),
        },
      },
    },
  });

  let itemsNeedAttention = [];

  data?.POByDefinerID?.items
    ?.filter((x) => x.openQty > 0)
    .forEach((x) => {
      const shipmemts = x.shippings.items;
      const shippedQty = shipmemts?.reduce((a, v) => a + v.qty, 0);

      if (shippedQty && Math.abs(x.qty - x.openQty - shippedQty) > 1) {
        // console.log("shippedQty", x.qty - x.openQty);
        // console.log("shippedQty2", shippedQty);

        // console.log({ shippedQty, qty: x.qty, openQty: x.openQty });
        itemsNeedAttention.push({
          date: x.date,
          mfr: x.material?.mfr,
          mpn: x.material?.mpn,
          code: x.materialEntityRelation?.code,
          id: x.id,
          no: x.no,
          qty: x.qty,
          openQty: x.openQty,
          shipmemts,
          shippedQty,
        });
      }
    });

  const headers = [
    "date",
    "no",
    "mfr",
    "mpn",
    "code",
    "qty",
    "openQty",
    "shippedQty",
  ];

  if (loading) return <BackdropoLoader isLoading={loading} />;

  return (
    <Box width="100%" height="100%">
      <Stack spacing={3} p={2}>
        <Button
          size="small"
          sx={{ height: "40px", alignSelf: "flex-start" }}
          variant="contained"
          onClick={() => {
            setIsCalibrating(false);
          }}
        >
          return to Table View
        </Button>
        {itemsNeedAttention?.length > 0 ? (
          <SimpleTable headers={headers} rows={itemsNeedAttention} />
        ) : (
          <Typography>No Mismatch</Typography>
        )}
      </Stack>
    </Box>
  );
}
