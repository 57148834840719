import { useEffect, useState } from "react";
import {
  Stack,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { gql, useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import { entityRelation } from "../../../../../../settings/formFieldEnum";

import {
  FormikField,
  FormikCheckbox,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikSelect,
} from "../../../../../../component/formikForm";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";

const entitiesByDefiner = /* GraphQL */ `
  query EntitiesByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isVerified
        name
        alias
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        paymentTerms
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function RelationForEntityCreation({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  // const entityID = params?.entityID;

  const { values, setFieldValue } = useFormikContext();

  const item = values?.[arrayFieldName]?.[index];
  let { definerID, paymentTerms, incoTerms } = values || {};

  const { relationType, thisEntityIs, theOtherEntity } = item || {};

  if (relationType === "CUSTOMER-SUPPLIER") {
    if (thisEntityIs === "CUSTOMER") {
      paymentTerms = paymentTerms?.filter((x) => typeof x === "string");
      // incoTerms = incoTerms?.filter((x) => !!x.terms);
    } else {
      paymentTerms = theOtherEntity?.paymentTerms;
      incoTerms = theOtherEntity?.incoTerms;
    }
  }

  const { data: entityByDefinerData } = useQuery(gql(entitiesByDefiner), {
    variables: { definerID: values?.definerID },
  });

  const entityOptions = entityByDefinerData?.entitiesByDefiner?.items;

  const autoCompleteLabel =
    thisEntityIs && relationType?.split("-").find((x) => x !== thisEntityIs);

  // useEffect(() => {
  //   setFieldValue(
  //     `[${arrayFieldName}][${index}][hideSubArray]`,
  //     relationType === "CUSTOMER-SUPPLIER" ? false : true
  //   );
  // }, [relationType, arrayFieldName, index, setFieldValue]);

  useEffect(() => {
    if (thisEntityIs && !theOtherEntity) {
      setFieldValue(
        `[${arrayFieldName}][${index}][theOtherEntity]`,
        entityOptions?.find((x) => x.id === definerID)
      );
    }
  }, [
    item,
    arrayFieldName,
    index,
    definerID,
    theOtherEntity,
    thisEntityIs,
    setFieldValue,
    entityOptions,
  ]);

  return (
    <Stack direction="row">
      <Stack spacing={1}>
        <Stack direction="row" width={1800} spacing={2} alignItems="flex-end">
          <FormikSelect
            label="Relation Type"
            name={`[${arrayFieldName}][${index}][relationType]`}
            // multiple
            options={entityRelation}
            width={300}
            // onChange={(e) => {
            //   // can over ride the default onchange logic
            //   // setFieldValue(`[${arrayFieldName}][${index}]`, {
            //   //   relationType: e,
            //   // });
            // }}
          />

          <FormikSelect
            label="This entity is"
            name={`[${arrayFieldName}][${index}][thisEntityIs]`}
            // multiple
            options={relationType?.split("-")}
            disabled={!relationType}
            width={200}
            onChange={(e) => {
              setFieldValue(
                `[${arrayFieldName}][${index}][theOtherEntity]`,
                null
              );
              setFieldValue(
                `[${arrayFieldName}][${index}][paymentTerms]`,
                null
              );
              setFieldValue(`[${arrayFieldName}][${index}][incoTerms]`, null);
            }}
          />

          <Typography variant="h6" width={200} sx={{ textAlign: "right" }}>
            {autoCompleteLabel && `${autoCompleteLabel} is `}
          </Typography>
          <FormikAutocomplete
            width={500}
            name={`[${arrayFieldName}][${index}][theOtherEntity]`}
            label={
              (autoCompleteLabel && `Please select ${autoCompleteLabel}`) || ""
            }
            required
            options={entityOptions || []}
            disabled={!entityOptions || entityOptions.length === 0}
            // isOptionEqualToValue={(option, value) =>{} }
            getOptionLabel={(option) => option?.alias || option?.name}
            getPrimaryText={(option) => option?.alias || option?.name}
            // getOptionDisabled={(option) =>
            //   values.incoTerms.some((x) => x?.terms === option?.terms)
            // }
          />
        </Stack>

        {/* <Stack></Stack> */}

        {relationType === "CUSTOMER-SUPPLIER" && (
          <Stack direction="row" spacing={3}>
            {thisEntityIs === "CUSTOMER" &&
            (!paymentTerms || paymentTerms?.length === 0) ? (
              <Typography color="red" variant="h5">
                Please add payment terms in the previous step
              </Typography>
            ) : (
              <FormikAutocomplete
                width={500}
                name={`[${arrayFieldName}][${index}][paymentTerms]`}
                label="paymentTerms"
                required
                multiple
                options={paymentTerms || []}
                disabled={!paymentTerms || paymentTerms.length === 0}
                // isOptionEqualToValue={(option, value) => option === value}
                // getOptionLabel={(option) => option?.terms || ""}
                // getPrimaryText={(option) => option?.terms}
                // getOptionDisabled={(option) =>
                //   fieldValue === option || subRelations?.some((x) => x === option)
                // }
              />
            )}
            {thisEntityIs === "CUSTOMER" &&
            (!incoTerms || incoTerms?.length === 0) ? (
              <Typography color="red" variant="h5">
                Please add inco terms in the previous step
              </Typography>
            ) : (
              <FormikAutocomplete
                width={500}
                name={`[${arrayFieldName}][${index}][[incoTerms]]`}
                label="incoterms"
                required
                multiple
                options={incoTerms || []}
                disabled={!incoTerms || incoTerms.length === 0}
                isOptionEqualToValue={(option, value) =>
                  (option?.refID && option.refID === value?.refID) ||
                  option?.terms === value?.terms
                }
                getOptionLabel={(option) => {
                  const termsFound = incoTerms.find(
                    (x) => x.refID === option.refID
                  );

                  return `${termsFound.terms}` || "";
                }}
                getPrimaryText={(option) => option?.terms}
                // getOptionDisabled={(option) =>
                //   fieldValue === option ||
                //   subRelations?.some((x) => x?.terms === option?.terms)
                // }
              />
            )}
            <FormikField
              name={`[${arrayFieldName}][${index}][vendorCode]`}
              label="vendorCode"
            />
          </Stack>
        )}
      </Stack>
      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        name={arrayFieldName}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}
