import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export const MaterialsByDefiner = /* GraphQL */ `
  query MaterialsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        mpn
        mfr
      }
      nextToken
      __typename
    }
  }
`;
export const offerByMaterialEntityRelation = /* GraphQL */ `
  query OfferByMaterialEntityRelation(
    $materialEntityRelationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByMaterialEntityRelation(
      materialEntityRelationID: $materialEntityRelationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        itemNumber
        statusCode
        materialID
        materialEntityRelationID
        salesID
        buyerID
        vendorID
        customerID
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
          entityUserRelationID
        }
        paymentTerms
      }
      nextToken
      __typename
    }
  }
`;

export const offerByMaterial = /* GraphQL */ `
  query OfferByMaterial(
    $materialID: ID!
    $customerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByMaterial(
      materialID: $materialID
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        itemNumber
        statusCode
        materialID
        materialEntityRelationID
        salesID
        buyerID
        vendorID
        customerID
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
          entityUserRelationID
        }
        paymentTerms
      }
      nextToken
      __typename
    }
  }
`;
export default function POItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { codes, customer, definerID, POItems } = values;
  const { code, findBy, material } = POItems[index] || {};

  const fileredCodes = filterOutSameItemInArray(codes, "code").map(
    (x) => x.code
  );

  const materialOptions = useMemo(
    () => codes?.filter((x) => x.code === code),
    [codes, code]
  );

  const { data } = useQuery(gql(MaterialsByDefiner), {
    variables: { definerID, limit: 5000 },
  });

  const MPNOptions = data?.MaterialsByDefiner?.items;

  useEffect(() => {
    setFieldValue(
      `[${arrayFieldName}][${index}][itemNumber]`,
      ((index + 1) * 100).toString().padStart(5, "0")
    );
  }, [arrayFieldName, setFieldValue, index]);

  useEffect(() => {
    if (materialOptions?.length === 1) {
      setFieldValue(
        `[${arrayFieldName}][${index}][material]`,
        materialOptions[0]
      );
    }
  }, [materialOptions, setFieldValue, arrayFieldName, index]);

  function onSelectOffer(offer) {
    // console.log({ offer });
    setFieldValue(`[${arrayFieldName}][${index}][currency]`, offer.currency);
    setFieldValue(`[${arrayFieldName}][${index}][price]`, offer.price);
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Typography>
          item: {values[arrayFieldName][index]?.itemNumber}
        </Typography>
        <FormikRadioGroup
          name={`[${arrayFieldName}][${index}][findBy]`}
          label="Find material by"
          selections={[
            { label: "customer's code", value: "code" },
            { label: "mpn", value: "mpn" },
          ]}
          row
          onChange={(e) => {
            setFieldValue(`[${arrayFieldName}][${index}][code]`, null);
            setFieldValue(`[${arrayFieldName}][${index}][material]`, null);
          }}
        />
        <Offers
          relationID={material?.relationID}
          materialID={material?.id}
          customerID={customer?.id}
          onSelectOffer={onSelectOffer}
        />
      </Stack>

      <Stack direction="row" spacing={2} alignItems="flex-end">
        {findBy === "code" ? (
          <Stack direction="row" spacing={3}>
            <FormikAutocomplete
              name={`[${arrayFieldName}][${index}][code]`}
              label="Code"
              required
              options={fileredCodes}
              // getOptionLabel={(option) =>
              //   `${option.code} (${option.mfr} - ${option.mpn})`
              // }
              // getPrimaryText={(option) => option.code}
              // getSecondaryText={(option) => `${option.mfr} - ${option.mpn}`}
              listItemPrimaryField={null}
              listItemSecondaryField={null}
              disabled={false}
              width={360}
              onChange={(e) => {
                setFieldValue(`[${arrayFieldName}][${index}][material]`, null);
                setFieldValue(`[${arrayFieldName}][${index}][price]`, null);
                setFieldValue(`[${arrayFieldName}][${index}][currency]`, null);
              }}
              getOptionDisabled={null}
            />
            <FormikAutocomplete
              name={`[${arrayFieldName}][${index}][material]`}
              label="material"
              required
              options={materialOptions}
              getOptionLabel={(option) => option.mfr + " " + option.mpn}
              getPrimaryText={(option) => option.mfr + " " + option.mpn}
              width={240}
              onChange={(e) => {
                setFieldValue(`[${arrayFieldName}][${index}][price]`, null);
                setFieldValue(`[${arrayFieldName}][${index}][currency]`, null);
              }}
            />
          </Stack>
        ) : (
          <FormikAutocomplete
            name={`[${arrayFieldName}][${index}][material]`}
            label="material"
            required
            options={MPNOptions}
            getOptionLabel={(option) => option.mfr + " " + option.mpn}
            getPrimaryText={(option) => option.mfr + " " + option.mpn}
            width={240}
          />
        )}

        <FormikSelect
          label="Currency"
          required
          name={`[${arrayFieldName}][${index}][currency]`}
          options={currencies}
          labelKey="label"
          valueKey="value"
          width={120}
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][price]`}
          label="Price"
          type="number"
        />
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="Qty"
          type="number"
        />
        <FormikField
          width={200}
          name={`[${arrayFieldName}][${index}][CRD]`}
          label="CRD"
          type="date"
        />
        <FormikArrayRemoveItemButton
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}

function Offers({ relationID, materialID, customerID, onSelectOffer }) {
  const { data: offerByMaterialData } = useQuery(gql(offerByMaterial), {
    variables: { materialID, customerID: { eq: customerID } },
    skip: !materialID || !customerID,
  });

  const { data: offerByMaterialEntityRelationData } = useQuery(
    gql(offerByMaterialEntityRelation),
    {
      variables: { materialEntityRelationID: relationID },
      skip: !relationID,
    }
  );

  const offerByMaterialItems = offerByMaterialData?.offerByMaterial?.items;

  const offerByMaterialEntityRelationItems =
    offerByMaterialEntityRelationData?.offerByMaterialEntityRelation?.items;

  const offers = [
    ...(offerByMaterialItems || []),
    ...(offerByMaterialEntityRelationItems || []),
  ];
  const filteredOffers = filterOutSameItemInArray(offers, "id");

  return (
    <Stack>
      {filteredOffers?.map((offer) => (
        <Stack
          key={offer.id}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // width={350}
          p={1}
          bgcolor="blue"
          borderRadius={2}
        >
          <Typography color="white" width={260}>
            Price: {offer.currency} {offer.price} on {offer.date}
          </Typography>
          <Button
            size="small"
            variant="contained"
            onClick={() => onSelectOffer(offer)}
          >
            select
          </Button>
        </Stack>
      ))}
    </Stack>
  );
}
