import { useEffect, useMemo } from "react";
import { Box, Button } from "@mui/material";
import FormikArray from "../FormikArray";

export default function FormComp({
  steps,
  activeStep,
  props,
  setIsNextButtonDisabled,
  setActiveStep,
}) {
  const { setTouched, errors, touched, values } = props;

  const [StepComp, formArray] = useMemo(() => {
    const step = steps[activeStep];
    const form = step.form || (() => <Button>back</Button>);
    const formArray =
      typeof step.formArray === "function"
        ? step.formArray(values)
        : step.formArray;
    return [form, formArray];
  }, [activeStep, steps, values]);

  const formFields = steps[activeStep].fields;

  useEffect(() => {
    const fieldWithError = Object.keys(errors);
    // const touchedFields = Object.keys(touched);

    const hasError = formFields?.some((x) => fieldWithError.includes(x));
    // const hasTouched = formFields.some((x) => touchedFields.includes(x));

    // console.log({ errors, touched });

    // console.log({ hasError111: hasError });
    if (!hasError) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
    // else if (hasTouched) {
    //   setIsNextButtonDisabled(true);
    // }
  }, [touched, errors, setTouched, formFields, setIsNextButtonDisabled]);

  return (
    <Box
      sx={{
        p: 5,
        borderRadius: 1,
        bgcolor: "#f0f0f0",
        minWidth: 600,
      }}
    >
      {/* <Button
        onClick={() => {
          const res = props.setErrors({});
          console.log({ res });
        }}
      >
        reset error
      </Button> */}
      <StepComp {...props} setActiveStep={setActiveStep} />

      {!values.hideArray &&
        formArray?.map((x) => {
          // console.log({ ppppppx: x });

          let disabled = false;

          if (x.disabled) {
            disabled = x.disabled(values);
          }

          return !disabled ? (
            <FormikArray
              defaultValue={x.defaultValue}
              key={x.name}
              name={x.name}
              Item={x.Item}
              width={x.width}
              subArray={x.subArray}
              bgcolor={x.bgcolor}
              btnColor={x.btnColor}
              size={x.size}
              disableAdd={x.disableAdd}
              narrow={x.narrow}
            />
          ) : null;
        })}
    </Box>
  );
}
