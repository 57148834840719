import { gql } from "@apollo/client";
import mergeArrayAndCombineQty from "../../lib/mergeArrayAndCombineQty";

const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      definerID

      mpn
      mfr
      coo

      desc
      unit
      leadtime
      mpq
      transitDays

      inventory
      createdAt
      updatedAt
      __typename
    }
  }
`;

const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      definerID

      mpn
      mfr
      coo

      desc
      unit
      leadtime
      mpq
      transitDays
      hsCode
      importDutyCN

      groupToRead
      groupToEdit
      groupToDelete
      isDeleted
      statusCode
      inventory
      createdAt
      updatedAt
      __typename
    }
  }
`;

export default async function updateInvenotryFromArray({ client, arr, type }) {
  if (type !== "deduct" && type !== "add")
    return alert("type must be deduct or add");

  if (arr.some((x) => !x.qty || !x.materialID))
    return alert("please make sure all items have qty and materialID");

  const congregatedArr = mergeArrayAndCombineQty(arr, ["materialID"], ["qty"]);

  await Promise.all(
    congregatedArr.map(async (x) => {
      const { materialID, qty } = x;
      const { data } = await client.query({
        query: gql(getMaterial),
        variables: { id: materialID },
      });
      const { inventory } = data?.getMaterial;
      const newInventoryQty =
        type === "deduct" ? (inventory || 0) - qty : (inventory || 0) + qty;

      // console.log({ newInventoryQty, type });
      // debugger;

      const res = await client.mutate({
        mutation: gql(updateMaterial),
        variables: {
          input: {
            id: materialID,
            inventory: newInventoryQty,
          },
        },
      });
      // console.log({ res });
    })
  );
}
