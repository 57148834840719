import { onDelete } from "../../../function/onDelete";
import { deleteInvoice } from "./query";
import { gql } from "@apollo/client";

// import updatePOOpenQtyFromArray from "../../../function/utilityAPI/updatePOOpenQtyFromArray";
// import updateInvenotryFromArray from "../../../function/utilityAPI/updateInvenotryFromArray";

export default async function onDeleteInvoice({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }

  // await updatePOOpenQtyFromArray({
  //   client,
  //   arr: selectionModel || [values],
  //   type: "add",
  // });

  // await updateInvenotryFromArray({
  //   client,
  //   arr: selectionModel || [values],
  //   type: "add",
  // });
}

async function deleteSingleItem(item, client) {
  // console.log({ item, client });
  const { id, poID, number } = item || {};

  await onDelete({
    input: { id },
    mutation: deleteInvoice,
    // updateCacheFields: ["relationsByCustomerID"],
    client,
    storagePath: number,
  });


  if (!poID) return;
  // UPDATE PO BILLING STATUS

  await client.mutate({
    mutation: gql(/* GraphQL */ `
      mutation UpdatePO(
        $input: UpdatePOInput!
        $condition: ModelPOConditionInput
      ) {
        updatePO(input: $input, condition: $condition) {
          id
          billingStatus
          __typename
        }
      }
    `),
    variables: {
      input: {
        id: poID,
        billingStatus: "OPEN",
      },
    },
  });
}
