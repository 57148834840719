import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export default function Message({ message, setMessage, onClose }) {
  const navigate = useNavigate();

  if (!message) return null;

  const { onClick, title, btnText } = message;

  const Icon = message.Icon || LockOutlinedIcon;
  // const Icon = LockOutlinedIcon;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 5,
        minHeight: 400,

        width: "100%",
        overflow: "hidden",
      }}
    >
      <Icon fontSize="large" color="secondary" />

      <Typography width="100%" variant="h5">
        {title}
      </Typography>

      <Button
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => {
          onClick ? onClick() : onClose ? onClose() : navigate("/");
          setMessage();
        }}
      >
        {btnText || "continue"}
      </Button>
    </Box>
  );
}
