import { useState } from "react";
import { Stack, Typography, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useApolloClient } from "@apollo/client";
import onUpdatePO from "../../../SPOs/onUpdate";

export default function POItemLine({
  po,
  selectedPOItems,
  setSelectedPOItems,
}) {
  const ETA = [...(po?.ETA?.lines || [])];
  ETA.sort((a, b) => (a.eta > b.eta ? 1 : -1));
  const { date, responderName } = po?.ETA?.response || {};

  // console.log({ ETA, date, responderName, responderID });

  const client = useApolloClient();

  const [isLoading, setIsloading] = useState(false);
  const [isHandled, setIsHandled] = useState(false);

  async function handleClosePO() {
    if (window.confirm("Are you sure you want to close this PO?")) {
      console.log("close PO", po.id);
      setIsloading(true);
      try {
        await onUpdatePO({
          client,
          values: {
            id: po.id,
            statusCode: "900",
          },
        });
        setIsHandled(true);
      } catch (err) {
        console.log({ error2222: err });
      }

      setIsloading(false);
    }
  }

  // console.log({ ETA });
  return (
    <Stack
      direction="row"
      spacing={2}
      m={0.5}
      bgcolor="#f1f1f1"
      alignItems="center"
    >
      <Typography width={160}>{po.no}</Typography>
      <Typography width={120}>{po.date}</Typography>
      <Typography width={300}>{po.material?.mpn}</Typography>
      <Typography width={120}>{po.qty.toFixed(3) * 1}</Typography>
      <Typography width={120}>{po.openQty.toFixed(3) * 1}</Typography>
      <Typography width={120}>{po.CRD}</Typography>

      <Stack width={200}>
        {responderName && (
          <Typography variant="p" sx={{ fontStyle: "italic", fontSize: 12 }}>
            by {responderName && JSON.parse(responderName)} at {date}
          </Typography>
        )}

        {ETA?.map((x, i) => (
          <Stack key={i} direction="row" bgcolor="#f3e5f5">
            <Typography width={120} sx={{ fontStyle: "italic" }}>
              {x.eta}
            </Typography>
            <Typography width={60} sx={{ fontStyle: "italic" }}>
              {x.qty}
            </Typography>
          </Stack>
        ))}
      </Stack>

      <LoadingButton
        loading={isLoading}
        disabled={isHandled}
        sx={{ alignSelf: "center", width: 100 }}
        variant="contained"
        size="small"
        onClick={handleClosePO}
      >
        close PO
      </LoadingButton>

      <Checkbox
        disabled={isHandled}
        checked={selectedPOItems.includes(po.id)}
        onChange={(e) => {
          if (e.target.checked) {
            // console.log({ e: e.target.checked, poid: po.id });

            setSelectedPOItems([...selectedPOItems, po.id]);
          } else {
            setSelectedPOItems(selectedPOItems.filter((x) => x !== po.id));
          }
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Stack>
  );
}
