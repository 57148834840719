export const offerByNumber = /* GraphQL */ `
  query OfferByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        number
        inquiryValidDays
        itemNumber
        statusCode
        materialID
        material {
          id
          mfr
          mpn
        }
        materialEntityRelationID
        salesID
        buyerID
        vendorID
        vendor {
          id
          name
          alias
        }
        customerID
        customer {
          id
          name
          alias
        }
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
        }
        paymentTerms
        TP
        TPCurrency
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        note
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      date
      number
      itemNumber
      materialID
      materialEntityRelationID
      statusCode
      salesID
      buyerID
      vendorID
      customerID
      price
      currency
      leadtime
      validDays
      incoTerms {
        terms
      }
      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      createdAt
      updatedAt
    }
  }
`;
