import { useFormikContext, useField } from "formik";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import FieldErrorMessage from "./FieldErrorMessage";

export default function FormikCheckbox({
  label,
  name,
  width,
  type,
  FormikCheckbox,
  disabled,
  multiple,
  required,
  labelKey = "label",
  valueKey = "value",
}) {
  const { setFieldValue } = useFormikContext();

  const [field, meta, helpers] = useField({ name, type });

  const value = field.value;

  return (
    <>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            name={name}
            checked={value || false}
            onChange={(e, v) => setFieldValue(name, v)}
          />
        }
        label={label}
      />
      <Box sx={{ height: "5px" }}>
        <FieldErrorMessage name={name} meta={meta} />
      </Box>
    </>
  );
}
