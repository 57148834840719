export const offerByDefiner = /* GraphQL */ `
  query OfferByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        statusCode
        inquiryValidDays
        materialID
        material {
          id
          mpn
          mfr
          desc
          materialEntityRelations {
            items {
              id

              code
            }
          }
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          entityID
          materialID
          code
        }
        salesID
        sales {
          id
          fn
          ln
          mn
          name @client
        }
        buyerID
        buyer {
          id
          fn
          ln
          mn
          name @client
        }
        vendorID
        vendor {
          id
          name
          alias
          abbr @client
        }
        customerID
        customer {
          id
          name
          alias
          abbr @client
        }
        date
        definerID
        price
        currency
        leadtime
        validDays
        incoTerms {
          terms
          entityUserRelationID
        }
        paymentTerms
        TP
        TPCurrency
        targetPrice @client
        offer @client
        EAU
        application
        projectName
        endCustomer
        version
        latestVersion
        note
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      id
      date
      number
      itemNumber
      statusCode
      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
      }
      materialEntityRelationID
      materialEntityRelation {
        id
        entityID
        materialID
        code
      }
      salesID
      sales {
        id
        email
        fn
        mn
        ln
        name @client
      }
      buyerID
      buyer {
        id
        email
        fn
        mn
        ln
        name @client
      }
      vendorID
      vendor {
        id
        name
        alias
      }
      customerID
      customer {
        id
        name
        alias
      }
      definerID

      price
      currency
      leadtime
      validDays
      incoTerms {
        terms
        entityUserRelationID
      }
      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      note
      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      date
      number
      itemNumber
      materialID
      materialEntityRelationID
      statusCode
      salesID
      buyerID
      vendorID
      customerID
      price
      currency
      leadtime
      validDays
      incoTerms {
        terms
        entityUserRelationID
      }
      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      createdAt
      updatedAt
    }
  }
`;
export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $input: CreateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    createOffer(input: $input, condition: $condition) {
      id
      date
      number
      itemNumber
      statusCode
      materialID

      materialEntityRelationID
      date
      salesID

      buyerID

      vendorID

      customerID

      definerID

      price
      currency
      leadtime
      validDays
      incoTerms {
        terms
        entityUserRelationID
      }
      paymentTerms
      TP
      TPCurrency
      EAU
      application
      projectName
      endCustomer
      version
      latestVersion
      note

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const fragment = /* GraphQL */ `
  fragment NewOffer on Offer {
    id
  }
`;

export const relationsByCustomerID = /* GraphQL */ `
  query relationsByCustomerID(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerVendorRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alias
        customerCode
        vendorCode
        statusCode
        customerID
        vendorID
        groups
        createdAt
        updatedAt
        # thirdPartyUsers {
        #   items {
        #     id
        #     email
        #   }
        # }
      }
      nextToken
    }
  }
`;

export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          alias
          name
        }
        entity2ID
        entity2 {
          id
          name
          alias
          relationsAsEntity2 {
            items {
              id
              relation
              entity1ID
            }
          }
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        incoTerms {
          terms
          condition
          entityUserRelationID
        }
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const entitiyUserRelationByEntityID = /* GraphQL */ `
  query EntitiyUserRelationByEntityID(
    $entityID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByEntityID(
      entityID: $entityID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
        }
        entityID
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
