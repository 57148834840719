import { useState } from "react";
import { gql, useQuery, useApolloClient, useReactiveVar } from "@apollo/client";
import { showInsightVar } from "../../../client/cache";
import { Stack, Button, TextField, Typography } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { materialPartRelationCode } from "../../../settings/formFieldEnum";
import DataGridComp from "../../../component/DataGrid";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import FileZone from "../../../component/FileZone";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { getMaterial, MaterialsByDefiner } from "./query";
import onDelete from "./onDelete";
import CreateForm from "./components/CreateForm";
import Item from "./components/Item";
import Relation from "./components/Relation";
import Specifications from "./components/Specifications";
import runInsight from "./functions/runInsight";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getMaterial), {
    variables: { id: lineData?.id },
  });

  data = data?.getMaterial;

  // console.log({ data });

  if (!data) return null;

  const storagePath = `${entityID}/materials/${lineData?.id}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ minWidth: 800, minHeight: 400, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={{ ...data, definerID: entityID }} onClose={onClose} />
        <Relation data={data} />
        <Specifications
          materialID={data?.id}
          specifications={data?.specifications}
          onClose={onClose}
        />
        <FileZone storagePath={storagePath} />
        <Notes itemID={lineData?.id} />
        <Updates itemID={lineData?.id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const client = useApolloClient();
  const showInsight = useReactiveVar(showInsightVar);
  const isAdmin = userRole?.includes("admin");
  const [fxRate, setFxRate] = useState(7.5);

  const { selfAndSubsidiaries } = useReactiveVar(userEntityRelationVar);

  // const { data } = useQuery(gql(MaterialsByDefiner), {
  //   variables: { definerID:entityID },
  // });

  // const { state } = useLocation();
  // const disableMaterialSelection = state?.disableMaterialSelection;
  // const materialsSelection =
  //   data?.materialsByDefiner?.items;

  // if (!commonPartsSelection || !entitySpecificPartsSelection) return null;

  const columns = [
    {
      field: "mfr",
      headerName: "mfr",
      width: 150,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "mpn",
      headerName: "mpn",
      width: 280,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "inventory",
      headerName: "inventory",
      width: 100,
      userRole: ["admin", "sales"],
    },
    ...(showInsight
      ? [
          {
            field: "excessive",
            headerName: "excessive",
            width: 120,
            userRole: ["admin", "sales"],
            renderCell: ({ value, row, ...props }) => {
              // console.log({ value });

              if (!value || value === 0) return "";

              return (
                <Typography
                  p={1}
                  width={200}
                  bgcolor={value > 0 ? "red" : "blue"}
                  color="white"
                  variant="h6"
                >
                  {value}
                </Typography>
              );
            },
          },
          {
            field: "openCPOQty",
            headerName: "openCPOQty",
            width: 120,
            userRole: ["admin", "sales"],

            // renderCell: (params) => {
            //   return (
            //     <Typography
            //       color={params.value === "dismatch" ? "red" : "green"}
            //     >
            //       {params.value}
            //     </Typography>
            //   );
            // },
          },
          {
            field: "CPOValue",
            headerName: "CPOValue",
            width: 120,
            userRole: ["admin"],
          },
          {
            field: "openSPOQty",
            headerName: "openSPOQty",
            width: 120,
            userRole: ["admin", "sales"],
            // renderCell: ({ value, row, ...props }) => {
            //   console.log({ row });

            //   const excessive = value + (row.inventory || 0) - row.openCPOQty;

            //   console.log({ excessive });

            //   if (excessive > 0)
            //     return (
            //       <Tooltip
            //         title={
            //           <Typography variant="h4">
            //             {`超量备货${excessive}`}
            //           </Typography>
            //         }
            //       >
            //         <span>
            //           <Typography bgcolor="red" color="white">
            //             {value}
            //           </Typography>
            //         </span>
            //       </Tooltip>
            //     );

            //   return value;
            // },
          },

          {
            field: "SPOValue",
            headerName: "SPOValue",
            width: 120,
            userRole: ["admin"],
          },

          {
            field: "pricing",
            headerName: "pricing",
            width: 100,
            userRole: ["admin"],
          },
          {
            field: "cost",
            headerName: "cost",
            width: 100,
            userRole: ["admin"],
          },
          {
            field: "cnyCost",
            headerName: "cnyCost",
            width: 100,
            userRole: ["admin"],
          },
          {
            field: "cnyPricing",
            headerName: "cnyPricing",
            width: 100,
            userRole: ["admin"],
          },

          {
            field: "rate",
            headerName: "rate",
            width: 100,
            userRole: ["admin"],
          },
          {
            field: "mg",
            headerName: "mg",
            width: 100,
            userRole: ["admin"],
          },
        ]
      : []),
    {
      field: "unit",
      headerName: "unit",
      width: 50,
      userRole: ["admin", "sales", "warehouse"],
    },

    {
      field: "mpq",
      headerName: "mpq",
      width: 100,
      userRole: ["admin", "sales", "warehouse"],
    },

    {
      field: "isDeleted",
      headerName: "isDeleted",
      width: 260,
      userRole: ["admin"],
      renderCell: ({ row, ...props }) => {
        return row.isDeleted ? "true" : "false";
      },
    },
    {
      field: "relation",
      headerName: "Relation",
      width: 600,
      userRole: ["admin", "sales", "warehouse"],
    },
    {
      field: "desc",
      headerName: "desc",
      width: 260,
      userRole: ["admin"],
    },
  ];

  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );
  const queryVariables = { definerID: entityID, limit: 5000 };

  const prepareItemsFunc = async ({ items }) => {
    return await Promise.all(
      items.map(async (x) => {
        let res = {};

        if (showInsight) {
          res = await runInsight({
            materialID: x.id,
            client,
            fxRate,
            selfAndSubsidiaries,
            definerID: entityID,
          });
        }

        return {
          ...x,
          relation: x?.materialEntityRelations?.items
            ?.map(
              (x) =>
                `${x.entity.alias || x.entity.name}: ${x?.code} ${
                  materialPartRelationCode.find(
                    (y) => y.value === x?.statusCode
                  )?.label
                }`
            )
            .join(", "),
          ...res,
        };
      })
    );
  };

  const topRightButtons = [
    {
      comp: ({ rows, setRows, client }) => (
        <Button
          sx={{ color: showInsight ? "red" : "primary" }}
          onClick={async () => {
            showInsightVar(!showInsight);
          }}
        >
          <AssessmentIcon /> Insight
        </Button>
      ),
      userRole: ["admin", "sales"],
    },
  ];

  const filteredTopRightButtons = getFilteredItemsByUserRole(
    topRightButtons,
    userRole
  );

  const toolbarFieldAddon = [
    {
      comp: null,
      element: <FXRateField fxRate={fxRate} setFxRate={setFxRate} />,
      userRole: ["admin"],
    },
  ];

  const filteredToolbarFieldAddon = getFilteredItemsByUserRole(
    toolbarFieldAddon,
    userRole
  );

  const sortBy = (arr) => {
    arr.sort((a, b) =>
      Math.abs(a.excessive) > Math.abs(b.excessive) ? -1 : 1
    );
    // arr.sort((a, b) => (a.number < b.number ? -1 : 1));
    // arr.sort((a, b) => (a.date > b.date ? -1 : 1));

    return arr;
  };
  const filterAttrs = [
    { label: "mfr", value: "mfr", type: "string" },
    {
      label: "mpn",
      value: "mpn",
      type: "string",
    },
    { label: "gcas", value: "relation", type: "string" },
    { label: "desc", value: "desc", type: "string" },
  ];

  return (
    <DataGridComp
      query={MaterialsByDefiner}
      queryVariables={queryVariables}
      onDelete={onDelete}
      sortBy={sortBy}
      onClickAddRoute={isAdmin ? "new" : null}
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      columns={filteredColumns}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      topRightButtons={filteredTopRightButtons}
      toolbarFieldAddon={filteredToolbarFieldAddon}
      filterAttrs={filterAttrs}
    />
  );
}

function FXRateField({ fxRate, setFxRate }) {
  return (
    <TextField
      label="FX Rate"
      variant="outlined"
      value={fxRate}
      type="number"
      sx={{ width: 180 }}
      InputProps={{
        sx: { height: 40 },
        inputProps: { min: 0, max: 30 },
      }}
      onChange={(e) => {
        setFxRate(e.target.value);
      }}
    />
  );
}

const materials = {
  path: "materials",

  sideNav: {
    group: 1,
    order: 30,
    label: "Materials",
    entityType: "all",
    allowedRole: ["admin", "sales", "warehouse"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default materials;
