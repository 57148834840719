import { useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { FormikField, FormikSwitch } from "../../../../../component/formikForm";
import {
  invoiceByType,
  // getEntityUserRelation,
  // POByCustomerAndOpenQty,
} from "./query";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import { vatRate } from "../../../../../settings/formFieldEnum";

export default function Step1EditItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const { invoiceItems, paymentType, needEdit, POItems } = values;

  const totalSelectedInvoicesAmount = useMemo(
    () => invoiceItems?.reduce((a, v) => a + v.paidAmount, 0),
    [invoiceItems]
  );

  const totalSelectedPOAmount = useMemo(
    () =>
      POItems?.reduce(
        (a, v) =>
          a +
          (v.taxAndHandlingChecked ? v.taxAndHandling : 0) +
          (v.goodsPaymentChecked ? v.goodsPayment : 0),
        0
      ),
    [POItems]
  );

  // useEffect(() => {
  //   setFieldValue("hideArray", !needEdit);
  // }, [needEdit, setFieldValue]);

  return (
    <Stack minWidth={1600} spacing={2}>
      <Stack direction="row" spacing={2}>
        {paymentType === "INVOICE" && (
          <>
            <Typography>
              Selected Invoices: {totalSelectedInvoicesAmount}
            </Typography>
            {invoiceItems?.[0]?.currency === "CNY" && (
              <Typography>
                With VAT:{" "}
                {(totalSelectedInvoicesAmount * (1 + vatRate))?.toFixed(3) * 1}
              </Typography>
            )}
          </>
        )}
      </Stack>
      {invoiceItems?.map((x, i) => (
        <Stack key={x.id} direction="row" spacing={2}>
          <Typography>
            Inv Number:{x.number}-{x.itemNumber}
          </Typography>
          <Typography>Amount:{x.paidAmount}</Typography>
        </Stack>
      ))}

      {paymentType === "PO" && (
        <>
          <Typography>Selected POs: {totalSelectedPOAmount}</Typography>
          {POItems?.map((x, i) => {
            return (
              <Stack key={x.id} direction="row" spacing={2}>
                <Typography>{x.poNo}</Typography>
                {x.taxAndHandlingChecked && (
                  <Typography>TaxAndHanlding:{x.taxAndHandling}</Typography>
                )}
                {x.goodsPaymentChecked && (
                  <Typography>Goods Payment:{x.goodsPayment}</Typography>
                )}
              </Stack>
            );
          })}
        </>
      )}

      {paymentType === "PO" && POItems?.some((x) => x.fxRate) && (
        <FormikField
          width={120}
          name="updatedFxRate"
          label="updatedFxRate"
          type="number"
        />
      )}
    </Stack>
  );
}
