import { useFormikContext } from "formik";
import { IconButton, Stack, Typography } from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";

export default function FormikArrayAddItemButton({
  push,
  field,
  name,
  meta,
  helpers,
  addBtnColor,
  size,
  hideLabel,
  defaultValue,
}) {
  const { values, setFieldTouched, validateForm, setTouched } =
    useFormikContext();

  const { newSubItemValue } = values;

  let iconSize = "medium";
  let fontVariant = "body1";

  switch (size) {
    case "sm":
      iconSize = "small";
      fontVariant = "body2";

      break;
    case "lg":
      iconSize = "large";
      fontVariant = "h6";

      break;

    default:
      break;
  }

  return (
    <IconButton
      sx={
        {
          // alignSelf: "center",
          // bgcolor:'red',
          // width: "150px",
        }
      }
      aria-label="add"
      onClick={async () => {

        const errors = await validateForm();
        setTouched({ ...errors });
        // const errors = meta.error;

        if (Object.keys(errors)?.length === 0) {
          push(newSubItemValue || defaultValue || {});
        } else {

          // setFieldTouched(name, errors);

          // if (!field.value || field.value?.length === 0) {
          //   push(newSubItemValue || {});
          // }
        }

        // else {
        //   setIsAddButtonDisabled(true);
        // }
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <AddCircleIcon sx={{ color: addBtnColor }} fontSize={iconSize} />
        <Typography color={addBtnColor} variant={fontVariant}>
          {hideLabel
            ? "Add item"
            : name?.split("][")?.slice(-1).pop().replace("]", "")}
        </Typography>
      </Stack>
    </IconButton>
  );
}
