import React, { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { Hub } from "aws-amplify";
import { useNavigate, Navigate, Outlet } from "react-router-dom";
import {
  Box,
  Typography,
  AppBar,
  Button,
  Toolbar,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Footer from "../component/Footer";
import getTopNav from "../function/getTopNav";
import { userVar, userEntityRelationVar } from "../client/cache";

export default function UnAuthLayout({ children }) {
  const user = useReactiveVar(userVar);


  if (user?.sub) return <Navigate to="/cloud" replace />;

  return (
    <>
      <UnAuthNavbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // border: 2,
          // borderColor: "secondary.main",
          height: "100vh",
        }}
      >
        <Outlet />
      </Box>

      <Footer />
    </>
  );
}

function UnAuthNavbar() {
  const userEntityRelation = useReactiveVar(userEntityRelationVar);

  const { entityType, userRole } = userEntityRelation;

  const navigate = useNavigate();

  const nav = getTopNav({
    entityType,
    userRole,
    isAuthenticated: false,
  });

  const [value, setValue] = useState(false);

  useEffect(() => {
    const menuMatched = nav.find(
      (x) => x.path === window.location.pathname.split("/")[1]
    );

    if (menuMatched) {
      setValue(menuMatched.label);
    } else {
      setValue(false);
    }
  }, [navigate, nav]);

  return (
    <AppBar position="fixed" sx={{ opacity: 0.8 }}>
      <Toolbar>
        <Stack direction="row" spacing={1} alignItems="center">
          <SvgIcon color="secondary" sx={{ position: "relative", top: 2 }}>
            <path d="M8.12249 1.60573C8.50171 0.911796 9.49829 0.911796 9.87751 1.60573L10.9602 3.58693C11.1657 3.96292 11.5879 4.16622 12.0099 4.09244L14.234 3.70368C15.0129 3.56751 15.6343 4.34666 15.3282 5.07581L14.4543 7.15757C14.2884 7.55264 14.3927 8.00946 14.7135 8.29344L16.4041 9.78987C16.9963 10.314 16.7745 11.2856 16.0136 11.5009L13.8412 12.1156C13.4289 12.2323 13.1367 12.5986 13.1147 13.0265L12.9989 15.2813C12.9583 16.071 12.0604 16.5034 11.4176 16.0427L9.58254 14.7275C9.23429 14.4779 8.76571 14.4779 8.41746 14.7275L6.58236 16.0427C5.9396 16.5034 5.04172 16.071 5.00113 15.2813L4.88526 13.0265C4.86327 12.5986 4.57112 12.2323 4.15884 12.1156L1.98638 11.5009C1.22546 11.2856 1.0037 10.314 1.59585 9.78987L3.28646 8.29344C3.6073 8.00946 3.71156 7.55264 3.54571 7.15757L2.6718 5.07581C2.36571 4.34666 2.98706 3.56751 3.76604 3.70368L5.99007 4.09244C6.41214 4.16622 6.83431 3.96292 7.03978 3.58693L8.12249 1.60573Z" />
          </SvgIcon>
          <Typography
            variant="h6"
            noWrap
            component="a"
            // href="/"
            onClick={() => {
              setValue(false);
              navigate("/");
            }}
            sx={{
              mr: 2,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            HANKENO
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1, ml: 5 }}>
          <Tabs
            // TabIndicatorProps={{
            //   style: {
            //     display: value === "Home" ? "none" : "block",
            //   },
            // }}
            value={value}
            onChange={(e, val) => {
              setValue(val);
            }}
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            {nav.map((menu) => (
              <Tab
                key={menu.label}
                value={menu.label}
                label={menu.label}
                onClick={() => navigate(`/${menu.path}`)}
                sx={{
                  visibility: menu.label === "Home" ? "hidden" : "visible",
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Button
            sx={{ color: "white" }}
            // href="/auth"
            onClick={() => {
              // navigate("auth");

              const vid = document.getElementById("video");

              vid && vid.pause();

              Hub.dispatch("dialogMonitor", {
                event: "auth",
                data: {},
                message: "",
              });
            }}
          >
            PORTAL
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
