import { gql, useQuery } from "@apollo/client";

import { Stack } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
} from "../../../../component/formikForm";
import onCreate from "../onCreate";
import { createMaterial, MaterialsByDefiner } from "../query";
import { CreateRelationFields } from "./Relation";
import onCreateRelation from "../onCreateRelation";

const validationSchemaForCreate = Yup.object().shape({
  mfr: Yup.string().min(2, "Too Short"),
  mpn: Yup.string().min(2, "Too Short"),
  desc: Yup.string().min(2, "Too Short"),

  // materials: Yup.array().of(
  //   Yup.object({
  //     material: Yup.object()
  //       .required("Please select or create material")
  //       .nullable(),
  //     statusCode: Yup.string().required("Please select status"),
  //   })
  //),
});

export default function CreateForm() {
  const { state } = useLocation();

  const { entityID } = useParams();
  const initialValues = state
    ? { ...state, definerID: entityID }
    : {
        definerID: entityID,
        isEditing: true,
      };

  const navigate = useNavigate();
  const onSubmit = async ({ values, client }) => {
    const createdMaterialID = await onCreate({
      values,
      client,
    });

    if (values.code) {
      await onCreateRelation({
        values: {
          ...values,
          materialID: createdMaterialID,
        },
        client,
      });
    }

    navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      onSubmit={onSubmit}
      FormMainFields={CreateFields}
      FormArray={null}
      handleCancel={handleCancel}
    />
  );
}

const CreateFields = ({ values, setFieldValue }) => {
  const { data: MaterialsByDefinerData } = useQuery(gql(MaterialsByDefiner), {
    variables: { definerID: values.definerID },
  });
  const MFRs = [
    ...new Set(
      MaterialsByDefinerData?.MaterialsByDefiner?.items.map((x) => x.mfr)
    ),
  ];

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5}>
        <FormikAutocomplete
          name="mfr"
          label="MFR"
          // required
          options={MFRs}
          freeSolo
        />

        <FormikField name="mpn" label="MPN" />
        <FormikField name="desc" label="Description" />
      </Stack>
      <CreateRelationFields values={values} setFieldValue={setFieldValue} />
    </Stack>
  );
};
