import { useEffect, useMemo } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { Stack, Button, Typography, stepButtonClasses } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import fillArrayWithNumber from "../../../../../lib/fillArrayWithNumber";

const invoiceByIssuer = /* GraphQL */ `
  query InvoiceByIssuer(
    $issuerID: ID!
    $statusCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByIssuer(
      issuerID: $issuerID
      statusCode: $statusCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        type
        batch
        number
        itemNumber
        date
        price
        qty
        currency
        note
        desc
        poID
        forwarderPOID
        paymentDueDate
        billingEntityID
        issuerID
        shippingID
        paymentStatus
        deductionDate
        definerID
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export default function DiscountingInvoiceItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();
  const client = useApolloClient();

  const { avaiablePOs, vendor, category, items } = values;

  //PLEASE NOTE MATERIAL IS ACTUALLY PO
  const { hideSubArray, discountingInvoices } = items?.[index] || {};

  const { data } = useQuery(gql(invoiceByIssuer), {
    variables: {
      issuerID: vendor?.id,
      stateCode: { eq: "100" },
    },
    skip: !vendor?.id,
  });

  const pendingInvoices = data?.invoiceByIssuer?.items || [];

  console.log({ pendingInvoices });

  // useEffect(() => {
  //   if (!POs) return

  //   const availableShippings = POs?.map((x) =>
  //     x.shippings?.items?.map((y) => ({ ...y, poNo: x.no })),
  //   ).flat()

  //   setFieldValue(
  //     `[${arrayFieldName}][${index}][availableShippings]`,
  //     availableShippings,
  //   )
  // }, [POs, setFieldValue, arrayFieldName, index])

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][discountingInvoices]`}
          label="Invoice to be issued"
          multiple
          required
          // disabled={!material}
          options={pendingInvoices || []}
          getOptionLabel={(option) => `${option.desc} (${option.price})`}
          getPrimaryText={(option) => option.desc}
          getSecondaryText={(option) => option.price}
          width={480}
          // getOptionDisabled={(option) =>
          //   values?.items?.some(
          //     (x) =>
          //       x.po?.number === option.number &&
          //       x.po?.materialID === material.materialID,
          //   )
          // }
        />
        <Typography variant="h6" color="primary">
          {" "}
          subtotal:{" "}
          {discountingInvoices?.reduce((a, v) => a + v.price * v.qty || 0, 0)}
        </Typography>

        {/* <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][billedQty]`}
          disabled
          label='Billed Qty'
        /> */}

        <FormikArrayRemoveItemButton
          disabled={index === 0}
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
