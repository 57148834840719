import { useMemo } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormikForm, {
  FormikAutocomplete,
  FormikSelect,
} from "../../../../../component/formikForm";

import { userEntityRole } from "../../../../../settings/formFieldEnum";
import { entitiesByDefiner } from "../../query";
import onCreateRelation from "../../onCreateRelation";
import { userEntityRelationVar } from "../../../../../client/cache";

export default function AddRelationship({ lineData, onClose }) {
  const { id: userID, name } = lineData;

  const { entityID } = useReactiveVar(userEntityRelationVar);

  if (!entityID) {
    alert("entityID not retrieved");
  }
  const initialValues = { userID, definerID: entityID, name, isEditing: true };

  const onSubmit = async ({ values, client }) => {
    await onCreateRelation({ values, client });

    onClose && onClose();
  };
  const handleCancel = () => {
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
      FormMainFields={CreateRelationFields}
      FormArray={null}
      handleCancel={handleCancel}
    />
  );
}

export const CreateRelationFields = ({ values, onClose }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  //   const {entityID} = params || {}

  if (!entityID) {
    alert("entityID not retrieved");
  }

  let { data: customEntityBase } = useQuery(gql(entitiesByDefiner), {
    variables: { definerID: entityID },
    skip: !entityID,
  });

  let { data: publicEntityBase } = useQuery(gql(entitiesByDefiner), {
    variables: { definerID: "PUBLIC" },
  });

  customEntityBase = customEntityBase?.entitiesByDefiner?.items || [];
  publicEntityBase = publicEntityBase?.entitiesByDefiner?.items || [];

  const entityBase = useMemo(
    () => [...customEntityBase, ...publicEntityBase],
    [customEntityBase, publicEntityBase]
  );

  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{values?.name}</Typography>

      <Stack direction="row" spacing={4} alignItems="flex-end">
        <FormikAutocomplete
          name={"entity"}
          options={entityBase || []}
          required
          // multiple
          freeSolo={false}
          width={400}
          type={null}
          label="Entity"
          getOptionLabel={(option) => option.alias || option.name}
          listItemPrimaryField="alias"
          listItemSecondaryField={null}
          disableCloseOnSelect={false}
          getOptionDisabled={null}
          getPrimaryText={null}
          getSecondaryText={null}
          disabled={false}
          noOptionsText={
            <Stack direction="row" justifyContent="space-between">
              <Typography>No Options</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/cloud/${entityID}/entities/new`, {});
                  onClose && onClose();
                }}
              >
                create entity
              </Button>
            </Stack>
          }
        />
      </Stack>
      <FormikSelect
        label="Role"
        name="role"
        width={300}
        options={userEntityRole}
        required
        labelKey="label"
        valueKey="value"
      />
    </Stack>
  );
};
