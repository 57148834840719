import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { API } from "aws-amplify";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";

export default function BraunCOAPrevBtn({ shippingItems, date }) {
  const isPreview = true;

  const [isLoading, setIsLoading] = useState(false);

  async function onClick() {
    setIsLoading(true);

    let items = mergeArrayAndCombineQty(shippingItems, ["materialID"], ["qty"]);

    console.log({ shippingItems, items });

    const formID = Date.now().toString().slice(1, 9);

    items.forEach((x, i) => {
      if (!x.specifications || x.specifications?.length === 0) {
        return alert(`${x.code} spec not found`);
      }
      x.formID = `${formID}-${i + 1}`;
    });

    items = items.map((x) => ({
      formID: x.formID,
      specifications: x.specifications,
      qty: x.qty,
      mfr: x.mfr,
      mpn: x.mpn,
      code: x.code,
    }));

    const recipient = "";
    // "wang.jf@pg.com, ying.c@pg.com,chen.w.2@pg.com, li.c.23@pg.com, yang.j.63@pg.com, guangjian.w@pg.com";

    const meta = {
      date,
      vendorPhoneNumber: "+86-21-50595180",
      to: isPreview ? null : recipient,
      cc: null,
      bcc: "steven.lu@hankeno.com",
      isPreview: true,
      vendorName: "SHANGHAI HANKENO ELECTRONICS TECHNOLOGY CO LTD",
      vendorAddress:
        "3/F, BLDG 2, CHANGZHENG INDUSTRIAL PARK, ALLEY 1225, TONGPU RD, PUTUO DISTRICT, SHANGHAI, PRC",
      inspectedBy: "TONY LIU",
      verifiedBy: "JAMES LEUNG",
    };

    try {
      const res = await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "SEND_COA",
          data: {
            meta,
            items,
          },
        },
      });
      console.log({ res });
    } catch (err) {
      console.log({ error3333: err });
    }
    setIsLoading(false);
  }

  return (
    <LoadingButton variant="contained" loading={isLoading} onClick={onClick}>
      COA Preview
    </LoadingButton>
  );
}
