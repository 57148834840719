import { gql } from "@apollo/client";
import { onUpdate } from "./index";
import comparePropertyBetweenObjects from "../../lib/comparePropertyBetweenObjects";
import { onCreate } from "../onCreate/index";
import { onDelete } from "../onDelete/index";

const getPart = gql`
  query GetPart($id: ID!) {
    getPart(id: $id) {
      id
      mpn
      mfr
      coo
      materials {
        items {
          id
          materialID
          statusCode
          material {
            id
            gcas
          }
        }
        nextToken
      }
      desc
      unit
      leadtime
      mpq
      transitDays
      initialInventory
      currentInventory
      hsCode
      importDutyCN
      createdAt
      updatedAt
    }
  }
`;
const updatePart = /* GraphQL */ `
  mutation UpdatePart(
    $input: UpdatePartInput!
    $condition: ModelPartConditionInput
  ) {
    updatePart(input: $input, condition: $condition) {
      id
      mpn
      mfr
      coo
      materials {
        items {
          id
          partID
          materialID
        }
        nextToken
      }
      desc
      unit
      leadtime
      mpq
      transitDays
      initialInventory
      currentInventory
      hsCode
      importDutyCN
      createdAt
      updatedAt
    }
  }
`;
const deleteMaterialPartRelation = gql`
  mutation DeleteMaterialPartRelation(
    $input: DeleteMaterialPartRelationInput!
    $condition: ModelMaterialPartRelationConditionInput
  ) {
    deleteMaterialPartRelation(input: $input, condition: $condition) {
      id
    }
  }
`;
const createMaterialPartRelation = gql`
  mutation CreateMaterialPartRelation(
    $input: CreateMaterialPartRelationInput!
    $condition: ModelMaterialPartRelationConditionInput
  ) {
    createMaterialPartRelation(input: $input, condition: $condition) {
      id
      statusCode
      materialID
      partID
    }
  }
`;

const updateMaterialPartRelation = gql`
  mutation UpdateMaterialPartRelation(
    $input: UpdateMaterialPartRelationInput!
    $condition: ModelMaterialPartRelationConditionInput
  ) {
    updateMaterialPartRelation(input: $input, condition: $condition) {
      id
      statusCode
      materialID

      partID
    }
  }
`;
export default async function onUpdatePart({ values, client }) {
  const { id } = values;

  const fieldsToUpdate = [
    { field: "mpn", createUpdate: true },
    { field: "mfr", createUpdate: true },
    {
      field: "materials",
      // createUpdate: false,
      funcAdd: async ({ addedItem, updatesToCreate }) => {
        const message = `${addedItem.material.gcas} was added with statusCode ${addedItem.statusCode}`;
        updatesToCreate.push(message);
        const fragment = /* GraphQL */ `
          fragment NewMaterialPartRelation on MaterialPartRelation {
            id
          }
        `;
        await onCreate({
          mutation: createMaterialPartRelation,
          fragment,
          input: {
            partID: id,
            materialID: addedItem.material.id,
            statusCode: addedItem.statusCode,
          },
          // updateCacheFields: ["materialPartRelationByMaterial"],
          // refetchQueries: ["GetMaterial"],
          client,
        });
      },
      funcDelete: async function funDelete({ deletedItem, updatesToCreate }) {
        const message = `${deletedItem.material.gcas} was deleted with statusCode ${deletedItem.statusCode}`;
        updatesToCreate.push(message);
        await onDelete({
          mutation: deleteMaterialPartRelation,
          input: { id: deletedItem.relationID },
          client,
        });
      },
      funcCheckUpdate: async function funcCheckUpdate({
        currentItem,
        initialValues,
        updatesToCreate,
        // changedProperties,
      }) {
        const arrayItemInInitialValue = initialValues[this.field].find(
          (y) => y.material?.id === currentItem?.material?.id
        );
        const changedProperties = comparePropertyBetweenObjects({
          properties: this.checkPropertiesForUpdate.map((x) => x.field),
          newObj: currentItem,
          oldObj: arrayItemInInitialValue,
        });

        if (Object.keys(changedProperties)?.length > 0) {
          const input = { id: currentItem.relationID, ...changedProperties };
          // console.log({ input });
          // debugger;
          await client.mutate({
            mutation: updateMaterialPartRelation,
            variables: { input },
          });

          Object.keys(changedProperties).forEach((key) => {
            const subField = this.checkPropertiesForUpdate.find(
              (x) => x.field === key
            );
            if (subField?.createUpdate) {
              const message = `${key} has been updated from ${arrayItemInInitialValue[key]} to ${currentItem[key]}`;
              updatesToCreate.push(message);
            }
          });
        }
      },
      checkPropertiesForUpdate: [{ field: "statusCode", createUpdate: true }],
      checkIsItemInNewArrayExisting: ({ item, originalArr }) => {
        return (
          item.relationID &&
          originalArr.map((y) => y?.material?.id).includes(item?.material.id)
        );
      },
      checkIsItemInOldArrayRemaining: ({ item, newArr }) => {
        return newArr.some(
          (y) => y.relationID && y?.material?.id === item.materialID
        );
      },
    },
  ];

  async function getInitialValue() {
    const { data } = await client.query({
      query: getPart,
      variables: { id },
    });

    let details = data?.getPart || {};

    details = {
      ...details,
      materials: details?.materials?.items?.map((x) => ({
        ...x,
        relationID: x.id,
      })),
    };

    return details;
  }

  await onUpdate({
    type: "Part",
    values,
    mutation: updatePart,
    // query,
    getInitialValue,
    fieldsToUpdate,
    client,
    refetchQueries: ["GetPart"],
  });
}
