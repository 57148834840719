import { useState } from "react";
import { useApolloClient } from "@apollo/client";

import { Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function DropdownMenu({
  selectionModel,
  setSelectionModel,
  menu,
  setIsLoading,
}) {
  const [anchorElMenu, setAnchorElMemu] = useState();
  const handleOpenMenu = (e) => setAnchorElMemu(e.currentTarget);
  const handleCloseMenu = () => setAnchorElMemu();
  const client = useApolloClient();

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MenuIcon />
      </IconButton>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorElMenu}
        onClose={handleCloseMenu}
      >
        {menu.map((setting) => (
          <MenuItem
            key={setting.label}
            onClick={async () => {
              setIsLoading(true);

              await setting.onClick({
                client,
                selectionModel,
                setSelectionModel,
              });
              handleCloseMenu();
              setIsLoading(false);
            }}
          >
            <Typography textAlign="center">{setting.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
