import { gql } from '@apollo/client'
import { API } from 'aws-amplify'
import emitSnackbar from '../../../function/emitSnackbar'
import { onCreate } from '../../../function/onCreate'
import { userByEmail, createUser, createInvite, fragment } from './query'

export default async function onCreateInvite({ client, values }) {
  const {
    email,
    entity,
    entityID,
    role,
    ln,
    fn,
    inviterID,
    inviterFn,
    inviterLn,
    entityName,
  } = values

  const enID = entity?.id || entityID
  const code =
    values.code ||
    Math.floor(Math.random() * 100000000)
      .toString()
      .padStart(8, '0')

  // console.log({ email, role, enID, code, inviterID })

  // FETCH USER

  const { data: getUserData } = await client.query({
    query: gql(userByEmail),
    variables: { email },
    fetchPolicy: 'network-only',
  })

  console.log({ getUserData })

  let { id: userID, sub } = getUserData?.userByEmail?.items[0] || {}

  console.log({ userID, sub })

  // CREATE USER IF USER DOES NOT EXIST

  if (!userID) {
    console.log('create new user')
    const fragment = /* GraphQL */ `
      fragment NewUser on User {
        id
      }
    `

    const inputCreateUser = { email, sub: 'PENDING', ln, fn }

    // console.log({ inputCreateUser });

    // debugger;
    const { data: createUserData } = await onCreate({
      input: inputCreateUser,
      mutation: createUser,
      fragment,
      updateCacheFields: ['listUsers'],
      client,
    })

    userID = createUserData?.createUser?.id
  }

  // CREATE INVITATION

  const input = {
    id: code,
    ln,
    fn,
    code,
    userID,
    entityID: enID,
    role,
    status: 'PENDING',
  }

  const { data: createInviteData, errors: createInviteErrors } = await onCreate(
    {
      input,
      mutation: createInvite,
      fragment,
      updateCacheFields: ['listInvites', 'inviteByEntity'],
      client,
    },
  )
  console.log({ createInviteData, createInviteErrors })

  // SEND INVITATION IF USER IS NOT REGISTERED

  try {
    if (!sub || sub === 'PENDING') {
      const recipientName =
        fn?.charAt(0).toUpperCase() + fn?.slice(1).toLowerCase()
      const inviterName = `${inviterFn?.charAt(0).toUpperCase()}${inviterFn
        ?.slice(1)
        .toLowerCase()} ${inviterLn?.charAt(0).toUpperCase()}${inviterLn
        ?.slice(1)
        .toLowerCase()}`

      await API.post('iSupplyAPI', '/restricted', {
        body: {
          operation: 'SEND_INVITATION',
          meta: {
            code,
            to: email,
            role,
            recipientName,
            inviterName,
            entity: entityName,
          },
        },
      })
      emitSnackbar({
        message: 'invitation sent successfully',
        duration: 5000,
      })
    }
  } catch (e) {
    console.error({ e23324: e.message })
  }
}
