import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";

import {
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Collapse,
  List,
  ListItem,
  Divider,
  TableRow,
  Paper,
  IconButton,
  Container,
  Typography,
  Stack,
  CssBaseline,
  Grow,
  Box,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { TransitionGroup } from "react-transition-group";
import { useSearchParams } from "react-router-dom";

export default function ETAForUnregisterdUser({
  entityType,
  userRole,
  isAuthenticated,
  entityID,
}) {
  // const ref = useRef();

  // ref.current = 0;
  // console.log({ entityType, userRole, isAuthenticated, entityID });

  let [searchParams, setSearchParams] = useSearchParams();

  const data = searchParams.get("data");

  let dataObj = {};
  if (data) {
    try {
      dataObj = JSON.parse(Buffer.from(data, "base64"));
    } catch (e) {
      console.log("error parsing data");
    }
  }
  const uid = dataObj.uid;

  const [POs, setPOs] = useState();
  const [loading, setLoading] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    async function fetchPO() {
      // console.log({ data });

      if (data) {
        try {
          const dataObj = JSON.parse(Buffer.from(data, "base64"));
          const POIDs = dataObj.POIDs;
          if (POIDs.length > 0 && !POs) {
            setLoading(true);

            console.log("fetchingfetchingfetchingfetchingfetching PO");

            const res = await API.post("iSupplyAPI", "/public", {
              body: {
                operation: "FETCH_ETA_PO",
                data: { POIDs },
              },
            });
            console.log({ resresresresresresresresresresresresres: res });
            setPOs(res);
          }

          setLoading(false);
        } catch (e) {
          console.log("error parsing data");
        }
      }

      // console.log({ POIDs });

      // ref.current = ref.current + 1;
    }
    fetchPO();
  }, [data, POs]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        // bgcolor: "red",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
      }}
    >
      <CssBaseline />

      {loading ? (
        <CircularProgress sx={{ alignSelf: "center" }} color="secondary" />
      ) : (
        <Container
          disableGutters
          maxWidth="lg"
          component="main"
          // alignContent="center"

          sx={{
            pt: 8,
            pb: 6,
            mt: 10,
            mb: 5,
            // bgcolor: "blue",
            justifyContent: "center",
            // alignItems: "center",
            display: "flex",
          }}
        >
          <Stack spacing={10}>
            {POs?.length === 0 ? (
              <>
                {hasSubmitted ? (
                  <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    fontWeight={300}
                    color="secondary"
                    gutterBottom
                  >
                    Thank you for your response!
                  </Typography>
                ) : (
                  <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    fontWeight={300}
                    color="secondary"
                    gutterBottom
                  >
                    Currently you have no open orders with us.
                  </Typography>
                )}
              </>
            ) : (
              <Stack spacing={2}>
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  fontWeight={300}
                  color="secondary"
                  gutterBottom
                >
                  ETA confirmation
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  color="text.secondary"
                  component="p"
                >
                  Please review your open orders with us and submit the ETAs
                </Typography>
              </Stack>
            )}

            {POs?.length > 0 && (
              <List>
                {/* <Stack spacing={2}> */}
                <ListItem>
                  <Stack direction="row" spacing={2} mb={5} color="gray">
                    <Typography align="center" width={100} variant="h5">
                      NUMBER
                    </Typography>
                    <Typography align="center" width={100} variant="h5">
                      ITEM
                    </Typography>
                    <Typography align="center" width={100} variant="h5">
                      MFR
                    </Typography>
                    <Typography align="center" width={200} variant="h5">
                      MPN
                    </Typography>
                    <Typography align="center" width={150} variant="h5">
                      PO QTY
                    </Typography>
                    <Typography align="center" width={150} variant="h5">
                      OPEN QTY
                    </Typography>
                    <Typography align="center" width={100} variant="h5">
                      CRD
                    </Typography>
                    <Typography align="center" width={500} variant="h5">
                      ETA
                    </Typography>
                  </Stack>
                </ListItem>
                <Divider />
                <TransitionGroup>
                  {POs?.map((row) => (
                    <Collapse key={row.id}>
                      <Item
                        row={row}
                        setPOs={setPOs}
                        uid={uid}
                        setHasSubmitted={setHasSubmitted}
                      />
                    </Collapse>
                  ))}
                </TransitionGroup>
              </List>
            )}
          </Stack>
        </Container>
      )}
    </Box>
  );
}

function Item({ row, setPOs, uid, setHasSubmitted }) {
  const { id, number, material, itemNumber, qty, openQty, CRD, ETA } = row;

  const existingLines = ETA?.lines?.map((x) => ({
    qty: { value: x.qty, error: "" },
    date: { value: x.eta, error: "" },
  }));

  const defaultLine = {
    qty: { value: "", error: "" },
    date: { value: "", error: "" },
    // note: { value: "", error: "" },
  };
  const [lines, setLines] = useState(existingLines || [defaultLine]);

  const [isLoading, setIsloading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsloading(true);

      const eta = lines.map((x) => {
        return {
          qty: x.qty.value * 1,
          eta: x.date.value,
        };
      });
      const res = await API.post("iSupplyAPI", "/public", {
        body: {
          operation: "SUBMIT_ETA",
          data: { eta, poID: id, uid },
        },
      });

      console.log({ res });

      setPOs((prev) => prev.filter((x) => x.id !== id));

      setHasSubmitted(true);

      setIsloading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <ListItem>
      <Stack
        direction="row"
        spacing={2}
        sx={{ p: 2, border: 1, borderRadius: 2, borderColor: "grey.300" }}
      >
        <Typography width={100}>{number}</Typography>
        <Typography width={100}>{itemNumber}</Typography>
        <Typography width={100}>{material?.mfr}</Typography>
        <Typography width={200}>{material?.mpn}</Typography>
        <Typography width={150}>{qty}</Typography>
        <Typography width={150}>{openQty}</Typography>
        <Typography width={100}>{CRD}</Typography>
        <form onSubmit={onSubmit} autoComplete="off">
          <Stack direction="row" spacing={5} width={500}>
            <Stack spacing={1}>
              <List>
                {/* <List sx={{ mt: 1 }}> */}
                <TransitionGroup>
                  {lines.map((x, i) => (
                    <Collapse key={i}>
                      <ETALine
                        key={i}
                        item={x}
                        setLines={setLines}
                        index={i}
                        lines={lines}
                        openQty={openQty}
                      />
                    </Collapse>
                  ))}
                </TransitionGroup>
              </List>

              <IconButton
                color="primary"
                sx={{ alignSelf: "center" }}
                disabled={
                  lines.some((x) => {
                    const val = Object.values(x);
                    return val.some(
                      (y) => y.value.length === 0 || y.error.length > 0
                    );
                  }) ||
                  lines.reduce((a, v) => a + v.qty.value * 1, 0) >= openQty
                }
                onClick={() => {
                  setLines([...lines, defaultLine]);
                }}
              >
                <Stack direction="row" spacing={1}>
                  <AddCircleIcon />
                  <Typography>add line</Typography>
                </Stack>
              </IconButton>
            </Stack>

            <LoadingButton
              loading={isLoading}
              disabled={lines.some((x) => {
                const val = Object.values(x);
                return val.some((y) => y.error.length > 0);
              })}
              type="submit"
              variant="contained"
              sx={{ alignSelf: "center" }}
            >
              {!!existingLines ? "UPDATE" : "SUBMIT"}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </ListItem>
  );
}

function ETALine({ item, index, lines, setLines, openQty }) {
  const { qty, date } = item;

  return (
    <ListItem>
      <Stack direction="row" spacing={2}>
        <TextField
          required
          label="Qty"
          value={qty.value}
          type="number"
          helperText={qty.error}
          error={qty.error.length > 0}
          onBlur={(e) => {
            if (e.target.value * 1 <= 0) {
              setLines(
                lines.map((x, i) => {
                  if (i === index) {
                    return {
                      ...x,
                      qty: { value: qty.value, error: "Invalid quantity" },
                    };
                  } else {
                    return x;
                  }
                })
              );
            }

            if (lines.reduce((a, v) => a + v.qty.value * 1, 0) > openQty) {
              setLines(
                lines.map((x, i) => {
                  if (i === index) {
                    return {
                      ...x,
                      qty: {
                        value: qty.value,
                        error: "Total qty is greater than open qty",
                      },
                    };
                  } else {
                    return x;
                  }
                })
              );
            }
          }}
          onChange={(e) => {
            setLines(
              lines.map((x, i) => {
                if (i === index) {
                  return {
                    ...x,
                    qty: { value: e.target.value, error: "" },
                  };
                } else {
                  return x;
                }
              })
            );
          }}
        />
        <TextField
          sx={{ width: 260 }}
          required
          label="ETA"
          type="date"
          value={date.value}
          InputProps={{
            inputProps: {
              min: new Date().toISOString("en-US").substring(0, 10),
            },
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setLines(
              lines.map((x, i) => {
                if (i === index) {
                  return { ...x, date: { value: e.target.value, error: "" } };
                } else {
                  return x;
                }
              })
            );
          }}
        />
        {/* <TextField
          label="Note"
          sx={{ width: 300 }}
          type="string"
          variant="standard"
          value={note.value}
          onBlur={(e) => {
            console.log({ value: e.target.value });
          }}
          onChange={(e) => {
            setLines(
              lines.map((x, i) => {
                if (i === index) {
                  return {
                    ...x,
                    note: { value: e.target.value.trimStart(), error: "" },
                  };
                } else {
                  return x;
                }
              })
            );
          }}
        /> */}
        <IconButton
          disabled={lines.length === 1}
          onClick={() => {
            console.log({ index, lines });
            setLines(lines.filter((x, i) => i !== index));
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </ListItem>
  );
}
