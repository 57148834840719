import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../../component/formikForm";
import filterOutSameItemInArray from "../../../../../../lib/filterOutSameItemInArray";

const MaterialEntityRelationsByDefiner = /* GraphQL */ `
  query MaterialEntityRelationsByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialEntityRelationsByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID
        material {
          id
          mfr
          mpn
        }
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const MaterialsByDefiner = /* GraphQL */ `
  query MaterialsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        mpn
        mfr
      }
      nextToken
      __typename
    }
  }
`;

export default function InquiryItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();

  const { inquiries, POItem, definerID, disableMultiple } = values;
  const { code, findBy } = (inquiries || POItem)?.[index] || {};

  let { data: materialEntityRelationsByDefinerData } = useQuery(
    gql(MaterialEntityRelationsByDefiner),
    { variables: { definerID } }
  );
  const codes = useMemo(
    () =>
      materialEntityRelationsByDefinerData?.MaterialEntityRelationsByDefiner?.items?.map(
        (x) => ({
          id: x.materialID,
          code: x.code,
          relationID: x.id,
          materialID: x.materialID,
          mfr: x.material.mfr,
          mpn: x.material.mpn,
        })
      ),
    [
      materialEntityRelationsByDefinerData?.MaterialEntityRelationsByDefiner
        ?.items,
    ]
  );

  const { data } = useQuery(gql(MaterialsByDefiner), {
    variables: { definerID, limit: 5000 },
    skip: findBy !== "mpn",
  });

  const MPNOptions = data?.MaterialsByDefiner?.items;

  const fileredCodes = filterOutSameItemInArray(codes, "code").map(
    (x) => x.code
  );

  const materialOptions = useMemo(
    () =>
      filterOutSameItemInArray(
        codes?.filter((x) => x.code === code),
        "materialID"
      ),
    [codes, code]
  );

  useEffect(() => {
    if (materialOptions?.length === 1) {
      setFieldValue(
        `[${arrayFieldName}][${index}][material]`,
        disableMultiple ? materialOptions[0] : [materialOptions[0]]
      );
    }
  }, [materialOptions, setFieldValue, disableMultiple, arrayFieldName, index]);

  // console.log({ materialOptions });

  return (
    <Stack spacing={1} m={2}>
      <Stack direction="row" spacing={2}>
        {/* <Typography>
          item: {values[arrayFieldName][index]?.itemNumber}
        </Typography> */}

        <FormikRadioGroup
          name={`[${arrayFieldName}][${index}][findBy]`}
          label="Find material by"
          selections={[
            { label: "customer's code", value: "code" },
            { label: "mpn", value: "mpn" },
          ]}
          row
          onChange={(e) => {
            setFieldValue(`[${arrayFieldName}][${index}][code]`, null);
            setFieldValue(
              `[${arrayFieldName}][${index}][material]`,
              disableMultiple ? null : []
            );
          }}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={5}
        alignItems="flex-end"
        width={1400}
        justifyContent="space-between"
      >
        {findBy === "code" ? (
          <Stack direction="row" spacing={3}>
            <FormikAutocomplete
              name={`[${arrayFieldName}][${index}][code]`}
              label="CODE"
              required
              options={fileredCodes}
              // getOptionLabel={(option) => option.code}
              // getPrimaryText={(option) => option.code}
              width={360}
              onChange={(e, v) => {
                // setFieldValue(
                //   "materialOptions",
                //   filterOutSameItemInArray(
                //     codes?.filter((x) => x.code === v),
                //     "materialID"
                //   )
                // );

                setFieldValue(
                  `[${arrayFieldName}][${index}][material]`,
                  disableMultiple ? null : []
                );
              }}
            />
            <FormikAutocomplete
              width={800}
              disableCloseOnSelect={!disableMultiple}
              multiple={!disableMultiple}
              name={`[${arrayFieldName}][${index}][material]`}
              label="material"
              required
              disabled={findBy === "code" && !code}
              options={materialOptions}
              getOptionLabel={(option) => option.mfr + " " + option.mpn}
              getPrimaryText={(option) => option.mfr}
              getSecondaryText={(option) => option.mpn}
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={3}>
            <FormikAutocomplete
              width={1200}
              disableCloseOnSelect={!disableMultiple}
              multiple={!disableMultiple}
              name={`[${arrayFieldName}][${index}][material]`}
              label="material"
              required
              options={MPNOptions}
              getOptionLabel={(option) => option.mfr + " " + option.mpn}
              getPrimaryText={(option) => option.mfr}
              getSecondaryText={(option) => option.mpn}
            />
          </Stack>
        )}

        <FormikArrayRemoveItemButton
          disabled={index === 0 ? true : false}
          onClick={() => remove(index)}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
    </Stack>
  );
}
