import { Box, Typography } from "@mui/material";

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const url =
    "https://www.tedsoquiphoto.com/img-get2/I0000ca0QVPMZxvs/sec=/fit=1200x1200/I0000ca0QVPMZxvs.jpg";
  return null;
  // <Box
  //   // component="img"
  //   sx={{
  //     width: "100%",
  //     height: "100vh",

  //     // maxHeight: { xs: 233, md: 167 },
  //     // maxWidth: { xs: 350, md: 250 },
  //   }}
  //   alt="home"
  //   // src={url}
  // />
}

const index = {
  path: "",
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default index;
