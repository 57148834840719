import { useCallback, useState } from "react";
import * as Yup from "yup";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step0ChooseItems from "./Step0ChooseItems";
import Step1EditItems from "./Step1EditItems";
import { useNavigate, useParams } from "react-router-dom";
import {
  onCreatePayment,
  onCreatePaymentsFromInvoices,
  onCreatePaymentsFromPOs,
} from "../../../inbound_payment/onCreate";
import InvoicePaymentItem from "./component/InvoicePaymentItem";
import POPaymentItem from "./component/POPaymentItem";
import BackdropLoader from "../../../../../component/BackdropLoader";


// import * as ReactDOMServer from "react-dom/server";

const validationSchema = Yup.object().shape({
  // findBy: Yup.string().required(),
  // parts: Yup.array()
  //   .when("findBy", {
  //     is: (value) => {
  //       return value === "byPart";
  //     },
  //     then: Yup.array()
  //       .of(Yup.object())
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 1,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           targetPrice: Yup.number(),
  //           currency: Yup.string(),
  //           // EAU: Yup.number().required("Please enter the EAU"),
  //           // application: Yup.string().required("Please enter the application"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 2,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           vendors: Yup.array()
  //             .min(1, "Please assign at least one vendor")
  //             .required("Please select at least one vendor"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   }),
  // materials: Yup.array().when("selectionType", {
  //   is: (value) => {
  //     return value === "byMaterial";
  //   },
  //   then: Yup.array()
  //     .of(Yup.object())
  //     .min(1, "Please select at lease one material")
  //     .required("Please select materials"),
  // }),
  // RFQByVendor: Yup.array().of(
  //   Yup.object({
  //     contact: Yup.object().required("Please select contact person"),
  //   })
  // ),
});

const labelOfSumbit = "CREATE PAYMENT";

export default function CreatePaymentForm() {
  const { entityID } = useParams();

  const initialValues = {
    // shippingItems: [{ findBy: "code" }],
    definerID: entityID,
    date: new Date().toISOString("en-US").slice(0, 10),
    disableAddButton: true,
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Items",
      form: Step0ChooseItems,

      isOptional: false,
      fields: ["materials", "parts", "findBy"],
    },
    {
      step: 1,
      label: "Edit and Review",
      form: Step1EditItems,
      isOptional: false,
      fields: [""],

      formArray: (values) => [
        ...(values.paymentType === "INVOICE"
          ? [
              {
                Item: InvoicePaymentItem,
                name: "invoiceItems",
                // width: 600,
                bgcolor: "#fff",
                btnColor: "#3f51b5",
                size: "md",
              },
            ]
          : []),
        ...(values.paymentType === "PO"
          ? [
              {
                Item: POPaymentItem,
                name: "POItems",
                // width: 600,
                bgcolor: "#fff",
                btnColor: "#3f51b5",
                size: "md",
              },
            ]
          : []),
        // ...(values.paymentType === "MANUAL"
        //   ? [
        //       {
        //         Item: ManualPaymentItem,
        //         name: "ManualPaymentItems",
        //         // width: 600,
        //         bgcolor: "#fff",
        //         btnColor: "#3f51b5",
        //         size: "md",
        //       },
        //     ]
        //   : []),
      ],
    },
    // formArray:  [
    //   {
    //     Item: InvoicePaymentItem,
    //     name: "invoiceItems",
    //     // width: "95%",
    //     bgcolor: "#fff",
    //     btnColor: "purple",
    //     size: "md",
    //   },
    // ],
    // },
    // {
    //   step: 2,
    //   label: "Review",
    //   form: Step2Review,
    //   isOptional: false,
    //   fields: [""],
    // },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);

      const {
        date,
        paymentType,
        definerID,
        invoiceItems,
        POItems,
        currency,
        amount,
        desc,
        payee,
      } = values;

      if (paymentType === "INVOICE") {
        await onCreatePaymentsFromInvoices({
          client,
          values: { invoiceItems, definerID, date },
          type: "OUTBOUND",
        });
      }
      if (paymentType === "PO") {
        await onCreatePaymentsFromPOs({
          client,
          values: { POItems, definerID, date },
          type: "OUTBOUND",
        });
      }

      if (paymentType === "MANUAL") {
        const formattedPayee = payee?.abbr || payee;
        const formattedDesc = `${formattedPayee}-${desc} `;
        await onCreatePayment({
          client,
          values: {
            desc: formattedDesc,
            definerID,
            date,
            currency,
            amount,
            type: "OUTBOUND",
          },
        });
        await client.refetchQueries({ include: ["PaymentByType"] });
      }

      setIsLoading(false);

      navigate(-1);
    },
    [navigate]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
