import { useFormikContext, useField } from "formik";
import { FormGroup, FormControlLabel, Switch, Box } from "@mui/material";
import FieldErrorMessage from "./FieldErrorMessage";

export default function FormikSwitch({
  label,
  name,
  width,
  onChange,
  required,
}) {
  const { setFieldValue } = useFormikContext();

  const [field, meta, helpers] = useField({ name });

  const value = field.value;

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              required={required}
              name={name}
              checked={value || false}
              onChange={(e) => {
                setFieldValue(name, e.target.checked);
                onChange && onChange(e.target.checked);
              }}
            />
          }
          label={label}
        />
      </FormGroup>
      <Box sx={{ height: "5px" }}>
        <FieldErrorMessage name={name} meta={meta} />
      </Box>
    </>
  );
}
