export const createReceivingAndShipping = /* GraphQL */ `
  mutation CreateReceivingAndShipping(
    $input: CreateReceivingAndShippingInput!
    $condition: ModelReceivingAndShippingConditionInput
  ) {
    createReceivingAndShipping(input: $input, condition: $condition) {
      id
      date
      number
      timeStamp
      itemNumber
      qty
      warehouseCode
      type
      poID
      coo

      materialID

      materialEntityRelationID

      courierID
      trackingNumber
      warehouseStaffID
      driverID
      creatorID
      definerID
      billingStatus
      timeStamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const ShippingByTypeAndMaterial = /* GraphQL */ `
  query ShippingByTypeAndMaterial(
    $type: String!
    $materialID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivingAndShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ShippingByTypeAndMaterial(
      type: $type
      materialID: $materialID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        no @client

        date
        number
        itemNumber
        timeStamp
        qty
        warehouseCode
        type
        coo
        poID
        po {
          id
          no @client
          number
          itemNumber
          customer {
            id
            abbr @client
            name
            alias
          }
        }
        materialID
        material {
          id
          mfr
          mpn
          unit
          desc
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        courierID
        courier {
          id
          name
          alias
          abbr @client
        }
        trackingNumber
        warehouseStaffID
        driverID
        creatorID
        definerID
        billingStatus
        createdAt
        updatedAt
        invoice {
          items {
            id
            number
            itemNumber
          }
        }
        __typename
      }

      nextToken
      __typename
    }
  }
`;
export const deleteReceivingAndShipping = /* GraphQL */ `
  mutation DeleteReceivingAndShipping(
    $input: DeleteReceivingAndShippingInput!
    $condition: ModelReceivingAndShippingConditionInput
  ) {
    deleteReceivingAndShipping(input: $input, condition: $condition) {
      id
      date
      number
      qty

      __typename
    }
  }
`;

export const getReceivingAndShipping = /* GraphQL */ `
  query GetReceivingAndShipping($id: ID!) {
    getReceivingAndShipping(id: $id) {
      id
      date
      number
      itemNumber
      qty
      warehouseCode
      type
      poID
      coo
      timeStamp
      po {
        id
        number
        itemNumber

        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID

        __typename
      }
      materialID
      material {
        id
        definerID
        mpn
        mfr
        unit
      }
      materialEntityRelationID
      materialEntityRelation {
        id
        entityID
        materialID
        code
        statusCode

        __typename
      }

      courierID
      trackingNumber
      warehouseStaffID
      driverID
      creatorID
      definerID
      billingStatus
      invoice {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ShippingByNumber = /* GraphQL */ `
  query ShippingByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivingAndShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ShippingByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coo
        timeStamp

        invoice {
          items {
            id
            number
            itemNumber
            date
            category
            price
            qty
            currency
          }
        }

        packingDetails {
          poNumber
          poID
          gcas
          desc
          mfr
          mpn
          ctnFrom
          ctnTo
          qty
          unit
          coo
          l
          w
          h
          nw
          gw
          dimensionUnit
          weightUnit
        }
        date
        number
        itemNumber
        qty
        warehouseCode
        type
        poID
        po {
          id
          number
          itemNumber
          incoTerms {
            terms
            entityUserRelationID
          }
          paymentTerms
          no @client
          customer {
            id
            name
            alias
            abbr @client
          }
          vendor {
            id
            name
            alias
            abbr @client
          }
        }
        materialID
        material {
          id
          mfr
          mpn
          unit
          desc
          specifications {
            attr
            order
            predicator
            specValueFrom
            specValueTo
            specValueEqual
            testValueFrom
            testValueTo
            testValueEqual
            unit
            digits
          }
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }

        courierID
        courier {
          id
          name
          alias
          abbr @client
        }
        trackingNumber
        warehouseStaffID
        driverID
        creatorID
        definerID
        billingStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const updateReceivingAndShipping = /* GraphQL */ `
  mutation UpdateReceivingAndShipping(
    $input: UpdateReceivingAndShippingInput!
    $condition: ModelReceivingAndShippingConditionInput
  ) {
    updateReceivingAndShipping(input: $input, condition: $condition) {
      id
      coo
      timeStamp

      date
      number
      itemNumber
      qty
      warehouseCode
      type
      poID

      materialID

      materialEntityRelationID

      courierID
      trackingNumber
      warehouseStaffID
      driverID
      creatorID
      definerID
      billingStatus
      invoice {
        nextToken
        __typename
      }
      packingDetails {
        poID
        poNumber
        gcas
        mfr
        mpn
        desc
        ctnFrom
        ctnTo
        qty
        unit
        coo
        l
        w
        h
        nw
        gw
        dimensionUnit
        weightUnit
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
