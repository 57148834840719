import { useEffect} from "react";
import { useApolloClient ,useReactiveVar} from "@apollo/client";
import { Stack } from "@mui/material";
import { gql } from "@apollo/client";
import { userEntityRelationVar } from "../../../../../client/cache";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
} from "../../../../../component/formikForm";
import {
  relationsByDefiner,
  getEntityUserRelation,
  POByCustomerAndOpenQty,
  // POByDefinerID,
} from "./query";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export default function Step0ChooseItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const { step } = values;

  const client = useApolloClient();
  const { definerID, POs, entityID } = values;

  const {subsidiaryIDs} = useReactiveVar(userEntityRelationVar);
  
  useEffect(() => {
    if (step !== 0) {
      console.log({ step });
      setFieldValue("step", 0);
    }
    // setErrors({});
  }, [setFieldValue, setErrors, step]);

  useEffect(() => {
    async function func() {
      const IDs = [entityID, ...(subsidiaryIDs || [])];
      const POs = (
        await Promise.all(
          IDs.map(async (id) => {
            const { data } = await client.query({
              query: gql(POByCustomerAndOpenQty),
              variables: { customerID: id, openQty: { gt: 0 } },
            });

            return data?.POByCustomerAndOpenQty?.items || [];
          })
        )
      )?.flat();
      setFieldValue("POs", POs);
    }

    func();
  }, [entityID, subsidiaryIDs, setFieldValue, client]);

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="date"
          label="Date"
          type="date"
          width={180}
          required
        />
        <FormikField
          name="number"
          label="Number"
          type="number"
          width={180}
          required
        />

        <FormikAutocomplete
          name="vendor"
          label="Vendor"
          options={filterOutSameItemInArray(POs, "vendorID")?.map(
            (x) => x.vendor
          )}
          getOptionLabel={(option) => option.abbr}
          required
          getPrimaryText={(option) => option.abbr}
          // getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={300}
          onChange={(e, v) => {
            setFieldValue(
              "avaiablePOs",
              POs?.filter((x) => x.vendorID === v?.id)
            );
            setFieldValue("items", [{}]);
          }}
          getOptionDisabled={null}
        />
      </Stack>
    </Stack>
  );
}
