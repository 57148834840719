import { InMemoryCache, makeVar } from "@apollo/client";

import moment from "moment";
import {
  entityTypeSelection,
  warehouseSelection,
} from "../settings/formFieldEnum";



export const showInsightVar = makeVar(false);
export const runInventoryCheckVar = makeVar(false);
export const userVar = makeVar("loading");
export const userEntityRelationVar = makeVar("loading");

const cache = new InMemoryCache({
  typePolicies: {
    Entity: {
      fields: {
        label: {
          read(_, { readField }) {
            const value = readField("type");
            const type = entityTypeSelection.find((x) => x.value === value);
            return type?.label || "";
          },
        },
        abbr: {
          read(_, { readField }) {
            const name = readField("name");
            const alias = readField("alias");
            return alias || name;
          },
        },
      },
    },
    Invite: {
      fields: {
        name: {
          read(_, { readField }) {
            const fn = readField("fn");
            const ln = readField("ln");

            return ln && fn ? `${ln}, ${fn}` : "";
          },
        },
        invitedAt: {
          read(_, { readField }) {
            const date = readField("createdAt");
            return moment(date).format("MMM Do YY");
          },
        },
      },
    },
    PO: {
      fields: {
        no: {
          read(_, { readField }) {
            const number = readField("number");
            const itemNumber = readField("itemNumber");

            return `${number}-${itemNumber}`;
          },
        },
        amount: {
          read(_, { readField }) {
            const price = readField("price");
            const qty = readField("qty");

            return (price * qty).toFixed(3) * 1;
          },
        },
      },
    },
    Invoice: {
      fields: {
        no: {
          read(_, { readField }) {
            const number = readField("number");
            const itemNumber = readField("itemNumber");

            return `${number}-${itemNumber}`;
          },
        },
        amount: {
          read(_, { readField }) {
            const price = readField("price");
            const qty = readField("qty");

            return (price * qty).toFixed(3) * 1;
          },
        },
      },
    },
    ReceivingAndShipping: {
      fields: {
        no: {
          read(_, { readField }) {
            const number = readField("number");
            const itemNumber = readField("itemNumber");

            return `${number}-${itemNumber}`;
          },
        },
      },
    },
    Note: {
      fields: {
        date: {
          read(_, { readField }) {
            const createdAt = readField("createdAt");
            return moment(createdAt).format("L");
          },
        },
      },
    },
    Update: {
      fields: {
        date: {
          read(_, { readField }) {
            const createdAt = readField("createdAt");
            return moment(createdAt).format("L");
          },
        },
      },
    },
    User: {
      fields: {
        name: {
          read(_, { readField }) {
            const fn = readField("fn");
            const ln = readField("ln");

            return fn ? `${ln ? ln : ""}${ln ? ", " : ""}${fn}` : "";
          },
        },
      },
    },
    InventoryHistory: {
      fields: {
        warehouse: {
          read(_, { readField }) {
            const warehouseCode = readField("warehouseCode");

            // console.log({ warehouseCode, warehouseSelection })

            // return '888'

            return warehouseSelection.find((x) => x.code == warehouseCode)
              ?.name;
          },
        },
      },
    },
    // ETAResponse: {
    //   fields: {
    //     name: {
    //       read(_, { readField }) {
    //         const responderID = readField("responderID");

    //         async function func() {
    //           const { data } = await API.graphql({
    //             query: getUser,
    //             variables: { id: responderID },
    //           });

    //           console.log({ data });

    //           return data?.getUser?.fn;
    //         }

    //         func();
    //       },
    //     },
    //   },
    // },

    // ThirdPartyUserRelation: {
    //   fields: {
    //     name: {
    //       read(_, { readField }) {
    //         const fn = readField("shownFn") || "dummyFn";
    //         const ln = readField("shownLn") || "dummyLn";
    //         return ln && fn ? `${ln}, ${fn}` : "";
    //       },
    //     },
    //   },
    // },
    Offer: {
      fields: {
        targetPrice: {
          read(_, { readField }) {
            const TP = readField("TP");
            const TPCurrency = readField("TPCurrency");
            return TPCurrency && TP ? `${TPCurrency} ${TP}` : "";
          },
        },
        offer: {
          read(_, { readField }) {
            const price = readField("price");
            const currency = readField("currency");
            return currency && price ? `${currency} ${price}` : "";
          },
        },
      },
    },
  },
});

export default cache;
