import { gql } from "@apollo/client";
// import { v4 as uuidv4 } from "uuid";
import { onCreate } from "../../../function/onCreate";
import { createUser, userByEmail } from "./query";

export default async function onCreateContact({ client, values }) {
  const { id, email, fn, mn, ln, phoneNumber, address, entityID, definerID } =
    values;

  // FETCH USER

  const { data: getUserData } = await client.query({
    query: gql(userByEmail),
    variables: { email },
    fetchPolicy: "network-only",
  });

  console.log({ getUserData });

  let { id: userID } = getUserData?.userByEmail?.items[0] || {};

  // CREATE USER IF USER DOES NOT EXIST

  if (userID) {
    alert("user already exists");
    return userID;
  } else {
    // userID = uuidv4();
    console.log("create new user");
    // debugger;

    const inputForCreateContact = {
      // id: userID,
      id,
      email,
      sub: "PENDING",
      // owner: userID,
      fn,
      ln,
      mn,
      phoneNumber: phoneNumber || undefined,
      address,
      definerID: entityID || definerID,
      // groupToEdit: ["admin", `${entityID}-admin`],
      // groupToDelete: ["admin"],
    };

    const fragment = /* GraphQL */ `
      fragment NewUser on User {
        id
      }
    `;

    const { data, errors: createUserErrors } = await onCreate({
      input: inputForCreateContact,
      mutation: createUser,
      fragment,
      updateCacheFields: ["contactsByDefiner"],
      client,
      refetchQueries: [],
    });

    return data.createUser?.id;

    // console.log({ createUserData, createUserErrors });
  }

  // CREATE THIRD PARTY USER RELATIONSHIP

  // debugger;
}
