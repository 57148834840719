import { useEffect, useMemo } from "react";
import {
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import groupArrayofObjectsByKeyAndGenerateArr from "../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";

export default function Summary({ setFieldValue, values }) {
  let { step, inquiries } = values;

  const parts = filterOutSameItemInArray(
    inquiries.map((x) => x.material)?.flat(),
    "id"
  );

  const byContact = parts
    .map((part) => {
      const { id: partID, code, relationID, materialID, mfr, mpn } = part;

      return part.vendors.map((vendorRelation) => {
        const {
          id: relationsID,
          relation,
          entity1,
          entity2,

          vendorCode,
        } = vendorRelation;

        return vendorRelation.contacts?.map((contact) => {
          const { id, name, email, entityID, sub } = contact;
          return {
            contactID: id,
            contact: { id, name, email, sub, entityID },
            part: { id: partID, code, relationID, materialID, mfr, mpn },
            vendorRelation: {
              customerID: entity1.id,
              customerName: entity1.name,
              customerAlias: entity1.alias,
              vendorID: entity2.id,
              vendorName: entity2.name,
              vendorAlias: entity2.alias,
              vendorCode,
            },
          };
        });
      });
    })
    .flat(2);

  const groupByContact = groupArrayofObjectsByKeyAndGenerateArr(
    byContact,
    "contactID"
  );

  return (
    <Stack spacing={5} alignItems="flex-start">
      <Typography sx={{ alignSelf: "center" }} variant="h5">
        Summary
      </Typography>
      {groupByContact?.map((x) => (
        <Stack key={x.contactID} spacing={2}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">
              {x.items[0].contact.name} {x.items[0].contact.email}{" "}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            {x.items.map((y) => (
              <Stack key={y.part.id} direction="row" spacing={2}>
                <Typography variant="h6">
                  {y.part.code || ""} {y.part.mfr} {y.part.mpn}
                </Typography>
                <Typography variant="h6">
                  customer:
                  {y.vendorRelation.customerAlias ||
                    y.vendorRelation.customerName}
                </Typography>
                <Typography variant="h6">
                  vendor:
                  {y.vendorRelation.vendorAlias || y.vendorRelation.vendorName}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
