import groupArrayofObjectsByKeyAndGenerateObj from "../lib/groupArrayofObjectsByKeyAndGenerateObj";
import entityModules from "../modules/entityModules";
import systemAdminModules from "../modules/systemAdminModules";

export default function getSideNav({ entityType, userRole, isAuthenticated }) {
  const auth = !!isAuthenticated ? "authenticated" : "unAuthenticated";

  if (!userRole || !entityType) return;

  const retrivedModules = [...entityModules, ...systemAdminModules]
    .filter((x) => !!x.sideNav)
    .map((x) => ({ ...x.sideNav, path: x.path }));

  const nav = retrivedModules.filter(
    (x) =>
      (x.allowedRole === "all" ||
        x.allowedRole?.some((y) => {

          // console.log("y", y);
          // console.log("userRole", userRole);

          return userRole?.includes(y);
        })) &&
      (x.entityType === "all" || x.entityType?.some((z) => entityType === z)) &&
      (x.auth === "all" || x.auth?.some((a) => a === auth))
  );

  // nav.sort((a, b) => (a.group < b.group ? 1 : -1));

  let groupedSideNav = groupArrayofObjectsByKeyAndGenerateObj(nav, "group");

  groupedSideNav = Object.values(groupedSideNav);

  groupedSideNav.sort((a, b) => (a[0]?.group > b[0]?.group ? 1 : -1));
  groupedSideNav?.forEach((arr) => {
    arr.sort((a, b) => (a.order > b.order ? 1 : -1));
  });

  return groupedSideNav;
}
