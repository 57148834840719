import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider, ApolloClient } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { Amplify, Storage, I18n } from "aws-amplify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import awsExports from "./aws-exports";
import dictionary from "./settings/dictionary";
import link from "./client/link";
import cache from "./client/cache";
import DialogListener from "./component/listeners/DialogListener";
import FullScreenMessageListener from "./component/listeners/FullScreenMessageListener";
import SnackbarListener from "./component/listeners/SnackbarListener";
// import "./App.css";

Amplify.configure(awsExports);
Storage.configure({ level: "public" });
I18n.putVocabularies(dictionary);

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#040e64",
    },
    secondary: {
      main: "#9c158f",
    },
    background: {
      default: "#ffffff",
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
          <DialogListener />
          <FullScreenMessageListener />
          <SnackbarListener />
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
