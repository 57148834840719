import CreateForm from "./components/CreateForm";

const arbitaryEmail = {
  path: "arbitaryEmail",
  sideNav: {
    group: 6,
    order: 30,
    label: "Arbitary Email",
    entityType: "all",
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: <CreateForm />,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default arbitaryEmail;
