import { gql, useReactiveVar } from "@apollo/client";

import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { userEntityRelationVar } from "../../../../client/cache";
import createMaterial from "../../materials/onCreate";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "partAndGCASmapping.csv";
  const path = `${entityID}/template/${filename}`;


  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  { label: "MFR", isRequired: true },
  { label: "MPN", isRequired: true },
  { label: "MPQ", isRequired: true },
  { label: "UNIT", isRequired: true },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      const values = {
        mfr: row.MFR,
        mpn: row.MPN,
        mpq: row.MPQ,
        unit: row.UNIT,
        definerID: definerID,
      };
      await createMaterial({ client, values });
    })
  );
}
