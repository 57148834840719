export const invoiceByType = /* GraphQL */ `
  query InvoiceByType(
    $type: String!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByType(
      type: $type
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        type
        number
        itemNumber
        date
        price
        qty
        currency
        note
        desc
        poID
        amount @client
        po {
          id
          date
          number
          vendor {
            id
            name
            alias
            abbr @client
          }
        }
        paymentDueDate
        billingEntityID
        shippingID
        paymentStatus
        deductionDate
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const entitiesByDefiner = /* GraphQL */ `
  query EntitiesByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isVerified
        name
        alias
        abbr @client
        address
        phoneNumber
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const POByVendorID = /* GraphQL */ `
  query POByVendorID(
    $vendorID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByVendorID(
      vendorID: $vendorID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        payments {
          items {
            id
            desc
            date
            amount
            currency
          }
        }
        date
        offerID
        customerID
        no @client
        amount @client
        pi {
          id
          price
          currency
          qty
        }

        vendorID
        definerID
        materialID
        material {
          id
          mfr
          mpn
          importDutyCN
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        incoTerms {
          terms
        }
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        importDutyCN
        serviceFeeRate
        minCharge
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        entity2 {
          id
          name
          alias
          abbr @client
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        serviceFeeRate
        minCharge
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
