export default function isArrayIdentical(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const duplicatedA = [...a].sort();
  const duplicateB = [...b].sort();

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < duplicatedA.length; ++i) {
    if (duplicatedA[i] !== duplicateB[i]) return false;
  }
  return true;
}
