import { useCallback, useState } from "react";
import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import * as Yup from "yup";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step0SelectMaterial from "./Step0SelectMaterial";
import Step1SelectOffers from "./Step1SelectOffers";
import Step2SelectEntity from "./Step2SelectEntity";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import onCreate from "../../onCreate";
import InquiryItem from "./Array/InquiryItem";
import POItemSubArrayCRD from "./Array/POItemSubArrayCRD";
import BackdropLoader from "../../../../../component/BackdropLoader";
import {
  getEntity,
  getUser,
  getEntityUserRelation,
  getMaterial,
  getMaterialEntityRelation,
} from "./query";

// import * as ReactDOMServer from "react-dom/server";

const validationSchema = Yup.object().shape({
  step: Yup.number().required(),

  POItem: Yup.array()
    .when("step", {
      is: (value) => value === 0,
      then: Yup.array().of(
        Yup.object().shape({
          material: Yup.object()
            .shape({
              vendors: Yup.array(),
            })
            .required("Please select material")
            .nullable(),

          CRD: Yup.array()
            .of(
              Yup.object().shape({
                CRD: Yup.string().required("Please select CRD"),
                qty: Yup.number().required("Please enter qty"),
              })
            )
            .min(1, "Please enter at least one CRD line")
            .required("Please enter CRD")
            .nullable(),
        })
      ),
    })
    .when("step", {
      is: (value) => value === 1,
      then: Yup.array().of(
        Yup.object().shape({
          price: Yup.number().required("Please enter price").nullable(),
          currency: Yup.string().required("Please select currency").nullable(),
        })
      ),
    })
    .when("step", {
      is: (value) => value === 2,
      then: Yup.array().of(
        Yup.object().shape({
          sales: Yup.object().required("Please select sales").nullable(),
          buyer: Yup.object().required("Please select buyer").nullable(),
          customer: Yup.object().required("Please select customer").nullable(),
          vendor: Yup.object().required("Please select vendor").nullable(),
          incoTerms: Yup.object()
            .required("Please select incoTerms")
            .nullable(),
          paymentTerms: Yup.string()
            .required("Please select paymentTerms")
            .nullable(),
        })
      ),
    }),
});

const labelOfSumbit = "CREATE PO";

export default function CreateSPOForm() {
  const { entityID } = useParams();

  const defaultLineItem = {
    material: {},
    price: null,
    currency: null,
    CRD: [{}],
    sales: null,
    buyer: null,
    customer: null,
    vendor: null,
    incoTerms: null,
    paymentTerms: null,
    emailDelivery: true,
  };

  const initialValues = {
    isEditing: true,
    POItem: [defaultLineItem],
    definerID: entityID,
    disableMultiple: true,
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Material",
      form: Step0SelectMaterial,
      formArray: [
        {
          Item: InquiryItem,
          defaultValue: defaultLineItem,
          name: "POItem",
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
          subArray: [
            {
              Item: POItemSubArrayCRD,
              name: "CRD",
              // width: 600,
              bgcolor: "#f3e5f5",
              btnColor: "blue",
              size: "sm",
            },
          ],
        },
      ],

      isOptional: false,
      fields: ["POItem"],
    },
    {
      step: 1,
      label: "Select Offers",
      form: Step1SelectOffers,
      isOptional: false,
      fields: ["POItem"],
    },
    {
      step: 2,
      label: "Select Entity",
      form: Step2SelectEntity,
      isOptional: false,
      fields: ["POItem"],
    },

    {
      step: 3,
      label: "Summary",
      form: Summary,
      isOptional: false,
      fields: ["RFQByVendor"],
    },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);

      try {
        const { groupedForSubmit } = values;

        const groupedForEmail = await Promise.all(
          groupedForSubmit.map(async (x) => {
            const { data: getVendorData } = await client.query({
              query: gql(getEntity),
              variables: { id: x[0].vendorID },
            });
            const {
              name: vendorName,
              address: vendorAddress,
              phoneNumber: vendorPhoneNumber,
            } = getVendorData?.getEntity || {};

            const { data: getCustomerData } = await client.query({
              query: gql(getEntity),
              variables: { id: x[0].customerID },
            });
            const {
              name: customerName,
              address: customerAddress,
              phoneNumber: customerPhoneNumber,
            } = getCustomerData?.getEntity || {};

            const { data: getSalesData } = await client.query({
              query: gql(getUser),
              variables: { id: x[0].salesID },
            });
            const {
              fn: salesFn,
              ln: salesLn,
              email: salesEmail,
              name: salesName,
              phoneNumber: salesPhoneNumber,
            } = getSalesData?.getUser;

            const { data: getBuyerData } = await client.query({
              query: gql(getUser),
              variables: { id: x[0].buyerID },
            });
            const {
              fn: buyerFn,
              ln: buyerLn,
              name: buyerName,
              email: buyerEmail,
              phoneNumber: buyerPhoneNumber,
            } = getBuyerData?.getUser || {};

            const { data: entityUserDataForReceiving } = await client.query({
              query: gql(getEntityUserRelation),
              variables: { id: x[0].incoTerms?.entityUserRelationID },
            });

            const { name: receiverCompanyName } =
              entityUserDataForReceiving?.getEntityUserRelation?.entity;

            const {
              fn: receiverFn,
              ln: receiverLn,
              name: receiverName,
              phoneNumber: receiverPhoneNumber,
              address: receiverAddress,
            } = entityUserDataForReceiving?.getEntityUserRelation?.user;

            let forwarderName;

            if (x[0].forwarderID) {
              const { data: getForwarderData } = await client.query({
                query: gql(getEntity),
                variables: { id: x[0].forwarderID },
              });
              forwarderName = getForwarderData?.getEntity?.name;
            }

            return {
              date: x[0].date,
              number: x[0].number,
              emailDelivery: x[0].emailDelivery,
              emailRecipients: x[0].emailRecipients?.map((x) => x.email),

              // vendorID: x[0].vendorID,
              vendorCode: x[0].vendorCode,
              vendorName,
              vendorAddress,
              vendorPhoneNumber,
              // salesID: x[0].salesID,
              salesFn,
              salesLn,
              salesName,
              salesEmail,
              salesPhoneNumber,
              // salesAddress: sales?.address,
              // buyerID: x[0].buyerID,
              buyerFn,
              buyerLn,
              buyerName,
              buyerEmail,
              buyerPhoneNumber,
              // buyerAddress: buyer?.address,
              // customerID: x[0].customerID,
              customerName,
              customerAddress,
              customerPhoneNumber,

              receiverCompanyName,
              receiverFn,
              receiverLn,
              receiverName,
              receiverPhoneNumber,
              receiverAddress,

              // forwarderID: x[0].forwarderID,
              // forwarderName,
              paymentTerms: x[0].paymentTerms,
              incoTerms: x[0].incoTerms?.terms,
              items: await Promise.all(
                x.map(async (item) => {
                  const { data: getMaterialData } = await client.query({
                    query: gql(getMaterial),
                    variables: { id: item.materialID },
                  });
                  const { mfr, mpn, unit, mpq } =
                    getMaterialData?.getMaterial || {};

                  let code;
                  if (item.materialEntityRelationID) {
                    const { data: getMaterialEntityRelationData } =
                      await client.query({
                        query: gql(getMaterialEntityRelation),
                        variables: { id: item.materialEntityRelationID },
                      });
                    code =
                      getMaterialEntityRelationData?.getMaterialEntityRelation
                        ?.code;
                  }

                  return {
                    itemNumber: item.itemNumber,
                    materialID: item.materialID,
                    materialEntityRelationID: item.materialEntityRelationID,
                    qty: item.qty,
                    price: item.price,
                    priceCur: item.priceCur,
                    CRD: item.CRD,
                    openQty: item.openQty,
                    mfr,
                    mpn,
                    unit,
                    mpq,
                    code,
                  };
                })
              ),
            };
          })
        );

        // console.log({ groupedForEmail });

        const res = await API.post("iSupplyAPI", "/restricted", {
          body: {
            operation: "SEND_SPO",
            data: {
              items: groupedForEmail,
              meta: { bcc: "steven.lu@hankeno.com, angel.an@hankeno.com" },
            },
          },
        });
        // console.log({ res });

        await Promise.all(
          groupedForSubmit.map(async (arr) => {
            await Promise.all(
              arr.map(async (item) => {
                const {
                  date,
                  number,
                  itemNumber,
                  materialID,
                  materialEntityRelationID,
                  salesID,
                  buyerID,
                  vendorID,
                  customerID,
                  vendorCode,
                  paymentTerms,
                  incoTerms,
                  forwarderID,
                  CRD,
                  price,
                  qty,
                  openQty,
                  priceCur,
                  version,
                  statusCode,
                } = item;
                const values = {
                  date,
                  number,
                  itemNumber,
                  materialID,
                  materialEntityRelationID,
                  salesID,
                  buyerID,
                  vendorID,
                  customerID,
                  definerID: entityID,
                  vendorCode,
                  paymentTerms,
                  incoTerms,
                  forwarderID,
                  CRD,
                  price,
                  qty,
                  openQty,
                  priceCur,
                  billingStatus: "OPEN",
                  // version: 0,
                  // statusCode: "010",
                };
                // console.log({ values });
                // debugger;
                await onCreate({ values, client });
              })
            );
          })
        );
      } catch (err) {
        console.log({ error888773: err });
        setIsLoading(false);
      }

      setIsLoading(false);

      navigate(-1);
    },
    [navigate, entityID]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
