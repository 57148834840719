import { useApolloClient } from "@apollo/client";
import * as Yup from "yup";
import { Stack, Typography, MenuItem } from "@mui/material";
import FormikForm, {
  FormikField,
  FormikSelect,
} from "../../../../component/formikForm";
import onDeletePO from "../onDelete";
import onUpdate from "../onUpdate";
import { currencies, POStatusCode } from "../../../../settings/formFieldEnum";

const validationSchema = Yup.object().shape({
  price: Yup.number().min(0).required("Please enter Price"),
  priceCur: Yup.string().required("Please select Currency"),
});

export default function Item({ data, userRole, onClose }) {
  const isAdmin = userRole?.includes("admin");
  const client = useApolloClient();

  const onDeleteBtnClick = async () => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      await onDeletePO({ values: data, client });
      onClose();
    }
  };

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    });
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onDelete={isAdmin ? onDeleteBtnClick : null}
      onSubmit={isAdmin ? onSubmit : null}
      disableEdit={isAdmin ? false : true}
    />
  );
}

const EditFields = ({ values }) => {
  const { isEditing, customer, vendor, material, materialEntityRelation } =
    values || {};
  const billingStatus = ["OPEN", "CLOSED"];
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={5}>
        <Typography> CUSTMER: {customer?.name || customer?.alias}</Typography>
        <Typography> VENDOR: {vendor?.name || vendor?.alias}</Typography>
      </Stack>
      <Stack direction="row" spacing={5}>
        <Typography> CODE: {materialEntityRelation?.code}</Typography>
        <Typography> MFR: {material?.mfr}</Typography>
        <Typography> MPN: {material?.mpn}</Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormikField
          width={120}
          name="date"
          label="Date"
          type="date"
          disabled={!isEditing}
        />

        <FormikField
          width={120}
          name="number"
          label="Number"
          disabled={!isEditing}
        />
        <FormikField
          width={120}
          name="itemNumber"
          label="itemNumber"
          disabled={!isEditing}
        />

        <FormikSelect
          label="Currency"
          required
          name="priceCur"
          options={currencies}
          labelKey="label"
          valueKey="value"
          width={120}
          disabled={!isEditing}
        />
        <FormikField
          width={120}
          name="price"
          label="Price"
          type="number"
          disabled={!isEditing}
        />
      </Stack>

      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          width={180}
          name="qty"
          label="Qty"
          type="number"
          disabled={!isEditing}
        />

        <FormikField
          width={180}
          name="openQty"
          label="Open Qty"
          type="number"
          disabled={!isEditing}
        />
        <FormikField
          width={200}
          name="CRD"
          label="CRD"
          type="date"
          disabled={!isEditing}
        />
        <FormikField
          disabled={!isEditing}
          width={200}
          name="billingStatus"
          label="Billing Status"
          select
        >
          {billingStatus.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </FormikField>
        <FormikSelect
          label="statusCode"
          name="statusCode"
          width={200}
          options={POStatusCode}
          required
          labelKey="label"
          valueKey="value"
          disabled={!isEditing}
        />
      </Stack>
    </Stack>
  );
};
