import { Typography, Container, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";

export default function Home() {
  const [url, setUrl] = useState("");
  useEffect(() => {
    async function func() {
      try {
        const url = await Storage.get("hero.mp4", {
          download: false,
          validateObjectExistence: true,
          // level: "public",
        });
        setUrl(url);

        // window.location.href = url;
      } catch (err) {
        alert({ downloaderr: err });
      }

      const vid = document.getElementById("video");

      if (vid) {
        vid.playbackRate = 0.8;
      }
    }
    func();
  }, []);


  return (
    <Box
      sx={{
        backgroundSize: "4px 4px",
        backgroundImage: "radial-gradient(black 10%, transparent 30%)",
        width: "100%",
        height: "100vh",
      }}
    >
      {url && (
        <>
          <video
            style={{
              position: "relative",
              zIndex: -1,
              objectFit: "cover",
            }}
            autoPlay
            loop
            muted
            id="video"
            width="100%"
            height="100%"
          >
            <source src={url} type="video/mp4" />
          </video>

          <Container
            sx={{
              position: "absolute",
              left: 0,
              top: "60%",
              right: 0,
              bottom: "10%",
              // border: 2,
              // borderColor: "red",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
              // backgroundColor: "red",
            }}
            // maxWidth="sm"
            // fixed
          >
            <Typography
              color="white"
              variant="h1"
              sx={{ fontWeight: 400, opacity: 0.9, letterSpacing: 10 }}
            >
              NEXT GEN PLATFORM
            </Typography>

            <Button
              disableElevation
              // disableFocusRipple
              disableRipple
              onClick={() => {}}
              size="large"
              color="secondary"
              variant="contained"
              sx={{
                width: 480,
                opacity: 0.85,
                fontSize: 24,
                letterSpacing: 10,
              }}
            >
              demo will be available soon
            </Button>
          </Container>
        </>
      )}
    </Box>
  );
}
