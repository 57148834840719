import { gql } from "@apollo/client";
import { getMaterialInDepth } from "../query";

export default async function runInsight({
  materialID,
  client,
  fxRate,
  selfAndSubsidiaries,
  definerID,
}) {
  // console.log({definerID})
  // if (i !== 0) return x;
  // console.log({ rows, setRows, client, selfAndSubsidiaries });

  let { data } = await client.query({
    query: gql(getMaterialInDepth),
    variables: {
      id: materialID,
    },
  });

  data = data.getMaterial;

  const materialInv = data.inventory;
  const pricings = data?.offers.items.filter((x) =>
    selfAndSubsidiaries.some(
      (y) => y.id === x.vendorID && x.definerID === definerID
    )
  );

  const costs = data?.offers.items.filter((x) =>
    selfAndSubsidiaries.some(
      (y) => y.id === x.customerID && x.definerID === definerID
    )
  );

  pricings.sort((a, b) => (a.date > b.date ? -1 : 1));
  costs.sort((a, b) => (a.date > b.date ? -1 : 1));

  const latestPricing = pricings[0];
  const latestCost = costs[0];

  // console.log({ latestPricing, latestCost });

  const CPOs = data?.POs.items.filter((x) =>
    selfAndSubsidiaries.some(
      (y) => y.id === x.vendorID && x.definerID === definerID
    )
  );

  const openCPOQty = CPOs.reduce((a, v) => a + (v.openQty * 1 || 0), 0);
  const CPOValue =
    CPOs.reduce((a, v) => a + (v.openQty * v.price || 0), 0).toFixed(2) * 1;

  const SPOs = data?.POs.items.filter((x) =>
    selfAndSubsidiaries.some(
      (y) => y.id === x.customerID && x.definerID === definerID
    )
  );
  const openSPOQty = SPOs.reduce((a, v) => a + (v.openQty * 1 || 0), 0);
  const SPOValue = SPOs.reduce(
    (a, v) =>
      a +
      (
        v?.openQty * v?.price * (v?.priceCur === "CNY" ? 1 : fxRate) || 0
      ).toFixed(2) *
        1,
    0
  );

  CPOs.sort((a, b) => (a.date > b.date ? -1 : 1));
  SPOs.sort((a, b) => (a.date > b.date ? -1 : 1));

  const { price: pricing, currency: pricingCurrency } = latestPricing || {};
  const { price: cost, currency: costCurrency } = latestCost || {};

  let cnyPricing = pricing;
  let cnyCost = cost;

  if (pricing && !isNaN(pricing) && pricingCurrency !== "CNY") {
    cnyPricing = (pricing * fxRate).toFixed(2) * 1;
  }

  if (cost && !isNaN(cost) && costCurrency !== "CNY") {
    cnyCost = (cost * fxRate).toFixed(2) * 1;
  }

  const rate =
    cnyCost && cnyPricing && !isNaN(cnyCost) && !isNaN(cnyPricing)
      ? ((cnyPricing - cnyCost) / cnyPricing).toFixed(2) * 1
      : "";
  const mg =
    !isNaN(cnyCost) && !isNaN(cnyPricing)
      ? (openCPOQty * (cnyPricing - cnyCost)).toFixed(2) * 1
      : "";

  // console.log({ data, CPOs, SPOs, pricing, cost, rate, mg });

  const excessive = openSPOQty + (materialInv || 0) - openCPOQty;
  return {
    openCPOQty,
    openSPOQty,
    CPOValue,
    SPOValue,
    pricing,
    cost,
    cnyPricing,
    cnyCost,
    rate,
    mg,
    excessive,
    // excessive: excessive > 0 ? excessive : null,

  };
}
