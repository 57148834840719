import { useState } from "react";
import {
  Stack,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useFormikContext } from "formik";

import {
  FormikField,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
} from "../../../../component/formikForm";
import filterOutSameItemInArray from "../../../../lib/filterOutSameItemInArray";

const entitiyUserRelationByDefiner = /* GraphQL */ `
  query EntitiyUserRelationByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
        }
        entityID
        entity {
          id
          alias
          name
        }
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function IncoTerms({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  // const entityID = params?.entityID;

  const { values } = useFormikContext();

  const entityID = values.id || "undefined";


  const { definerID, isEditing } = values || {};

  const [range, setRange] = useState("");

  // const operator = isInternal ? "eq" : "ne";

  let { data } = useQuery(gql(entitiyUserRelationByDefiner), {
    variables: {
      definerID,
      ...(range === "internal" && { entityID: { eq: entityID } }),
    },
  });

  let entityUserRelations =
    data?.entitiyUserRelationByDefiner?.items?.map((x) => ({
      ...x.user,
      userID: x.user.id,
      id: x.id,
      entity: x.entity?.alias || x.entity?.name,
      role: x.role,
    })) || [];

  entityUserRelations = filterOutSameItemInArray(entityUserRelations, "id");

  return (
    <Stack
      direction="row"
      spacing={5}
      alignItems="flex-end"
      // sx={{ height: 200, bgcolor: "red" }}
    >
      <FormControl disabled={!isEditing}>
        <Stack direction="row" spacing={2}>
          <FormikField
            name={`[${arrayFieldName}][${index}][terms]`}
            label="IncoTerms"
            size={size}
            disabled={!isEditing}
          />
          <FormLabel id="demo-row-radio-buttons-group-label">contact</FormLabel>
          <RadioGroup
            row
            value={range}
            onChange={(e) => setRange(e.target.value)}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="internal"
              control={<Radio />}
              label="Internal"
            />
            <FormControlLabel value="all" control={<Radio />} label="All" />
          </RadioGroup>
          <FormikAutocomplete
            disabled={!isEditing}
            width={500}
            name={`[${arrayFieldName}][${index}][entityUserRelation]`}
            label="entityUserRelation"
            required
            options={entityUserRelations || []}
            // options={[]}
            getOptionLabel={(option) => {
              const item = entityUserRelations?.find((x) => x.id === option.id);
              return item
                ? `${item.name} @ ${item.entity} as ${item.role}`
                : "";
            }}
            getPrimaryText={(option) =>
              `${option.name} @ ${option.entity} as ${option.role}`
            }
          />
        </Stack>
      </FormControl>

      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        name={arrayFieldName}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}
