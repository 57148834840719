
import { Button } from '@mui/material'
import {  Hub } from 'aws-amplify'
import PackingListDialog from './PackingListDialog'

export default function GeneratePackingBtn({ shippingItems,packingDetails }) {
  async function onClick() {
    Hub.dispatch('dialogMonitor', {
      event: 'showDetails',
      data: {
        Component: PackingListDialog,
        lineData: {shippingItems,packingDetails},
      },
    })
  }

  return (
    <Button variant='contained' onClick={onClick}>
      GENERATE/MODIFY PACKING
    </Button>
  )
}
