import Plans from "./Plans";
// import { UnAuthLayout } from "../../../layout";

const plans = {
  path: "plans",

  topNav: {
    order: 30,
    label: "Plans",
    entityType: ["public"],
    allowedRole: "all",
    auth: ["unAuthenticated"],
  },
  route: {
    index: {
      element: <Plans />,
      allowedRole: "all",
      entityType: ["public", "ebp"],
      auth: ["unAuthenticated"],
    },
    // wrapper: {
    //   element: <UnAuthLayout />,
    //   allowedRole: "all",
    //   entityType: "all",
    //   auth: ["unAuthenticated"],
    // },
  },
};

export default plans;
