import { useCallback, useState } from "react";
import * as Yup from "yup";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step0ChooseItems from "./Step0ChooseItems";
import Step1Review from "./Step1Review";
import { useNavigate, useParams } from "react-router-dom";
import OutboundInvoiceItem from "./Array/OutboundInvoiceItem";
import BackdropLoader from "../../../../../component/BackdropLoader";
import onCreateInvoiceItems from "../../onCreate";
// import * as ReactDOMServer from "react-dom/server";

const validationSchema = Yup.object().shape({
  // findBy: Yup.string().required(),
  // parts: Yup.array()
  //   .when("findBy", {
  //     is: (value) => {
  //       return value === "byPart";
  //     },
  //     then: Yup.array()
  //       .of(Yup.object())
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 1,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           targetPrice: Yup.number(),
  //           currency: Yup.string(),
  //           // EAU: Yup.number().required("Please enter the EAU"),
  //           // application: Yup.string().required("Please enter the application"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 2,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           vendors: Yup.array()
  //             .min(1, "Please assign at least one vendor")
  //             .required("Please select at least one vendor"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   }),
  // materials: Yup.array().when("selectionType", {
  //   is: (value) => {
  //     return value === "byMaterial";
  //   },
  //   then: Yup.array()
  //     .of(Yup.object())
  //     .min(1, "Please select at lease one material")
  //     .required("Please select materials"),
  // }),
  // RFQByVendor: Yup.array().of(
  //   Yup.object({
  //     contact: Yup.object().required("Please select contact person"),
  //   })
  // ),
});

const labelOfSumbit = "CREATE INVOICES";

export default function CreateInvoiceForm() {
  const { entityID } = useParams();

  const initialValues = {
    isEditing: true,
    // items: [{}],
    definerID: entityID,
    entityID,
    date: new Date().toISOString("en-US").slice(0, 10),
    number: Date.now().toString().slice(1, 9),
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Items",
      form: Step0ChooseItems,
      formArray: [
        {
          Item: OutboundInvoiceItem,
          name: "invoiceItem",
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
          // disableAdd: true,
          // subArray: [
          //   {
          //     Item: InboundInvoiceItemSubArray,
          //     name: 'subItems',
          //     // width: 600,
          //     bgcolor: '#f3e5f5',
          //     btnColor: 'blue',
          //     size: 'sm',
          //     disableAdd: true,
          //   },
          // ],
        },
      ],

      isOptional: false,
      fields: ["materials", "parts", "findBy"],
    },
    {
      step: 1,
      label: "Review",
      form: Step1Review,
      isOptional: false,
      fields: [""],
    },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);

      const { preparedData } = values;

      const {
        billingEntityID,
        number,
        batch,
        date,
        category,
        definerID,
        invoiceItems,
      } = preparedData;

      await onCreateInvoiceItems({
        client,
        values: {
          invoiceItems,
          definerID,
          date,
          number,
          batch,
          category,
          billingEntityID,
        },
        type: "OUTGOING",
      });

      // debugger;

      // const res = await API.post("iSupplyAPI", "/restricted", {
      //   body: {
      //     operation: "SEND_RFQ",
      //     data: groupByContact,
      //   },
      // });
      // console.log({ res });
      setIsLoading(false);

      navigate(-1);
    },
    [navigate]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
