import { useEffect } from "react";
import { useApolloClient, useReactiveVar,gql } from "@apollo/client";
import { Stack, MenuItem } from "@mui/material";
import {
  FormikField,
  FormikAutocomplete,
} from "../../../../../component/formikForm";
import { invoiceCategory } from "../../../../../settings/formFieldEnum";

import {
  POByCustomerAndBillingStatus,
  relationsByDefiner,
  entitiesByDefiner,
  // POByDefinerID,
} from "./query";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import { userEntityRelationVar } from "../../../../../client/cache";

export default function Step0ChooseItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const client = useApolloClient();
  const {
    definerID,
    POs,
    vendorOptions,
    defaultLineItem,
    billingEntity,
    entityID,
    category,
  } = values;

  const { subsidiaryIDs, selfAndSubsidiaries } = useReactiveVar(
    userEntityRelationVar
  );

  // console.log({ selfAndSubsidiaries });

  useEffect(() => {
    if (
      selfAndSubsidiaries?.length > 0 &&
      ["SERVICE", "OTHERS"].includes(category) &&
      !billingEntity
    ) {
      setFieldValue(
        "billingEntity",
        selfAndSubsidiaries.find((x) => x.abbr === "HKN CN")
      );
    }
  }, [selfAndSubsidiaries, setFieldValue, category, billingEntity]);

  useEffect(() => {
    async function func() {
      if (category === "DISCOUNTING") {
        const { data: getRelationData } = await client.query({
          query: gql(relationsByDefiner),
          variables: {
            definerID,
            relation: { eq: "PRINCIPAL-CONTRACTOR" },
            filter: { type: { eq: "discountingPayment" } },
          },
        });
        const vendorOptions = getRelationData?.relationsByDefiner?.items?.map(
          (x) => ({
            abbr: x.entity2?.abbr,
            id: x.entity2ID,
            rate: x.serviceFeeRate,
          })
        );
        // console.log({ vendorOptions })
        setFieldValue("vendorOptions", vendorOptions);
        if (vendorOptions?.length === 1) {
          setFieldValue("vendor", vendorOptions[0]);
        }
      } else if (["SERVICE", "OTHERS"].includes(category)) {
        // const IDs = [entityID, ...(subsidiaryIDs || [])];

        // console.log({ IDs });

        const { data: getEntityByDefinerData } = await client.query({
          query: gql(entitiesByDefiner),
          variables: {
            definerID,
          },
        });
        const vendorOptions = getEntityByDefinerData?.entitiesByDefiner?.items;

        // console.log({ vendorOptions })
        setFieldValue("vendorOptions", vendorOptions);
      } else if (["VAT", "PI", "RECEIPT"].includes(category)) {
        const IDs = [entityID, ...(subsidiaryIDs || [])];
        const POs = (
          await Promise.all(
            IDs.map(async (id) => {
              const { data } = await client.query({
                query: gql(POByCustomerAndBillingStatus),
                variables: { customerID: id, billingStatus: { eq: "OPEN" } },
              });

              return data?.POByCustomerAndBillingStatus?.items || [];
            })
          )
        )?.flat();

        const vendorOptions = filterOutSameItemInArray(POs, "vendorID")?.map(
          (x) => x.vendor
        );
        setFieldValue("POs", POs);
        setFieldValue("vendorOptions", vendorOptions);
      }
    }

    func();
  }, [entityID, subsidiaryIDs, setFieldValue, client, definerID, category]);

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="date"
          label="Date"
          type="date"
          width={180}
          required
        />
        <FormikField name="number" label="Number" width={180} required />
        <FormikField
          name="category"
          id="category"
          label="category"
          select
          width={180}
          required
          children={invoiceCategory.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          onChange={(e) => {
            // setFieldValue("category", e.target.value);
            //ATTENTION IF YOU SET THE VENDOR TO NULL AUTOCOMPLETE WONT CHANGE VALUE WHEN FREESOLO IS TRUE
            setFieldValue("vendor", "");
            setFieldValue("vendorOptions", []);

            // setFieldValue("items", [{ subItems: [{}] }]);
          }}
        />

        <FormikAutocomplete
          name="vendor"
          label="Vendor"
          freeSolo={category === "SERVICE" || category === "OTHERS"}
          options={vendorOptions}
          getOptionLabel={(option) => option?.abbr}
          required
          getPrimaryText={(option) => option?.abbr}
          // getSecondaryText={(option) => option.contactName}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={300}
          onChange={(e, v) => {
            if (category === "DISCOUNTING") return;
            setFieldValue(
              "avaiablePOs",
              POs?.filter((x) => x.vendorID === v?.id)
            );
            setFieldValue("items", [defaultLineItem]);
          }}
          getOptionDisabled={null}
        />
        {["SERVICE", "OTHERS"].includes(category) && (
          <FormikAutocomplete
            name="billingEntity"
            label="Billing Entity"
            options={selfAndSubsidiaries}
            getOptionLabel={(option) => option?.abbr}
            // required
            getPrimaryText={(option) => option?.abbr}
            // getSecondaryText={(option) => option.contactName}
            listItemPrimaryField={null}
            listItemSecondaryField={null}
            disabled={false}
            width={300}
            getOptionDisabled={null}
          />
        )}
      </Stack>
    </Stack>
  );
}
