import { useState } from "react";
import { gql,useReactiveVar } from "@apollo/client";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { materialByMPN, createNote } from "../query";
import createMaterialEntityRelation from "../../materials/onCreateRelation";
import { userEntityRelationVar } from "../../../../client/cache";



export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);
  const [isLoading, setIsloading] = useState(false);
  const filename = "partAndGCASmapping.csv";
  const path = `${entityID}/template/${filename}`;

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  { label: "MFR", isRequired: true },
  { label: "MPN", isRequired: true },
  { label: "MPQ", isRequired: true },
  { label: "UNIT", isRequired: true },
  { label: "STATUSCODE", isRequired: true },
  { label: "PG_CN", isRequired: true },
  { label: "PG_EU", isRequired: true },
  { label: "GCAS", isRequired: true },
  { label: "NOTES", isRequired: true },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      let { MFR, MPN, STATUSCODE, NOTES, PG_CN, PG_EU, GCAS } = row;
      GCAS = GCAS?.split(",").map((x) => x.trim());
      MFR = MFR?.trim();
      MPN = MPN?.trim();
      if (GCAS?.length === 0 || (GCAS?.length === 1 && GCAS[0] === "")) return;

      const { data } = await client.query({
        query: gql(materialByMPN),
        variables: {
          mpn: MPN,
          mfr: { eq: MFR },
          filter: { definerID: { eq: definerID } },
        },
      });
      const materialID = data?.materialByMPN?.items?.[0]?.id;
      if (!materialID) {
        alert(`Material ${MFR} ${MPN} does not exist`);
        return;
      }

      await Promise.all(
        GCAS.map(async (x) => {
          if (!x) {
            alert("GCAS is empty");
            return;
          } else {
            const values = {
              definerID,
              code: x,
              materialID,
              statusCode: STATUSCODE,
            };

            if (PG_CN === "Y") {
              values.entityID = "8f7ee0ab-a70c-4955-a9cf-aa0313728135";
              const res = await createMaterialEntityRelation({
                values,
                client,
              });
              if (NOTES?.length > 0) {
                const res = await client.mutate({
                  mutation: gql(createNote),
                  variables: {
                    input: {
                      itemID: materialID,
                      type: "COMMENT",
                      message: NOTES,
                    },
                  },
                });
              }
            }
            if (PG_EU === "Y") {
              values.entityID = "72185ce2-1863-4949-86af-efee8ad3588f";

              const res = await createMaterialEntityRelation({
                values,
                client,
              });
              // const id = res.data.createMaterialEntityRelation.id;
              if (NOTES?.length > 0) {
                const res2 = await client.mutate({
                  mutation: gql(createNote),
                  variables: {
                    input: {
                      itemID: materialID,
                      type: "COMMENT",
                      message: NOTES,
                    },
                  },
                });
              }
            }
          }
        })
      );
    })
  );
}
