import { useFormikContext } from "formik";
import { Stack, Grow, Collapse, Box, Typography } from "@mui/material";
import { FieldArray, useField } from "formik";
import { FormikArrayAddItemButton } from "./index";
import { Fragment } from "react";
import FieldErrorMessage from "./FieldErrorMessage";
import { TransitionGroup } from "react-transition-group";

export default function FormikArray({
  name,
  width,
  Item,
  subArray,
  bgcolor,
  btnColor,
  size,
  hideLabel,
  disableAdd,
  narrow,
  defaultValue,
}) {
  const {
    errors,
    values: { disableAddButton, isEditing },
  } = useFormikContext();
  const [field, meta, helpers] = useField({ name });
  const fieldValue = field.value;

  // console.log({subArray})

  // console.log({ disableAddButton, isEditing, disableAdd });

  let fontVariant = "h5";

  switch (size) {
    case "sm":
      fontVariant = "h6";
      break;
    case "lg":
      fontVariant = "h3";
      break;

    default:
      break;
  }
  // ATTENTION   STACK SPACING IS NOT WORKING BECAUSE OF TransitionGroup SO I USED MARGIN UNDER COLLAPSE INSTEAD

  return (
    <Stack spacing={4}>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <Stack
            spacing={5}
            // bgcolor='blue'
            // width="100%"
            width={width}
            alignItems="flex-start"
            sx={{ p: 2, minWidth: 800 }}
          >
            {/* <Typography variant={fontVariant}>
              {name?.split("][")?.slice(-1).pop().replace("]", "")}
            </Typography> */}
            {/* <Stack direction="column" spacing={3} alignItems="flex-start"> */}
            <TransitionGroup>
              {fieldValue?.map((item, index) => {
                // console.log({ item, index });

                return (
                  <Collapse key={index}>
                    <Stack
                      spacing={5}
                      mt={narrow ? 1 : 5}
                      key={index}
                      bgcolor={bgcolor}
                    >
                      <Item
                        key={index}
                        index={index}
                        remove={remove}
                        arrayFieldName={name}
                        removeBtnColor={btnColor}
                        size={size}
                      />

                      {subArray &&
                        !item.hideSubArray &&
                        subArray.map((x) => {
                          return (
                            <FormikArray
                              key={x.name}
                              name={`[${name}][${index}][${x.name}]`}
                              Item={x.Item}
                              width={x.width}
                              subArray={x.subArray}
                              bgcolor={x.bgcolor}
                              btnColor={x.btnColor}
                              size={x.size}
                              disableAdd={x.disableAdd}
                              narrow={x.narrow}
                              defaultValue={x.defaultValue}
                            />
                          );
                        })}
                    </Stack>
                  </Collapse>
                );
              })}
            </TransitionGroup>

            {!disableAddButton && !disableAdd && isEditing && (
              <FormikArrayAddItemButton
                addBtnColor={btnColor}
                size={size}
                push={push}
                field={field}
                name={name}
                meta={meta}
                helpers={helpers}
                hideLabel={hideLabel}
                defaultValue={defaultValue}
              />
            )}
            {/* </Stack> */}
          </Stack>
        )}
      </FieldArray>
      <FieldErrorMessage name={name} meta={meta} />
    </Stack>
  );
}
