import { onDelete } from "../../../function/onDelete";
import {
  deleteEntity,
  relationsByEntity1,
  relationsByEntity2,
  deleteEntityRelations,
  entitiyUserRelationByEntityID,
  deleteEntityUserRelation,
  materialEntityRelationByEntityAndStatusCode,
  deleteMaterialEntityRelation,
} from "./query";

export default async function onDeleteEntity({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }
}

async function deleteSingleItem(item, client) {
  // console.log({ item, client });
  const { id } = item || {};

  await onDelete({
    input: { id },
    mutation: deleteEntity,
    // updateCacheFields: ["relationsByRelationType"],
    client,
    relations: [
      {
        query: relationsByEntity1,
        mutation: deleteEntityRelations,
        variables: { entity1ID: id },
      },
      {
        query: relationsByEntity2,
        mutation: deleteEntityRelations,
        variables: { entity2ID: id },
      },
      {
        query: entitiyUserRelationByEntityID,
        mutation: deleteEntityUserRelation,
        variables: { entityID: id },
      },
      {
        query: materialEntityRelationByEntityAndStatusCode,
        mutation: deleteMaterialEntityRelation,
        variables: { entityID: id },
      },
      {
        query: entitiyUserRelationByEntityID,
        mutation: deleteMaterialEntityRelation,
        variables: { entityID: id },
      },
    ],
    storagePath: id,
  });
}
