import { gql } from "@apollo/client";
import { onDelete } from "../../../function/onDelete";
import {
  deleteUser,
  entitiyUserRelationByUserID,
  deleteEntityUserRelation,
} from "./query";

export default async function onDeleteContact({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }
}

async function deleteSingleItem(item, client) {
  const { id } = item || {};

  await onDelete({
    input: { id },
    mutation: deleteUser,
    // updateCacheFields: ["ThirdPartyUserRelationByThirdPartyID"],
    client,
    relations: [
      {
        query: entitiyUserRelationByUserID,
        mutation: deleteEntityUserRelation,
        variables: { userID: id },
      },
    ],
    storagePath: id,
  });
}
