import { Hub } from "aws-amplify";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Stack, Button, Typography } from "@mui/material";
import FileZone from "../../../component/FileZone";
import Notes from "../../../component/Notes";
import Updates from "../../../component/Updates";
import Item from "./components/Item";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { getInvoice, invoiceByType } from "./query";
import onDeleteInvoice from "./onDelete";
import CreateOutBoundInvoiceStepForm from "./components/CreateOutBoundInvoiceStepForm";
import { userEntityRelationVar } from "../../../client/cache";

function ItemView({ lineData: { id }, onClose }) {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  let { data } = useQuery(gql(getInvoice), {
    variables: { id },
  });
  data = data?.getInvoice;

  if (!data) return null;

  const storagePath = `${entityID}/outboundInvoices/${data?.number}`;

  return (
    <Stack
      justifyContent="space-between"
      sx={{ width: 1500, height: 900, padding: 2 }}
    >
      <Stack spacing={5}>
        <Item data={{ ...data, definerID: entityID }} onClose={onClose} />
        <FileZone storagePath={storagePath} />
        <Notes itemID={id} />
        <Updates itemID={id} />
      </Stack>

      <Button
        variant="contained"
        sx={{ alignSelf: "center", justifySelf: "flex-end" }}
        onClick={onClose}
      >
        back
      </Button>
    </Stack>
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");

  const queryVariables = {
    type: "OUTGOING",
    definerID: { eq: entityID },
    // limit: 5000,
    // filter: {
    //   or: IDs.map((id) => ({
    //     vendorID: { eq: id },
    //   })),
    // },
  };

  const columns = [
    {
      field: "paymentDueDate",
      headerName: "paymentDueDate",
      width: 150,
      userRole: ["admin", "accounting"],
      renderCell: ({ row, ...props }) => {
        return (
          <Typography
            {...(new Date(row.paymentDueDate).getTime() < Date.now() &&
              row.paymentStatus !== "PAID" && {
                sx: { bgcolor: "red", color: "white" },
              })}
          >
            {row?.paymentDueDate}
          </Typography>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      userRole: ["admin", "accounting"],
    },
    {
      field: "taxRate",
      headerName: "taxRate",
      width: 120,
      userRole: ["admin", "sales", "accounting"],
    },
    {
      field: "paymentStatus",
      headerName: "paymentStatus",
      width: 150,
      userRole: ["admin", "accounting"],
    },
    {
      field: "batch",
      headerName: "batch",
      width: 180,
      userRole: ["admin", "accounting"],
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "accounting"],
    },

    {
      field: "no",
      headerName: "Number",
      width: 240,
      userRole: ["admin", "accounting"],
    },

    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin", "accounting"],
    },
    {
      field: "poNumber",
      headerName: "PO Number",
      width: 240,
      userRole: ["admin", "accounting"],
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
      userRole: ["admin", "accounting"],
    },

    {
      field: "mfr",
      headerName: "MFR",
      width: 180,
      userRole: ["admin", "accounting"],
    },
    {
      field: "mpn",
      headerName: "MPN",
      width: 180,
      userRole: ["admin", "accounting"],
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      userRole: ["admin", "accounting"],
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 150,
      userRole: ["admin", "accounting"],
    },
    {
      field: "price",
      headerName: "price",
      width: 150,
      userRole: ["admin", "accounting"],
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      userRole: ["admin", "accounting"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeleteInvoice({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
    {
      label: "Batch Update",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        Hub.dispatch("dialogMonitor", {
          event: "batchUpdate",
          data: {
            selectionModel,
            setSelectionModel,
            attrs: ["number", "paymentDueDate", "paymentStatus"],
            model: "Invoice",
          },
        });
        // await onDeletePO({
        //   selectionModel,
        //   setSelectionModel,
        //   client,
        // });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = ({ items }) => {
    console.log({ items });
    return items.map((x) => {
      return {
        ...x,
        poNumber: x.po?.no,
        customer: x.po?.customer?.abbr,
        // vendor: x.vendor?.alias || x.vendor?.name,
        code: x.po?.materialEntityRelation?.code,
        mfr: x.po?.material?.mfr,
        mpn: x.po?.material?.mpn,
        unit: x.po?.material?.unit,
      };
    });
  };

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.itemNumber > b.itemNumber ? 1 : -1));
    arr.sort((a, b) => (a.number < b.number ? -1 : 1));
    arr.sort((a, b) => (a.date > b.date ? -1 : 1));

    return arr;
  };

  const filterAttrs = [
    { label: "date", value: "date", type: "date" },
    { label: "category", value: "category", type: "string" },

    { label: "batch", value: "batch", type: "string" },

    { label: "number", value: "number", type: "string" },

    {
      label: "code",
      value: "code",
      type: "string",
    },
    { label: "mfr", value: "mfr", type: "string" },
    { label: "mpn", value: "mpn", type: "string" },
    { label: "poNumber", value: "poNumber", type: "string" },

    { label: "paymentStatus", value: "paymentStatus", type: "string" },

    { label: "customer", value: "customer", type: "string" },
  ];

  return (
    <DataGridComp
      query={invoiceByType}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={isAdmin ? ItemView : null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : false}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
    />
  );
}

const billing = {
  path: "billing",
  sideNav: {
    group: 3,
    order: 50,
    label: "Billing",
    entityType: ["200", "300"],
    allowedRole: ["admin", "accounting"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin", "accounting"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreateOutBoundInvoiceStepForm />,
      allowedRole: ["admin", "accounting"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

function ToolbarInsert({ selectionModel }) {
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          SELECTED QTY:
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel.reduce((a, v) => a + (v?.qty || 0), 0).toFixed(3) * 1}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          SELECTED VALUE:
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel
            .reduce((a, v) => a + (v?.qty * v?.price || 0), 0)
            .toFixed(3) * 1}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          WITH TAX:
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel
            .reduce((a, v) => a + v?.qty * v?.price * (1 + v?.taxRate || 0), 0)
            .toFixed(3) * 1}
        </Typography>
      </Stack>
    </Stack>
  );
}
export default billing;
