import React, {
  useState,
  useEffect,
  Suspense,
  lazy,
  Fragment,
  useCallback,
} from "react";
import { Dialog, Box, Zoom } from "@mui/material";
import { Hub } from "aws-amplify";
import DraggablePaperComp from "../DraggablePaperComp";
import S3ImageViewer from "../S3ImageViewer";
import S3TextViewer from "../S3TextViewer";
import InvoiceGen from "../InvoiceGen";
import ForwarderPOGen from "../ForwarderPOGen";

import AuthPage from "../auth/AuthPage";

import BatchUpdate from "../BatchUpdate";

export default function DialogListener() {
  const [dialogPayloads, setDialogPayloads] = useState([]);

  function closeAll() {
    setDialogPayloads([]);
  }

  useEffect(() => {
    let hubListenerCancelToken;
    const listener = ({ payload }) => {
      setDialogPayloads((prev) => prev.concat(payload));
    };

    hubListenerCancelToken = Hub.listen("dialogMonitor", listener);
    console.log("entering dialog listener");

    return () => {
      hubListenerCancelToken && hubListenerCancelToken();
      console.log("removing dialog listener");
    };
  }, []);

  return (
    <Box>
      {dialogPayloads?.map((payload, i) => {
        return (
          <DialogItem
            key={i}
            payload={payload}
            setDialogPayloads={setDialogPayloads}
            closeAll={closeAll}
          />
        );
      })}
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  // return <Slide direction="up" ref={ref} {...props} />;
  return <Zoom ref={ref} {...props} />;
});

const DialogItem = ({ payload, closeAll, setDialogPayloads }) => {
  const { event, data } = payload || {};
  const {
    lineData,
    Component,
    userRole,
    selectionModel,
    setSelectionModel,
    attrs,
    model,
    definerID,
  } = data || {};
  const [isOpen, setIsOpen] = useState(true);

  const onClose = useCallback(() => {
    const vid = document.getElementById("video");
    vid && vid.play();
    setIsOpen(false);

    // if you add the following line, the transition will not work
    // setDialogPayloads((prev) => prev.slice(0, -1));
  }, []);

  return (
    <Dialog
      // fullWidth={Component?.name === 'FullWidthItemView' ? true : false}
      // fullWidth={true}
      maxWidth={false}
      // maxWidth={
      //   Component?.name === 'FullWidthItemView' || event === 'forwarderPOGen'
      //     ? false
      //     : 'xl'
      // }
      open={isOpen}
      // style={{ backgroundColor: 'blue' }}
      // {...(FormikForm && { PaperComponent: DraggablePaperComp })}
      TransitionComponent={Transition}
      PaperComponent={DraggablePaperComp}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="draggable-dialog-title"
    >
      <Suspense fallback={<Fragment />}>
        {event === "auth" && <AuthPage onClose={onClose} />}
        {event === "batchUpdate" && (
          <>
            <div
              style={{
                cursor: "move",
                backgroundColor: "pink",
                height: 50,
              }}
              id="draggable-dialog-title"
            />
            <BatchUpdate
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              attrs={attrs}
              onClose={onClose}
              model={model}
            />
          </>
        )}

        {event === "showDetails" && (
          <>
            <div
              style={{
                cursor: "move",
                backgroundColor: "pink",
                height: 50,
              }}
              id="draggable-dialog-title"
            />
            <Component
              onClose={onClose}
              closeAll={closeAll}
              lineData={lineData}
              userRole={userRole}
            />
          </>
        )}

        {event === "invoiceGen" && (
          <>
            <div
              style={{
                cursor: "move",
                backgroundColor: "pink",
                height: 50,
              }}
              id="draggable-dialog-title"
            />
            <InvoiceGen
              onClose={onClose}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              definerID={definerID}
            />
          </>
        )}
        {event === "forwarderPOGen" && (
          <>
            <div
              style={{
                cursor: "move",
                backgroundColor: "pink",
                height: 50,
              }}
              id="draggable-dialog-title"
            />
            <ForwarderPOGen
              onClose={onClose}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              definerID={definerID}
            />
          </>
        )}

        {event === "displayFile" && (
          <>
            {(data.type === "jpg" || data.type === "png") && (
              <S3ImageViewer url={data.url} filename={data.filename} />
            )}
            {data.type === "txt" && (
              <S3TextViewer url={data.url} filename={data.filename} />
            )}
          </>
        )}
      </Suspense>
    </Dialog>
  );
};
