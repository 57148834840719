import { Box, Link, Stack, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import LoadingButton from "@mui/lab/LoadingButton";

export default function VerifyEmailForForgotPassword({
  loading,
  setAuthType,
  action,
  setEmail,
  email,
  setPassword,
  password,
  sx,
  clearAlert,
  ...props
}) {
  const label = "Reset Password";
  return (
    <Box sx={{ ...sx, width: 400 }}>
      <LockOutlinedIcon fontSize="large" color="secondary" />
      <Typography variant="h5">{label}</Typography>

      <Box component="form" onSubmit={action.onSubmit} width="100%">
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus={email ? false : true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <LoadingButton
          loading={loading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Continue
        </LoadingButton>
      </Box>
      <Stack width="90%" direction="row" justifyContent="space-between">
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            clearAlert();
            setAuthType("signIn");
          }}
        >
          Back to Sign in
        </Link>
      </Stack>
    </Box>
  );
}
