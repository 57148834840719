import { useEffect, useState } from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { API, Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
export const listPOS = /* GraphQL */ `
  query ListPOS($filter: ModelPOFilterInput, $limit: Int, $nextToken: String) {
    listPOS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        itemNumber
        date
        offerID
        customerID
        vendorID
        definerID
        materialID
        materialEntityRelationID
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        importDutyCN
        serviceFeeRate
        minCharge
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const updatePO = /* GraphQL */ `
  mutation UpdatePO($input: UpdatePOInput!, $condition: ModelPOConditionInput) {
    updatePO(input: $input, condition: $condition) {
      id
      number
      itemNumber
      date
      offerID

      customerID

      vendorID

      definerID

      materialID

      materialEntityRelationID

      buyerID

      salesID

      qty
      openQty
      price
      priceCur
      paymentTerms

      billingEntityID
      CRD
      shippingStatus
      billingStatus
      paymentStatus
      importAgentID
      paymentAgentID
      forwarderID

      fxRate
      importDutyCN
      serviceFeeRate
      minCharge
      piID

      groupToRead
      groupToEdit
      groupToDelete

      version
      latestVersion
      statusCode

      createdAt
      updatedAt
      __typename
    }
  }
`;

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const { data } = useQuery(gql(listPOS));

  const items = data?.listPOS?.items;
  console.log({ items });

  return (
    <Stack>
      <Typography>TEST</Typography>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={async () => {
          setLoading(true);

          try {
            await Promise.all(
              items.map(async (x) => {
                // console.log({ xxxxx: x });
                if (!x.vendorID || !x.definerID || !x.customerID) {
                  console.log({ x });
                  debugger;
                  // const input = {
                  //   id: x.id,
                  //   statusCode: "900",
                  // };
                  // console.log({ input });
                  // const res = await client.mutate({
                  //   mutation: gql(updatePO),
                  //   variables: { input },
                  // });
                  // console.log({ res });
                }
              })
            );
          } catch (e) {
            console.log({ error: e });
          }

          setLoading(false);
        }}
      >
        run func
      </LoadingButton>
    </Stack>
  );
}

const playground = {
  path: "playGround",

  sideNav: {
    group: 1,
    order: 60,
    label: "Playground",
    entityType: "all",
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default playground;
