import { useCallback, useState } from "react";
import * as Yup from "yup";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step0ChooseItems from "./Step0ChooseItems";
import Step1ChooseInvoice from "./Step1ChooseInvoice";
import Step2Review from "./Step2Review";
import { useNavigate, useParams } from "react-router-dom";
import ReceivingItem from "./Array/ReceivingItem";
import ReceivingItemSubArray from "./Array/ReceivingItemSubArray";
import BackdropLoader from "../../../../../component/BackdropLoader";
import { onCreateShippings } from "../../../shippings/onCreate";

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Please enter date"),
  number: Yup.string().required("Please enter number"),
  vendor: Yup.object().required("Please select vendor").nullable(),
  items: Yup.array()
    .of(
      Yup.object({
        material: Yup.object().required("Please select material").nullable(),
        po: Yup.object().required("Please select PO").nullable(),
        qty: Yup.number()
          .min(0.0001, "Quanty must be greater than zero")
          .required("Please enter number"),

        // subItems: Yup.array().of(
        //   Yup.object({
        //     receivedQty: Yup.number()

        //       .min(0.0001, "Quanty must be greater than zero")
        //       .required("Please enter number"),
        //   })
        // ),
      })
    )
    .required("Please select item")
    .nullable(),

  // parts: Yup.array()
  //   .when("findBy", {
  //     is: (value) => {
  //       return value === "byPart";
  //     },
  //     then: Yup.array()
  //       .of(Yup.object())
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 1,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           targetPrice: Yup.number(),
  //           currency: Yup.string(),
  //           // EAU: Yup.number().required("Please enter the EAU"),
  //           // application: Yup.string().required("Please enter the application"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 2,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           vendors: Yup.array()
  //             .min(1, "Please assign at least one vendor")
  //             .required("Please select at least one vendor"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   }),
  // materials: Yup.array().when("selectionType", {
  //   is: (value) => {
  //     return value === "byMaterial";
  //   },
  //   then: Yup.array()
  //     .of(Yup.object())
  //     .min(1, "Please select at lease one material")
  //     .required("Please select materials"),
  // }),
  // RFQByVendor: Yup.array().of(
  //   Yup.object({
  //     contact: Yup.object().required("Please select contact person"),
  //   })
  // ),
});

const labelOfSumbit = "CREATE RECEIVING";

export default function CreateShippingForm() {
  const { entityID } = useParams();

  const initialValues = {
    items: [{}],
    definerID: entityID,
    entityID,
    date: new Date().toISOString("en-US").slice(0, 10),
    number: Date.now().toString().slice(1, 9),
    isEditing: true,
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Select Items",
      form: Step0ChooseItems,
      formArray: [
        {
          Item: ReceivingItem,
          name: "items",
          // width: "95%",
          bgcolor: "#fff",
          btnColor: "purple",
          size: "md",
          subArray: [
            {
              Item: ReceivingItemSubArray,
              name: "subItems",
              // width: 600,
              bgcolor: "#f3e5f5",
              btnColor: "blue",
              size: "sm",
              disableAdd: true,
            },
          ],
        },
      ],

      isOptional: false,
      fields: ["vendor", "items"],
    },
    // {
    //   step: 1,
    //   label: "Choose Invoice",
    //   form: Step1ChooseInvoice,
    //   isOptional: false,
    //   fields: [""],
    // },
    {
      step: 1,
      label: "Review",
      form: Step2Review,
      isOptional: false,
      fields: [""],
    },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      setIsLoading(true);

      const { date, definerID, number, items } = values;

      // ATTENTION DON'T REMOVE PO FROM BELOW LIST BEFORE UPDATEING PO LOGIC NEED FORWARDER PO INFO FROM IT

      const aggregatedShippingItems = items
        .map((x) => x.subItems)
        .flat()
        ?.map(
          (
            {
              poID,
              itemNumber,
              materialID,
              materialEntityRelationID,
              receivedQty,
              po,
              piID,
            },
            i
          ) => ({
            poID,
            itemNumber: i + 1,
            materialID,
            materialEntityRelationID,
            qty: receivedQty,
            po,
            piID,
          })
        )
        ?.filter((x) => x.qty > 0);

      // console.log({ aggregatedShippingItems });

      await onCreateShippings({
        client,
        values: { aggregatedShippingItems, definerID, date, number },
        type: "INCOMING",
      });

      // debugger;

      // const res = await API.post("iSupplyAPI", "/restricted", {
      //   body: {
      //     operation: "SEND_RFQ",
      //     data: groupByContact,
      //   },
      // });
      // console.log({ res });
      setIsLoading(false);

      navigate(-1);
    },
    [navigate]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
