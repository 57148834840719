import VATProcess from './VATProcess'

const vat_gen = {
  path: 'vat_gen',
  // sideNav: {
  //   group: 6,
  //   order: 30,
  //   label: 'VAT Gen',
  //   entityType: 'all',
  //   allowedRole: ['admin', 'sales', 'accounting'],
  //   auth: ['authenticated'],
  // },
  route: {
    index: {
      element: <VATProcess />,
      allowedRole: 'all',
      entityType: 'all',
      auth: ['authenticated'],
    },
  },
}

export default vat_gen
