import { Fragment, useState, useEffect } from "react";
import { Hub } from "aws-amplify";
import { useQuery, NetworkStatus, gql, useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Box,
  LinearProgress,
  Stack,
  Button,
  IconButton,
  Badge,
  TextField,
  InputAdornment,
  Typography,
  Popper,
  Paper,
  Card,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Popover,
  ClickAwayListener,
  MenuItem,
} from "@mui/material";
import {
  Clear as ClearIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import DropdownMenu from "../DropdownMenu";
import BackdropoLoader from "../BackdropLoader";
import NoRowsOverlay from "./component/NoRowsOverlay";
import LoadingOverlay from "./component/LoadingOverlay";
import Filters from "./component/Filter";

// LicenseInfo.setLicenseKey(
//   'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
// );

export default function DataGridComp({
  passedItems,
  query,
  queryVariables,
  sortBy,
  skip,
  onClickAddRoute,
  onClickAdd,
  columns,
  DetailsDialog,
  userRole,
  getDropdownMenu,
  checkboxSelection,
  prepareItemsFunc,
  addIconLabel,
  actionCellWrapper,
  topRightButtons,
  ToolbarInsert,
  toolbarFieldAddon,
  filterAttrs,
}) {
  // const entityID = useParams().entityID
  // return <p>loading</p>;
  // const client = useApolloClient();
  const {
    data,
    loading: queryLoading,
    refetch,
    networkStatus,
  } = useQuery(gql(query), {
    ...(queryVariables && { variables: queryVariables }),
    notifyOnNetworkStatusChange: true,
    skip: skip || !!passedItems,
    // fetchPolicy: "cache-first",
  });

  // console.log({data})
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [selectionModel, setSelectionModel] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function func() {
      let items = passedItems;

      if (!passedItems) {
        const dataProperty = data && Object.keys(data)[0];
        const dataValue = data?.[dataProperty];
        if (dataValue) {
          if (prepareItemsFunc) {
            setIsLoading(true);

            items = await prepareItemsFunc(dataValue);
            setIsLoading(false);
          } else {
            items = dataValue?.items;
          }

          // items = prepareItemsFunc
          //   ? await prepareItemsFunc(dataValue)
          //   : dataValue?.items;
        }
        if (items && sortBy) {
          items = sortBy([...items]);
        }

        if (!searchText || searchText?.length === 0) {
          setRows(items || []);
          setFilteredRows(items || []);
        } else {
          const escapeRegExp = (value) =>
            value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
          const searchRegex = new RegExp(escapeRegExp(searchText?.trim()), "i");

          const filteredRows = items?.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field] && row[field].toString());
            });
          });
          setRows(filteredRows || []);
          setFilteredRows(filteredRows || []);
        }
      }
    }
    func();
  }, [data, passedItems, prepareItemsFunc, searchText, sortBy]);

  // if (queryLoading) {
  //   return <LinearProgress />;
  // }

  // if (!rows) return null;
  // let items = passedItems;

  // if (!passedItems) {
  //   const dataProperty = data && Object.keys(data)[0];
  //   const dataValue = data?.[dataProperty];
  //   if (!dataValue) return <LinearProgress />;
  //   items = prepareItemsFunc ? prepareItemsFunc(dataValue) : dataValue?.items;
  // }

  // if (!items) {
  //   return <LinearProgress />;
  // }

  // if (sortBy) {
  //   items = sortBy([...items]);
  // }

  const onClickDetails = (row) => {
    // console.log({row})
    // debugger
    Hub.dispatch("dialogMonitor", {
      event: "showDetails",
      data: {
        lineData: { ...row },
        Component: DetailsDialog,
        userRole,

        // DetailsDialog: ({ onClose }) => (
        //   <DetailsDialog id={row.id} item={row} onClose={onClose} />
        // ),
      },
    });
  };

  const actionsColumn = {
    field: "actions",
    headerName: "Actions",
    width: 100,
    renderCell: ({ row, ...props }) => {
      const Wrapper = actionCellWrapper || (({ children }) => children);
      return (
        <Wrapper row={row}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              onClickDetails(row);
            }}
          >
            View
          </Button>
        </Wrapper>
      );
    },
  };

  return (
    <Box width="100%" height="100%">
      <DataGrid
        loading={queryLoading}
        // unstable_headerFilters
        rows={filteredRows}
        columns={DetailsDialog ? [actionsColumn, ...columns] : columns}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        components={{ Toolbar, NoRowsOverlay, LoadingOverlay }}
        componentsProps={{
          toolbar: {
            selectionModel,
            setSelectionModel,
            rows,
            setRows,
            getDropdownMenu,
            setIsLoading,
            setSearchText,
            searchText,
            refetch,
            onClickAdd,
            onClickAddRoute,
            addIconLabel,
            topRightButtons,
            networkStatus,
            ToolbarInsert,
            toolbarFieldAddon,
            filterAttrs,
            setFilteredRows,
          },
        }}
        onRowClick={(data) => {
          console.log("row clicked", { data });
        }}
      />
      <BackdropoLoader isLoading={isLoading || !rows} />
    </Box>
  );
}

const Toolbar = ({
  selectionModel,
  setSelectionModel,
  rows,
  setRows,
  getDropdownMenu,
  setIsLoading,
  setSearchText,
  searchText,
  refetch,
  onClickAdd,
  onClickAddRoute,
  addIconLabel,
  topRightButtons,
  networkStatus,
  ToolbarInsert,
  toolbarFieldAddon,
  filterAttrs,
  setFilteredRows,
}) => {
  // console.log({ filterAttrsfilterAttrsfilterAttrs2222: filterAttrs });
  const client = useApolloClient();

  const navigate = useNavigate();

  const onRefetch = async () => {
    await refetch();
  };

  const handleClickAdd = () => {
    onClickAdd ? onClickAdd() : navigate(`./${onClickAddRoute}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="flex-end">
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          {/* <GridToolbarFilterButton /> */}
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
        {/* <GridToolbar /> */}

        {filterAttrs && (
          <Filters
            setFilteredRows={setFilteredRows}
            filterAttrs={filterAttrs}
            rows={rows}
          />
        )}

        {!!ToolbarInsert && (
          <ToolbarInsert
            selectionModel={selectionModel?.map((x) =>
              rows.find((y) => y.id === x)
            )}
          />
        )}
      </Stack>

      <Stack direction="row" height={50} spacing={3}>
        {selectionModel?.length > 0 && getDropdownMenu?.length > 0 ? (
          <DropdownMenu
            selectionModel={selectionModel?.map((x) =>
              rows.find((y) => y.id === x)
            )}
            setSelectionModel={setSelectionModel}
            menu={getDropdownMenu}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Stack direction="row" spacing={2} mt={2}>
            {toolbarFieldAddon?.map((x, i) => {
              const Comp = x.comp;
              const element = x.element;

              return Comp ? (
                <Comp key={i} rows={rows} setRows={setRows} client={client} />
              ) : (
                <Fragment key={i}>{element}</Fragment>
              );
            })}

            {/* <TextField
              id="outlined-search"
              label="Search..."
              variant="outlined"
              InputProps={{
                sx: { height: 40 },

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      // aria-label="toggle password visibility"
                      onClick={() => {
                        setSearchText("");
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchText}
              onChange={async (e) => {
                setSearchText(e.target.value);
              }}
            /> */}
            {(onClickAddRoute || onClickAdd) && (
              <Button onClick={handleClickAdd}>
                <AddCircleIcon /> {addIconLabel || "add"}
              </Button>
            )}
            {topRightButtons?.map((x, i) => {
              const Comp = x.comp;

              return (
                <Comp key={i} rows={rows} setRows={setRows} client={client} />
              );
              // return <Fragment key={i}>{x.element} </Fragment>;
            })}

            <LoadingButton
              onClick={onRefetch}
              loading={networkStatus === NetworkStatus.refetch}
            >
              <ReplayCircleFilledIcon /> refresh
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
