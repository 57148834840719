import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { Box, Stack, Typography, Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const UPDATE_MESSAGE = gql`
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      receiverID
      senderID
      sender {
        id
        email
        name
        phoneNumber
        address
        group
      }
      subject
      message
      isRead
      priority
      owners
      createdAt
      updatedAt
      isDeletedByReceiver
    }
  }
`;

export default function Notification({ messages }) {
  const [mutationFunc, { data, loading, error }] = useMutation(UPDATE_MESSAGE);

  const [selected, setSelected] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const onClickDelete = async () => {
    setIsDeleting(true);
    await Promise.all(
      selected.map(async (item) => {
        await mutationFunc({
          variables: { input: { id: item, isDeletedByReceiver: true } },
          update(cache, { data: { updateMessage } }) {
            console.log({ cache, updateMessage });
            const normalizedId = cache.identify(updateMessage);
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
      })
    );
    console.log("deleted");
    setIsDeleting(false);
  };

  useEffect(() => {
    async function func() {
      await messages?.map(async (x) => {
        if (!x.isRead) {
          await mutationFunc({
            variables: { input: { id: x.id, isRead: true } },
          });
        }
      });
    }
    func();
  }, [messages, mutationFunc]);
  return (
    <Box sx={{ p: 2, width: 600, height: 400, bgcolor: "#f0f0f0" }}>
      {messages?.length > 0 ? (
        <>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              //   loading={isDeleting}
              onClick={onClickDelete}
              disabled={selected.length === 0}
            >
              Delete
            </LoadingButton>
          </Stack>
          {messages?.map((x, i) => (
            <NotificationItem
              key={i}
              item={x}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </>
      ) : (
        <Typography
          sx={{ textAlign: "center", mt: 5, color: "gray" }}
          variant="h4"
        >
          You have no messages
        </Typography>
      )}
    </Box>
  );
}

function NotificationItem({ item, selected, setSelected }) {
  const isSelected = selected.includes(item.id);

  const handleChange = () => {
    if (isSelected) {
      setSelected((selected) => selected.filter((x) => x !== item.id));
    } else {
      setSelected((selected) => selected.concat(item.id));
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ bgcolor: "#e1bee7", borderRadius: 1, p: 1, m: 1 }}
    >
      <Checkbox
        sx={{ alignSelf: "center" }}
        checked={isSelected}
        onChange={handleChange}
      />
      <Stack>
        <Typography>{item.message}</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="overline">{item.createdAt}</Typography>
          <Typography variant="caption">
            From: {item.sender?.name || "System Admin"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
