import { useEffect, useMemo } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import {
  Stack,
  Button,
  Typography,
  stepButtonClasses,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Clear as ClearIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
  KeyboardReturn as KeyboardReturnIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikSelect,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
  FormikRadioGroup,
} from "../../../../../component/formikForm";
import { currencies } from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import mergeArrayAndCombineQty from "../../../../../lib/mergeArrayAndCombineQty";
import fillArrayWithNumber from "../../../../../lib/fillArrayWithNumber";

export default function ArbitaryInvoiceItem({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();
  const client = useApolloClient();

  const { avaiablePOs, vendor, category, items } = values;
  const currentItem = items[index];
  // console.log({ currentItem });
  const { price, qty, taxRate, desc, currency } = currentItem || {};
  const amount = (price * qty).toFixed(3) * 1;
  const amountWithVat = (price * qty * (1 + taxRate / 100)).toFixed(3) * 1;

  // useEffect(() => {
  //   if (vendor && !vendor.id) {
  //     setFieldValue(
  //       `[${arrayFieldName}][${index}][desc]`,
  //       `${vendor}-${desc})`
  //     );
  //   }
  // }, [setFieldValue, desc, vendor, arrayFieldName, index]);

  const descOptions = ["运费", "代理服务费", "商品", "杂费"];

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <FormikAutocomplete
          name={`[${arrayFieldName}][${index}][desc]`}
          label="Desc"
          freeSolo={true}
          options={descOptions}
          // getOptionLabel={(option) => option.abbr}
          required
          // getPrimaryText={(option) => option.abbr}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={360}
          getOptionDisabled={null}
        />

        {["商品", "杂费"].includes(desc) && (
          <FormikField
            width={360}
            name={`[${arrayFieldName}][${index}][descDetails]`}
            label="说明"
            required

          />
        )}

        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][price]`}
          type="number"
          label="Price"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][currency]`}
          label="Currency"
          select
          children={currencies.map((x) => (
            <MenuItem key={x.label} value={x.value}>
              {x.label}
            </MenuItem>
          ))}
        />
        <FormikField
          width={120}
          name={`[${arrayFieldName}][${index}][qty]`}
          type="number"
          label="Qty"
          InputProps={{ inputProps: { min: 0 } }}
        />

        <FormikField
          width={80}
          name={`[${arrayFieldName}][${index}][taxRate]`}
          label="Tax Rate"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 1000 } }}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />

        <FormikArrayRemoveItemButton
          disabled={index === 0}
          onClick={() => remove(index)}
          name={arrayFieldName}
          removeBtnColor={removeBtnColor}
          size={size}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography minWidth={120}>金额 {!isNaN(amount) && amount}</Typography>
        <Typography variant="h5" color="blue" minWidth={120}>
          含税金额 {!isNaN(amountWithVat) && amountWithVat}
        </Typography>
      </Stack>
    </Stack>
  );
}
