import React, { useRef } from "react";
import { Paper } from "@mui/material";
import Draggable from "react-draggable";

export default function DraggablePaperComp(props) {
  const nodeRef = useRef(null);

  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} ref={nodeRef} style={{ minHeight: 300 }} />
    </Draggable>
  );
}
