import { useEffect } from "react";
import { Stack, Button, MenuItem, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikField,
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
} from "../../../../../component/formikForm";
import {
  dimensionUnits,
  weightUnits,
} from "../../../../../settings/formFieldEnum";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export default function PackingListItems({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { packingListItems, shippingItems, mode } = values;
  const item = packingListItems?.[index];
  const { ctnFrom, ctnTo, po, dimensionUnit, weightUnit } = item || {};

  useEffect(() => {
    if (index === 0 && ctnFrom * 1 !== 1) {
      setFieldValue(`[${arrayFieldName}][${index}][ctnFrom]`, 1);
    }

    if (packingListItems[index + 1] && !packingListItems[index + 1].ctnFrom) {
      setFieldValue(
        `[${arrayFieldName}][${index + 1}][ctnFrom]`,
        ctnTo ? ctnTo * 1 + 1 : undefined
      );
    }

    if (!dimensionUnit) {
      setFieldValue(`[${arrayFieldName}][${index}][dimensionUnit]`, "CM");
    }
    if (!weightUnit) {
      setFieldValue(`[${arrayFieldName}][${index}][weightUnit]`, "KG");
    }
  }, [
    index,
    ctnFrom,
    arrayFieldName,
    ctnTo,
    setFieldValue,
    packingListItems,
    dimensionUnit,
    weightUnit,
  ]);

  const POOptions = filterOutSameItemInArray(
    shippingItems.map((x) => x.po),
    "id"
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={2}>
        <FormikAutocomplete
          width={300}
          name={`[${arrayFieldName}][${index}][po]`}
          label="PO"
          options={POOptions}
          getOptionLabel={(option) => option?.no}
          getPrimaryText={(option) => option?.no}
          onChange={(e, v) => {
            // console.log({ e, v });
            const itemFound = shippingItems.find((x) => x.poID === v?.id);
            if (!itemFound) return;
            const updatedItem = {};

            const { coo, code, mfr, mpn, unit } = itemFound;
            updatedItem.po = v;

            updatedItem.code = code;
            // updatedItem.desc = desc
            updatedItem.unit = unit;
            updatedItem.mfr = mfr;
            updatedItem.mpn = mpn;
            updatedItem.coo = coo;
            updatedItem.weightUnit = "KG";
            updatedItem.dimensionUnit = "CM";
            // updatedItem.ctnFrom = 1

            updatedItem.ctnTo = undefined;

            setFieldValue(`[${arrayFieldName}][${index}]`, {
              ...item,
              ...updatedItem,
            });
          }}
        />
        <FormikField
          width={180}
          name={`[${arrayFieldName}][${index}][mfr]`}
          label="mfr"
        />
        {/* <Typography> mfr:{po?.material?.mfr}</Typography> */}
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][ctnFrom]`}
          label="ctnFrom"
          type="number"
          disabled={index === 0}
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][ctnTo]`}
          label="ctnTo"
          type="number"
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][qty]`}
          label="qty"
          type="number"
          // onChange={(v) => {}}
          // validate={(value) => {
          //   console.log({ val: value })
          //   let errorMsg
          //   if (
          //     packingListItems?.reduce(
          //       (a, v) => a + (v.ctnTo * 1 - v.ctnFrom * 1 + 1) * v.qty,
          //       0,
          //     ) > shippingItems?.reduce((a, v) => a + v.qty, 0)
          //   ) {
          //     errorMsg = 'PACKING QTY IS GREATER THAN SHIPPING QTY'
          //     console.log({ errorMsg })
          //     debugger
          //   }
          //   return errorMsg
          // }}
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][l]`}
          label="Length"
          type="number"
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][w]`}
          label="Width"
          type="number"
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][h]`}
          label="Height"
          type="number"
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][gw]`}
          label="GW"
          type="number"
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][nw]`}
          label="NW"
          type="number"
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][dimensionUnit]`}
          label="DIMENSION UNIT"
          children={dimensionUnits.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
          select
        />
        <FormikField
          width={100}
          name={`[${arrayFieldName}][${index}][weightUnit]`}
          label="WEIGHT  UNIT"
          children={weightUnits.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
          select
        />
      </Stack>
      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        name={arrayFieldName}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}
