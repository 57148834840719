import { useEffect, useState } from "react";
import { API } from "aws-amplify";
// import { Container, Stack, Typography } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import BackdropoLoader from "../../../component/BackdropLoader";
// import LoadingButton from "@mui/lab/LoadingButton";

function* generator() {
  yield "rendered";
}

const gen = generator();
export default function VATDelivery() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const data = searchParams.get("data");

  const [loading, setLoading] = useState(true);
  // const [isSent, setIsSent] = useState(false);
  // const [ignore, setIsIgnore] = useState(false)

  // const [counter, setCounter] = useState(0)

  useEffect(() => {
    async function func() {
      if (!data || !gen.next().value) return;

      const dataObj = JSON.parse(Buffer.from(data, "base64"));

      const { invoiceNumber, to, cc } = dataObj;

      await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "SEND_VAT_TO_CTM",
          data: {
            meta: {
              invoiceNumber,
              to,
              cc,
              isPreview: false,
            },
          },
        },
      });
      setLoading(false);
      navigate("../billing");
    }
    func();
  }, [data, navigate]);

  // async function onClick() {
  //   // setLoading(true);

  // }

  if (loading) {
    return <BackdropoLoader isLoading={loading} minLoadingTime={5000} />;
  }
  return null;

  // return (
  //   <Container maxWidth={false}>
  //     <Stack spacing={2}>
  //       <Stack direction="row" spacing={2}>
  //         <Typography variant="h4">NEW VATS</Typography>
  //         <Typography variant="h4">
  //           {invoiceNumber}
  //           {to}
  //           {cc}
  //         </Typography>
  //         {/* <LoadingButton
  //           variant="contained"
  //           loading={loading}
  //           onClick={onClick}
  //           label="submit"
  //           children="send"
  //         /> */}
  //       </Stack>
  //     </Stack>
  //   </Container>
  // );
}
