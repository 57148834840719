import { useCallback, useState } from "react";
import * as Yup from "yup";
import StepForm from "../../../../../component/formikForm/StepForm";
import Step1EntityBasic from "./Step1EntityBasic";
import Step2PaymentTerms from "./Step2PaymentTerms";
import Step3IncoTerms from "./Step3IncoTerms";
import Step4Relations from "./Step4Relations";
import Step5Contacts from "./Step5Contacts";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import PaymentTerms from "./Array/PaymentTerms";
import IncoTermsForEntityCreation from "./Array/IncoTermsForEntityCreation";
import RelationForEntityCreation from "./Array/RelationForEntityCreation";
import ContactItemForEntityForm from "./Array/ContactItemForEntityForm";
import BackdropLoader from "../../../../../component/BackdropLoader";
import onCreateEntity from "../../onCreate";
import onCreateRelation from "../../onCreateRelation";
import onCreateUserEntityRelation from "../../../contacts/onCreateRelation";
import onCreateContact from "../../../contacts/onCreate";

// import * as ReactDOMServer from "react-dom/server";

const validationSchema = Yup.object().shape({
  // findBy: Yup.string().required(),
  // parts: Yup.array()
  //   .when("findBy", {
  //     is: (value) => {
  //       return value === "byPart";
  //     },
  //     then: Yup.array()
  //       .of(Yup.object())
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 1,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           targetPrice: Yup.number(),
  //           currency: Yup.string(),
  //           // EAU: Yup.number().required("Please enter the EAU"),
  //           // application: Yup.string().required("Please enter the application"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part"),
  //   })
  //   .when("step", {
  //     is: (value) => value === 2,
  //     then: Yup.array()
  //       .of(
  //         Yup.object({
  //           vendors: Yup.array()
  //             .min(1, "Please assign at least one vendor")
  //             .required("Please select at least one vendor"),
  //         })
  //       )
  //       .min(1, "Please select at lease one part")
  //       .required("Please select parts"),
  //   }),
  // materials: Yup.array().when("selectionType", {
  //   is: (value) => {
  //     return value === "byMaterial";
  //   },
  //   then: Yup.array()
  //     .of(Yup.object())
  //     .min(1, "Please select at lease one material")
  //     .required("Please select materials"),
  // }),
  // RFQByVendor: Yup.array().of(
  //   Yup.object({
  //     contact: Yup.object().required("Please select contact person"),
  //   })
  // ),
});

const labelOfSumbit = "CREATE ENTITY";

export default function CreateOfferForm() {
  const { entityID: definerID } = useParams();

  const initialValues = {
    definerID,
    isEditing: true,
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const steps = [
    {
      step: 0,
      label: "Basic Info",
      form: Step1EntityBasic,
      // formArray: [
      //   {
      //     Item: InquiryItem,
      //     name: "inquiries",
      //     // width: "95%",
      //     bgcolor: "#fff",
      //     btnColor: "purple",
      //     size: "md",
      //   },
      // ],

      isOptional: false,
      fields: ["materials", "parts", "findBy"],
    },
    {
      step: 1,
      label: "Payment Terms",
      form: Step2PaymentTerms,
      isOptional: true,
      fields: ["parts"],
      formArray: (values) => [
        ...(values.hasSpecifiedPaymentTerms === "true"
          ? [
              {
                Item: PaymentTerms,
                name: "paymentTerms",
                // width: 600,
                bgcolor: "#e3f2fd",
                btnColor: "#3f51b5",
                size: "md",
              },
            ]
          : []),
      ],
      // formArray: [
      //   {
      //     Item: PaymentTerms,
      //     name: "paymentTerms",
      //     // width: 600,
      //     bgcolor: "#e3f2fd",
      //     btnColor: "#3f51b5",
      //     size: "md",
      //   },
      // ],
    },
    {
      step: 2,
      label: "IncoTerms",
      form: Step3IncoTerms,
      isOptional: true,
      fields: ["parts"],
      formArray: (values) => [
        ...(values.hasSpecifiedIncoTerms === "true"
          ? [
              {
                Item: IncoTermsForEntityCreation,
                name: "incoTerms",
                // width: 600,
                bgcolor: "#e3f2fd",
                btnColor: "#3f51b5",
                size: "md",
              },
            ]
          : []),
      ],
      // formArray: [
      //   {
      //     Item: IncoTerms,
      //     name: "incoTerms",
      //     // width: 600,
      //     bgcolor: "#e3f2fd",
      //     btnColor: "#3f51b5",
      //     size: "md",
      //   },
      // ],
    },
    {
      step: 3,
      label: "Relations",
      form: Step4Relations,
      isOptional: false,
      fields: ["RFQByVendor"],
      formArray: (values) => [
        ...(values.hasRelations === "true"
          ? [
              {
                Item: RelationForEntityCreation,
                name: "relations",
                // width: 600,
                bgcolor: "#e3f2fd",
                btnColor: "#3f51b5",
                size: "md",
                // subArray: [
                //   {
                //     Item: IncoTermsForEntityCreationSubArray,
                //     name: "IncoTermsForEntityCreationSubArray",
                //     // width: 600,
                //     bgcolor: "#f3e5f5",
                //     btnColor: "purple",
                //     size: "sm",
                //   },
                //   {
                //     Item: PaymentTermsForEntityCreationSubArray,
                //     name: "PaymentTermsForEntityCreationSubArray",
                //     // width: 600,
                //     bgcolor: "yellow",
                //     btnColor: "blue",
                //     size: "sm",
                //   },
                // ],
              },
            ]
          : []),
      ],
    },
    {
      step: 4,
      label: "Contacts",
      form: Step5Contacts,
      isOptional: false,
      fields: ["RFQByVendor"],
      formArray: (values) => [
        ...(values.addContacts === "true"
          ? [
              {
                Item: ContactItemForEntityForm,
                name: "contacts",
                // width: 600,
                bgcolor: "#e3f2fd",
                btnColor: "#3f51b5",
                size: "md",
              },
            ]
          : []),
      ],
    },
    {
      step: 5,
      label: "Summary",
      form: Summary,
      isOptional: false,
      fields: ["RFQByVendor"],
    },
  ];

  const onSubmit = useCallback(
    async ({ values, client }) => {
      let {
        id,
        definerID,
        statusCode,
        name,
        alias,
        businessType,
        paymentTerms,
        incoTerms,
        relations,
        contacts,
      } = values;

      setIsLoading(true);

      const newContactsFromIncoTerms = incoTerms
        ?.filter((x) => !x.entityUserRelation)
        ?.map((x) => ({ ...x, role: ["WAREHOUSE"] }));

      if (
        paymentTerms !== null &&
        typeof paymentTerms === "object" &&
        Object.keys(paymentTerms).length === 0
      ) {
        paymentTerms = null;
      }

      // CREATE ENTITY

      await onCreateEntity({
        values: {
          id,
          definerID,
          statusCode,
          name,
          alias,
          businessType,
          paymentTerms,
          incoTerms: incoTerms?.map((x) => ({
            terms: x.terms,
            entityUserRelationID: x.entityUserRelationID,
          })),
        },
        client,
      });

      // CREATE CONTACT and ENTITY RELATION

      if (newContactsFromIncoTerms?.length > 0) {
        await Promise.all(
          newContactsFromIncoTerms?.map(async (x) => {
            const {
              userID,
              entityUserRelationID,
              fn,
              ln,
              address,
              phoneNumber,
              email,
            } = x;

            const correctedUserID = await onCreateContact({
              client,
              values: {
                id: userID,
                fn,
                ln,
                address,
                phoneNumber,
                email,
                definerID,
              },
            });

            // console.log({ correctedUserID, userID });
            // debugger;

            await onCreateUserEntityRelation({
              client,
              values: {
                id: entityUserRelationID,
                role: "WAREHOUSE",
                userID: correctedUserID,
                entityID: id,
                definerID,
              },
            });
          })
        );

        // alert(
        //   `${newContactsFromIncoTerms.length} userEntityRelations created from incoTerms`
        // );
      }

      // CREATE CONTACT and ENTITY RELATION

      if (contacts?.length > 0) {
        await Promise.all(
          contacts?.map(async (x) => {
            const { fn, ln, address, phoneNumber, email, role } = x || {};

            const contactID = await onCreateContact({
              client,
              values: {
                fn,
                ln,
                address,
                phoneNumber,
                email,
                definerID,
              },
            });

            if (role?.length > 0) {
              await Promise.all(
                role.map(async (x) => {
                  await onCreateUserEntityRelation({
                    client,
                    values: {
                      role: x,
                      userID: contactID,
                      entityID: id,
                      definerID,
                    },
                  });
                })
              );
            }
          })
        );
      }

      // CREATE ENTITY RELATIONS
      if (relations?.length > 0) {
        await Promise.all(
          relations?.map(async (relation) => {
            const {
              thisEntityIs,
              theOtherEntity,
              relationType,
              paymentTerms,
              incoTerms: relationIncoTerms,
              vendorCode,
              statusCode,
            } = relation;

            await onCreateRelation({
              values: {
                definerID,
                entity1ID:
                  thisEntityIs === relationType.split("-")[0]
                    ? id
                    : theOtherEntity?.id,
                entity2ID:
                  thisEntityIs === relationType.split("-")[1]
                    ? id
                    : theOtherEntity?.id,
                paymentTerms,
                incoTerms: relationIncoTerms?.map((x) => {
                  let correctedIncoTerms = incoTerms?.find(
                    (y) => y.refID === x.refID
                  );

                  return {
                    terms: correctedIncoTerms?.terms || x.terms,
                    entityUserRelationID:
                      correctedIncoTerms?.entityUserRelationID ||
                      x.entityUserRelationID,
                  };
                }),
                vendorCode,
                statusCode,
                relation: relationType,
              },
              client,
            });
          })
        );
      }

      setIsLoading(false);

      navigate(-1);
    },
    [navigate]
  );

  return (
    <>
      <StepForm
        steps={steps}
        onSubmit={onSubmit}
        labelOfSumbit={labelOfSumbit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
      <BackdropLoader isLoading={isLoading} />
    </>
  );
}
