import { useState, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  Stack,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import FormikForm, {
  FormikField,
  FormikAutocomplete,
} from '../../../../component/formikForm'
import filterOutSameItemInArray from '../../../../lib/filterOutSameItemInArray'
import onUpdateBilling from '../onUpdateBilling'

const entitiyUserRelationByDefiner = /* GraphQL */ `
  query EntitiyUserRelationByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
        }
        entityID
        entity {
          id
          alias
          name
        }
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export default function BillingInfo({ data, onClose }) {
  const { id, definerID, billingInfo } = data || {}

  const onSubmit = async ({ values, client }) => {
    try {
      await onUpdateBilling({ values, client })
    } catch (error) {
      console.log({ updateBillingError: error })
    }

    onClose && onClose()
  }

  return (
    <FormikForm
      initialValues={{
        id,
        definerID,
        isEditing: false,
        ...(billingInfo && {
          ...billingInfo,
          ...(billingInfo.entityUserRelationID && {
            entityUserRelation: { id: billingInfo.entityUserRelationID },
          }),
        }),
      }}
      validationSchema={null}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onSubmit={onSubmit}
    />
  )
}

const EditFields = ({ values }) => {
  const { isEditing } = values || {}

  const [range, setRange] = useState('')

  let { data } = useQuery(gql(entitiyUserRelationByDefiner), {
    variables: {
      definerID: values.definerID,
      ...(range === 'internal' && { entityID: { eq: values.id } }),
    },
  })

  let entityUserRelations = useMemo(
    () =>
      data?.entitiyUserRelationByDefiner?.items?.map((x) => ({
        ...x.user,
        userID: x.user.id,
        id: x.id,
        entity: x.entity?.alias || x.entity?.name,
      })) || [],
    [data?.entitiyUserRelationByDefiner?.items],
  )

  entityUserRelations = useMemo(
    () => filterOutSameItemInArray(entityUserRelations, 'userID'),
    [entityUserRelations],
  )

  return (
    <Stack minWidth={800} spacing={5}>
      <Stack>
        <Typography
          variant='h5'
          sx={{ alignSelf: 'center', fontStyle: 'italic' }}>
          Billing Info
        </Typography>

        <Stack direction='row' spacing={5}>
          <FormikField
            disabled={!isEditing}
            name='billingName'
            label='BillingName'
            width={240}
            disableUnderline={!isEditing}
          />
          <FormikField
            disabled={!isEditing}
            name='regNumber'
            label='regNumber'
            width={240}
            disableUnderline={!isEditing}
          />
          <FormikField
            disabled={!isEditing}
            name='taxID'
            label='taxID'
            width={240}
            disableUnderlin
            disableUnderline={!isEditing}
          />
        </Stack>
        <Stack direction='row' spacing={5}>
          <FormikField
            disabled={!isEditing}
            name='address'
            label='Address'
            width={480}
            disableUnderline={!isEditing}
          />
          <FormikField
            disabled={!isEditing}
            name='phoneNumber'
            label='Phone Number'
            width={200}
            disableUnderline={!isEditing}
          />
        </Stack>
        <Stack direction='row' spacing={5}>
          <FormikField
            disabled={!isEditing}
            name='bank'
            label='bank'
            width={240}
            disableUnderline={!isEditing}
          />

          <FormikField
            disabled={!isEditing}
            name='accountNumber'
            label='accountNumber'
            width={240}
            disableUnderline={!isEditing}
          />
        </Stack>
        <Stack direction='row' spacing={5}>
          <FormikField
            disabled={!isEditing}
            name='swiftCode'
            label='swiftCode'
            width={240}
            disableUnderline={!isEditing}
          />
          <FormikField
            disabled={!isEditing}
            name='routingNumber'
            label='routingNumber'
            width={240}
            disableUnderline={!isEditing}
          />
          <FormikField
            disabled={!isEditing}
            name='iban'
            label='iban'
            width={240}
            disableUnderline={!isEditing}
          />
        </Stack>

        <Stack direction='row' spacing={5}>
          <FormControl disabled={!isEditing}>
            <FormLabel id='demo-row-radio-buttons-group-label'>
              contact
            </FormLabel>
            <RadioGroup
              row
              value={range}
              onChange={(e) => setRange(e.target.value)}
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'>
              <FormControlLabel
                value='internal'
                control={<Radio />}
                label='Internal'
              />
              <FormControlLabel
                value='external'
                control={<Radio />}
                label='External'
              />
            </RadioGroup>
          </FormControl>
          <FormikAutocomplete
            disabled={!isEditing}
            width={500}
            name='entityUserRelation'
            label='entityUserRelation'
            options={entityUserRelations || []}
            // options={[]}
            getOptionLabel={(option) => {
              const item = entityUserRelations?.find((x) => x.id === option.id)
              return item ? item.name + '@' + item.entity : ''
            }}
            getPrimaryText={(option) => option.name + '@' + option.entity}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
