import { gql, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { saveAs } from "file-saver";
import simpleSheet from "../../../../lib/simpleSheet";
import { userEntityRelationVar } from "../../../../client/cache";

import { POByDefinerID } from "../query";

import updatePO from "../../CPOs/onUpdate";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "po_align.xls";
  const path = `${entityID}/template/${filename}`;

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

function Action({ row, client, definerID }) {
  const [submitted, setSubmitted] = useState(false);

  async function handleClick() {
    await updatePO({
      values: { id: row.id, statusCode: "900" },
      client,
    });
    // console.log({ res });
    setSubmitted(true);
  }
  return (
    <Stack>
      <LoadingButton
        disabled={submitted}
        onClick={handleClick}
        variant="contained"
        size="small"
      >
        CLOSE PO
      </LoadingButton>
    </Stack>
  );
}

export const headerIdentifier = [
  { label: "SYSTEM", isRequired: true },
  { label: "DOCUMENT", isRequired: true },
  { label: "MATERIAL", isRequired: true },
  { label: "DOCUMENT QUANTITY", isRequired: true },
  { label: "OPEN QUANTITY", isRequired: true },
  { label: "date", isRequired: false },
  { label: "MPN", isRequired: false },
  { label: "operation", isRequired: false, comp: Action },
];

export async function onCreate({ rows, definerID, client }) {
  let problematicRows = rows.filter((x) => x.ERROR.length > 0);
  problematicRows = problematicRows.map((x) => ({
    ...x,
    ERROR: x.ERROR?.map((y) => y.msg).join(", "),
  }));

  // let res = papaparse.unparse(problematicRows, {
  //   skipEmptyLines: true,
  //   header: true,
  // });
  // const blob = new Blob([res], { type: "text/csv;charset=utf-8" });

  const buffer = await simpleSheet(problematicRows, "portrait");

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, "po_align_error.xlsx");
}

export async function validator({
  rows,
  definerID,
  client,
  selfAndSubsidiaries,
}) {
  const { data: POByDefinerIData } = await client.query({
    query: gql(POByDefinerID),
    variables: {
      definerID: "896dc48f-3c18-4154-b552-b2751645d1b3",
      // openQty: { gt: 0 },
      filter: {
        or: selfAndSubsidiaries.map((x) => ({ vendorID: { eq: x.id } })),
      },
    },
  });

  let POs = POByDefinerIData?.POByDefinerID?.items.filter(
    (x) => x.openQty > 0 && x.statusCode !== "900"
  );

  POs = POs.map((x) => {
    return {
      SYSTEM: x.number.split("-")[0],
      DOCUMENT: x.number.split("-")[1],
      MATERIAL: x.materialEntityRelation?.code,
      MPN: x.material?.mpn,
      qty: x.qty,
      openQty: x.openQty,
      date: x.date,
      id: x.id,
    };
  });

  POs.forEach((x) => {
    x["DOCUMENT QUANTITY"] = x.qty;
    x["OPEN QUANTITY"] = x.openQty;
  });

  const newarr = await Promise.all(
    POs?.map(async (po) => {
      let ERROR = [];

      const itemFound = rows.find((x) => x.DOCUMENT * 1 === po.DOCUMENT * 1);
      if (itemFound) {
        console.log({ itemFound });

        if (itemFound["OPEN QUANTITY"] * 1 !== po["OPEN QUANTITY"] * 1) {
          ERROR.push({
            attr: "OPEN QUANTITY",
            isMajor: false,
            msg: `system open PO qty${po["OPEN QUANTITY"]} does not match with input open PO qty${itemFound["OPEN QUANTITY"]}`,
          });
        }
      } else {
        ERROR.push({
          attr: "DOCUMENT",
          isMajor: false,
          msg: "PO not found",
        });
      }

      return { ...po, ERROR };
    })
  );

  return newarr;
}
