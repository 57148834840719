import { Auth } from "aws-amplify";
import { gql } from "@apollo/client";

import groupArrayofObjectsByKey from "../lib/groupArrayofObjectsByKeyAndGenerateObj";
import privateModules from "../modules/privateModules";

export const userMenu = [
  {
    label: "Logout",
    allowedRole: "all",
    entityType: "all",
    auth: ["authenticated"],
    group: 3,
    order: 3,
    onClick: () => Auth.signOut(),
  },
];

export default function getUserMenu({ entityType, userRole, isAuthenticated }) {
  const auth = !!isAuthenticated ? "authenticated" : "unAuthenticated";

  // console.log({ entityModules, systemAdminModules, publicModules });
  // console.log({ entityType, userRole, isAuthenticated });

  // // if (!userRole || !entityType) return;

  const retrivedModules = [...privateModules]
    .filter((x) => !!x.userMenu)
    .map((x) => ({
      ...x.userMenu,
      path: x.path,
    }));

  // console.log({ retrivedModulesTopmenu: retrivedModules });

  const menu = userMenu
    .concat(retrivedModules)
    .filter(
      (x) =>
        (x.allowedRole === "all" ||
          x.allowedRole?.some((y) => userRole?.includes(y))) &&
        (x.entityType === "all" ||
          x.entityType?.some((z) => entityType === z)) &&
        (x.auth === "all" || x.auth?.some((a) => a === auth))
    );

  let groupedUserMenu = groupArrayofObjectsByKey(menu, "group");

  groupedUserMenu = Object.values(groupedUserMenu);

  groupedUserMenu.sort((a, b) => (a[0]?.group > b[0]?.group ? 1 : -1));
  groupedUserMenu?.forEach((arr) => {
    arr.sort((a, b) => (a.order > b.order ? 1 : -1));
  });

  // console.log({ groupedUserMenu });

  return groupedUserMenu;
}
