import { useEffect, useState } from "react";
import { API, Storage, Auth } from "aws-amplify";
import { useQuery, gql, useApolloClient } from "@apollo/client";
import {
  Container,
  Stack,
  Box,
  Typography,
  LinearProgress,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import groupArrayofObjectsByKeyAndGenerateArr from "../../../lib/groupArrayofObjectsByKeyAndGenerateArr";
import SimpleTable from "../../../component/SimpleTable";
import { invoiceByBatch } from "./query";
import FileZone from "../../../component/FileZone";
import updateInvoice from "../../../modules/entityModules/billing/onUpdate";

const headers = [
  "number",
  "itemNumber",
  "mfr",
  "mpn",
  "unit",
  "code",
  "poNo",
  "qty",
  "price",
  "currency",
  "amount",
  // 'subTotal',
];

export default function VATProcess() {
  const navigate = useNavigate();
  const [vatsByNumber, setVatsByNumber] = useState();

  const { entityID } = useParams();
  const client = useApolloClient();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [hasSubmittedUpdate, setHasSubmittedUpdate] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [hasSubmittedCreate, setHasSubmittedCreate] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();

  const data = searchParams.get("data");

  let dataObj = {};
  if (data) {
    dataObj = JSON.parse(Buffer.from(data, "base64"));
  }

  const batch = dataObj.batch;

  const { data: invoiceByBatchData } = useQuery(gql(invoiceByBatch), {
    variables: { batch },
    skip: !batch,
  });

  const invoices = invoiceByBatchData?.invoiceByBatch?.items;

  useEffect(() => {
    const items = invoices?.map((x) => {
      return {
        // ...x,
        id: x.id,
        number: x.number,
        itemNumber: x.itemNumber,
        mfr: x.po?.material?.mfr,
        mpn: x.po?.material?.mpn,
        unit: x.po?.material?.unit,
        code: x.po?.materialEntityRelation?.code,
        poNo: x.po?.no,
        price: x.price,
        currency: x.currency,
        qty: x.qty,
        amount: x.amount,
      };
    });

    items?.sort((a, b) => (a.itemNumber > b.itemNumber ? -1 : 1));

    items?.sort((a, b) => (a.number > b.number ? 1 : -1));

    const vatsByNumber = groupArrayofObjectsByKeyAndGenerateArr(
      items,
      "number"
    );
    setVatsByNumber(vatsByNumber);
  }, [invoices]);

  return (
    <Container maxWidth={false}>
      <Stack spacing={5}>
        {vatsByNumber?.length > 0 && (
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h4">NEW VATS</Typography>
            </Stack>

            {vatsByNumber?.map(({ number, items }) => {
              return (
                // <Stack key={number} spacing={2}>
                <VATByNumber key={number} number={number} items={items} />
                // </Stack>
              );
            })}
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

function VATByNumber({ number, items }) {
  const client = useApolloClient();

  async function cb(number, files) {
    if (number?.length < 1) {
      return alert("please enter a number first");
    }
    await Promise.all(
      items.map(async (x) => {
        const values = { id: x.id, number };
        await updateInvoice({ values, client });
      })
    );

    await API.post("iSupplyAPI", "/restricted", {
      body: {
        operation: "SEND_VAT_TO_CTM",
        data: {
          meta: {
            invoiceNumber: number,
            to: "pgchinaeinvoice.im@pg.com",
            // bcc: "steven.lu@hankeno.com",
            isPreview: true,
          },
        },
      },
    });
  }

  // console.log({ number });
  // debugger
  return (
    <Stack spacing={2} justifyContent="center">
      <SimpleTable headers={headers} rows={items} />
      {/* <Stack direction="row" spacing={2} justifyContent="center"> */}
      {/* <LoadingButton variant='contained'> submit</LoadingButton> */}
      <Box sx={{ alignSelf: "center" }}>
        <FileZone
          storagePath={number}
          allowInputNumber
          cb={cb}
          maxUploadFiles={1}
        />
      </Box>
      {/* </Stack> */}
    </Stack>
  );
}
