import {
  onCreate as onCreateMaterial,
  headerIdentifier as materialHeaderIdentifier,
  TemplateLabel as MaterialTemplateLabel,
} from "./ByType/material";

import {
  onCreate as onCreateEntityMaterialRelation,
  headerIdentifier as entityMaterialRelationIdentifier,
  TemplateLabel as EntityMaterialRelationTemplateLabel,
} from "./ByType/entity_material_relation";

import {
  onCreate as onCreateQuote,
  headerIdentifier as pricingHeaderIdentifier,
  validator as pricingValidator,
  TemplateLabel as PricingTemplateLabel,
} from "./ByType/pricing";

import {
  onCreate as onCreateCost,
  headerIdentifier as costHeaderIdentifier,
  validator as costValidator,
  TemplateLabel as CostTemplateLabel,
} from "./ByType/cost";

import {
  onCreate as onCreateCPO,
  headerIdentifier as CPOHeaderIdentifier,
  validator as CPOValidator,
  TemplateLabel as CPOTemplateLabel,
} from "./ByType/CPO";

import {
  onCreate as onCreateAlignment,
  headerIdentifier as POAlignmentHeaderIdentifier,
  validator as POAlignmentValidator,
  TemplateLabel as POAlignmentTemplateLabel,
} from "./ByType/PO_align";

import {
  onCreate as onCreateShipping,
  headerIdentifier as shippingHeaderIdentifier,
  validator as shippingValidator,
  Bar as ShippingBar,
  TemplateLabel as ShippingTemplateLabel,
  navigateTo as shippingNavigateTo,
} from "./ByType/shipping";

import {
  onCreate as onCreateVendor,
  headerIdentifier as vendorHeaderIdentifier,
  TemplateLabel as VendorTemplateLabel,
  // validator as vendorValidator,
} from "./ByType/vendor";

import {
  onCreate as onCreateContact,
  headerIdentifier as contactHeaderIdentifier,
  validator as contactValidator,
  TemplateLabel as ContactTemplateLabel,
} from "./ByType/contact";

import {
  onCreate as onCreateSPO,
  headerIdentifier as SPOHeaderIdentifier,
  validator as SPOValidator,
  TemplateLabel as SPOTemplateLabel,
} from "./ByType/SPO";

export const getTypeOptions = ({ userRole }) => {
  if (userRole?.includes("admin")) {
    return [
      "MATERIAL",
      "ENTITY_MATERIAL_RELATION",
      "PRICING",
      "CPO",
      "SHIPPING",
      "VENDOR",
      "CONTACT",
      "COST",
      "SPO",
      "PO_ALIGN",
    ];
  } else {
    return ["SHIPPING"];
  }
};

export function getTypeSettings(type) {
  if (!type) return {};
  let headerIdentifier,
    onCreate,
    validator,
    refetchQueries,
    Bar,
    navigateTo,
    TemplateLabel;

  switch (type) {
    case "MATERIAL":
      headerIdentifier = materialHeaderIdentifier;
      onCreate = onCreateMaterial;
      refetchQueries = ["MaterialByDefinerID"];
      TemplateLabel = MaterialTemplateLabel;

      break;

    case "ENTITY_MATERIAL_RELATION":
      headerIdentifier = entityMaterialRelationIdentifier;
      onCreate = onCreateEntityMaterialRelation;
      refetchQueries = ["MaterialByDefinerID"];
      TemplateLabel = EntityMaterialRelationTemplateLabel;
      break;

    case "PRICING":
      headerIdentifier = pricingHeaderIdentifier;
      onCreate = onCreateQuote;
      validator = pricingValidator;
      refetchQueries = ["offerByDefiner"];
      TemplateLabel = PricingTemplateLabel;
      break;
    case "CPO":
      headerIdentifier = CPOHeaderIdentifier;
      onCreate = onCreateCPO;
      validator = CPOValidator;
      refetchQueries = ["POByDefinerID"];
      TemplateLabel = CPOTemplateLabel;

      break;

    case "SHIPPING":
      headerIdentifier = shippingHeaderIdentifier;
      onCreate = onCreateShipping;
      validator = shippingValidator;
      refetchQueries = ["ShippingByTypeAndMaterial"];
      TemplateLabel = ShippingTemplateLabel;
      Bar = ShippingBar;
      navigateTo = shippingNavigateTo;
      break;

    case "VENDOR":
      headerIdentifier = vendorHeaderIdentifier;
      onCreate = onCreateVendor;
      refetchQueries = ["EntitiesByDefiner"];
      TemplateLabel = VendorTemplateLabel;
      break;
    case "CONTACT":
      headerIdentifier = contactHeaderIdentifier;
      onCreate = onCreateContact;
      validator = contactValidator;
      refetchQueries = ["ContactsByDefiner"];
      TemplateLabel = ContactTemplateLabel;

      break;
    case "COST":
      headerIdentifier = costHeaderIdentifier;
      onCreate = onCreateCost;
      validator = costValidator;
      refetchQueries = [""];
      TemplateLabel = CostTemplateLabel;
      break;
    case "SPO":
      headerIdentifier = SPOHeaderIdentifier;
      onCreate = onCreateSPO;
      validator = SPOValidator;
      refetchQueries = [""];
      TemplateLabel = SPOTemplateLabel;
      break;
    case "PO_ALIGN":
      headerIdentifier = POAlignmentHeaderIdentifier;
      onCreate = onCreateAlignment;
      validator = POAlignmentValidator;
      refetchQueries = [""];
      TemplateLabel = POAlignmentTemplateLabel;
      break;

    default:
      break;
  }

  return {
    headerIdentifier,
    onCreate,
    validator,
    refetchQueries,
    Bar,
    TemplateLabel,
    navigateTo,
  };
}
