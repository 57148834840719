import { useState, useEffect } from "react";
import { gql, useReactiveVar, useApolloClient } from "@apollo/client";
import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  Stack,
  Container,
  Typography,
} from "@mui/material";
import { TreeView, TreeItem, LoadingButton } from "@mui/lab";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { userEntityRelationVar } from "../../../../../client/cache";
import BackdropoLoader from "../../../../../component/BackdropLoader";

import groupArrayofObjectsByKeyAndGenerateArr from "../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";
import VendorNameLine from "./VendorNameLine";
import POItemLine from "./POItemLine";
import handleSendETA from "../../functions/handleSendETA";

import { POByCustomerAndOpenQty } from "../../query";

export default function Tree() {
  const { entityID, selfAndSubsidiaries } = useReactiveVar(
    userEntityRelationVar
  );
  const [nodes, setNodes] = useState(null);
  const [expanded, setExpanded] = useState(["root"]);
  const [selected, setSelected] = useState([]);
  const [selectedPOItems, setSelectedPOItems] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  // console.log({ selectedPOItems});

  useEffect(() => {
    if (!expanded.includes("root")) setExpanded((old) => ["root"].concat(old));
  }, [expanded]);

  const handleToggle = (event, nodeIds) => {
    // console.log("toggle");
    // console.log({ nodeIds, event });
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    // console.log("select");
    // console.log({ nodeIds, event });

    setSelected(nodeIds);

    // const closedNodes = nodeIds.filter((x) => nodes.children());
  };

  // const handleExpandClick = () => {
  //   setExpanded((oldExpanded) =>
  //     oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
  //   );
  // };

  // const handleSelectClick = () => {
  //   setSelected((oldSelected) =>
  //     oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
  //   );
  // };

  // console.log({ selfAndSubsidiaries, nodes });
  const client = useApolloClient();

  useEffect(() => {
    if (!selfAndSubsidiaries) return;

    async function func() {
      setIsPageLoading(true);

      const children = await Promise.all(
        selfAndSubsidiaries.map(async (hknEntity) => {
          const { data: POByCustomerAndOpenQtyData } = await client.query({
            query: gql(POByCustomerAndOpenQty),
            variables: {
              customerID: hknEntity.id,
              openQty: { gt: 0 },
              // filter: {
              //   and: {
              //     definerID: { eq: "896dc48f-3c18-4154-b552-b2751645d1b3" },
              //     statusCode: { ne: "900" },
              //   },
              // },
              filter: {
                and: { statusCode: { ne: "900" }, definerID: { eq: entityID } },
              },
            },
          });
          const groupedByVendor = groupArrayofObjectsByKeyAndGenerateArr(
            POByCustomerAndOpenQtyData.POByCustomerAndOpenQty.items,
            "vendorID"
          );

          // console.log({ groupedByVendor });

          return {
            id: hknEntity.id,
            name: hknEntity.abbr,
            children: await Promise.all(
              groupedByVendor.map(async (x) => {
                x.items.sort((a, b) => (a.number > b.number ? 1 : -1));
                x.items.sort((a, b) =>
                  a.itemNumber * 1 < b.itemNumber * 1 ? 1 : -1
                );

                x.items.sort((a, b) => (a.date < b.date ? 1 : -1));

                const subChildren = x.items.map((z) => {
                  return {
                    id: z.id,
                    name: (
                      <POItemLine
                        po={z}
                        setSelectedPOItems={setSelectedPOItems}
                        selectedPOItems={selectedPOItems}
                      />
                    ),
                  };
                });

                subChildren.unshift({
                  id: `${x.number}-header`,
                  disabled: true,
                  name: (
                    <Stack direction="row" spacing={2}>
                      <Typography variant="title" width={160}>
                        Number
                      </Typography>
                      <Typography variant="title" width={120}>
                        Date
                      </Typography>
                      <Typography variant="title" width={300}>
                        MPN
                      </Typography>
                      <Typography variant="title" width={120}>
                        Qty
                      </Typography>
                      <Typography variant="title" width={120}>
                        Open Qty
                      </Typography>
                      <Typography variant="title" width={120}>
                        CRD
                      </Typography>
                      <Typography variant="title" width={200}>
                        ETA
                      </Typography>
                    </Stack>
                  ),
                });

                // const groupedByPONumber =
                //   groupArrayofObjectsByKeyAndGenerateArr(x.items, "number");

                return {
                  id: `${x.vendorID}-${hknEntity.id}`,
                  name: (
                    <VendorNameLine
                      id={`${x.vendorID}-${hknEntity.id}`}
                      vendorID={x.vendorID}
                      customerID={hknEntity.id}
                      po={x.items[0]}
                      setSelectedPOItems={setSelectedPOItems}
                      selectedPOItems={selectedPOItems}
                      vendorPOs={x.items}
                      setExpanded={setExpanded}
                    />
                  ),
                  children: subChildren,
                };
              })
            ),
          };
        })
      );

      const nd = { id: "root", name: "HKN Entities", children };

      setNodes(nd);
      setIsPageLoading(false);
    }

    func();
  }, [selfAndSubsidiaries, client, entityID, selectedPOItems]);

  const renderTree = (nodes) => {
    if (!nodes) return null;

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.name}
        disabled={nodes.disabled}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  // if (!nodes) return null;

  if (isPageLoading) return <BackdropoLoader isLoading={isPageLoading} />;

  return (
    <Box
      sx={{
        px:5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 2,
        // p: 5,
        // mt: 5,
        // height: "100%",
        // bgcolor: "lightBlue",
      }}
    >
      <Stack direction="row" spacing={2}>
        {/* <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
        </Button>
        <Button onClick={handleSelectClick}>
          {selected.length === 0 ? 'Select all' : 'Unselect all'}
        </Button> */}
        {/* <Button
          variant="text"
          disabled={expanded.length < 2}
          onClick={() => {
            setExpanded(["root"]);
          }}
        >
          close all
        </Button> */}
        <LoadingButton
          disabled={selectedPOItems.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsloading(true);
            try {
              await handleSendETA({ selectedPOItems, client });
            } catch (e) {
              console.log({ error778: e });
            }

            setIsloading(false);
          }}
        >
          REQUEST ETA
        </LoadingButton>
      </Stack>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {renderTree(nodes)}
      </TreeView>
    </Box>
  );
}
