export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sub: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
      }
      nextToken
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
    }
  }
`;

export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      code
      fn
      ln
      userID
      user {
        id
      }
      role
      entityID
      createdAt
      updatedAt
    }
  }
`;

export const fragment = /* GraphQL */ `
  fragment NewInvite on Invite {
    id
  }
`;


export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      code
      fn
      ln
      userID
      role
      entityID
      validDays
      status
      groups
      createdAt
      updatedAt
    }
  }
`;

export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      code
      userID
      role
      fn
      ln
      entityID
      status
      validDays
      status
      groups
      createdAt
      updatedAt
    }
  }
`;