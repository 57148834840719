import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import {
  FormikAutocomplete,
  FormikArrayRemoveItemButton,
} from "../../../../../../component/formikForm";
import { purePaymentTerms } from "../../../../../../settings/formFieldEnum";

export default function PaymentTerms({
  index,
  remove,
  arrayFieldName,
  removeBtnColor,
  size,
}) {
  const { values } = useFormikContext();

  const { paymentTerms } = values;

  return (
    <Stack
      direction="row"
      spacing={5}
      alignItems="flex-end"
      // sx={{ height: 200, bgcolor: "red" }}
    >
      <FormikAutocomplete
        // disabled
        name={`[${arrayFieldName}][${index}]`}
        options={purePaymentTerms}
        required
        // multiple
        freeSolo
        width={420}
        label="PaymentTerms"
        getOptionLabel={(option) => option}
        // listItemPrimaryField="label"
        // disableCloseOnSelect
        getOptionDisabled={(option) => {
          return paymentTerms?.includes(option);
        }}
        getPrimaryText={(option) => option}
        //  getSecondaryText={(option) =>
        //    option?.contacts?.length === 0
        //      ? "please go to External Coworkers and add sales contact"
        //      : null
        //  }
      />

      <FormikArrayRemoveItemButton
        onClick={() => remove(index)}
        name={arrayFieldName}
        removeBtnColor={removeBtnColor}
        size={size}
      />
    </Stack>
  );
}
