import { useState } from "react";
import { Auth } from "aws-amplify";
import { TextField, Stack, IconButton, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import emitSnackbar from "../../function/emitSnackbar";

export default function ChangePassword() {
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [retypeNewPwd, setRetypeNewPwd] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isCurrentPwdWrong, setIsCurrentPwdWrong] = useState(false);
  const [loading, setLoading] = useState(false);

  const setInitialValues = () => {
    setOldPwd("");
    setNewPwd("");
    setRetypeNewPwd("");
    setIsEditing(false);
    setErrorText("");
    setIsCurrentPwdWrong(false);
    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // setInitialValues();
    if (newPwd !== retypeNewPwd) {
      setErrorText("The inputs don't mtach");
      return;
    } else {
      setErrorText("");
      setLoading(true);
      console.log({ oldPwd, newPwd, retypeNewPwd, isEditing, loading });

      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPwd, newPwd);
        })
        .then((data) => {
          setInitialValues();
          emitSnackbar({
            duration: 5000,
            message: "Password changed successfully",
          });
        })
        .catch((err) => {
          console.log({ err11222222: err });
          if (err.message.startsWith("Incorrect")) {
            setIsCurrentPwdWrong(true);
            emitSnackbar({
              severity: "error",
              duration: 5000,
              message: "Incorrect password, please retry",
            });
          } else {
            setIsCurrentPwdWrong(false);

            emitSnackbar({
              severity: "error",
              duration: 5000,
              message: err.message,
            });
          }
        });
      setLoading(false);
    }
  };

  return (
    <Stack
      // justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      component="form"
      onSubmit={onSubmit}
      spacing={5}
    >
      {isEditing ? (
        <Stack spacing={3}>
          <TextField
            error={isCurrentPwdWrong}
            required
            sx={{ width: 340 }}
            label={"Current Password"}
            variant="standard"
            type="password"
            value={oldPwd}
            onChange={(e) => {
              setOldPwd(e.target.value);
            }}
          />
          <TextField
            required
            sx={{ width: 340 }}
            label={"New Password"}
            variant="standard"
            type="password"
            value={newPwd}
            onChange={(e) => {
              setNewPwd(e.target.value);
            }}
          />
          <TextField
            error={!!errorText}
            required
            sx={{ width: 340 }}
            label={"Retype New Password"}
            variant="standard"
            type="password"
            value={retypeNewPwd}
            helperText={errorText}
            onChange={(e) => {
              setRetypeNewPwd(e.target.value);
            }}
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography color="gray" disabled>
            Password
          </Typography>

          <IconButton onClick={() => setIsEditing(true)}>
            <EditOutlinedIcon />
          </IconButton>
        </Stack>
      )}

      {isEditing && (
        <Stack alignSelf="center" direction="row" spacing={4}>
          <LoadingButton
            color="secondary"
            variant="contained"
            onClick={() => {
              setOldPwd("");
              setNewPwd("");
              setRetypeNewPwd("");
              setErrorText("");
              setIsEditing(false);
            }}
          >
            CANCEL
          </LoadingButton>
          <LoadingButton
            loading={loading}
            color="secondary"
            variant="contained"
            type="submit"
          >
            CONFIRM
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
}
