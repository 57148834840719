import { useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { Stack, Typography } from "@mui/material";
import SimpleTable from "../../../../component/SimpleTable";
import { getMaterial, receivingAndShippingByMaterialAndTime } from "../query";

export default function History({ data }) {
  const [checkShipping, setCheckShipping] = useState(false);

  const {
    materialID,
    definerID,
    timeStamp: inventoryCheckTimeStamp,
    quantity: inventoryCheckQuantity,
    material,
  } = data || {};

  const { data: receivingAndShippingByMaterialAndTimeData } = useQuery(
    gql(receivingAndShippingByMaterialAndTime),
    {
      variables: {
        materialID,
        // timeStamp: { gt: inventoryCheckTimeStamp },
      },
      skip: !materialID,
    }
  );

  const receivingAndShippings =
    receivingAndShippingByMaterialAndTimeData?.receivingAndShippingByMaterialAndTime?.items?.filter(
      (x) => x.timeStamp > inventoryCheckTimeStamp
    );
  const shippings = receivingAndShippings?.filter((x) => x.type === "OUTGOING");

  const receivings = receivingAndShippings?.filter(
    (x) => x.type === "INCOMING"
  );

  const headers = ["date", "qty", "no"];

  const receivedQtySinceLastCheck = receivings?.reduce((a, v) => a + v.qty, 0);
  const shippedQtySinceLastCheck = shippings?.reduce((a, v) => a + v.qty, 0);
  const calculatedCurrentInventory =
    inventoryCheckQuantity +
    receivedQtySinceLastCheck -
    shippedQtySinceLastCheck;
  const autoGeneratedQty = material?.inventory;

  // console.log({ calculatedCurrentInventory, autoGeneratedQty });

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ minHeight: 120 }}
      bgcolor="#f1f1f1"
      borderRadius={1}
      padding={2}
    >
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ alignSelf: "center" }}>
            Shippings Since Last Check on{" "}
            {new Date(inventoryCheckTimeStamp).toISOString("en-US")}
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Typography>
            Last checked inventory: {inventoryCheckQuantity}
          </Typography>
          <Typography>
            Receivings since the last check:
            {receivedQtySinceLastCheck}
          </Typography>
          <Typography>
            Shippings since the last check:
            {shippedQtySinceLastCheck}
          </Typography>
          <Typography>
            Current Inventory Should be:
            {calculatedCurrentInventory}
          </Typography>

          {!isNaN(calculatedCurrentInventory) &&
            autoGeneratedQty &&
            calculatedCurrentInventory !== autoGeneratedQty && (
              <Typography variant="h5" color="red">
                System Generated Inventory:
                {autoGeneratedQty}, please check and correct
              </Typography>
            )}
        </Stack>

        {receivings?.length > 0 && (
          <Stack spacing={2}>
            <Typography>Receivings:</Typography>
            <SimpleTable headers={headers} rows={receivings} />
          </Stack>
        )}

        {shippings?.length > 0 && (
          <Stack spacing={2}>
            <Typography>Shippings:</Typography>
            <SimpleTable headers={headers} rows={shippings} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
