export default function calculatePaymentDue({
  terms,
  shippingDate,
  invoicingDate,
}) {
  let paymentDueDate;
  const paymentDue =
    Math.max(
      new Date(shippingDate).getTime(),
      new Date(invoicingDate).getTime()
    ) +
    terms * 86400000;

  paymentDueDate = new Date(paymentDue).toISOString("en-US").slice(0, 10);

  return paymentDueDate;
}
