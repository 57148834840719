import { useState, useEffect } from "react";

import { Paper, Typography } from "@mui/material";
// import { AmplifyS3Text } from "@aws-amplify/ui-react/legacy";

export default function S3TextViewer({ url, filename }) {
  const [text, setText] = useState("loading");

  useEffect(() => {
    async function func() {
      if (url) {
        const res = await fetch(url);
        const text = await res.text();
        setText(text);
      }
    }

    func();
  }, [url]);

  return (
    <Paper
      sx={{
        minHeight: 400,
        minWidth: 600,
        // maxHeight: { xs: 233, md: 167 },
        // maxWidth: { xs: 350, md: 250 },
      }}
    >
      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
        {text}
      </Typography>
    </Paper>
  );
}
