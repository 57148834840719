import { onUpdate } from "./index";

const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      code
      fn
      ln
      userID
      role
      entityID
      validDays
      status
      createdAt
      updatedAt
    }
  }
`;

const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      code
      userID
      role
      fn
      ln
      entityID
      status
      validDays
      status
      createdAt
      updatedAt
    }
  }
`;

export default async function onUpdateInvite({ values, props, client }) {
  // const { id, status } = values;

  const fieldsToUpdate = [{ field: "status", createUpdate: false }];

  await onUpdate({
    type: "Invite",
    values,
    mutation: updateInvite,
    query: getInvite,
    // getInitialValue: getInitialValueForUser,
    fieldsToUpdate,
    client,
    refetchQueries: ["UserBySub"],
  });
}
