import { onUpdate } from '../../../function/onUpdate'
import { updateEntityRelations, getEntityRelations } from './query'

export default async function onUpdateRelation({ client, values }) {
  const fieldsToUpdate = [
    // { field: "entity1ID", createUpdate: true },
    // { field: "relation", createUpdate: true },
    { field: 'vendorCode', createUpdate: true },
    // { field: "alias", createUpdate: true },
    { field: 'statusCode', createUpdate: true },
    { field: 'incoTerms', createUpdate: true, key: 'terms' },
    { field: 'paymentTerms', createUpdate: true },
    { field: 'minCharge', createUpdate: true },
    { field: 'serviceFeeRate', createUpdate: true },
    { field: 'type', createUpdate: false },
    { field: 'logisticsTrackingTemplate', createUpdate: false },
    { field: 'logisticsAccountNumber', createUpdate: false },
  ]

  await onUpdate({
    type: 'EntityRelations',
    values,
    mutation: updateEntityRelations,
    query: getEntityRelations,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: ['GetEntity'],
  })
}
