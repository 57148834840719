import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { gql, useReactiveVar, useApolloClient } from "@apollo/client";
import { userVar, userEntityRelationVar } from "../../client/cache";
import { Auth, Hub } from "aws-amplify";

const userBySub = gql`
  query UserBySub(
    $sub: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        email
        statusCode
        fn
        mn
        name @client

        ln
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        entityUserRelations {
          items {
            id
            role
            definerID
            entityID
            entity {
              id
              alias
              name
            }
          }
        }
        invites(filter: { status: { eq: PENDING } }) {
          items {
            id
            code
            userID
            role
            status
            entity {
              id
              name
              alias
              abbr @client
            }
          }
        }
        receivedMessages(filter: { isDeletedByReceiver: { ne: true } }) {
          items {
            id
            message
            subject
            isRead
            createdAt
            sender {
              id
              fn
              ln
              name @client
            }
          }
        }
        offersReceived(filter: { statusCode: { eq: "100" } }) {
          items {
            id
            material {
              id
              mfr
              mpn
            }
            TP
            TPCurrency
            EAU
            application
            projectName
            endCustomer
            paymentTerms
            incoTerms {
              terms
              condition
              entityUserRelationID
            }
            number
            itemNumber
            statusCode
            customer {
              id
              name
              alias
            }
            vendor {
              id
              name
              alias
            }
            buyer {
              id
              fn
              ln
              email
              name @client
            }
            salesID
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const getEntity = gql`
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      name
      alias
      abbr @client
      statusCode
      type
      relationsAsEntity1(
        filter: {
          and: { definerID: { eq: $id }, relation: { eq: "PARENT-SUBSIDIARY" } }
        }
      ) {
        items {
          id
          relation
          entity2ID
          entity2 {
            id
            name
            alias
            abbr @client
          }
        }
      }
    }
  }
`;

export default function useCurrentUser() {
  const navigator = useNavigate();
  const client = useApolloClient();
  const user = useReactiveVar(userVar);
  const userEntityRelation = useReactiveVar(userEntityRelationVar);
  const { pathname } = useLocation();

  useEffect(() => {
    let userData;
    let hubListenerCancelToken;

    async function setUser(data) {
      if (!data) return userVar(null);
      const { attributes, signInUserSession } = data;
      const sub = attributes?.sub;
      const groups = signInUserSession?.accessToken.payload[
        "cognito:groups"
      ]?.map((x) => x?.toLowerCase());

      const { data: userBySubData } = await client.query({
        query: userBySub,
        variables: { sub },
        fetchPolicy: "network-only",
      });

      const fetchedUser = userBySubData?.userBySub?.items?.[0] || {};
      let {
        receivedMessages,
        invites,
        offersReceived,
        id,
        entityUserRelations,
        name,
        ln,
        fn,
      } = fetchedUser;
      receivedMessages = receivedMessages?.items;
      invites = invites?.items;
      entityUserRelations = entityUserRelations?.items;
      offersReceived = offersReceived?.items;

      userVar({
        ...attributes,
        groups,
        id,
        receivedMessages,
        invites,
        offersReceived,
        entityUserRelations,
        name,
        ln,
        fn,
      });
    }

    async function func() {
      if (user === "loading") {
        try {
          userData = await Auth.currentAuthenticatedUser();
        } catch (e) {
          console.log({ "auth error": e });
          userData = null;
        }
        await setUser(userData);
      } else {
        console.log("listening auth...");

        async function listener(data) {
          console.log("data.payload.event", data.payload.event);

          switch (data.payload.event) {
            case "manualForceTokenRefresh":
              await Auth.currentSession();
              userData = await Auth.currentAuthenticatedUser({
                bypassCache: true,
              });
              await setUser(userData);

              break;
            case "signIn":
            case "autoSignIn":
              userData = data.payload.data;
              console.log({ userData });
              // debugger
              await setUser(userData);

              // console.log({ userData });
              // debugger
              break;
            case "signUp":
              userData = data.payload.data;
              await setUser(userData);

              break;
            case "signOut":
              // navigateFunc("/");
              console.log("signing out...");
              userData = null;
              // navigator("/");

              await setUser(userData);
              // Navigate("/");

              // window.location.replace("/");
              break;
            // return <Navigate to="/" />;

            case "signIn_failure":
              // logger.error('user sign in failed')
              break;
            case "tokenRefresh":
              // logger.info('token refresh succeeded')r
              break;
            case "tokenRefresh_failure":
              // logger.error('token refresh failed')
              break;
            case "configured":
              // logger.info('the Auth module is configured')
              break;
            default:
              break;
          }
        }

        hubListenerCancelToken = Hub.listen("auth", listener);
      }
    }

    func();

    return () => {
      console.log("existing  currentAuthenticatedUser ");
      hubListenerCancelToken && hubListenerCancelToken();
    };
  }, [user, client]);

  // console.log({ user, userEntityRelation });

  useEffect(() => {
    async function func() {
      const entityID = pathname?.split("/")[2];
      const isEBPPortal = pathname?.split("/")[1] === "ebp";

      let entity,
        relatedEntityIDs,
        subsidiaryIDs,
        selfAndSubsidiaries,
        userRole = "public",
        entityType = isEBPPortal ? "ebp" : "public",
        isUserEntityAdmin;

      if (user?.groups?.includes("admin")) {
        userRole = "systemAdmin";
        entityType = "systemAdmin";
      } else if (entityID && user?.id) {
        const { data: getEntityData } = await client.query({
          query: getEntity,
          variables: { id: entityID },
        });

        if (getEntityData?.getEntity) {
          entity = getEntityData?.getEntity;

          const subsidiary = entity?.relationsAsEntity1?.items;

          relatedEntityIDs = [
            entityID,
            ...(subsidiary?.map((x) => x.entity2ID) || []),
          ];

          subsidiaryIDs = subsidiary.map((x) => x.entity2ID) || [];

          selfAndSubsidiaries = [
            { id: entityID, abbr: entity.abbr, type: "self" },
            ...(subsidiary?.map((x) => ({
              id: x.entity2ID,
              abbr: x.entity2?.abbr,
              type: "subsidiary",
            })) || []),
          ];

          userRole = user.groups?.map((x) => x.split(`${entityID}-`)[1]);

          entityType = entity.type;

          isUserEntityAdmin = user.groups?.includes(`${entityID}-admin`);
        }
      }

      userEntityRelationVar({
        entityID,
        relatedEntityIDs,
        subsidiaryIDs,
        selfAndSubsidiaries,
        userRole,
        entityType,
        isUserEntityAdmin,
      });
    }

    func();
  }, [pathname, client, user]);
}
