import { useEffect, useMemo } from "react";
import {
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useQuery, gql, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import { FormikCheckbox } from "../../../../../component/formikForm";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";
import { userEntityRelationVar } from "../../../../../client/cache";

export const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          alias
          name
        }
        entity2ID
        entity2 {
          id
          name
          alias
        }
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        incoTerms {
          terms
        }

        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function Form2Vendor({
  handleChange,
  setFieldValue,
  handleBlur,
  values,
  setErrors,
}) {
  const { inquiries, step } = values;

  useEffect(() => {
    if (step !== 2) {
      setFieldValue("step", 2);
    }
  }, [setFieldValue, step]);

  const { entityID, subsidiaryIDs } = useReactiveVar(userEntityRelationVar);

  let { data: relationsByDefinerData } = useQuery(gql(relationsByDefiner), {
    variables: {
      definerID: entityID,
      relation: { eq: "CUSTOMER-SUPPLIER" },
    },
  });

  relationsByDefinerData = relationsByDefinerData?.relationsByDefiner?.items;

  const vendorOptions = useMemo(
    () =>
      filterOutSameItemInArray(relationsByDefinerData, "entity2ID")?.filter(
        (x) => [entityID, ...subsidiaryIDs].includes(x.entity1ID)
      ),
    [relationsByDefinerData, entityID, subsidiaryIDs]
  );

  // console.log({ vendorOptions });

  const parts = useMemo(
    () =>
      filterOutSameItemInArray(inquiries.map((x) => x.material)?.flat(), "id"),
    [inquiries]
  );

  useEffect(() => {
    if (step !== 2) {
      setFieldValue("step", 2);
    }
  }, [setFieldValue, step]);

  return (
    <Stack>
      {parts?.length > 1 && (
        <FormikCheckbox
          label="apply first line's vendor to all items"
          name="applyVendorToAll"
        />
      )}
      <Stack spacing={5}>
        {parts?.map((item, index) => {
          return (
            <ItemByMPN
              key={item.id}
              item={item}
              index={index}
              vendorOptions={vendorOptions}
              values={values}
              parts={parts}
              setFieldValue={setFieldValue}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

function ItemByMPN({
  item,
  index,
  vendorOptions,
  values,
  parts,
  setFieldValue,
}) {
  const { inquiries, applyVendorToAll } = values;

  useEffect(() => {
    if (applyVendorToAll && index !== 0 && item.vendors !== parts[0]?.vendors) {
      setFieldValue(
        "inquiries",
        inquiries.map((x) => {
          const material = x.material.map((y) => ({
            ...y,
            vendors: parts[0]?.vendors,
          }));

          return { ...x, material };
        })
      );
    }
  }, [item, index, setFieldValue, applyVendorToAll, parts, inquiries]);

  return (
    <Stack spacing={2}>
      <Chip
        siza="small"
        label={`${item.code || ""} ${item?.mfr}-${item.mpn}`}
        color="primary"
        variant="contained"
        clickable
      />

      <Stack direction="row" spacing={4} alignItems="flex-end">
        <Autocomplete
          sx={{ width: 500 }}
          disabled={index !== 0 && applyVendorToAll}
          options={vendorOptions || []}
          multiple
          getOptionLabel={(option) =>
            option.entity2?.alias || option.entity2?.name
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableCloseOnSelect
          value={item?.vendors || []}
          onChange={(e, v) => {
            console.log({ e, v });
            setFieldValue(
              "inquiries",
              inquiries.map((x) => {
                const material = x.material.map((y) => ({
                  ...y,
                  vendors: item?.id === y?.id ? v : y.vendors,
                }));

                return { ...x, material };
              })
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option.contacts.length === 0;
          // }}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <ListItemText
                  primary={option?.entity2?.alias || option?.entity2?.name}
                  secondary={option?.entity1?.alias || option?.entity1?.name}
                />
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                autoComplete="off"
                label="Please select vendor(s)"
                required
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                }}
              />
            );
          }}
          // getSecondaryText={(option) =>
          //   option?.contacts?.length === 0
          //     ? "please go to External Coworkers and add sales contact"
          //     : null
          // }
        />
      </Stack>
    </Stack>
  );
}
