export const invoiceByBatch = /* GraphQL */ `
  query InvoiceByBatch(
    $batch: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByBatch(
      batch: $batch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        type
        batch
        number
        itemNumber
        date
        price
        qty
        amount @client
        currency
        note
        desc
        poID
        po {
          id
          number
          itemNumber
          no @client
          material {
            id
            mfr
            mpn
            unit
          }
          materialEntityRelation {
            id
            code
          }
        }
        forwarderPOID
        paymentDueDate
        billingEntityID
        shippingID
        paymentStatus
        deductionDate
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
