import { onCreate } from "../../../function/onCreate";
import { createPayment } from "./query";
import onUpdateInvoice from "../billing/onUpdate";
import updateInvoicePaymentStatusFromArray from "../../../function/utilityAPI/updateInvoicePaymentStatusFromArray";

// import updateInvoicePaymentStatusFromForwarderPOPayment from "../../../function/utilityAPI/updateInvoicePaymentStatusFromForwarderPOPayment";

export async function onCreatePayment({ client, values }) {
  const fragment = /* GraphQL */ `
    fragment NewPayment on Payment {
      id
    }
  `;
  // console.log({ values });
  // debugger;
  await onCreate({
    input: values,
    mutation: createPayment,
    fragment,
    updateCacheFields: [],
    client,
    refetchQueries: [],
  });
}

export async function onCreatePaymentsFromInvoices({ client, values, type }) {
  const { date, definerID, invoiceItems } = values;

  if (!invoiceItems?.length) return;

  //DON'T CHANGE SEQUENCE

  await updateInvoicePaymentStatusFromArray({
    client,
    arr: invoiceItems?.map((x) => ({
      amount: x.paidAmount,
      invoiceID: x.id,
    })),
    type: "add",
  });

  await Promise.all(
    invoiceItems.map(
      async ({
        itemNumber,
        materialID,
        materialEntityRelationID,
        poID,
        qty,
        id: invoiceID,
        paidAmount,
      }) => {
        const values = {
          date,
          type,
          invoiceID,
          definerID,
          amount: paidAmount,
        };

        await onCreatePayment({ client, values });
      }
    )
  );
  await client.refetchQueries({ include: ["PaymentByType"] });
}

export async function onCreatePaymentsFromPOs({ client, values, type }) {
  const { date, definerID, POItems } = values;

  // console.log({ POItems });

  // debugger;

  if (!POItems?.length) return;

  await Promise.all(
    POItems.map(
      async ({
        itemNumber,
        materialID,
        materialEntityRelationID,
        id: poID,
        qty,
        paidAmount,
        pi,
        priceCur,
        taxAndHandlingChecked,
        taxAndHandling,
        goodsPaymentChecked,
        goodsPayment,
      }) => {
        if (!pi && paidAmount * 1 > 0) {
          const values = {
            date,
            type,
            poID,
            definerID,
            amount: paidAmount,
          };

          await onCreatePayment({ client, values });
        } else {
          if (taxAndHandlingChecked && taxAndHandling * 1 > 0) {
            const values = {
              date,
              type,
              poID,
              definerID,
              amount: taxAndHandling,
              desc: "tax/handling/duty",
            };

            await onCreatePayment({ client, values });
          }

          if (goodsPaymentChecked && goodsPayment * 1 > 0) {
            const values = {
              date,
              type,
              poID,
              definerID,
              amount: goodsPayment,
              desc: "goods payment",
            };

            await onCreatePayment({ client, values });

            const piID = pi?.id || pi;

            if (!piID || typeof piID !== "string") {
              return alert(`something wrong with piID ${piID}`);
            }
            await onUpdateInvoice({
              client,
              values: {
                id: piID,
                paymentStatus: "PAID",
              },
            });
          }
        }
      }
    )
  );

  await client.refetchQueries({ include: ["PaymentByType"] });
}
