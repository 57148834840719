import React, { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { Hub } from "aws-amplify";

export default function SnackbarListener() {
  const [payload, setPayload] = useState();
  const { data } = payload || {};
  const { duration, severity, onCloseAlert, message } = data || {};

  useEffect(() => {
    let ignore = false;

    const listener = ({ payload }) => {
      if (!ignore) setPayload(payload);
    };

    Hub.listen("snackbarMonitor", listener);

    return () => {
      ignore = true;
      Hub.remove("snackbarMonitor", listener);
    };
  }, []);

  if (!payload) return null;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open
      {...(duration && {
        autoHideDuration: duration,
      })}
      // autoHideDuration={3000}
      onClose={(e, reason) => {
        // console.log({ e, reason });
        if (reason === "clickaway") {
          return;
        }
        setPayload();
      }}
    >
      <Alert
        {...(onCloseAlert && {
          onClose: () => {
            setPayload();
            onCloseAlert();
          },
        })}
        variant="filled"
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
