const mergeArrayAndCombineQty = (items, reduceBy, qtyAttr) => {
  // console.log('reduceBy', reduceBy)
  // debugger

  if (!Array.isArray(items)) return;
  const copyedArr = JSON.parse(JSON.stringify(items));

  const mergedArr = copyedArr.reduce((a, v) => {
    const sameItem = a.find((x) => {
      let found = true;
      for (let attr of reduceBy) {
        // console.log('xxxxxxxxxxxxxx', x)
        // console.log(' x[attr]  x[attr] ', x[attr])

        if (
          (typeof x[attr] === "object" || typeof x[attr] === "function") &&
          x[attr] !== null
        ) {
          // console.log('true11111111111111111111111111')

          if (x[attr].value !== v[attr].value) return false;
          // console.log(' xxxxxxxxxxxxxxxx', x)
          // console.log(' [attr]  [attr] ', attr)
          // console.log(' x[attr]  x[attr] ', x[attr])
        } else if (x[attr] !== v[attr]) {
          // console.log('true2222222222222222222222222')
          // console.log(' xxxxxxxxxxxxxxxx', x)
          // console.log(' [attr]  [attr] ', attr)
          // console.log(' x[attr]  x[attr] ', x[attr])

          return false;
        } else {
          // console.log('falseeeeeeeeeeeee')
          // console.log(' xxxxxxxxxxxxxxxx', x)
          // console.log(' [attr]  [attr] ', attr)
          // console.log(' x[attr]  x[attr] ', x[attr])
        }
      }

      return found;
    });

    // console.log('sameItem', sameItem)

    // debugger

    if (sameItem) {
      // console.log('sameeeeeeeeeeeeeeeeeeee')
      if (qtyAttr?.length > 0) {
        // console.log('sameItem', sameItem)
        qtyAttr.forEach((x) => {
          // console.log('sameItem[x]', sameItem[x])
          // console.log('v[x]', v[x])

          sameItem[x] = (sameItem[x] * 1 + v[x] * 1).toFixed(4) * 1;
        });
      }

      return a;
    } else {
      return a.concat(v);
    }
  }, []);

  return mergedArr;
};

export default mergeArrayAndCombineQty;
