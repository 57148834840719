import { onDelete } from "../../../function/onDelete";
import onUpdate from "./onUpdate";

import {
  deleteMaterial,
  materialEntityRelationByMaterial,
  deleteMaterialEntityRelation,
} from "./query";

export default async function onDeleteMaterial({
  selectionModel,
  setSelectionModel,
  client,
  values,
}) {
  if (selectionModel) {
    await Promise.all(
      selectionModel?.map(async (items) => {
        await deleteSingleItem(items, client);
      })
    );
    setSelectionModel && setSelectionModel([]);
  } else if (values) {
    await deleteSingleItem(values, client);
  }
}

async function deleteSingleItem(item, client) {
  const { id } = item || {};
  // const res = await onUpdate({ values: { id, isDeleted: true }, client });
  // console.log({ res });

  await onDelete({
    input: { id },
    mutation: deleteMaterial,
    client,
    relations: [
      {
        query: materialEntityRelationByMaterial,
        variables: { materialID: id },
        mutation: deleteMaterialEntityRelation,
      },
    ],
    storagePath: id,
  });
}
