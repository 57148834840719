import { onUpdate } from "../../../function/onUpdate";
import { updateEntity, getEntity } from "./query";

export default async function onUpdateEntity({ values, client }) {
  const incoTerms = values.incoTerms?.map((x) => ({
    terms: x.terms,
    condition: x.condition,
    entityUserRelationID: x.entityUserRelation.id,
  }));
  const fieldsToUpdate = [
    { field: "name", createUpdate: true },
    { field: "type", createUpdate: true },
    { field: "alias", createUpdate: true },
    { field: "incoTerms", createUpdate: true, key: "terms" },
    { field: "paymentTerms", createUpdate: true },
  ];

  await onUpdate({
    type: "Entity",
    values: { ...values, incoTerms },
    mutation: updateEntity,
    query: getEntity,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: [],
    onUpdate: null,
  });
}
