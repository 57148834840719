import { useApolloClient } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import FormikForm from '../../../../component/formikForm'
import onDeleteShipping from '../../shippings/onDelete'

export default function Item({ data, onClose }) {
  const client = useApolloClient()

  const onDeleteBtnClick = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await onDeleteShipping({ values: data, client, type: 'RECEIVING' })
      onClose()
    }
  }

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={null}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onDelete={onDeleteBtnClick}
      onSubmit={null}
      disableEdit
    />
  )
}

const EditFields = ({ values }) => {
  const { date, number, itemNumber, material, materialEntityRelation, qty } =
    values
  return (
    <Stack spacing={3}>
      <Stack direction='row' spacing={5}>
        <Typography> DATE: {date}</Typography>
        <Typography> NUMBER: {number}</Typography>
        <Typography> ITEM NUMBER: {itemNumber}</Typography>
      </Stack>

      <Stack direction='row' spacing={5}>
        {materialEntityRelation && (
          <Typography> CODE: {materialEntityRelation?.code}</Typography>
        )}

        <Typography> MFR: {material?.mfr}</Typography>
        <Typography> MPN: {material?.mpn}</Typography>
      </Stack>
      <Stack direction='row' spacing={5}>
        <Typography>
          QTY: {qty} {material?.unit}
        </Typography>
      </Stack>
    </Stack>
  )
}
