import { Typography } from "@mui/material";
import RFQForUnregisterdUser from "./component/RFQForUnregisterdUser";

const rfq = {
  path: "rfq",

  // topNav: {
  //   order: 30,
  //   label: "RFQ",
  //   entityType: ["ebp"],
  //   allowedRole: "all",
  //   auth: ["unAuthenticated"],
  // },
  route: {
    index: {
      element: <RFQForUnregisterdUser />,
      allowedRole: ["public"],
      entityType: ["ebp"],
      auth: ["unAuthenticated"],
    },
  },
};

export default rfq;
