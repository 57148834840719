import BatchInput from "./BatchInput";

const batch_import = {
  path: "batch_import",

  sideNav: {
    group: 6,
    order: 10,
    label: "Batch Import",
    entityType: "all",
    allowedRole: ["admin", "sales"],
    auth: ["authenticated"],
  },

  route: {
    index: {
      element: null,
      Comp: BatchInput,
      allowedRole: "all",
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default batch_import;
