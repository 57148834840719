import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { API } from "aws-amplify";
import { useQuery, gql } from "@apollo/client";

const getEntityUserRelation = /* GraphQL */ `
  query GetEntityUserRelation($id: ID!) {
    getEntityUserRelation(id: $id) {
      id
      role
      title
      shownFn
      shownLn
      shownMn
      statusCode
      isEmailVerified
      userID
      user {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        name @client
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      entityID
      entity {
        id
        isVerified
        name
        alias
        address
        phoneNumber
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      definerID

      groupToRead
      groupToEdit
      groupToDelete
      createdAt
      updatedAt
      __typename
    }
  }
`;

export default function PGShippingDoBtn({
  shippingNumber,
  shippingItems,
  packingDetails,
  date,
  carrier,
  trackingNumber,
  invoices,
  type,
}) {
  const trackingURL =
    "https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=TRACKING_NUMBER".replace(
      "TRACKING_NUMBER",
      trackingNumber
    );

  const [isLoading, setIsLoading] = useState(false);
  const { vendor, customer, incoTerms, paymentTerms } =
    shippingItems[0]?.po || {};

  const { data } = useQuery(gql(getEntityUserRelation), {
    variables: { id: incoTerms.entityUserRelationID },
    skip: !incoTerms.entityUserRelationID,
  });

  const { user, entity } = data?.getEntityUserRelation || {};

  // console.log({
  //   user,
  //   entity,
  //   date,
  //   carrier,
  //   trackingNumber,
  //   vendorName: vendor.name,
  //   customerName: customer.name,
  //   incoTerms: incoTerms?.terms,
  //   entityUserRelationID: incoTerms.entityUserRelationID,
  // })

  async function onClick() {
    if (
      type === "prev" ||
      window.confirm("Are you sure you wish to send docs?")
    ) {
      setIsLoading(true);
      if (!packingDetails || !invoices || invoices.length === 0) {
        return alert("packing details or invoice items missing");
      }

      const meta = {
        date,
        invoiceNumber: invoices?.[0]?.number,
        vendorName: vendor.name,
        customerName: customer.name,
        hknVendorCode: "15326403",
        shippingNumber,
        vendorAddress:
          "ONE AMERICA PLAZA, 600 W BROADWAY, #175-180, SAN DIEGO, CA 92101",
        vendorPhoneNumber: "+1-951-297-7788",
        incoTerms: incoTerms?.terms,
        // customerBillingAddress,
        // customerBillingPhoneNumber,
        // customerBillingContact,
        // customerBillingSubsdiary,
        paymentTerms,
        currency: invoices?.[0]?.currency,
        customerReceivingAddress: user?.address,
        customerReceivingPhoneNumber: user?.phoneNumber,
        customerReceivingContact: user?.name,
        customerReceivingSubsdiary: entity?.name,
        trackingNumber,
        carrier,
        trackingURL,
        internalRecipient: "steven.lu@hankeno.com",
        //please add
        agentRecipient: "pug-avis-rod@geodis.com",
        customerRecipient: "keller.m.4@pg.com, bartosch.m@pg.com",
      };

      // console.log({ meta, packingDetails, invoices, type });
      // debugger;

      await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "SEND_SHIPPING_DOCUMENTS",
          data: {
            meta,
            packingItems: packingDetails,
            invoiceItems: invoices,
            type,
          },
        },
      });
      setIsLoading(false);
    }
  }

  return (
    <LoadingButton loading={isLoading} variant="contained" onClick={onClick}>
      PG Shipping Doc {type}
    </LoadingButton>
  );
}
