import { API } from "aws-amplify";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, { FormikField } from "../../../../component/formikForm";

const validationSchemaForCreate = Yup.object().shape({});

export default function CreateForm() {

  const navigate = useNavigate();

  // const { state } = useLocation();
  // const { entityID } = useParams();

  // const { data: MaterialsByDefinerData } = useQuery(gql(MaterialsByDefiner), {
  //   variables: { definerID: entityID },
  // });

  // const materialOptions = MaterialsByDefinerData?.MaterialsByDefiner?.items.map(
  //   (x) => {
  //     return {
  //       id: x.id,
  //       material: `${x.mpn} ${x.mfr} `,
  //       unit: x.unit,
  //     };
  //   }
  // );

  // if (!materialOptions) return null;

  const initialValues = {
    isEditing: true,
    date: new Date().toISOString("en-US").slice(0, 10),
  };

  const onSubmit = async ({ values, client }) => {
    const res = await API.post("iSupplyAPI", "/restricted", {
      body: {
        operation: "SEND_EMAIL",
        data: {
          subject: "test",
          to: "steven.lu@hankeno.com",
          cc: "antrons@gmail.com",
          bcc: null,
          senderCompanyName: "Steven Lu",
          recipientName: "Denny Lu",
          items: ["item1", "item2", "item3"],
          btnURL: "www.google.com",
          btnLabel: "confirm",
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      },
    });

    console.log(res);
    // await onCreateOrUpdateBatchInventory({
    //   values,
    //   client,
    // });

    // navigate(-1);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      onSubmit={onSubmit}
      FormMainFields={CreateFields}
      FormArray={null}
      handleCancel={handleCancel}
    />
  );
}

function CreateFields({ values, setFieldValue }) {
  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5}>
        <FormikField
          name="date"
          label="Date"
          type="date"
          required
          width={180}
        />
      </Stack>
    </Stack>
  );
}
