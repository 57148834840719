import { useState, useEffect, useMemo } from "react";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import papaparse from "papaparse";
import {
  Container,
  Box,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import inputConversion from "./inputConversion";
import { getTypeOptions, getTypeSettings } from "./typeSettings";
import { userEntityRelationVar } from "../../../client/cache";

export default function BatchInput({
  entityType,
  userRole,
  isAuthenticated,
  entityID,
}) {
  const isAdmin = userRole?.includes("admin");

  const { selfAndSubsidiaries } = useReactiveVar(userEntityRelationVar);

  // console.log({ selfAndSubsidiaries });

  const typeOptions = getTypeOptions({ userRole });
  const navigate = useNavigate();
  // const { entityID } = useParams();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [textarea, setTextarea] = useState("");
  const [formattedData, setFormattedData] = useState();

  // console.log({ formattedData });

  const {
    headerIdentifier,
    onCreate,
    validator,
    refetchQueries,
    Bar,
    TemplateLabel,
    navigateTo,
  } = useMemo(() => getTypeSettings(type), [type]);

  // const headers = headerIdentifier?.map((x) => x.label);

  async function handleSubmit() {
    setLoading(true);
    // console.log({ navigateTo });
    // debugger;

    try {


      await onCreate({ rows: formattedData, definerID: entityID, client });


      // await Promise.all(
      //   formattedData.map(async (row) => {
      //     await onCreate({ row, definerID: entityID, client });
      //   })
      // );
      await client.refetchQueries({
        include: refetchQueries,
      });
      setLoading(false);
      setTextarea("");

      navigateTo ? navigate(navigateTo) : navigate(-1);
    } catch (err) {
      console.log({ err2555: err });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!type) return;
    async function func() {
      let { data, errors } = papaparse.parse(textarea, {
        skipEmptyLines: true,
        newline: "",
        // delimiter: "",
        delimitersToGuess: [",", "\t", "|", ";"],
      });

      if (errors.length > 0) {
        // alert("papaparse error, see console");
        console.log("papaparse error", errors);
        // setFormattedData(null);
        // return;
      }

      let rows = inputConversion({
        data,
        type,
      });

      if (validator && rows?.length > 0) {
        rows = await validator({
          rows,
          client,
          definerID: entityID,
          selfAndSubsidiaries,
        });
      }
      // console.log({ rows });

      setFormattedData(rows);
    }

    func();
  }, [type, textarea, client, selfAndSubsidiaries, validator, entityID]);

  return (
    <Stack width="100%" spacing={3} alignItems="center" p={3} m={3}>
      <Container
        disableGutters
        maxWidth={false}
        // component="main"
        sx={{
          bgcolor: "#f1f1f1",
          p: 3,
          width: 800,
        }}
      >
        <Stack spacing={2} alignItems="center">
          <Typography
            component="h1"
            variant="h2"
            fontWeight={300}
            color="secondary"
            gutterBottom
          >
            Batch Input
          </Typography>

          <TextField
            id="outlined-select-type"
            select
            sx={{ width: 500 }}
            label="Select"
            value={type}
            onChange={(e) => setType(e.target.value)}
            // defaultValue="MATERIAL"
            // helperText="Please select type"
          >
            {typeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ width: 500, minHeight: 50 }}>
            {TemplateLabel && <TemplateLabel />}
          </Box>

          <TextField
            disabled={!type}
            id="pasteInput"
            label="Please paste your data here"
            // placeholder="Please paste your data here"
            multiline
            rows={4}
            sx={{ width: 500 }}
            value={textarea}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Stack direction="row">
                    <IconButton
                      // aria-label="toggle password visibility"
                      onClick={() => {
                        setTextarea("");
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Stack>
                </InputAdornment>
              ),
            }}
            onChange={async (e) => {
              setTextarea(e.target.value);
            }}
          />
        </Stack>
      </Container>

      {Bar && <Bar rows={formattedData} />}

      <TableContainer component={Paper} sx={{ minWidth: 1200 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerIdentifier?.map((header) => (
                <TableCell key={header.label}>{header.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedData?.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {headerIdentifier?.map(({ label, comp }) => {
                  const Comp = comp;
                  const error = row.ERROR?.find((x) => x.attr === label);
                  const { isMajor, msg } = error || {};
                  // console.log({ error, isMajor, msg });

                  if (Comp) {
                    return (
                      <TableCell key={label}>
                        <Comp row={row} client={client} />
                      </TableCell>
                    );
                  }
                  return (
                    <Tooltip
                      key={label}
                      title={
                        msg ? (
                          <Typography variant="h4"> {msg} </Typography>
                        ) : null
                      }
                    >
                      <TableCell
                        key={label}
                        {...(error && {
                          bgcolor: isMajor ? "red" : "orange",
                        })}
                      >
                        {row[label]}
                      </TableCell>
                    </Tooltip>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!!onCreate && (
        <LoadingButton
          loading={loading}
          disabled={
            !formattedData?.length > 0 ||
            formattedData?.some((x) => x.ERROR?.some((y) => y.isMajor))
          }
          variant="contained"
          onClick={handleSubmit}
        >
          submit
        </LoadingButton>
      )}
    </Stack>
  );
}
