import React, { Fragment } from "react";

import {
  Container,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Link,
  GlobalStyles,
  CssBaseline,
  Button,
  CardContent,
  Box,
  CardHeader,
  Card,
  CardActions,
} from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#9e9e9e"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="#9e9e9e" href="#">
        Hankeno LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

export default function Footer() {
  return (
    <Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      {/* Footer */}
      <Box sx={{ py: [5, 6], bgcolor: "#424242" }}>
        <Container
          component="footer"
          sx={{
            // bgcolor: "red",
          }}
        >
          <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title} >
                <Typography variant="h6" color="white" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item}>
                      <Link href="#" variant="subtitle1" color="#9e9e9e">
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Box>

      {/* End footer */}
    </Fragment>
  );
}
