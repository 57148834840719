import { gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import SimpleTable from "../component/SimpleTable";
import { vatRate } from "../settings/formFieldEnum";

export default async function calculateForwarderPOFees({
  items,
  exchangeRate = 0,
  serviceFeeRate = 0,
  minCharge = 0,
  date,
  incoTerms,
  paymentTerms,
}) {
  let modifiedItems = items;

  if (!modifiedItems || !Array.isArray(modifiedItems)) return [null, null];

  if (modifiedItems.some((x) => !x)) return [null, null];

  const arr = modifiedItems.map((x) => {
    let {
      price,
      currency,
      qty,
      importDutyCN,
      ...props
    } = x || {};

    importDutyCN = importDutyCN || 0;

    const cnyValue = (price * qty * exchangeRate).toFixed(2) * 1;
    const importDuty = (cnyValue * importDutyCN).toFixed(2) * 1;
    const handlingFee =
      ((cnyValue + importDuty) * serviceFeeRate).toFixed(2) * 1;
    const vat =
      ((cnyValue + importDuty + handlingFee) * vatRate).toFixed(2) * 1;
    const contractAmount =
      (cnyValue + importDuty + handlingFee + vat).toFixed(2) * 1;

    return {
      ...props,
      qty,
      cnyValue,
      importDuty,
      handlingFee,
      vat,
      contractAmount,
      piPrice: price,
      piPriceCur: currency,
      price: ((contractAmount - vat) / qty).toFixed(4) * 1,
      priceCur: "CNY",
      importDutyCN,
    };
  });

  const handlingFeeTotal = arr.reduce((a, v) => a + v.handlingFee, 0);

  let extraCharge;
  if (handlingFeeTotal < minCharge) {
    extraCharge =
      ((minCharge - handlingFeeTotal) * (1 + vatRate)).toFixed(2) * 1;
  } else {
    extraCharge = 0;
  }

  arr.forEach((x) => {
    x.extraCharge = extraCharge;
  });

  // console.log("arrrrrrrrr888888", arr);

  return [
    arr,
    <ForwarderPODetails
      calculatedItems={arr}
      exchangeRate={exchangeRate}
      serviceFeeRate={serviceFeeRate}
      minCharge={minCharge}
      date={date}
      incoTerms={incoTerms}
      paymentTerms={paymentTerms}
    />,
  ];
}

function ForwarderPODetails({
  calculatedItems,
  exchangeRate,
  serviceFeeRate,
  minCharge,
  date,
  incoTerms,
  paymentTerms,
}) {
  return (
    <Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">Date: {date}</Typography>
        <Typography variant="h6">IncoTerms: {incoTerms}</Typography>
        <Typography variant="h6">PaymentTerms: {paymentTerms}</Typography>
        <Typography variant="h6">FXRate: {exchangeRate}</Typography>
        <Typography variant="h6">ServiceFeeRate: {serviceFeeRate}</Typography>
        <Typography variant="h6">MinCharge: {minCharge}</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Typography variant="h6">
          税款合计: {calculatedItems?.reduce((a, v) => a + v.vat, 0).toFixed(2)}
        </Typography>
        <Typography variant="h6">
          关税合计:
          {calculatedItems?.reduce((a, v) => a + v.importDuty, 0).toFixed(2)}
        </Typography>
        <Typography variant="h6">
          代理费合计:
          {calculatedItems?.reduce((a, v) => a + v.handlingFee, 0).toFixed(2)}
        </Typography>
        <Typography variant="h6">
          附加: {calculatedItems?.[0]?.extraCharge}
        </Typography>
        <Typography variant="h6">
          税代合计:
          {(
            calculatedItems.reduce(
              (a, v) => a + v.importDuty + v.vat + v.handlingFee,
              0
            ) + calculatedItems[0].extraCharge
          ).toFixed(2)}
        </Typography>
        <Typography variant="h6">
          总计:
          {(
            calculatedItems.reduce((a, v) => a + v.contractAmount, 0) +
            calculatedItems[0].extraCharge
          ).toFixed(2)}
        </Typography>
      </Stack>

      {calculatedItems?.length > 0 && (
        <SimpleTable
          headers={[
            "poNo",
            "mfr",
            "mpn",
            "qty",
            "piPriceCur",
            "piPrice",
            "cnyValue",
            "importDutyCN",
            "importDuty",
            "handlingFee",
            "vat",
            "contractAmount",
            "priceCur",
            "price",
          ]}
          rows={calculatedItems}
        />
      )}

      {/* {calculatedItems?.map(
        ({
          id,
          poNo,

          mfr,
          mpn,
          importDutyCN,

          qty,
          price,
          priceCur,
          cnyValue,
          handlingFee,
          importDuty,
          vat,
          contractAmount,
          piPriceCur,
          piPrice,
        }) => {
          return (
            <Stack key={id} spacing={1}>
              <Stack direction="row" spacing={2}>
                <Typography>PO Number: {poNo}</Typography>
                <Typography>MFR: {mfr}</Typography>
                <Typography>MPN: {mpn}</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography width={80}> Qty: {qty}</Typography>
                <Typography>
                  Price: {priceCur}
                  {price}
                </Typography>
                <Typography>Amount: {(price * qty || 0).toFixed(2)}</Typography>
                <Typography>cnyValue: {(cnyValue || 0).toFixed(2)}</Typography>
                <Typography>importDutyRate: {importDutyCN || 0}</Typography>
                <Typography>importDuty: {importDuty || 0}</Typography>
                <Typography>vat: {vat || 0}</Typography>
                <Typography>handlingFee: {handlingFee || 0}</Typography>
                <Typography>contractAmount: {contractAmount || 0}</Typography>

                <Typography>
                  PI Price: {piPriceCur}
                  {piPrice}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      )} */}
    </Stack>
  );
}
