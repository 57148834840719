export const paymentByType = /* GraphQL */ `
  query PaymentByType(
    $type: String!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByType(
      type: $type
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        isPrepayment
        date
        poID
        invoiceID
        invoice {
          id
          number
          itemNumber
          no @client
          po {
            id
            customer {
              id
              name
              alias
              abbr @client
            }
          }
        }
        amount
        desc
        definerID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      type
      isPrepayment
      date
      poID

      invoiceID

      amount
      desc
      definerID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;

export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      type
      date
      poID

      owner
      __typename
    }
  }
`;

