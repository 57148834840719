import { Box } from "@mui/material";
// import { AmplifyS3Image } from "@aws-amplify/ui-react/legacy";

export default function S3ImageViewer({ url, filename }) {
  console.log({ filename });
  return (
    <Box
      component="img"
      sx={{
        minHeight: 400,
        minWidth: 600,
        // maxHeight: { xs: 233, md: 167 },
        // maxWidth: { xs: 350, md: 250 },
      }}
      alt={filename}
      src={url}
    />
  );
}
