import { useState, useEffect, } from "react";
import {
  Stack,
  Button,
  Popover,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  IconButton,
  MenuItem,
  TextField,
  Badge,
  InputAdornment,
} from "@mui/material";
import {
  Clear as ClearIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { customizedI18nGet } from "../../../settings/dictionary";

export default function Filters({
  setFilteredRows,
  filterAttrs,
  rows: originalRows,
}) {
  const defaultLine = { attr: "", operator: "", value: "", value2: "" };

  const [anchorEl, setAnchorEl] = useState(null);
  const [condition, setCondition] = useState("and");
  // const [isApplied, setIsApplied] = useState(false);
  const [lines, setLines] = useState([defaultLine]);
  const [searchText, setSearchText] = useState("");

  // console.log({ lines });

  // disabled={
  //   lines.some((x) => {
  //     console.log({ xxxxxx: x });

  //     const type = filterAttrs?.find(
  //       (y) => y.value === x.attr
  //     )?.type;
  //     console.log({ type });

  //     return (
  //       !x.attr ||
  //       !x.operator ||
  //       (type !== "date" && !x.value) ||
  //       (x.operator === "between" && !x.value2)
  //     );
  //   }) ||
  //   (condition === "and" && lines.length === filterAttrs.length)
  // }

  //   console.log({ condition, lines });
  // console.log({ isApplied });

  useEffect(() => {
    if (
      lines.some((x) => {
        // console.log({ xxxxxx: x });

        const type = filterAttrs?.find(
          (y) => y.value?.trim() === x.attr?.trim()
        )?.type;
        // console.log({ type });

        return (
          !x.attr ||
          !x.operator ||
          (type !== "date" && !x.value) ||
          (x.operator === "between" && !x.value2)
        );
      })
    )
      return;

    let filtered;

    if (condition === "and") {
      filtered = originalRows.filter((row) => {
        return lines.every((line) => checkLine({ line, filterAttrs, row }));
      });
    } else {
      filtered = originalRows.filter((row) => {
        return lines.some((line) => checkLine({ line, filterAttrs, row }));
      });
    }

    setFilteredRows(filtered);

    // handleFilter();
  }, [lines, condition, filterAttrs, originalRows, setFilteredRows]);

  // const handleFilter = useCallback(() => {
  //   // setIsApplied(true);
  //   // setAnchorEl(null);
  //   let filtered;

  //   if (condition === "and") {
  //     filtered = originalRows.filter((row) => {
  //       return lines.every((line) => checkLine({ line, filterAttrs, row }));
  //     });
  //   } else {
  //     filtered = originalRows.filter((row) => {
  //       return lines.some((line) => checkLine({ line, filterAttrs, row }));
  //     });
  //   }

  //   setFilteredRows(filtered);
  // }, [condition, lines, filterAttrs, originalRows, setFilteredRows]);

  function removeFilter() {
    // setIsApplied(false);
    setAnchorEl(null);
    setSearchText("");
    setFilteredRows(originalRows);
    setLines([defaultLine]);
  }

  function handleClick(event) {
    // setSearchText("");
    // setFilteredRows(originalRows);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  return (
    <Stack direction="row" spacing={2}>
      <Badge
        badgeContent={
          lines.filter((x) =>
            x.operator === "between"
              ? x.attr && x.value && x.value2
              : x.attr && x.value
          )?.length
        }
        color="secondary"
      >
        <Button variant="contained" size="small" onClick={handleClick}>
          {customizedI18nGet("filters")}
        </Button>
      </Badge>

      <TextField
        id="outlined-search"
        label="Search..."
        variant="outlined"
        inputProps={{
          autoComplete: "off",
        }}
        InputProps={{
          sx: { height: 40 },

          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                // aria-label="toggle password visibility"
                onClick={() => {
                  setSearchText("");
                  removeFilter();
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={searchText}
        onChange={async (e) => {
          setSearchText(e.target.value);

          const stringFields = filterAttrs?.filter((x) => x.type === "string");
          setCondition("or");
          const lines = stringFields.map((x) => ({
            attr: x.value,
            operator: "includes",
            value: e.target.value,
          }));
          setLines(lines);
        }}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper sx={{ width: 700, height: 500, p: 5 }}>
          <Stack
            height={400}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Stack>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Condition
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="and"
                  name="radio-buttons-group"
                  value={condition}
                  onChange={(e) => {
                    setCondition(e.target.value);
                    setLines([defaultLine]);
                    setSearchText("");
                  }}
                >
                  {["and", "or"].map((x) => (
                    <FormControlLabel
                      value={x}
                      key={x}
                      control={<Radio />}
                      label={x}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              <Stack spacing={2} sx={{ alignSelf: "flex-start" }}>
                {lines.map((line, i) => (
                  <FilterLine
                    key={i}
                    index={i}
                    lines={lines}
                    setLines={setLines}
                    item={line}
                    filterAttrs={filterAttrs}
                    condition={condition}
                  />
                ))}
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between" width="100%">
              <IconButton
                color="primary"
                disabled={
                  lines.some((x) => {
                    // console.log({ xxxxxx: x });

                    const type = filterAttrs?.find(
                      (y) => y.value === x.attr
                    )?.type;
                    // console.log({ type });

                    return (
                      !x.attr ||
                      !x.operator ||
                      (type !== "date" && !x.value) ||
                      (x.operator === "between" && !x.value2)
                    );
                  }) ||
                  (condition === "and" && lines.length === filterAttrs.length)
                }
                onClick={() => {
                  setLines([...lines, defaultLine]);
                }}
              >
                <Stack direction="row" spacing={1}>
                  <AddCircleIcon />
                  <Typography variant="p">add filter</Typography>
                </Stack>
              </IconButton>

              <Stack direction="row" spacing={2}>
                <Button
                  disabled={lines.length === 1 && !lines[0].attr}
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={removeFilter}
                >
                  Remove Filters
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Popover>
    </Stack>
  );
}

function FilterLine({ index, item, lines, setLines, filterAttrs, condition }) {
  const { attr, operator, value, value2 } = item;

  useEffect(() => {
    if (value2 && value2 < value) {
      alert("value2 must be greater than value");
      setLines(
        lines.map((x, i) => {
          if (i === index) {
            return { ...x, value2: null };
          } else {
            return x;
          }
        })
      );
    }
  }, [lines, index, setLines, value, value2]);

  const [availabieFilterAttrs, setAvailabieFilterAttrs] = useState(filterAttrs);

  useEffect(() => {
    let attrs = filterAttrs;

    if (condition === "and") {
      attrs = filterAttrs.filter((x) => {
        const isAttrExistedInOtherLines = lines.some((y, i) => {
          return i !== index && y.attr === x.value;
        });
        return !isAttrExistedInOtherLines;
      });
    }
    setAvailabieFilterAttrs(attrs);
  }, [lines, index, condition, filterAttrs]);

  const type = filterAttrs?.find((x) => x.value === attr)?.type;

  const operators = {
    string: [
      { label: "contains", value: "includes" },
      { label: "not contains", value: "not_contains" },
      { label: "is", value: "==" },
      { label: "is not", value: "!=" },
    ],
    date: [
      { label: "is", value: "==" },
      { label: "is not", value: "!=" },
      { label: "after", value: ">" },
      { label: "on or after", value: ">=" },
      { label: "before", value: "<" },
      { label: "on or before", value: "<=" },
      { label: "between", value: "between" },
    ],
    number: [
      { label: "=", value: "==" },
      { label: "!=", value: "!=" },
      { label: ">", value: ">" },
      { label: ">=", value: ">=" },
      { label: "<", value: "<" },
      { label: "<=", value: "<=" },
      { label: "between", value: "between" },
    ],
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="flex-start">
      <IconButton
        disabled={lines.length === 1}
        onClick={() => {
          // console.log({ index, lines });
          setLines(lines.filter((x, i) => i !== index));
        }}
      >
        <CloseIcon />
      </IconButton>
      <TextField
        sx={{ width: 120 }}
        label="Attr"
        variant="standard"
        select
        type={type}
        value={attr}
        onChange={(e) => {
          setLines(
            lines.map((x, i) => {
              if (i === index) {
                const type = filterAttrs?.find(
                  (x) => x.value === e.target.value
                )?.type;
                return {
                  attr: e.target.value,
                  operator: type === "string" ? "includes" : "==",
                  value: "",
                  value2: "",
                };
              } else {
                return x;
              }
            })
          );
        }}
        children={availabieFilterAttrs?.map((x) => (
          <MenuItem key={x.label} value={x.value}>
            {x.label}
          </MenuItem>
        ))}
      />
      <TextField
        sx={{ width: 120 }}
        label="Operator"
        variant="standard"
        type={type}
        select
        value={operator}
        onChange={(e) => {
          setLines(
            lines.map((x, i) => {
              if (i === index) {
                return {
                  ...x,
                  operator: e.target.value,
                  value2: "",
                };
              } else {
                return x;
              }
            })
          );
        }}
        children={
          operators[type]?.map((x) => (
            <MenuItem key={x.label} value={x.value}>
              {x.label}
            </MenuItem>
          )) || []
        }
      />
      <TextField
        sx={{ width: 120 }}
        label={operator === "between" ? "from" : "Value"}
        value={value}
        type={type}
        InputLabelProps={{
          ...(type === "date" && {
            shrink: true,
          }),
        }}
        // InputProps={{
        //   ...(type === "date" && {
        //     endAdornment: (
        //       <InputAdornment position="end">
        //         <IconButton
        //           aria-label="remove date"
        //           onClick={() => {
        //             setLines(
        //               lines.map((x, i) => {
        //                 if (i === index) {
        //                   return { ...x, value: '' };
        //                 } else {
        //                   return x;
        //                 }
        //               })
        //             );
        //           }}
        //           // onMouseDown={handleMouseDownPassword}
        //         >
        //           <ClearIcon />
        //           {/* {toggle.passwordVisibility && <Visibility />}
        //           {!toggle.passwordVisibility && <VisibilityOff />} */}
        //         </IconButton>
        //       </InputAdornment>
        //     ),
        //   }),
        // }}
        onChange={(e) => {
          setLines(
            lines.map((x, i) => {
              if (i === index) {
                return { ...x, value: e.target.value };
              } else {
                return x;
              }
            })
          );
        }}
        variant="standard"
      />
      {operator === "between" && (
        <TextField
          sx={{ width: 120 }}
          label="To"
          value={value2 !== null ? value2 : ""}
          type={type}
          InputLabelProps={{ ...(type === "date" && { shrink: true }) }}
          onChange={(e) => {
            setLines(
              lines.map((x, i) => {
                if (i === index) {
                  return { ...x, value2: e.target.value };
                } else {
                  return x;
                }
              })
            );
          }}
          variant="standard"
        />
      )}
    </Stack>
  );
}

function checkLine({ line, filterAttrs, row }) {
  const { attr, operator, value, value2 } = line;
  // debugger;

  // console.log({ attr, operator, value, value2 });

  const type = filterAttrs?.find((x) => x.value === attr)?.type;

  switch (operator) {
    case "includes":
      return row[attr]
        ?.trim()
        ?.toLowerCase()
        ?.includes(value?.trim()?.toLowerCase());
    case "not_contains":
      return !row[attr]
        ?.trim()
        ?.toLowerCase()
        .includes(value?.trim()?.toLowerCase());
    case "==":
      let adjustedValue = value;

      if (!value || value.length === 0) {
        adjustedValue = null;
      }
      // console.log({row, attr, value, type})
      return type === "string"
        ? row[attr]?.trim()?.toLowerCase() == value?.trim()?.toLowerCase()
        : row[attr]?.trim() == adjustedValue?.trim();
    case "!=":
      return type === "string"
        ? row[attr]?.trim()?.toLowerCase() != value?.trim()?.toLowerCase()
        : row[attr]?.trim() != value?.trim();
    case ">":
      return row[attr] > value;
    case ">=":
      return row[attr] >= value;
    case "<":
      return row[attr] < value;
    case "<=":
      return row[attr] <= value;
    case "between":
      return row[attr] >= value && row[attr] <= value2;
    default:
      return true;
  }
}
