import { gql } from "@apollo/client";
import onCreateVersioned from "../../../function/onCreateVersioned";
import { onCreate } from "../../../function/onCreate";
import onUpdateInventory from "./onUpdate";

import {
  InventoryByMaterial,
  getInventoryHistory,
  createInventoryHistory,
  updateInventoryHistory,
} from "./query";

async function onCreateInventory({ client, values }) {
  const { date, definerID, materialID, quantity, creatorID, warehouseCode } =
    values;
  const fragment = /* GraphQL */ `
    fragment NewInventoryHistory on InventoryHistory {
      id
    }
  `;

  const input = {
    date,
    definerID,
    materialID,
    quantity,
    creatorID,
    warehouseCode,
    timeStamp: new Date().getTime(),
  };

  const { data } = await onCreate({
    input,
    mutation: createInventoryHistory,
    fragment,
    updateCacheFields: [],
    client,
  });

  // const { data: inventoryByMaterialData } = await client.query({
  //   query: gql(InventoryByMaterial),
  //   variables: {
  //     materialID,
  //     definerID: { eq: definerID },
  //     filter: { version: { eq: 0 } },
  //   },
  // })

  // const versionZeroItem =
  //   inventoryByMaterialData?.InventoryByMaterial?.items?.[0]

  // const fieldsToUpdate = [
  //   { field: 'quantity', createUpdate: false },
  //   { field: 'latestVersion', createUpdate: false },
  //   { field: 'timeStamp', createUpdate: false },
  // ]

  // await onCreateVersioned({
  //   type: 'InventoryHistory',
  //   versionZeroItem,
  //   input,
  //   query: getInventoryHistory,
  //   mutationCreate: createInventoryHistory,
  //   mutationUpdate: updateInventoryHistory,
  //   fieldsToUpdate,
  //   fragment,
  //   updateCacheFields: [],
  //   client,
  //   refetchQueries: [],
  // })
}

export default async function onCreateBatchInventory({ client, values }) {
  const { creatorID, date, definerID, inventoryItems } = values;

  await Promise.all(
    inventoryItems?.map(
      async ({ material, quantity, warehouseCode, inventoryFound }) => {
        try {
          if (!inventoryFound) {
            const inputForCreate = {
              date,
              creatorID,
              definerID,
              materialID: material?.id,
              quantity,
              warehouseCode,
            };

            await onCreateInventory({ client, values: inputForCreate });
          } else {
            const inputForUpdate = {
              id: inventoryFound?.id,
              date,
              timeStamp: new Date().getTime(),
              quantity,
            };

            await onUpdateInventory({ client, values: inputForUpdate });
          }
        } catch (e) {
          console.log("errorCreatingPart", e.message);
        }
      }
    )
  );
}
