import { useApolloClient, useReactiveVar } from "@apollo/client";
import { Stack, MenuItem } from "@mui/material";
import {
  FormikField,
} from "../../../../../component/formikForm";
import { invoiceCategory } from "../../../../../settings/formFieldEnum";
import { userEntityRelationVar } from "../../../../../client/cache";


export default function Step0ChooseItems({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  // const { step } = values;

  const client = useApolloClient();
  const { definerID, POs, entityID } = values;
  const { subsidiaryIDs } = useReactiveVar(userEntityRelationVar);

  // useEffect(() => {
  //   async function func() {
  //     const IDs = [entityID, ...(subsidiaryIDs || [])]
  //     const POs = (
  //       await Promise.all(
  //         IDs.map(async (id) => {
  //           const { data } = await client.query({
  //             query: gql(POByCustomerAndBillingStatus),
  //             variables: { customerID: id, billingStatus: { eq: 'OPEN' } },
  //           })

  //           return data?.POByCustomerAndBillingStatus?.items || []
  //         }),
  //       )
  //     )?.flat()
  //     setFieldValue('POs', POs)
  //   }

  //   func()
  // }, [entityID, subsidiaryIDs, setFieldValue, client])

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          name="date"
          label="Date"
          type="date"
          width={180}
          required
        />
        <FormikField name="number" label="Number" width={180} required />
        <FormikField
          name="batch"
          label="invoice batch number"
          // required
          width={180}
        />
        <FormikField
          name="category"
          id="category"
          label="category"
          select
          width={180}
          required
          children={invoiceCategory.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        />
      </Stack>
    </Stack>
  );
}
