import { useState } from 'react'
import { gql, useQuery, useApolloClient } from '@apollo/client'
import { Hub } from 'aws-amplify'
import { Stack, Typography, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import FormikForm, {
  FormikField,
  FormikAutocomplete,
  FormikSelect,
} from '../../../../../component/formikForm'
import { mailEntityRelation } from '../../../../../settings/formFieldEnum'
import {
  contactsByDefiner,
  mailEntityRelationByEntityRelation,
} from '../../query'
import groupArrayofObjectsByKeyAndGenerateArr from '../../../../../lib/groupArrayofObjectsByKeyAndGenerateArr'
import onDeleteMailRelation from '../../onDeleteMailRelation'
import onCreateMailRelation from '../../onCreateMailRelation'

export default function MailRelation({ relationID, definerID }) {
  const { data } = useQuery(gql(mailEntityRelationByEntityRelation), {
    variables: { entityRelationID: relationID },
  })

  let mailEntityRelation = data?.mailEntityRelationByEntityRelation?.items || []

  mailEntityRelation = groupArrayofObjectsByKeyAndGenerateArr(
    mailEntityRelation,
    'type',
  )

  async function onAddAutoMail() {
    Hub.dispatch('dialogMonitor', {
      event: 'showDetails',
      data: {
        Component: AddOrEditMailRelation,
        lineData: {
          relationID,
          definerID,
          editType: 'create',
        },
      },
    })
  }

  return (
    <Stack spacing={2} bgcolor='#fff' sx={{ alignSelf: 'flex-start' }}>
      <Stack direction='row' spacing={2}>
        {mailEntityRelation?.map(({ type, items }) => {
          return (
            <Stack key={type} spacing={2} bgcolor='blue' color='#fff'>
              <Typography variant='h6'>{type}</Typography>

              <Stack spacing={1}>
                {items?.map(({ id, user }) => {
                  return <MailEntityRelationLine key={id} id={id} user={user} />
                })}
              </Stack>
            </Stack>
          )
        })}
      </Stack>
      <Button sx={{ alignSelf: 'flex-start' }} onClick={onAddAutoMail}>
        Add AutoMail
      </Button>
    </Stack>
  )
}

function MailEntityRelationLine({ id, user }) {
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()
  return (
    <Stack
      direction='row'
      width={260}
      spacing={2}
      alignItems='center'
      justifyContent='space-between'>
      <Typography> {user?.name} </Typography>
      <LoadingButton
        loading={loading}
        variant='contained'
        onClick={async () => {
          setLoading(true)
          await onDeleteMailRelation({
            values: { id },
            client,
          })
          setLoading(false)
        }}>
        delete
      </LoadingButton>
    </Stack>
  )
}

function AddOrEditMailRelation({ lineData, onClose, closeAll }) {
  async function onSubmit({ values, client }) {
    console.log({ values })

    const { users, type, definerID, editType, relationID } = values

    await Promise.all(
      users.map(async ({ id: userID }) => {
        await onCreateMailRelation({
          client,
          values: { userID, type, entityRelationID: relationID },
        })
      }),
    )
    onClose()
  }

  const handleCancel = ({ isCloseAll }) => {
    if (isCloseAll) {
      closeAll()
    } else {
      onClose && onClose()
    }
  }

  return (
    <FormikForm
      initialValues={{ ...lineData, isEditing: true }}
      validationSchema={null}
      onSubmit={onSubmit}
      FormMainFields={AddOrEditMailRelationField}
      handleCancel={handleCancel}
      FormArray={null}
    />
  )
}

function AddOrEditMailRelationField({
  values,
  setFieldValue,
  onClose,
  noLabel,
}) {
  // console.log({ values333: values });
  // const entityID = params?.entityID;

  let { relationID, definerID, editType } = values || {}

  const { data: contactData } = useQuery(gql(contactsByDefiner), {
    query: gql(contactsByDefiner),
    variables: { definerID },
  })

  const contactOptions = contactData?.contactsByDefiner?.items?.map((x) => ({
    ...x,
    entityUserRelations: x?.entityUserRelations?.items
      ?.map((x) => x?.entity?.abbr)
      .join(', '),
  }))

  return (
    <Stack direction='row'>
      <Stack spacing={1}>
        <Stack direction='row' spacing={2} alignItems='flex-end'>
          <FormikSelect
            label='Mail Type'
            name='type'
            // multiple
            options={mailEntityRelation}
            width={300}
            disabled={editType === 'edit'}
          />

          <FormikAutocomplete
            width={500}
            multiple
            name='users'
            label='Please select contact'
            // required
            options={contactOptions || []}
            // isOptionEqualToValue={(option, value) =>{} }
            getOptionLabel={(option) =>
              `${option?.name} / ${option?.entityUserRelations}`
            }
            getPrimaryText={(option) => option?.name}
            getSecondaryText={(option) => option?.entityUserRelations}

            // getOptionDisabled={(option) => id === option?.id}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
