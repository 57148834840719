import { API, Hub, Auth } from "aws-amplify";
import emitSnackbar from "./emitSnackbar";
import { onUpdateInvite } from "./onUpdate";

export default async function handleAcceptOrRejectRole({
  client,
  inviteID,
  isRoleAccepetd,
}) {
  try {
    //UPDATE INVITE STATUS
    await onUpdateInvite({
      values: {
        id: inviteID,
        status: isRoleAccepetd ? "ACCEPTED" : "REJECTED",
      },
      client,
    });

    // ADD TO GROUP
    if (isRoleAccepetd) {
      await API.post("iSupplyAPI", "/restricted", {
        body: {
          operation: "ADD_TO_GROUP",
          meta: { invitationID: inviteID },
        },
      });

      Hub.dispatch("auth", {
        event: "manualForceTokenRefresh",
      });

      emitSnackbar({ message: "Role added successfully", duration: 5000 });
    }
  } catch (e) {
    console.log("errrrr", e.message);
  }
}
