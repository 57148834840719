export default function formattedCPO(rows) {
  const CPOIdentifer = [
    // "Vendor",
    // 'ETA Plant',
    "Material",
    // 'System',
    "Document",
    // 'ETA',
    "Open Quantity",
    "Document Date",
    "Chg",
  ];

  let headerRow = rows.find((row) => {
    const rowInLowercase = row.map((x) => {
      return x && typeof x === "string" ? x.toLowerCase() : null;
    });

    let isInclude = true;
    for (let ele of CPOIdentifer) {
      if (!rowInLowercase.includes(ele?.toLowerCase())) {
        isInclude = false;
        break;
      }
    }

    return isInclude;
  });

  if (headerRow) {
    const index = rows.indexOf(headerRow);

    // console.log({ headerRow, index });

    // headerRow = headerRow.map((x, i) => ({ [x]: i }));

    const map = headerRow.reduce((a, v) => {
      const index = headerRow.indexOf(v);

      a[v?.toLowerCase()] = index;
      return a;
    }, {});

    const { material, document, system, currency } = map;

    let newArr = rows.slice(index + 1);

    console.log({ newArr });

    newArr = newArr.map((x) => {
      let CRD;
      if (x[map["eta plant"]]) {
        console.log({ eta: x[map["eta plant"]] });

        // console.log({ CRD })

        // debugger

        CRD = new Date(x[map["eta plant"]])
          .toISOString("en-US")
          .substring(0, 10);
      } else if (x[map["supplier deliv.date"]]) {
        // console.log({ Suppliercrd: x[map['supplier deliv.date']] })

        // debugger

        CRD = new Date(x[map["supplier deliv.date"]])
          .toISOString("en-US")
          .substring(0, 10);
      }

      return {
        gcas: x[material],
        number: `${x[system]}-${x[document]}`,
        CRD,
        qty: x[map["document quantity"]] * 1,
        openQty: x[map["open quantity"]] * 1,
        plant: x[map["deliver to plant"]],
        price: x[map["gross price"]] / x[map["per"]],
        priceCur: x[currency],
        date: new Date(x[map["document date"]])
          .toISOString("en-US")
          .substring(0, 10),
        desc: x[map["material descr"]],
      };
    });

    return newArr;
  }
  return null;
}
