import { useApolloClient } from '@apollo/client'
import * as Yup from 'yup'
import { Stack, Typography } from '@mui/material'
import FormikForm, {
  FormikField,
  FormikSelect,
} from '../../../../component/formikForm'
import onDeleteOffer from '../onDelete'
import onUpdate from '../onUpdate'
import { currencies,offerStatus } from '../../../../settings/formFieldEnum'

const validationSchema = Yup.object().shape({
  price: Yup.number().min(0).required('Please enter Price'),
  currency: Yup.string().min(2, 'Too Short').required('Please enter Currency'),
})

export default function Item({ data, onClose }) {
  const client = useApolloClient()

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    })
    onClose && onClose()
  }

  const onDeleteBtnClick = async () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await onDeleteOffer({ values: data, client })
      onClose()
    }
  }

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data)
        setFieldValue('isEditing', false)
      }}
      saveButtonLabel='update'
      onDelete={onDeleteBtnClick}
      onSubmit={onSubmit}
    />
  )
}

const EditFields = ({ values }) => {
  const {
    isEditing,
    date,
    number,
    itemNumber,
    material,
    EAU,
    customer,
    vendor,
    sales,
    buyer,
    application,
    targetPrice,
    validDays,
    incoTerms,
    paymentTerms,
    statusCode,
    note,
  } = values

  const { mfr, mpn } = material || {}

  return (
    <Stack
      width='100%'
      spacing={3}
      justifyContent='flex-start'
      alignItems='flex-start'>
      <Stack direction='row' spacing={5}>
        <Typography> DATE: {date}</Typography>
        <Typography> NUMBER: {number}</Typography>
        <Typography> ITEM: {itemNumber}</Typography>
      </Stack>

      <Stack direction='row' spacing={5}>
        <Typography> VENDOR: {vendor?.name || vendor?.alias}</Typography>
        <Typography> SALES: {sales?.name}</Typography>
        <Typography> CUSTMER: {customer?.name || customer?.alias}</Typography>
        <Typography> BUYER: {buyer?.name}</Typography>
      </Stack>

      <Stack direction='row' spacing={5}>
        <Typography> MFR: {mfr}</Typography>
        <Typography> MPN: {mpn}</Typography>
        <Typography> EAU: {EAU}</Typography>
        <Typography> APPLICATION: {application}</Typography>
        {targetPrice && <Typography> TARGET PRICE: {targetPrice}</Typography>}
      </Stack>

      <Stack direction='row' spacing={5}>
        <Typography> ValidDays: {validDays}</Typography>

        <Typography> StatusCode: {statusCode}</Typography>
        <Typography> IncoTerms: {incoTerms?.terms}</Typography>
        <Typography> PaymentTerms: {paymentTerms}</Typography>
      </Stack>
      <Typography> Note: {note}</Typography>

      <Stack direction='row' spacing={5} alignItems='flex-end'>
        <FormikSelect
          name='currency'
          label='Currency'
          width={100}
          options={currencies}
          labelKey='label'
          valueKey='value'
          required
          disabled={!isEditing}
        />
        <FormikField
          name='price'
          label='Price'
          type='number'
          required
          width={100}
          disabled={!isEditing}
        />

        <FormikField
          name='leadtime'
          label='leadtime (wks)'
          width={120}
          required
          disabled={!isEditing}
        />
         <FormikSelect
          label='statusCode'
          name='statusCode'
          width={200}
          options={offerStatus}
          required
          labelKey='status'
          valueKey='code'
          disabled={!isEditing}
        />
      </Stack>
    </Stack>
  )
}
