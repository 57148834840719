import { onCreate } from "../../../function/onCreate";
import { createEntityRelations } from "./query";

export default async function onCreateRelation({ client, values }) {
  const {
    definerID,
    entity1ID,
    entity2ID,
    relation,
    vendorCode,
    paymentTerms,
    statusCode,
    incoTerms,
    minCharge,
    serviceFeeRate,
    type,
    logisticsTrackingTemplate,
    logisticsAccountNumber,
  } = values

  const inputForCreateEntityRelations = {
    relation,
    definerID,
    entity1ID,
    entity2ID,
    vendorCode,
    incoTerms,
    statusCode,
    paymentTerms,
    minCharge,
    serviceFeeRate,
    type,
    logisticsTrackingTemplate,
    logisticsAccountNumber,
    // groupToRead: [
    //   "admin",
    //   `${entityID}-admin`,
    //   `${entityID}-sales`,
    //   `${entityID}-accounting`,
    // ],
  }

  // console.log({ inputForCreateEntityRelations });
  // debugger

  const fragment = /* GraphQL */ `
    fragment NewEntityRelations on EntityRelations {
      id
    }
  `;

  const res = await onCreate({
    input: inputForCreateEntityRelations,
    mutation: createEntityRelations,
    fragment,
    updateCacheFields: [""],
    client,
    refetchQueries: ["GetEntity"],
  });
  // console.log({ inputForCreateEntityRelations });
  // console.log({ res });
  // debugger;
}
