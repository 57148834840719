// export { default as profile } from "./profile";
// export { default as rfq } from "./rfq";

import profile from "./profile";
import rfq from "./rfq";


const modules = [profile];

export default modules;
