import { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import * as Yup from 'yup'
import FormikForm, {
  FormikField,
  FormikAutocomplete,
  FormikSelect,
} from '../../../../../component/formikForm'
import { entityRelation } from '../../../../../settings/formFieldEnum'
import onCreateRelation from '../../onCreateRelation'
import onUpdateRelation from '../../onUpdateRelation'
import { entitiesByDefiner } from '../../query'

export default function AddOrEditRelationship({ lineData, onClose, closeAll }) {
  // console.log({lineData})

  const validationSchemaForCreateRelation = Yup.lazy((values) => {
    // console.log({ valuesinSchema: values });
    return Yup.object().shape({
      // entity2: Yup.object().required("Please enter  name"),
      vendorCode: Yup.string().nullable(),

      incoTerms: Yup.array()
        .of(
          Yup.object({
            terms: Yup.string().required('Please enter the terms'),
          }).nullable(),
        )
        .nullable(),
    })
  })

  const onSubmit = async ({ values, client }) => {
    const {
      id,
      thisEntityIs,
      theOtherEntity,
      relation,
      incoTerms,
      paymentTerms,
      vendorCode,
      statusCode,
      definerID,
      editType,
      minCharge,
      serviceFeeRate,
      type,
      logisticsAccountNumber,
      logisticsTrackingTemplate,
    } = values

    if (editType === 'edit') {
      await onUpdateRelation({
        values: {
          id,
          vendorCode,
          paymentTerms,
          statusCode,
          incoTerms: incoTerms?.map((x) => ({
            terms: x.terms,
            entityUserRelationID: x.entityUserRelationID,
          })),
          minCharge,
          serviceFeeRate,
          type,
          logisticsAccountNumber,
          logisticsTrackingTemplate,
        },
        client,
      })
    } else if (editType === 'create') {
      await onCreateRelation({
        values: {
          definerID,
          entity1ID:
            thisEntityIs === relation.split('-')[0] ? id : theOtherEntity?.id,
          entity2ID:
            thisEntityIs === relation.split('-')[1] ? id : theOtherEntity?.id,
          incoTerms: incoTerms?.map((x) => ({
            terms: x.terms,
            entityUserRelationID: x.entityUserRelationID,
          })),
          paymentTerms,
          vendorCode,
          statusCode,
          relation,
          minCharge,
          serviceFeeRate,
          type,
          logisticsAccountNumber,
          logisticsTrackingTemplate,
        },
        client,
      })
    }

    onClose && onClose()
  }

  const handleCancel = ({ isCloseAll }) => {
    if (isCloseAll) {
      closeAll()
    } else {
      onClose && onClose()
    }
  }

  return (
    <FormikForm
      initialValues={{ ...lineData, isEditing: true }}
      validationSchema={null}
      onSubmit={onSubmit}
      FormMainFields={AddOrEditRelation}
      handleCancel={handleCancel}
      FormArray={null}
    />
  )
}

function AddOrEditRelation({ values, setFieldValue, onClose, noLabel }) {
  let {
    id,
    entityID,
    definerID,
    relation,
    paymentTermsOptions,
    incoTermsOptions,
    thisEntityIs,
    theOtherEntity,
    editType,
    entity1,
    entity2,
    type,
  } = values || {}

  useEffect(() => {
    if (editType === 'edit') {
      setFieldValue(
        'thisEntityIs',
        entity1?.id === entityID
          ? relation?.split('-')[0]
          : relation?.split('-')[1],
      )

      setFieldValue(
        'theOtherEntity',
        entity1?.id === entityID ? entity2 : entity1,
      )
    }
  }, [
    theOtherEntity,
    entityID,
    entity1,
    entity2,
    editType,
    id,
    relation,
    setFieldValue,
  ])

  const { data: entityByDefinerData } = useQuery(gql(entitiesByDefiner), {
    variables: { definerID },
    skip: !definerID,
  })

  const entityOptions = entityByDefinerData?.entitiesByDefiner?.items

  const autoCompleteLabel =
    thisEntityIs && relation?.split('-').find((x) => x !== thisEntityIs)

  // SET CURRENT DEFINER AS DEFAULT OTHER ENTITY
  useEffect(() => {
    if (thisEntityIs && !theOtherEntity) {
      setFieldValue(
        'theOtherEntity',
        entityOptions?.find((x) => x.id === definerID),
      )
    }
  }, [definerID, theOtherEntity, thisEntityIs, setFieldValue, entityOptions])

  return (
    <Stack direction='row'>
      <Stack spacing={1}>
        <Stack direction='row' spacing={2} alignItems='flex-end'>
          <FormikSelect
            label='Relation Type'
            name='relation'
            // multiple
            options={entityRelation}
            width={300}
            disabled={editType === 'edit'}
          />

          <FormikSelect
            label='This entity is'
            name='thisEntityIs'
            // multiple
            options={relation?.split('-')}
            disabled={!relation || editType === 'edit'}
            width={200}
            onChange={(e) => {
              setFieldValue('theOtherEntity', null)
              setFieldValue('paymentTerms', null)
              setFieldValue('incoTerms', null)
            }}
          />

          <Typography variant='h6' width={200} sx={{ textAlign: 'right' }}>
            {autoCompleteLabel && `${autoCompleteLabel} is `}
          </Typography>
          <FormikAutocomplete
            width={500}
            name='theOtherEntity'
            label={
              (autoCompleteLabel && `Please select ${autoCompleteLabel}`) || ''
            }
            required
            options={entityOptions || []}
            disabled={
              !entityOptions ||
              entityOptions.length === 0 ||
              editType === 'edit'
            }
            // isOptionEqualToValue={(option, value) =>{} }
            getOptionLabel={(option) => option?.alias || option?.name}
            getPrimaryText={(option) => option?.alias || option?.name}
            getOptionDisabled={(option) => id === option?.id}
          />
        </Stack>

        {/* <Stack></Stack> */}

        {relation === 'CUSTOMER-SUPPLIER' && thisEntityIs === 'CUSTOMER' && (
          <Stack direction='row' spacing={3}>
            {!paymentTermsOptions || paymentTermsOptions?.length === 0 ? (
              <Typography color='red' variant='h5'>
                Please add payment terms to entity
              </Typography>
            ) : (
              <FormikAutocomplete
                width={500}
                name='paymentTerms'
                label='paymentTerms'
                // required
                multiple
                options={
                  paymentTermsOptions || theOtherEntity?.paymentTerms || []
                }
              />
            )}

            {!incoTermsOptions || incoTermsOptions?.length === 0 ? (
              <Typography color='red' variant='h5'>
                Please add inco terms to entity
              </Typography>
            ) : (
              <FormikAutocomplete
                width={500}
                name='incoTerms'
                label='incoterms'
                // required
                multiple
                options={incoTermsOptions || theOtherEntity?.incoTerms || []}
                // disabled={!incoTermsOptions || incoTermsOptions.length === 0}
                isOptionEqualToValue={(option, value) =>
                  option.terms === value.terms
                }
                getOptionLabel={(option) => option?.terms}
                getPrimaryText={(option) => option?.terms}
                // getOptionDisabled={(option) =>
                //   fieldValue === option ||
                //   subRelations?.some((x) => x?.terms === option?.terms)
                // }
              />
            )}

            <FormikField name='vendorCode' label='vendorCode' />
          </Stack>
        )}

        {relation === 'PRINCIPAL-FORWARDER' && thisEntityIs === 'PRINCIPAL' && (
          <Stack direction='row' spacing={3}>
            {!paymentTermsOptions || paymentTermsOptions?.length === 0 ? (
              <Typography color='red' variant='h5'>
                Please add payment terms to entity
              </Typography>
            ) : (
              <FormikAutocomplete
                width={500}
                name='paymentTerms'
                label='paymentTerms'
                // required
                multiple
                options={
                  paymentTermsOptions || theOtherEntity?.paymentTerms || []
                }
              />
            )}

            {!incoTermsOptions || incoTermsOptions?.length === 0 ? (
              <Typography color='red' variant='h5'>
                Please add inco terms to entity
              </Typography>
            ) : (
              <FormikAutocomplete
                width={500}
                name='incoTerms'
                label='incoterms'
                // required
                multiple
                options={incoTermsOptions || theOtherEntity?.incoTerms || []}
                // disabled={!incoTermsOptions || incoTermsOptions.length === 0}
                isOptionEqualToValue={(option, value) =>
                  option.terms === value.terms
                }
                getOptionLabel={(option) => option?.terms}
                getPrimaryText={(option) => option?.terms}
                // getOptionDisabled={(option) =>
                //   fieldValue === option ||
                //   subRelations?.some((x) => x?.terms === option?.terms)
                // }
              />
            )}

            <FormikField name='vendorCode' label='vendorCode' />
            <FormikField
              name='serviceFeeRate'
              label='Service Fee Rate'
              width={120}
            />
            <FormikField name='minCharge' label='Min Charge' width={120} />
          </Stack>
        )}
        {relation === 'PRINCIPAL-CONTRACTOR' &&
          thisEntityIs === 'PRINCIPAL' && (
            <Stack direction='row' spacing={3} alignItems='flex-end'>
              <FormikSelect
                label='Type'
                name='type'
                // multiple
                options={['logistics', 'trading', 'discountingPayment']}
                width={300}
                // disabled={editType === 'edit'}
              />

              {type === 'logistics' && (
                <>
                  <FormikField
                    name='logisticsAccountNumber'
                    label='logisticsAccountNumber'
                  />
                  <FormikField
                    name='logisticsTrackingTemplate'
                    label='logisticsTrackingTemplate'
                    width={800}
                  />
                </>
              )}
              {type === 'discountingPayment' && (
                <>
                  <FormikField name='serviceFeeRate' label='Rate' />
                </>
              )}
            </Stack>
          )}
      </Stack>
    </Stack>
  )
}
