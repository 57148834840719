import {
  Box,
  Link,
  FormControlLabel,
  Stack,
  TextField,
  Checkbox,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SignIn({
  loading,
  setAuthType,
  action,
  clearAlert,
  setEmail,
  email,
  setPassword,
  password,
  sx,
  ...props
}) {
  const label = "Sign In";
  return (
    <Box sx={{ ...sx, width: 400 }}>
      <LockOutlinedIcon fontSize="large" color="secondary" />
      <Typography variant="h5">{label}</Typography>
      <Box
        component="form"
        width="100%"
        onSubmit={action.onSubmit}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          // autoFocus={email ? false : true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          // autoFocus={email ? true : false}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <LoadingButton
          loading={loading}
          type="submit"
          // fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, alignSelf: "center", width: 220 }}
        >
          {label}
        </LoadingButton>
      </Box>
      <Stack width="90%" direction="row" justifyContent="space-between">
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            clearAlert();
            setAuthType("verifyEmailForForgotPassword");
          }}
        >
          Forgot password?
        </Link>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            clearAlert();
            setAuthType("signUpSelection");
          }}
        >
          {"Don't have an account? Sign Up"}
        </Link>
      </Stack>
    </Box>
  );
}
