import { useState, useEffect } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { Stack, TextField, Typography, Collapse } from "@mui/material";
import calculateForwarderPOFees from "../function/calculateForwarderPOFees";

const POByNumber = /* GraphQL */ `
  query POByNumber(
    $number: String!
    $itemNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPOFilterInput
    $limit: Int
    $nextToken: String
  ) {
    POByNumber(
      number: $number
      itemNumber: $itemNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        itemNumber
        no @client
        date
        pi {
          id
          price
          currency
          qty
          # po {
          #   id
          #   material {
          #     id
          #     importDutyCN
          #     mfr
          #     mpn
          #   }
          # }
        }
        offerID
        customerID
        vendorID
        definerID
        materialID
        material {
          id
          mfr
          mpn
          importDutyCN
        }
        materialEntityRelationID
        materialEntityRelation {
          id
          code
        }
        buyerID
        salesID
        qty
        openQty
        price
        priceCur
        paymentTerms
        billingEntityID
        CRD
        shippingStatus
        billingStatus
        paymentStatus
        importAgentID
        paymentAgentID
        forwarderID
        fxRate
        minCharge
        serviceFeeRate
        importDutyCN
        piID
        groupToRead
        groupToEdit
        groupToDelete
        version
        latestVersion
        statusCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity2ID
        alias
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        serviceFeeRate

        minCharge
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export default function ForwarderPO({ data }) {
  const {
    date,
    definerID,
    number,
    fxRate,
    minCharge,
    serviceFeeRate,
    customerID,
    vendorID,
  } = data;

  const [currentFxRate, setCurrentFxRate] = useState(null);

  const { data: POByNumberData } = useQuery(gql(POByNumber), {
    variables: { number },
    skip: !number,
  });

  let items = POByNumberData?.POByNumber?.items;

  const { data: relationsByDefinerData } = useQuery(gql(relationsByDefiner), {
    variables: { definerID, relation: { eq: "PRINCIPAL-FORWARDER" } },
    skip: !definerID,
  });

  const relation = relationsByDefinerData?.relationsByDefiner?.items?.find(
    (x) => x?.entity1ID === customerID && x?.entity2ID === vendorID
  );

  if (!relation || !items || items?.length < 1 || items?.some((x) => !x.pi))
    return null;

  const {
    minCharge: currentMinCharge,
    serviceFeeRate: currentServiceFeeRate,
    incoTerms,
    paymentTerms,
  } = relation || {};

  items = items?.map((x) => {
    const { id, no, pi, importDutyCN, material, materialEntityRelation } =
      x || {};
    return {
      id,
      poNo: no,
      price: pi?.price,
      currency: pi?.currency,
      qty: pi?.qty,
      mpn: material?.mpn,
      mfr: material?.mfr,
      materialID: material?.id,
      materialEntityRelationID: materialEntityRelation?.id,
      importDutyCN,
    };
  });

  return (
    <Stack bgcolor="#f1f1f1" spacing={2} padding={1}>
      <Typography variant="h4" sx={{ alignSelf: "center" }}>
        Forwarder PO
      </Typography>
      <DispalyDetails
        {...{
          items,
          fxRate,
          serviceFeeRate,
          minCharge,
          date,
          incoTerms: incoTerms?.[0]?.terms,
          paymentTerms: paymentTerms?.[0],
        }}
      />
      <Stack direction="row" spacing={1}>
        <TextField
          label="Current FXRate"
          sx={{ alignSelf: "flex-start" }}
          type="number"
          onChange={(e) => setCurrentFxRate(e.target.value)}
          value={currentFxRate || ""}
        />
      </Stack>

      <Collapse in={!!currentFxRate}>
        <DispalyDetails
          {...{
            items,
            fxRate: currentFxRate,
            serviceFeeRate: currentServiceFeeRate,
            date,
            minCharge: currentMinCharge,
            incoTerms: incoTerms[0]?.terms,
            paymentTerms: paymentTerms?.[0],
          }}
        />
      </Collapse>
    </Stack>
  );
}

function DispalyDetails({
  items,
  fxRate,
  serviceFeeRate,
  minCharge,
  date,
  incoTerms,
  paymentTerms,
}) {
  const [element, setElement] = useState(null);
  useEffect(() => {
    async function func() {
      const [, ele] = await calculateForwarderPOFees({
        items,
        exchangeRate: fxRate,
        serviceFeeRate,
        minCharge,
        date,
        incoTerms,
        paymentTerms,
      });
      setElement(ele);
    }
    func();
  }, [fxRate, items, serviceFeeRate, minCharge, date, incoTerms, paymentTerms]);

  return element;
}
