import { useApolloClient } from "@apollo/client";
import * as Yup from "yup";
import { Stack, Typography, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FormikForm, {
  FormikField,
  FormikSelect,
} from "../../../../component/formikForm";
import onDeleteInvoice from "../../billing/onDelete";
import { currencies } from "../../../../settings/formFieldEnum";
import onUpdate from "../../billing/onUpdate";

const validationSchema = Yup.object().shape({
  price: Yup.number().min(0).required("Please enter Price"),
  currency: Yup.string().required("Please select Currency"),
});

export default function Item({ data, onClose, userRole }) {
  const isAdmin = userRole?.includes("admin");

  const client = useApolloClient();

  const onDeleteBtnClick = async () => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      await onDeleteInvoice({ values: data, client });
      onClose();
    }
  };

  const onSubmit = async ({ values, client }) => {
    await onUpdate({
      values,
      client,
    });
    onClose && onClose();
  };

  return (
    <FormikForm
      initialValues={{ ...data, isEditing: false }}
      validationSchema={validationSchema}
      FormMainFields={EditFields}
      FormArray={null}
      handleCancel={({ setFieldValue, setValues }) => {
        setValues(data);
        setFieldValue("isEditing", false);
      }}
      saveButtonLabel="update"
      onDelete={isAdmin ? onDeleteBtnClick : null}
      onSubmit={isAdmin ? onSubmit : null}
      disableEdit={isAdmin ? false : true}
    />
  );
}

const EditFields = ({ values, setFieldValue }) => {
  console.log({ values });
  const {
    isEditing,
    number,
    itemNumber,
    date,
    po,
    qty,
    currency,
    price,
    taxRate,
  } = values || {};

  const { materialEntityRelation, material } = po || {};

  return (
    <Stack spacing={5} width={1200}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={5}>
          <Typography> DATE: {date}</Typography>
          <Typography> NUMBER: {number}</Typography>
          <FormikField
            disabled={!isEditing}
            name="number"
            label="NUMBER"
            width={120}
          />
          <Typography> ITEM NUMBER: {itemNumber}</Typography>
          <FormikField
            disabled={!isEditing}
            name="itemNumber"
            label="ITEM NUMBER"
            width={60}
          />
        </Stack>

        <Stack direction="row" spacing={5}>
          {materialEntityRelation && (
            <Typography> CODE: {materialEntityRelation?.code}</Typography>
          )}
          <Typography> MFR: {material?.mfr}</Typography>
          <Typography> MPN: {material?.mpn}</Typography>
          <Typography>
            {" "}
            AMOUNT: {currency}
            {(qty * price).toFixed(3) * 1}
          </Typography>
          <Typography>
            {" "}
            AMOUNT WITH TAX: {currency}
            {(qty * price * (1 + (taxRate || 0))).toFixed(3) * 1}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField
          disabled={!isEditing}
          name="paymentDueDate"
          label="PaymentDueDate"
          type="date"
          endAdornment={
            <IconButton onClick={() => setFieldValue("paymentDueDate", null)}>
              <ClearIcon />
            </IconButton>
          }
        />
        <FormikField
          disabled={!isEditing}
          name="deductionDate"
          label="DeductionDate"
          type="date"
          endAdornment={
            <IconButton onClick={() => setFieldValue("deductionDate", null)}>
              <ClearIcon />
            </IconButton>
          }
        />

        <FormikSelect
          disabled={!isEditing}
          label="Currency"
          required
          name="currency"
          options={currencies}
          labelKey="label"
          valueKey="value"
          width={120}
        />
        <FormikField
          disabled={!isEditing}
          width={120}
          name="price"
          label="Price"
          type="number"
        />
        <FormikField
          disabled={!isEditing}
          width={180}
          name="qty"
          label="Qty"
          type="number"
        />
      </Stack>
    </Stack>
  );
};
