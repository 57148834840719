import { useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { Storage } from "aws-amplify";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import createEntity from "../../entities/onCreate";
import createEntityRelation from "../../entities/onCreateRelation";
import { userEntityRelationVar } from "../../../../client/cache";

import { v4 as uuidv4 } from "uuid";

export const TemplateLabel = ({ rows }) => {
  const { entityID } = useReactiveVar(userEntityRelationVar);

  const [isLoading, setIsloading] = useState(false);
  const filename = "vendor.csv";
  const path = `${entityID}/template/${filename}`;

  return (
    <Stack
      direction="row"
      spacing={2}
      // display="flex"
      // justifyContent="center"
      alignItems="center"
      // sx={{ alignItems: "center" }}
    >
      <Typography variant="h6">Template: </Typography>
      <Typography variant="p">{filename}</Typography>

      <LoadingButton
        loading={isLoading}
        size="small"
        onClick={async () => {
          setIsloading(true);
          try {
            const fetchedURL = await Storage.get(path, {
              download: false,
              validateObjectExistence: true,
              // level: 'protected',
            });
            window.location.href = fetchedURL;

            // console.log({window})

            // const downloadFolder = window.downloads.getDownloadFolder();

            // console.log({ downloadFolder });
            // debugger;
          } catch (err) {
            console.log({ err444: err });
          }
          setIsloading(false);
        }}
        variant="contained"
      >
        download
      </LoadingButton>
    </Stack>
  );
};

export const headerIdentifier = [
  { label: "ALIAS", isRequired: true },
  { label: "NAME", isRequired: true },
  { label: "BUSINESSTYPE", isRequired: true },
  { label: "VENDORCODE", isRequired: true },
  { label: "ENTITY1ID", isRequired: true },
  { label: "RELATION", isRequired: true },
  { label: "PAYMENTTERMS", isRequired: false },
  { label: "INCOTERMS", isRequired: false },
  { label: "ENTITYUSERRELATIONID", isRequired: false },
];

export async function onCreate({ rows, definerID, client }) {
  await Promise.all(
    rows.map(async (row) => {
      let {
        ALIAS,
        NAME,
        BUSINESSTYPE,
        RELATION,
        ENTITY1ID,
        VENDORCODE,
        PAYMENTTERMS,
        INCOTERMS,
        ENTITYUSERRELATIONID,
      } = row;
      ALIAS = ALIAS?.trim();
      NAME = NAME?.trim();
      BUSINESSTYPE = BUSINESSTYPE?.trim();
      RELATION = RELATION?.trim();
      ENTITY1ID = ENTITY1ID?.trim();
      VENDORCODE = VENDORCODE?.trim();
      PAYMENTTERMS = PAYMENTTERMS && [PAYMENTTERMS?.trim()];
      INCOTERMS = INCOTERMS && [
        {
          terms: INCOTERMS?.trim(),
          entityUserRelationID: ENTITYUSERRELATIONID?.trim(),
        },
      ];
      const id = uuidv4();
      const inputForCreateEntity = {
        id,
        alias: ALIAS,
        name: NAME,
        businessType: BUSINESSTYPE,
        definerID,
        isVerified: true,
        statusCode: "800",
      };

      await createEntity({ client, values: inputForCreateEntity });

      const inputForCreateRelation = {
        definerID,
        relation: RELATION,
        entity1ID: ENTITY1ID,
        entity2ID: id,
        vendorCode: VENDORCODE,
        incoTerms: INCOTERMS,
        paymentTerms: PAYMENTTERMS,
      };
      await createEntityRelation({ client, values: inputForCreateRelation });

      // debugger;
    })
  );
}
