export const MaterialsByDefiner = /* GraphQL */ `
  query MaterialsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        materialEntityRelations {
          items {
            id
            code
            statusCode
            entityID
            entity {
              id
              name
              alias
            }
          }
        }

        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        inventory
        createdAt

        updatedAt

        __typename
      }
      nextToken

      __typename
    }
  }
`;
export const createInventoryHistory = /* GraphQL */ `
  mutation CreateInventoryHistory(
    $input: CreateInventoryHistoryInput!
    $condition: ModelInventoryHistoryConditionInput
  ) {
    createInventoryHistory(input: $input, condition: $condition) {
      id
      date
      definerID
      timeStamp
      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit

        __typename
      }
      quantity
      unit
      creatorID
      creator {
        id
        sub
        email
        statusCode
        fn
        mn
        ln
        phoneNumber
        address
        groupToRead
        groupToEdit
        groupToDelete
        owner
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      warehouseCode

      groupToRead
      groupToEdit
      groupToDelete
      statusCode

      createdAt
      updatedAt
      __typename
    }
  }
`;

export const InventoryByDefiner = /* GraphQL */ `
  query InventoryByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InventoryByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        timeStamp

        definerID
        materialID
        material {
          id
          mfr
          mpn
          unit
          inventory
          # offers(filter: { statusCode: { ne: "900" } }) {
          #   items {
          #     id
          #     price
          #     currency
          #     statusCode
          #     vendorID
          #     vendor {
          #       id
          #       name
          #       alias
          #       abbr @client
          #     }
          #   }
          # }
          materialEntityRelations {
            items {
              id
              entityID
              code
            }
          }
        }
        quantity
        unit
        creatorID
        creator {
          id
          fn
          mn
          ln
          name @client
        }
        warehouseCode
        warehouse @client
        groupToRead
        groupToEdit
        groupToDelete
        statusCode

        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getInventoryHistory = /* GraphQL */ `
  query GetInventoryHistory($id: ID!) {
    getInventoryHistory(id: $id) {
      id
      date
      definerID
      timeStamp

      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        isDeleted
        statusCode
        inventory
        createdAt
        updatedAt
        __typename
      }
      quantity
      unit
      creatorID
      creator {
        id
        name @client
        fn
        ln
      }

      warehouseCode

      groupToRead
      groupToEdit
      groupToDelete
      statusCode

      createdAt
      updatedAt
      __typename
    }
  }
`;
export const InventoryByMaterial = /* GraphQL */ `
  query InventoryByMaterial(
    $materialID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InventoryByMaterial(
      materialID: $materialID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        timeStamp

        definerID
        materialID
        material {
          id
          inventory
          receivingAndShippings {
            items {
              id
              date
              timeStamp
              qty
              unit
            }
          }
        }
        quantity
        unit
        creatorID
        creator {
          id
          fn
          ln
          mn
          name @client
        }
        warehouseCode
        warehouse @client
        groupToRead
        groupToEdit
        groupToDelete
        statusCode

        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const updateInventoryHistory = /* GraphQL */ `
  mutation UpdateInventoryHistory(
    $input: UpdateInventoryHistoryInput!
    $condition: ModelInventoryHistoryConditionInput
  ) {
    updateInventoryHistory(input: $input, condition: $condition) {
      id
      date
      timeStamp

      definerID
      definer {
        id
        isVerified
        name
        alias
        address
        phoneNumber
        type
        businessType
        statusCode
        paymentTerms
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      materialID
      material {
        id
        definerID
        mpn
        mfr
        coo
        desc
        unit
        leadtime
        mpq
        transitDays
        hsCode
        importDutyCN
        groupToRead
        groupToEdit
        groupToDelete
        isDeleted
        statusCode
        inventory
        createdAt
        updatedAt
        __typename
      }
      quantity
      unit
      creatorID

      warehouseCode

      groupToRead
      groupToEdit
      groupToDelete
      statusCode

      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteInventoryHistory = /* GraphQL */ `
  mutation DeleteInventoryHistory(
    $input: DeleteInventoryHistoryInput!
    $condition: ModelInventoryHistoryConditionInput
  ) {
    deleteInventoryHistory(input: $input, condition: $condition) {
      id
      date
      timeStamp
      definerID

      materialID

      quantity
      unit
      creatorID

      warehouseCode

      groupToRead
      groupToEdit
      groupToDelete
      statusCode
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const getMaterial = /* GraphQL */ `
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      id
      definerID

      mpn
      mfr
      coo
      desc
      unit
      leadtime
      mpq
      transitDays
      hsCode
      importDutyCN

      receivingAndShippings {
        items {
          id
          type
          date
          timeStamp
          number
          itemNumber
          qty
          unit
        }
        nextToken
        __typename
      }

      __typename
    }
  }
`;

export const receivingAndShippingByMaterialAndTime = /* GraphQL */ `
  query ReceivingAndShippingByMaterialAndTime(
    $materialID: ID!
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivingAndShippingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    receivingAndShippingByMaterialAndTime(
      materialID: $materialID
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        timeStamp
        number
        no @client
        batchCode
        itemNumber
        qty
        coo
        unit
        warehouseCode
        type
        poID
        materialID
        materialEntityRelationID
        trackingNumber
        warehouseStaffID
        courierID
        driverID
        creatorID
        definerID
        billingStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const offerByMaterial = /* GraphQL */ `
  query OfferByMaterial(
    $materialID: ID!
    $customerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offerByMaterial(
      materialID: $materialID
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        price
        # material {
        #   id
        #   mfr
        #   mpn
        # }
        currency
        vendorID
        statusCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
