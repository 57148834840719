import { gql } from "@apollo/client";
import { API } from "aws-amplify";

import groupArrayofObjectsByKeyAndGenerateArr from "../../../../lib/groupArrayofObjectsByKeyAndGenerateArr";

import {
  relationsByEntity1,
  mailEntityRelationByEntityRelation,
  getSimplePO,
} from "../query";

export default async function handleSendETA({ client, selectedPOItems }) {
  const POs = await Promise.all(
    selectedPOItems.map(async (poID) => {
      const { data: getSimplePOData } = await client.query({
        query: gql(getSimplePO),
        variables: { id: poID },
      });

      const PO = getSimplePOData?.getPO;
      return PO;
    })
  );
  // console.log({ POs });

  let groupedByVendorAndCustomer = groupArrayofObjectsByKeyAndGenerateArr(POs, [
    "vendorID",
    "customerID",
  ]);

  // console.log({ groupedByVendorAndCustomer });

  groupedByVendorAndCustomer = await Promise.all(
    groupedByVendorAndCustomer.map(async (x) => {
      const { data: relationsByEntity1Data } = await client.query({
        query: gql(relationsByEntity1),
        variables: {
          entity1ID: x.items[0].customerID,
          entity2ID: { eq: x.items[0].vendorID },
          filter: { relation: { eq: "CUSTOMER-SUPPLIER" } },
        },
      });

      const entityRelation =
        relationsByEntity1Data?.relationsByEntity1?.items?.[0];

      const entityRelationID = entityRelation?.id;

      if (!entityRelationID) {
        alert(
          `No entityRelationID defined between ${x.items[0].customer.abbr} and ${x.items[0].vendor.abbr}`
        );
        return;
      } else {
        const { data: mailEntityRelationByEntityRelationData } =
          await client.query({
            query: gql(mailEntityRelationByEntityRelation),
            variables: {
              entityRelationID,
              filter: { type: { eq: "SEND_PO" } },
            },
          });

        const recipients =
          mailEntityRelationByEntityRelationData?.mailEntityRelationByEntityRelation?.items?.map(
            (x) => ({
              name: x.user?.name,
              email: x.user?.email,
              fn: x.user?.fn,
              sub: x.user?.sub,
              uid: x.user?.id,
            })
          );

        if (recipients.length === 0) {
          alert(
            `No PO receipients defined between ${x.items[0].customer.abbr} and ${x.items[0].vendor.abbr}`
          );
          // throw Error(
          //   `No PO receipients defined between ${x.items[0].customer.abbr} and ${x.items[0].vendor.abbr}`
          // );
        }
        return {
          recipients,
          senderCompanyName: x.items[0].customer.name,
          vendorID: x.items[0].vendor.id,
          customerID: x.items[0].customer.id,
          POIDs: x.items.map((x) => x.id),
        };
      }
    })
  );

  groupedByVendorAndCustomer = groupedByVendorAndCustomer.filter(Boolean);

  console.log({ groupedByVendorAndCustomer2222: groupedByVendorAndCustomer });

  await API.post("iSupplyAPI", "/restricted", {
    body: {
      operation: "SEND_ETA_REQUEST",
      data: {
        poByVendorAndCustomer: groupedByVendorAndCustomer,
      },
    },
  });
}
