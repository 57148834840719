import { useEffect, useState } from "react";
import { Storage, API } from "aws-amplify";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  Container,
  Box,
  Stack,
  Button,
  Typography,
  LinearProgress,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { read, utils } from "xlsx";
import formattedCPO from "./functions/formattedCPO";
import handleCPO from "./functions/handleCPO";
import onCreatePO from "../CPOs/onCreate";
import onUpdatePO from "../CPOs/onUpdate";
import LoadingButton from "@mui/lab/LoadingButton";
import SimpleTable from "../../../component/SimpleTable";
import { userEntityRelationVar } from "../../../client/cache";

const newPOHeaders = [
  "number",
  "gcas",
  "mpn",
  "mfr",
  "desc",
  "qty",
  "openQty",
  "price",
  "priceCur",
  "PriceSelection",
  "CRD",
  "customerName",
  "vendorName",
  "shipTo",
];

const existingPOHeaders = [
  "number",
  "gcas",
  "mfr",
  "mpn",
  "qty",
  "openQty",
  "CRD",
];

export default function CPOImport() {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { entityID, subsidiaryIDs } = useReactiveVar(userEntityRelationVar);

  const client = useApolloClient();
  const [fetching, setFetching] = useState(false);
  const [newOrders, setNewOrders] = useState();
  const [existingOrders, setExistingOrders] = useState();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [hasSubmittedUpdate, setHasSubmittedUpdate] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [hasSubmittedCreate, setHasSubmittedCreate] = useState(false);
  const [filename, setFilename] = useState();

  const data = searchParams.get("data");

  useEffect(() => {
    if (data) {
      const dataObj = JSON.parse(Buffer.from(data, "base64"));
      if (dataObj.filename) {
        console.log({ filename: dataObj.filename });
        debugger;
        setFilename(dataObj.filename);
      }
    }
  }, [filename, data]);

  async function handleFetch() {
    setFetching(true);
    const res = await API.post("iSupplyAPI", "/restricted", {
      body: {
        operation: "INVOKE_CPO_IMPORT",
      },
    });

    if (res) {
      const filename = JSON.parse(res);

      console.log({ filename });

      setFilename(filename?.[0]);
    }
    setFetching(false);
  }

  useEffect(() => {
    let ignore = false;
    const func = async () => {
      try {
        if (filename) {
          setFetching(true);
          const url = await Storage.get(`${entityID}/CPO/${filename}`, {
            download: false,
            validateObjectExistence: true,
            // level: 'public',
          });
          const res = await fetch(url);

          const buffer = await res.arrayBuffer();

          const workbook = read(buffer);

          const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // get the first worksheet

          const arr = utils.sheet_to_json(worksheet, {
            header: 1,
            defval: null,
            raw: false,
          }); // generate objects

          const CPOs = formattedCPO(arr);

          if (!ignore && CPOs) {
            const relatedEntityIDs = [entityID, ...subsidiaryIDs];

            const [newPOs, existingPOs] = await handleCPO(
              CPOs,
              client,
              relatedEntityIDs
            );

            setNewOrders(
              newPOs.map((x) => ({
                ...x,
                ...(x.offerOptions && {
                  PriceSelection: (
                    <PriceSelection
                      setNewOrders={setNewOrders}
                      offerOptions={x.offerOptions}
                      materialID={x.materialID}
                      number={x.number}
                    />
                  ),
                }),
              }))
            );
            setExistingOrders(existingPOs);
            setFetching(false);
          }
        }
      } catch (err) {
        setFetching(false);

        console.error({ err2222: err });
      }
    };

    func();

    return () => {
      ignore = true;
    };
  }, [filename, client, subsidiaryIDs, entityID]);

  if (fetching)
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );

  const updatedPOs = existingOrders?.filter((x) => {
    return Object.keys(x.oldValues).length > 0;
  });

  return (
    <Container maxWidth={false}>
      <Typography variant="h3">CPO Import</Typography>
      {!filename && (
        <Button variant="contained" onClick={handleFetch}>
          manual fetch
        </Button>
      )}

      <Stack spacing={5}>
        {existingOrders?.length > 0 && (
          <Stack spacing={2}>
            <Typography variant="h4">Existing POs</Typography>
            <SimpleTable headers={existingPOHeaders} rows={existingOrders} />
            <LoadingButton
              loading={isLoadingUpdate}
              disabled={updatedPOs?.length === 0 || hasSubmittedUpdate}
              variant="contained"
              sx={{ alignSelf: "center" }}
              onClick={async () => {
                setIsLoadingUpdate(true);

                await Promise.all(
                  updatedPOs.map(async (x) => {
                    await onUpdatePO({ values: x, client });
                    // navigate(-1);
                  })
                );
                setIsLoadingUpdate(false);
                setHasSubmittedUpdate(true);
              }}
            >
              {existingOrders?.length > 0 && updatedPOs?.length === 0
                ? "No Changes"
                : "Update Exising POs"}
            </LoadingButton>
          </Stack>
        )}
        {newOrders?.length > 0 && (
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h4">{newOrders?.length} NEW POs </Typography>
              <Typography variant="h6" sx={{ color: "gray" }}>
                VALUE: {newOrders[0].priceCur}{" "}
                {newOrders.reduce((a, v) => a + v.price * v.qty, 0)}
              </Typography>
            </Stack>
            <SimpleTable headers={newPOHeaders} rows={newOrders} />

            <LoadingButton
              loading={isLoadingCreate}
              disabled={
                newOrders?.length === 0 ||
                hasSubmittedCreate ||
                newOrders?.some((x) => x.error?.some((y) => y.isMajor)) ||
                newOrders?.some((x) => !x?.price)
              }
              variant="contained"
              sx={{ marginBottom: 5, alignSelf: "center" }}
              onClick={async () => {
                setIsLoadingCreate(true);

                await Promise.all(
                  newOrders.map(async (x) => {
                    // console.log({ xxxxx: x });
                    const {
                      CRD,
                      customerID,
                      date,
                      incoTerms,
                      paymentTerms,
                      materialID,
                      materialEntityRelationID,
                      number,
                      openQty,
                      price,
                      priceCur,
                      qty,
                      vendorID,
                      buyerID,
                      salesID,
                    } = x;

                    const values = {
                      CRD,
                      customerID,
                      date,
                      incoTerms,
                      paymentTerms,
                      materialEntityRelationID,
                      materialID,
                      number,
                      itemNumber: "00010",
                      qty,
                      openQty,
                      price,
                      priceCur,
                      vendorID,
                      buyerID,
                      salesID,
                      definerID: entityID,
                    };

                    const res = await onCreatePO({ values, client });
                  })
                );
                setIsLoadingCreate(false);
                setHasSubmittedCreate(true);
                navigate("/cloud/896dc48f-3c18-4154-b552-b2751645d1b3/cpos");
              }}
            >
              Generate New POs
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

function PriceSelection({ number, materialID, setNewOrders, offerOptions }) {
  const [slectedPrice, setSelectedPrice] = useState("");
  const [applyToAll, setApplyToAll] = useState(true);

  useEffect(() => {
    // console.log({ applyToAll });
    const offerFound = offerOptions?.find((x) => x.price === slectedPrice);

    if (!offerFound) return;

    if (applyToAll) {
      setNewOrders((prev) => {
        return prev.map((x) => ({
          ...x,
          price: x.materialID === materialID ? offerFound.price : x.price,
          priceCur:
            x.materialID === materialID ? offerFound.currency : x.priceCur,
        }));
      });
    } else {
      setNewOrders((prev) => {
        return prev.map((x) => ({
          ...x,
          price: x.number === number ? offerFound.price : x.price,
          priceCur: x.number === number ? offerFound.currency : x.priceCur,
        }));
      });
    }

    // console.log({ offerFound, materialID });
  }, [
    slectedPrice,
    applyToAll,
    materialID,
    offerOptions,
    number,
    setNewOrders,
  ]);

  return (
    <Stack>
      <TextField
        sx={{ width: 180 }}
        label="Please select price"
        value={slectedPrice}
        select
        onChange={(e) => {
          setSelectedPrice(e.target.value);
        }}
        children={offerOptions?.map((option) => (
          <MenuItem key={option?.id} value={option.price}>
            {option.currency} {option.price}
          </MenuItem>
        ))}
      />
      <FormControlLabel
        disabled={!slectedPrice}
        control={
          <Checkbox
            checked={applyToAll}
            onChange={(e, v) => setApplyToAll(v)}
          />
        }
        label="Apply to all?"
      />
    </Stack>
  );
}
