import React, { useEffect } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Stack, Collapse } from "@mui/material";
import onUpdateInvoices from "../modules/entityModules/billing/onUpdate";
import FormikForm, { FormikField, FormikAutocomplete } from "./formikForm";
import filterOutSameItemInArray from "../lib/filterOutSameItemInArray";
import { onCreatePO } from "../modules/entityModules/CPOs/onCreate";
import calculateForwarderPOFees from "../function/calculateForwarderPOFees";
import { userEntityRelationVar } from "../client/cache";

const relationsByDefiner = /* GraphQL */ `
  query RelationsByDefiner(
    $definerID: ID!
    $relation: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityRelationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationsByDefiner(
      definerID: $definerID
      relation: $relation
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        relation
        definerID
        entity1ID
        entity1 {
          id
          name
          alias
          abbr @client
        }
        entity2ID
        entity2 {
          id
          name
          alias
          abbr @client
        }
        alias
        minCharge
        serviceFeeRate
        customerCode
        vendorCode
        statusCode
        type
        paymentTerms
        incoTerms {
          terms
          entityUserRelationID
        }
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export default function ForwarderPOGen({
  onClose,
  selectionModel,
  setSelectionModel,
  definerID,
}) {
  const initialValues = {
    definerID,
    selectionModel,
    date: new Date().toISOString("en-US").slice(0, 10),
    isEditing: true,
  };

  async function onSubmit({ values, client }) {
    console.log({ values });
    const { date, number, definerID, fxRate, calculatedItems, relation } =
      values;

    const {
      entity1: customer,
      entity2: vendor,
      incoTerms,
      paymentTerms,
      minCharge,
      serviceFeeRate,
    } = relation;

    const POItems = calculatedItems.map((x, i) => ({
      id: uuidv4(),
      piID: x.id,
      itemNumber: i + 1,
      currency: x.priceCur,
      price: x.price,
      qty: x.qty,
      materialID: x.materialID,
      materialEntityRelationID: x.materialEntityRelationID,
      importDutyCN: x.importDutyCN,
    }));

    const valuesForCreatePO = {
      POItems,
      // customer,
      customerID: customer.id,
      vendorID: vendor.id,
      // vendor,
      date,
      incoTerms: incoTerms[0],
      paymentTerms: paymentTerms[0],
      minCharge,
      serviceFeeRate,
      number,
      fxRate,
      definerID,
    };

    // console.log({ valuesForCreatePO });
    // debugger;

    await onCreatePO({ client, values: valuesForCreatePO });

    await Promise.all(
      POItems.map(async (x) => {
        await onUpdateInvoices({
          values: { id: x.piID, forwarderPOID: x.id },
          client,
        });
      })
    );

    await client.refetchQueries({ include: ["InvoiceByType"] });

    onClose && onClose();
    setSelectionModel && setSelectionModel([]);
  }

  const validationSchemaForCreate = Yup.object().shape({});

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      FormMainFields={CreateFields}
      onSubmit={onSubmit}
      FormArray={null}
      handleCancel={onClose}
    />
  );
}

const CreateFields = ({ values, setFieldValue }) => {
  const {
    definerID,
    forwarder,
    fxRate,
    selectionModel,
    customerOptions,
    customer,
    relation,
    element,
    date,
  } = values;

  const userEntityRelation = useReactiveVar(userEntityRelationVar);
  const { relatedEntityIDs } = userEntityRelation 

  const { minCharge, serviceFeeRate } = forwarder || {
    minCharge: 0,
    serviceFeeRate: 0,
  };

  const { data } = useQuery(gql(relationsByDefiner), {
    variables: {
      definerID,
      relation: { eq: "PRINCIPAL-FORWARDER" },
      filter: {
        or: relatedEntityIDs.map((id) => ({
          entity1ID: { eq: id },
        })),
      },
    },
  });

  const forwarderOptions = data?.relationsByDefiner?.items;

  useEffect(() => {
    if (customer?.id && forwarder?.entity2ID) {
      const relationFound = forwarderOptions?.find(
        (x) =>
          x.entity1ID === customer.id && x.entity2ID === forwarder.entity2ID
      );
      setFieldValue("relation", relationFound);
    }
  }, [forwarder?.entity2ID, customer?.id, forwarderOptions, setFieldValue]);

  const filtedForwarderOptions = filterOutSameItemInArray(
    forwarderOptions,
    "entity2ID"
  );

  useEffect(() => {
    async function func() {
      const [items, element] = await calculateForwarderPOFees({
        items: selectionModel.map((x) => ({
          // ...x,
          id: x.id,
          poNo: x.po?.no,
          // poID: x.poID,
          price: x.price,
          currency: x.currency,
          qty: x.qty,
          mpn: x.po?.material?.mpn,
          mfr: x.po?.material?.mfr,
          materialID: x.po?.material?.id,
          materialEntityRelationID: x.po?.materialEntityRelation?.id,
          importDutyCN: x.po?.material?.importDutyCN,
        })),
        exchangeRate: fxRate,
        serviceFeeRate,
        minCharge,
        date,
        incoTerms: relation?.incoTerms?.[0]?.terms,
        paymentTerms: relation?.paymentTerms?.[0],
      });

      setFieldValue("calculatedItems", items);
      setFieldValue("element", element);
    }
    func();
  }, [
    setFieldValue,
    date,
    relation,
    selectionModel,
    fxRate,
    serviceFeeRate,
    minCharge,
  ]);

  return (
    <Stack spacing={1} sx={{ minWidth: 1600 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <FormikAutocomplete
          name="forwarder"
          label="Forwarder"
          options={filtedForwarderOptions}
          getOptionLabel={(option) => option.entity2.abbr}
          required
          getPrimaryText={(option) => option.entity2.abbr}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          getOptionDisabled={null}
          onChange={(e, v) => {
            let filteredRelations = filtedForwarderOptions
              .filter((x) => x.entity2ID === v.entity2ID)
              .map((x) => x.entity1);
            filteredRelations = filterOutSameItemInArray(
              filteredRelations,
              "id"
            );
            setFieldValue("customerOptions", filteredRelations);
            if (filteredRelations?.length === 1) {
              setFieldValue("customer", filteredRelations[0]);
            }
          }}
        />
        <FormikAutocomplete
          name="customer"
          label="Customer"
          options={customerOptions}
          getOptionLabel={(option) => option.abbr}
          required
          getPrimaryText={(option) => option.abbr}
          getSecondaryText={null}
          listItemPrimaryField={null}
          listItemSecondaryField={null}
          disabled={false}
          width={240}
          getOptionDisabled={null}
        />
        <FormikField name="date" label="Date" type="date" />
        <FormikField name="number" label="Number" width={200} />
        <FormikField
          name="fxRate"
          label="Exchange Rate"
          width={100}
          type="number"
        />
      </Stack>

      <Collapse in={forwarder && fxRate !== null}>{element}</Collapse>
    </Stack>
  );
};
