export default function groupVAT(
  items,
  VATMaxAmountPerInv,
  VATMaxLines,
  startNum
) {
  if (!items || items.length === 0) return;

  for (const item of items) {
    if (!item.price || !item.qty) return alert("vat item not valid");
  }

  const formattedItems = items
    .map((x) => {
      if (x.qty * x.price >= VATMaxAmountPerInv) {
        const counts = Math.ceil((x.qty * x.price) / VATMaxAmountPerInv);
        const maxQtyPerInv = Math.floor(VATMaxAmountPerInv / x.price);

        // console.log("counts", counts);
        // console.log("maxQtyPerInv", maxQtyPerInv);

        const arr = [];

        for (let i = 1; i <= counts; i++) {
          arr.push({
            ...x,
            qty:
              i < counts ? maxQtyPerInv : x.qty - maxQtyPerInv * (counts - 1),
          });
        }
        return arr;
      } else {
        return x;
      }
    })
    .flat()
    .sort((a, b) => (a.qty * a.price > b.qty * b.price ? -1 : 1))
    .sort((a, b) => (a.poID > b.poID ? 1 : -1))
    .sort((a, b) => (a.gcas > b.gcas ? 1 : -1));

  let invs = [];

  for (var i = 0; i < formattedItems.length; i++) {
    for (var j = 0; j < invs.length; j++) {
      // if (invs[j]?.length === 8) {
      //   if (!formattedItems[i].added) {
      //     invs.push([formattedItems[i]]);
      //   }
      //   break;
      // }
      if (
        invs[j].reduce((a, v) => a + v.price * v.qty, 0) +
          formattedItems[i].price * formattedItems[i].qty <
          VATMaxAmountPerInv &&
        invs[j].length < VATMaxLines
      ) {
        invs[j].push(formattedItems[i]);
        formattedItems[i].added = true;

        break;
      }
    }

    if (!formattedItems[i].added) {
      invs.push([formattedItems[i]]);
    }
  }

  invs.forEach((arr, i) => {
    arr.forEach((item, j) => {
      const invoiceNumber = startNum
        ? (startNum * 1 + i).toString().padStart(8, 0)
        : "";
      item.invoiceNumber = invoiceNumber;
      item.invoiceItemNumber = j + 1;

      // `${invoiceNumber}-${j + 1}`;
    });
  });

  return invs;
}
