import { useEffect, useMemo } from "react";
import {
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import filterOutSameItemInArray from "../../../../../lib/filterOutSameItemInArray";

export const entitiyUserRelationByEntityID = /* GraphQL */ `
  query EntitiyUserRelationByEntityID(
    $entityID: ID!
    $definerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityUserRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiyUserRelationByEntityID(
      entityID: $entityID
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        title
        shownFn
        shownLn
        shownMn
        statusCode
        isEmailVerified
        userID
        user {
          id
          fn
          ln
          mn
          name @client
          email
          sub
        }
        entityID
        definerID
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export default function Form3Contact({ setFieldValue, values }) {
  let { step, inquiries } = values;

  const parts = useMemo(
    () =>
      filterOutSameItemInArray(inquiries.map((x) => x.material)?.flat(), "id"),
    [inquiries]
  );

  const vendors = parts.map((x) => x.vendors).flat();

  const filteredVendors = filterOutSameItemInArray(vendors, "id");

  // console.log({vendors,filteredVendors})

  // debugger

  const vendorOptions = filteredVendors?.map((x) => ({
    ...x.entity2,
    contacts: x.contacts,
  }));

  console.log({ parts, vendors });

  useEffect(() => {
    if (step !== 3) {
      setFieldValue("step", 3);
    }
  }, [setFieldValue, step]);

  return (
    <Stack spacing={5} alignItems="flex-start">
      {vendorOptions?.map((x) => (
        <ItemByVendor
          key={x.id}
          item={x}
          values={values}
          setFieldValue={setFieldValue}
          parts={parts}
        />
      ))}
    </Stack>
  );
}

const ItemByVendor = ({ item, setFieldValue, values, parts }) => {
  const partsByVendor = parts.filter((x) =>
    x.vendors.some((y) => y.entity2ID === item.id)
  );

  // console.log({ item, parts, partsByVendor });

  const { inquiries } = values;

  const { entityID } = useParams();
  const { data } = useQuery(gql(entitiyUserRelationByEntityID), {
    variables: {
      entityID: item.id,
      definerID: { eq: entityID },
      filter: { role: { eq: "sales" } },
    },
  });

  const salesOptions = useMemo(
    () =>
      data?.entitiyUserRelationByEntityID?.items?.map((x) => ({
        ...x.user,
        entityID: x.entityID,
      })),
    [data?.entitiyUserRelationByEntityID?.items]
  );

  // useEffect(() => {
  //   // console.log({ item, salesOptions, inquiries });

  //   // debugger;

  //   if (!item.contacts && salesOptions?.length === 1) {
  //     setFieldValue(
  //       "inquiries",
  //       inquiries.map((x) => {
  //         const material = x.material.map((y) => ({
  //           ...y,
  //           vendors: y.vendors.map((z) => {
  //             console.log({ z, item });
  //             return {
  //               ...z,
  //               contacts: z.entity2ID === item.id ? salesOptions : z.contacts,
  //             };
  //           }),
  //         }));

  //         return { ...x, material };
  //       })
  //     );
  //   }
  // }, [item, setFieldValue, salesOptions, inquiries]);

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{item.name}</Typography>

      <Stack direction="row" spacing={2}>
        {partsByVendor?.map((x) => (
          <Chip
            key={x.id}
            siza="small"
            label={`${x.mfr} ${x.mpn}`}
            color="primary"
            variant="contained"
          />
        ))}{" "}
      </Stack>

      <Autocomplete
        sx={{ width: 500 }}
        options={salesOptions || []}
        multiple
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        disableCloseOnSelect
        value={item?.contacts || []}
        onChange={(e, v) => {
          setFieldValue(
            "inquiries",
            inquiries.map((x) => {
              const material = x.material.map((y) => ({
                ...y,
                vendors: y.vendors.map((z) => {
                  console.log({ z, item });
                  return {
                    ...z,
                    contacts: z.entity2ID === item.id ? v : z.contacts,
                  };
                }),
              }));

              return { ...x, material };
            })
          );
        }}
        // getOptionDisabled={(option) => {
        //   return option.contacts.length === 0;
        // }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              <ListItemText
                primary={option?.name}
                // secondary={option?.entity1?.alias || option?.entity1?.name}
              />
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoComplete="off"
              label="Please select contact(s)"
              required
              InputProps={{
                ...params.InputProps,
                // type: "search",
              }}
            />
          );
        }}
        // getSecondaryText={(option) =>
        //   option?.contacts?.length === 0
        //     ? "please go to External Coworkers and add sales contact"
        //     : null
        // }
      />
    </Stack>
  );
};
