import { Stack, Button } from "@mui/material";
import { useFormikContext } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";

export default function FormButtons({
  isSubmitting,
  onSubmit,
  handleCancel,
  saveButtonLabel,
  onDelete,
}) {
  const { setFieldValue, setValues, values, errors } = useFormikContext();
  return (
    <Stack direction="row" spacing={2} mt={2} sx={{ alignSelf: "center" }}>
      {onSubmit && (
        <LoadingButton
          disabled={Object.keys(errors).length > 0}
          loading={isSubmitting}
          variant="outlined"
          type="submit"
          children={saveButtonLabel}
        />
      )}
      {handleCancel && (
        <Button
          variant="outlined"
          onClick={() => handleCancel({ setFieldValue, setValues })}
          children="cancel"
        />
      )}
    </Stack>
  );
}
