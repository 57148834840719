export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      name
      alias
      type
      label @client
      createdAt
      updatedAt
      incoTerms {
        terms
        entityUserRelationID
      }
      paymentTerms
    }
  }
`;

export const updateEntity = /* GraphQL */ `
  mutation UpdateEntity(
    $input: UpdateEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    updateEntity(input: $input, condition: $condition) {
      id
      name
      alias
      type
      createdAt
      updatedAt
      incoTerms {
        terms
        entityUserRelationID
      }
      paymentTerms
    }
  }
`;

export const deleteEntity = /* GraphQL */ `
  mutation DeleteEntity(
    $input: DeleteEntityInput!
    $condition: ModelEntityConditionInput
  ) {
    deleteEntity(input: $input, condition: $condition) {
      id
    }
  }
`;
