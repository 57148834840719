import { Stack, Typography } from "@mui/material";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import CreatePaymentStepForm from "./components/CreatePaymentStepForm";
import { paymentByType } from "./query";
import onDeletePayment from "../inbound_payment/onDelete";
import { vatRate } from "../../../settings/formFieldEnum";


function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const isAdmin = userRole?.includes("admin");

  const queryVariables = {
    type: "OUTBOUND",
    definerID: { eq: entityID },
    // limit: 5000,
    // filter: {
    //   or: IDs.map((id) => ({
    //     vendorID: { eq: id },
    //   })),
    // },
  };
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "buyer", "accounting"],
    },
    {
      field: "payee",
      headerName: "Payee",
      width: 180,
      userRole: ["admin", "buyer", "accounting"],
    },
    {
      field: "invoiceNumber",
      headerName: "InvoiceNumber",
      width: 240,
      userRole: ["admin", "buyer", "accounting"],
    },
    {
      field: "PONumber",
      headerName: "PONumber",
      width: 240,
      userRole: ["admin", "buyer", "accounting"],
    },

    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      userRole: ["admin", "buyer", "accounting"],
    },
    {
      field: "desc",
      headerName: "desc",
      width: 300,
      userRole: ["admin", "buyer", "accounting"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeletePayment({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = ({ items }) => {
    return items.map((x) => {
      return {
        ...x,
        payee:
          x.invoice?.po?.vendor?.abbr ||
          x.po?.vendor?.abbr ||
          x.payee?.abbr ||
          x.desc?.split("-")?.[0],
        invoiceNumber: x.invoice?.no,
        PONumber: x.po?.no,
        // desc: !x.po && !x.invoice ? x.desc?.split('-')?.[1] : x.desc,
      };
    });
  };

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.date > b.date ? -1 : 1));
    return arr;
  };

  return (
    <DataGridComp
      query={paymentByType}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute={isAdmin ? "new" : null}
      columns={filteredColumns}
      DetailsDialog={null}
      userRole={userRole}
      checkboxSelection={isAdmin ? true : null}
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
    />
  );
}

const filterAttrs = [
  { label: "date", value: "date", type: "date" },
  {
    label: "invoice Number",
    value: "invoiceNumber",
    type: "string",
  },
  { label: "payee", value: "payee", type: "string" },
  { label: "poNumber", value: "PONumber", type: "string" },

  { label: "description", value: "desc", type: "string" },
];

const outbound_payment = {
  path: "outbound_payment",

  sideNav: {
    group: 2,
    order: 60,
    label: "Outbound Payment",
    entityType: ["100", "300"],
    allowedRole: ["admin", "buyer", "accounting"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin", "buyer", "accounting"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreatePaymentStepForm />,
      allowedRole: ["admin", "buyer", "accounting"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

function ToolbarInsert({ selectionModel }) {
  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
    >
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        SELECTED AMOUNT:
      </Typography>
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        {selectionModel.reduce((a, v) => a + (v?.amount || 0), 0).toFixed(3) *
          1}
      </Typography>
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        WITH VAT:
      </Typography>
      <Typography variant="title" sx={{ fontWeight: 500 }}>
        {selectionModel
          .reduce((a, v) => a + (v?.amount * (1 + vatRate) || 0), 0)
          .toFixed(3) * 1}
      </Typography>
    </Stack>
  );
}

export default outbound_payment;
