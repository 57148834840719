import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import DataGridComp from "../../../component/DataGrid";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { paymentByType } from "./query";
import onDeletePayment from "./onDelete";
import CreatePaymentStepForm from "./components/CreatePaymentStepForm";
import { vatRate, discountingRate } from "../../../settings/formFieldEnum";

function Comp({ entityType, userRole, isAuthenticated, entityID }) {


  const queryVariables = {
    type: "INBOUND",
    definerID: { eq: entityID },
    // filter: {
    //   or: IDs.map((id) => ({
    //     vendorID: { eq: id },
    //   })),
    // },
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 180,
      userRole: ["admin", "accounting"],
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      userRole: ["admin", "accounting"],
    },
    {
      field: "invoiceNumber",
      headerName: "InvoiceNumber",
      width: 240,
      userRole: ["admin", "accounting"],
    },

    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      userRole: ["admin", "accounting"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["admin", "accounting"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeletePayment({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  const prepareItemsFunc = ({ items }) => {
    return items.map((x) => {
      return {
        ...x,
        customer: x.invoice?.po?.customer?.abbr,
        invoiceNumber: x.invoice?.no,
      };
    });
  };

  const sortBy = (arr) => {
    arr.sort((a, b) => (a.date > b.date ? -1 : 1));
    return arr;
  };

  const filterAttrs = [
    { label: "date", value: "date", type: "date" },
    { label: "invoiceNumber", value: "invoiceNumber", type: "string" },
    { label: "desc", value: "desc", type: "string" },
  ];
  return (
    <DataGridComp
      query={paymentByType}
      queryVariables={queryVariables}
      sortBy={sortBy}
      onClickAddRoute="new"
      columns={filteredColumns}
      // DetailsDialog={ItemView}
      userRole={userRole}
      checkboxSelection
      getDropdownMenu={filteredDropdownMenu}
      prepareItemsFunc={prepareItemsFunc}
      ToolbarInsert={ToolbarInsert}
      filterAttrs={filterAttrs}
    />
  );
}

const inbound_payment = {
  path: "inbound_payment",

  sideNav: {
    group: 3,
    order: 60,
    label: "Inbound Payment",
    entityType: ["200", "300"],
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
    new: {
      element: <CreatePaymentStepForm />,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

function ToolbarInsert({ selectionModel }) {
  // const [discountingDays, setDiscountingDays] = useState("");
  // const [rate, setRate] = useState(discountingRate);

  const paymentWithVat =
    selectionModel.reduce((a, v) => a + (v?.amount || 0), 0) * (vatRate + 1);
  // const discountingFee =
  //   (paymentWithVat * discountingRate * discountingDays) / 360;

  return !selectionModel?.length ? null : (
    <Stack
      direction="row"
      spacing={2}
      color="secondary.main"
      sx={{ position: "relative", top: -7 }}
      alignItems="flex-end"
    >
      <Stack direction="row" spacing={1}>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          SELECTED AMOUNT:
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {selectionModel.reduce((a, v) => a + (v?.amount || 0), 0).toFixed(3) *
            1}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          with VAT (if appliable):
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {paymentWithVat?.toFixed(3) * 1}
        </Typography>
      </Stack>

      {/* <Stack direction="row" spacing={2}>
        <TextField
          sx={{ width: 100 }}
          variant="standard"
          label="Rate"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
        />
        <TextField
          sx={{ width: 100 }}
          variant="standard"
          label="Discounting Days"
          value={discountingDays}
          onChange={(e) => setDiscountingDays(e.target.value)}
        />
      </Stack> */}
      {/* <Stack direction="row" spacing={1}>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          DISCOUNT:
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {discountingFee?.toFixed(3) * 1}
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          PAID AMOUNT:
        </Typography>
        <Typography variant="title" sx={{ fontWeight: 500 }}>
          {(paymentWithVat - discountingFee)?.toFixed(3) * 1}
        </Typography>
      </Stack> */}
    </Stack>
  );
}
export default inbound_payment;
