import { Typography, Stack } from '@mui/material'
import * as Yup from 'yup'

import SimpleTable from '../../../../../component/SimpleTable'

import FormikForm from '../../../../../component/formikForm'
import PackingListItems from './PackingListItems'
import onUpdateShipping from '../../onUpdate'

export default function FullWidthItemView({ onClose, closeAll, lineData }) {
  const { shippingItems, packingDetails } = lineData || {}

  // console.log({ shippingItems, packingDetails })

  // const validationSchemaForCreate = Yup.object().shape({
  //   mfr: Yup.string().min(2, 'Too Short'),
  //   mpn: Yup.string().min(2, 'Too Short'),
  //   desc: Yup.string().min(2, 'Too Short'),

  //   // materials: Yup.array().of(
  //   //   Yup.object({
  //   //     material: Yup.object()
  //   //       .required("Please select or create material")
  //   //       .nullable(),
  //   //     statusCode: Yup.string().required("Please select status"),
  //   //   })
  //   //),
  // })

  const initialValues = {
    shippingItems,
    packingListItems: packingDetails,
    isEditing: true,
    mode: 'create',
  }

  async function onSubmit({ values, client }) {
    const { shippingItems, packingListItems } = values

    const totalShippingQty = shippingItems?.reduce((a, v) => a + v.qty, 0)
    const totalPackingQty = packingListItems?.reduce(
      (a, v) => a + (v.ctnTo * 1 - v.ctnFrom * 1 + 1) * v.qty * 1,
      0,
    )

    // console.log({ totalShippingQty, totalPackingQty })

    if (totalPackingQty !== totalShippingQty) {
      return alert('shipping and packing qty dont match')
    }

    const id = shippingItems?.find((x) => x.itemNumber * 1 === 1)?.id

    const input = {
      id,
      packingDetails: packingListItems.map((y) => ({
        poNumber: y.po?.no,
        gcas: y.code,
        // desc: y.desc,
        mfr: y.mfr,
        mpn: y.mpn,
        ctnFrom: y.ctnFrom,
        ctnTo: y.ctnTo,
        qty: y.qty,
        unit: y.unit,
        coo: y.coo,
        l: y.l,
        w: y.w,
        h: y.h,
        nw: y.nw,
        gw: y.gw,
        dimensionUnit: y.dimensionUnit,
        weightUnit: y.weightUnit,
      })),
    }
    // console.log({ input })
    // debugger
    await onUpdateShipping({ values: input, client })
    // close()
  }

  const FormArray = [
    {
      Item: PackingListItems,
      name: 'packingListItems',
      // width: "1200",
      bgcolor: '#f3e5f5',
      btnColor: 'purple',
      size: 'md',
    },
  ]
  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={null}
      onSubmit={onSubmit}
      FormMainFields={PackingListMainForm}
      FormArray={FormArray}
      handleCancel={onClose}
    />
  )
}

function PackingListMainForm({ values }) {
  const headers = [
    'poNumber',
    'code',
    'mfr',
    'mpn',
    'desc',
    'qty',
    'unit',
    'coo',
  ]

  const { shippingItems, packingListItems } = values

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={2}>
        <Typography> {shippingItems?.[0]?.po?.customer?.name}</Typography>
        <Typography>
          SHIPMENT TOTAL:
          {shippingItems?.reduce((a, v) => a + v.qty, 0).toFixed(2)}
        </Typography>
        <Typography>
          PACKING TOTAL:
          {packingListItems
            ?.reduce(
              (a, v) => a + (v.ctnTo * 1 - v.ctnFrom * 1 + 1) * v.qty * 1,
              0,
            )
            .toFixed(2)}
        </Typography>
        <Typography>
          TOTAL GW:
          {packingListItems
            ?.reduce(
              (a, v) => a + (v.ctnTo * 1 - v.ctnFrom * 1 + 1) * v.gw * 1,
              0,
            )
            .toFixed(2)}
        </Typography>
        <Typography>
          TOTAL NW:
          {packingListItems
            ?.reduce(
              (a, v) => a + (v.ctnTo * 1 - v.ctnFrom * 1 + 1) * v.nw * 1,
              0,
            )
            .toFixed(2)}
        </Typography>
      </Stack>
      <SimpleTable headers={headers} rows={shippingItems} />
    </Stack>
  )
}
