import React, { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { customizedI18nGet } from "../settings/dictionary";
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";

export default function DrawerComponent({ menusArray }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split("/").pop();
  // console.log({ path });

  if (!menusArray || menusArray.length === 0) return null;
  return (
    <Drawer
      sx={{
        maxWidth: 220,
        // width: 180,
        // flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          position: "static",
          // top:100,
          // width: 240,
          // boxSizing: "border-box",
          // bgcolor:'purple'
        },
      }}
      variant="permanent"

      // anchor="left"
    >
      {menusArray?.map((menus, i) => {
        if (menus?.length === 0) return null;
        return (
          <Fragment key={i}>
            <List sx={{ minWidth: 120 }}>
              {menus.map((menu, index) => {
                // if (menu.badge) {
                //   console.log({ menu });
                //   return menu.badge;
                // }

                // console.log({ menu });

                // console.log({ customizedI18n: customizedI18nGet(menu.label) });

                return (
                  <ListItem
                    key={menu.label}
                    disablePadding
                    sx={{ bgcolor: path === menu.path ? "#bbdefb" : "inherit" }}
                  >
                    <ListItemButton
                      // color="blue"
                      // disabled={path === menu.path}
                      onClick={() => {
                        if (path === menu.path) return;
                        navigate(menu.path);
                      }}
                    >
                      {/* <ListItemIcon>
            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon> */}
                      {/* <ListItemText primary={menu.label} /> */}
                      <ListItemText
                        // primary={I18n.get(menu.label.toLowerCase())}
                        primary={customizedI18nGet(menu.label)}
                      />

                      {menu.badge && menu.badge}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>

            {i < menusArray.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </Drawer>
  );
}
