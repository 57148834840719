import { useState, useCallback } from 'react'
import { useQuery, gql, useApolloClient ,useReactiveVar} from '@apollo/client'
import { API, Hub } from 'aws-amplify'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography, Button } from '@mui/material'
import onDeleteRelation from '../../onDeleteRelation'
import AddRelationship from './AddRelationship'
import onCreateInvite from '../../../invite/onCreate'
import {userVar} from '../../../../../client/cache'

const inviteByUser = /* GraphQL */ `
  query InviteByUser(
    $userID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inviteByUser(
      userID: $userID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        fn
        ln
        userID
        role
        entityID
        validDays
        status
        groupToRead
        groupToEdit
        groupToDelete
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`

export default function Relation({ data, onClose }) {
  const onAddBtnClick = useCallback(() => {
    Hub.dispatch('dialogMonitor', {
      event: 'showDetails',
      data: {
        Component: AddRelationship,
        lineData: data,
      },
    })
  }, [data])

  return (
    <Stack
      spacing={2}
      justifyContent='space-between'
      sx={{ minHeight: 120 }}
      bgcolor='#f1f1f1'
      borderRadius={1}
      padding={2}>
      <Stack>
        <Typography variant='h6' sx={{ alignSelf: 'center' }}>
          Entity Relations
        </Typography>
        {data?.entityUserRelations?.items?.map((item) => (
          <RelationLine
            key={item.id}
            relationData={item}
            userData={data}
            definerID={data.definerID}
            onClose={onClose}
          />
        ))}
      </Stack>

      <Button
        variant='contained'
        sx={{ alignSelf: 'center' }}
        onClick={onAddBtnClick}>
        add
      </Button>
    </Stack>
  )
}

const RelationLine = ({
  definerID,
  userData: { id: userID, fn, ln, email },
  relationData: {
    id,
    role,
    entity: { id: entityID, name, abbr },
  },
  onClose,
}) => {


  const user = useReactiveVar(userVar)


  const { data: inviteData, refetch } = useQuery(gql(inviteByUser), {
    variables: { userID, entityID: { eq: entityID } },
    skip: !userID || !entityID,
    fetchPolicy: 'network-only',
  })

  const invitesFound = inviteData?.inviteByUser?.items?.filter(
    (x) => x.role?.toLowerCase() === role?.toLowerCase(),
  )

  console.log({ invitesFound })

  const [loading, setLoading] = useState(false)
  const [loadingRevoke, setLoadingRevoke] = useState(false)

  const client = useApolloClient()
  // console.log({ role, user, abbr, definerID, entityID, userID, fn, ln, email })
  if (!inviteData) return null

  async function handleDelete() {
    onDeleteRelation({ values: { id }, client })
  }

  async function handleRemoveRelation(invitationID) {
    setLoadingRevoke(true)
    try {
      const res = await API.post('iSupplyAPI', '/restricted', {
        body: {
          operation: 'REMOVE_FROM_GROUP',
          meta: { invitationID },
        },
      })
      console.log({ res })
      await refetch()
    } catch (err) {
      console.log({ error3344: err })
    }
    setLoadingRevoke(false)
  }

  async function handleSendInvite() {
    setLoading(true)
    try {
      await onCreateInvite({
        client,
        values: {
          email,
          entityID,
          entityName: abbr,
          role,
          ln,
          fn,
          inviterID: user.id,
          inviterFn: user.fn,
          inviterLn: user.ln,
        },
      })
      await refetch()
    } catch (e) {
      console.log({ error: e })
    }
    setLoading(false)
  }

  return (
    <Stack>
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          <Typography>
            {role} at {abbr}
          </Typography>
          <Stack direction='row'>
            <Typography>RELATION ID:</Typography>
            <Typography>{id}</Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Button onClick={handleDelete}>delete</Button>
        </Stack>
      </Stack>
      {definerID === entityID && (
        <Stack spacing={1} ml={2} bgcolor='lightBlue'>
          {invitesFound?.some((x) => x.status !== 'REMOVED') ? (
            invitesFound.map((x) => (
              <Stack key={x.id} direction='row' spacing={2} alignItems='center'>
                <Typography>Invite Status: {x?.status}</Typography>
                {x?.status !== 'REMOVED' ? (
                  <LoadingButton
                    size='small'
                    variant='contained'
                    color='secondary'
                    loading={loadingRevoke}
                    onClick={async () => {
                      await handleRemoveRelation(x.id)
                    }}>
                    revoke
                  </LoadingButton>
                ) : (
                  <></>
                )}
              </Stack>
            ))
          ) : (
            <LoadingButton
              sx={{ alignSelf: 'flex-start' }}
              variant='contained'
              color='secondary'
              loading={loading}
              onClick={handleSendInvite}>
              send root role invitation
            </LoadingButton>
          )}

          {/* {inviteFound && (
                <Typography>Invite Status: {inviteFound?.status}</Typography>
              )}

              {inviteFound?.status !== 'REMOVED' ? (
                <Stack direction='row' spacing={2}>
                  <LoadingButton
                    variant='contained'
                    color='secondary'
                    loading={loadingRevoke}
                    onClick={handleRemoveRelation}>
                    revoke
                  </LoadingButton>
                </Stack>
              ) : (
                <LoadingButton
                  variant='contained'
                  color='secondary'
                  loading={loading}
                  onClick={handleSendInvite}>
                  send root role invitation
                </LoadingButton>
              )} */}
        </Stack>
      )}
    </Stack>
  )
}
