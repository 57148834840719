import {
  Box,
  Link,
  Stack,
  TextField,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
export default function SignUpSelection({
  loading,
  setAuthType,
  action,
  clearAlert,
  setEmail,
  email,
  onClose,
  setPassword,
  password,
  setConfirmPassword,
  confirmPassword,
  sx,
  invitationCode,
  setInvitationCode,
  isInvitationSentByEmail,
  ...props
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ...sx,
        justifyContent: "center",
        alignSelf: "center",
        height: 450,
        width: 600,
      }}
    >
      <Stack spacing={8} sx={{}}>
        <Button
          size="large"
          sx={{ fontSize: 24, width:550 }}
          variant="contained"
          onClick={() => {
            setAuthType("signUpWithCode");
          }}
        >
          I have an invitation code
        </Button>
        <Button
          size="large"
          disabled
          sx={{ fontSize: 24, width:550 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            onClose && onClose();
            navigate("/plans");
          }}
        >
          I don't have an invitation code
        </Button>
      </Stack>
    </Box>
  );
}
