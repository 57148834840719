import { gql } from "@apollo/client";
import { Storage } from "aws-amplify";

import emitSnackbar from "../emitSnackbar";

export { default as onDeleteEntity } from "./onDeleteEntity";
export { default as onDeleteInvite } from "./onDeleteInvite";
export { default as onDeleteRole } from "./onDeleteRole";
export { default as onDeleteSupplier } from "./onDeleteSupplier";
export { default as onDeleteThirdPartyUser } from "./onDeleteThirdPartyUser";
export { default as onDeletePart } from "./onDeletePart";
export { default as onDeleteMaterial } from "./onDeleteMaterial";
export { default as onDeleteOffer } from "./onDeleteOffer";

const notesByItemAndType = /* GraphQL */ `
  query NotesByItemAndType(
    $itemID: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notesByItemAndType(
      itemID: $itemID
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        itemID
        message
        createdBy
        groupToRead
        groupToEdit
        groupToDelete
        createdAt
        updatedAt
        date @client
      }
      nextToken
    }
  }
`;
// const updateByItem = /* GraphQL */ `
//   query UpdateByItem(
//     $itemID: ID!
//     $sortDirection: ModelSortDirection
//     $filter: ModelUpdateFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     updateByItem(
//       itemID: $itemID
//       sortDirection: $sortDirection
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         itemID
//         message
//         createdBy
//         createdAt
//         updatedAt
//       }
//       nextToken
//     }
//   }
// `;

const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      itemID
      message
      createdBy
      createdAt
      updatedAt
    }
  }
`;
// const deleteUpdate = /* GraphQL */ `
//   mutation DeleteUpdate(
//     $input: DeleteUpdateInput!
//     $condition: ModelUpdateConditionInput
//   ) {
//     deleteUpdate(input: $input, condition: $condition) {
//       id
//       itemID
//       message
//       createdBy
//       createdAt
//       updatedAt
//     }
//   }
// `;

export async function onDelete({
  mutation,
  input,
  updateCacheFields,
  client,
  relations,
  storagePath,
}) {
  const res = await client.mutate({
    mutation: gql(mutation),
    variables: { input },
    update: (cache, { data }) => {
      const dataProperty = Object.keys(data)[0];
      const deletedItem = data[dataProperty];

      // console.log({ deletedItem });
      // debugger;

      const idToRemove = cache.identify(deletedItem);
      cache.evict({ id: idToRemove });
      cache.gc();

      // if (!updateCacheFields) return;

      // const idToRemove = cache.identify(deletedItem);

      // updateCacheFields.forEach((field) => {
      //   cache.modify({
      //     fields: {
      //       [field](existingItems = {}) {
      //         return {
      //           ...existingItems,
      //           items: [
      //             ...existingItems.items.filter((x) => x.__ref !== idToRemove),
      //           ],
      //         };
      //       },
      //     },
      //   });
      // });
    },
  });

  // DELETE NOTES
  const { data: listNotesData } = await client.query({
    query: gql`
      ${notesByItemAndType}
    `,
    variables: { itemID: input?.id },
  });

  const notes = listNotesData?.notesByItemAndType?.items;

  if (notes?.length > 0) {
    await Promise.all(
      notes?.map(async (x) => {
        await onDelete({
          mutation: deleteNote,
          input: { id: x.id },
          client,
        });
      })
    );
    alert(`${notes?.length} note(s) deleted`);
  }

  // DELETE RELATIONS

  if (relations) {
    await Promise.all(
      relations.map(async (relation) => {
        const { data: listRelationData } = await client.query({
          query: gql`
            ${relation.query}
          `,
          variables: relation.variables,
        });
        const dataProperty = Object.keys(listRelationData)[0];

        const relationItems = listRelationData?.[dataProperty]?.items;
        if (relationItems?.length > 0) {
          await Promise.all(
            relationItems?.map(async (x) => {
              await onDelete({
                mutation: relation.mutation,
                input: { id: x.id },
                client,
              });
            })
          );
          alert(`${relationItems?.length} relation(s) deleted`);
        }
      })
    );
  }

  // DELETE STORAGE

  if (storagePath) {
    const files = await Storage.list(storagePath);

    // console.log({ storagePath, files });

    // debugger;

    if (files?.length > 0) {
      await Promise.all(files?.map((x) => Storage.remove(x.key)));

      alert(`${files?.length} file(s) deleted`);
    }
  }

  // IT WILL BE TRIGGER MULTIPLE TIMES DUE TO ITINERATION
  // emitSnackbar({
  //   message: "Data deleted successfully",
  //   duration: 3000,
  // });
  return res;
}
