const groupArrayofObjectsByKeyAndGenerateArr = (arr, property) => {
  if (!Array.isArray(arr) || !property) {
    return [];
  }

  const isPropertyArray = Array.isArray(property);

  // console.log({ isPropertyArray });

  if (!isPropertyArray) {
    return arr.reduce((acc, obj) => {
      if (acc.findIndex((x) => x[property] === obj[property]) === -1) {
        acc.push({ [property]: obj[property], items: [obj] });
        return acc;
      } else {
        const index = acc.findIndex((x) => x[property] === obj[property]);
        acc[index].items.push(obj);
        return acc;
      }
    }, []);
  } else {
    const newProperty = property.join("-");
    const newArr = arr.map((x) => {
      if (property.some((y) => !x[y])) {
        console.log(222);
        return alert(" one of the properties not found");
      }
      const newPropertyValue = property.map((y) => x[y]).join("-");

      return { ...x, [newProperty]: newPropertyValue };
    });

    // console.log({ newArr, newProperty });

    return newArr.reduce((acc, obj) => {
      if (acc.findIndex((x) => x[newProperty] === obj[newProperty]) === -1) {
        acc.push({ [newProperty]: obj[newProperty], items: [obj] });
        return acc;
      } else {
        const index = acc.findIndex((x) => x[newProperty] === obj[newProperty]);
        acc[index].items.push(obj);
        return acc;
      }
    }, []);
  }
};

export default groupArrayofObjectsByKeyAndGenerateArr;
