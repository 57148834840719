import { gql, useQuery } from "@apollo/client";
import { Stack, Typography, Button, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, {
  FormikField,
  FormikAutocomplete,
  FormikSelect,
} from "../../../component/formikForm";
import DataGridComp from "../../../component/DataGrid";
import { roles } from "../../../settings/formFieldEnum";
import getFilteredItemsByUserRole from "../../../function/getFilteredItemsByUserRole";
import { listInvites, getInvite, listEntities } from "./query";
import onDelete from "./onDelete";
import onDeleteRole from "./onDeleteRole";
import onUpdate from "./onUpdate";
import onCreate from "./onCreate";

const validationSchema = Yup.object().shape({
  entity: Yup.object().required("Please select entity"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter a valid email"),
  fn: Yup.string()
    .min(2, "At least 2 characters")
    .max(10, "Too Long")
    .required("Please enter first name"),
  ln: Yup.string()
    .min(2, "At least 2 characters")
    .max(10, "Too Long")
    .required("Please enter last name"),
  role: Yup.array()
    .min(1, "Please assign at least one role")
    .required("Please select role(s)"),
});

// const FormArrayForCreate = [
//   {
//     Item: ContactItemForEntityForm,
//     name: "contacts",
//   },
// ];
const FormArrayForEdit = null;

function CreateFields(props) {
  const navigate = useNavigate();
  const { data, refetch } = useQuery(gql(listEntities));

  const entities = data?.listEntities.items || [];

  return (
    <Stack direction="row" spacing={5} alignItems="flex-end">
      <Stack direction="row" spacing={1} alignItems="center">
        <FormikAutocomplete
          name="entity"
          label="Entity"
          options={entities}
          required
          getOptionLabel={(option) => option.alias}
          listItemPrimaryField="alias"
          noOptionsText={
            <Stack direction="row" justifyContent="space-between">
              <Typography>No Options</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/cloud/admin-console/entities/new`);
                }}
              >
                create entity
              </Button>
            </Stack>
          }
        />

        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      <FormikField name="fn" label="First Name" required />
      <FormikField name="ln" label="Last Name" required />
      <FormikField name="email" label="Email" required />

      <FormikSelect
        name="role"
        label="Role(s)"
        multiple
        options={roles}
        required
      />
    </Stack>
  );
}

function CreateForm() {
  // const { entityID } = useParams();
  const initialValues = {};

  return (
    <Typography variant="h3">YOU MAY NEED TO REBUILD THIS FORM</Typography>
    // <NewForm
    //   initialValues={initialValues}
    //   validationSchema={validationSchema}
    //   FormMainFields={CreateFields}
    //   onCreate={onCreate}
    //   // ArrayField={FormArrayForCreate}
    // />
  );
}

function EditFields({ values }) {
  return (
    <Stack
      width="100%"
      spacing={3}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <FormikField name="fn" label="First name" required />
      <FormikField name="ln" label="Last Name" required />
      <FormikField name="status" label="Status" required />
    </Stack>
  );
}

function EditForm({ data, handleCancel, setIsEditing, onClose }) {
  return (
    <FormikForm
      initialValues={data}
      validationSchema={null}
      FormMainFields={EditFields}
      FormArray={FormArrayForEdit}
      type="edit"
      handleCancel={handleCancel}
      saveButtonLabel="save"
      onClose={onClose}
      setIsEditing={setIsEditing}
      onSubmit={onUpdate}
      onDelete={onDelete}
    />
  );
}

function Item({ data }) {
  const { status, fn, ln, entity, role, user } = data;
  return (
    <Stack spacing={3}>
      <Typography> Entity {entity?.alias || entity?.name}</Typography>
      <Typography> Status: {status}</Typography>
      <Typography> First Name: {fn}</Typography>
      <Typography> Last Name: {ln}</Typography>
      <Typography> Email {user?.email}</Typography>
      <Typography> Role: {role.join(", ")}</Typography>
    </Stack>
  );
}

function ItemView({ id, onClose }) {
  const { data } = useQuery(gql(getInvite), {
    variables: { id },
  });

  if (!data) return null;

  const details = data?.getInvite || {};

  return (
    <p>please rebuild this page</p>
    // <DetailsView
    //   onClose={onClose}
    //   Details={Item}
    //   data={details}
    //   EditForm={EditForm}
    //   allowEdit
    // />
  );
}

function Comp({ entityType, userRole, isAuthenticated, entityID }) {
  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 200,
      userRole: ["systemAdmin"],
    },
    {
      field: "user",
      headerName: "User Email",
      width: 300,
      userRole: ["systemAdmin"],
      renderCell: ({ row, ...props }) => {
        return row.user?.email;
      },
    },
    {
      field: "sub",
      headerName: "Sub",
      width: 300,
      userRole: ["systemAdmin"],
      renderCell: ({ row, ...props }) => {
        return row.user?.sub;
      },
    },
    {
      field: "name",
      headerName: "name",
      width: 200,
      userRole: ["systemAdmin"],
    },
    {
      field: "role",
      headerName: "role",
      width: 200,
      userRole: ["systemAdmin"],
    },
    {
      field: "entity",
      headerName: "entity",
      width: 300,
      userRole: ["systemAdmin"],
      renderCell: ({ row, ...props }) => {
        return row.entity?.alias;
      },
    },
    {
      field: "status",
      headerName: "status",
      width: 200,
      userRole: ["systemAdmin"],
    },
    {
      field: "invitedAt",
      headerName: "invitedAt",
      width: 300,
      userRole: ["systemAdmin"],
    },
  ];
  const dropdownMenu = [
    {
      label: "Delete",
      userRole: ["systemAdmin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDelete({
          selectionModel,
          setSelectionModel,
          client,
        });
      },
    },
    {
      label: "Remove Role",
      userRole: ["systemAdmin"],
      onClick: async ({ client, selectionModel, setSelectionModel }) => {
        await onDeleteRole({ selectionModel, setSelectionModel, client });
      },
    },
  ];

  const filteredColumns = getFilteredItemsByUserRole(columns, userRole);

  const filteredDropdownMenu = getFilteredItemsByUserRole(
    dropdownMenu,
    userRole
  );

  // console.log({ filteredDropdownMenu });
  // debugger;

  return (
    <DataGridComp
      query={listInvites}
      // queryVariables={queryVariables}
      onDelete={onDelete}
      onClickAddRoute="new"
      columns={filteredColumns}
      DetailsDialog={ItemView}
      userRole={userRole}
      checkboxSelection
      getDropdownMenu={filteredDropdownMenu}
      // prepareItemsFunc={prepareItemsFunc}
    />
  );
}

const invites = {
  path: "invites",
  sideNav: {
    group: 4,
    order: 60,
    label: "Invites",
    entityType: ["systemAdmin"],
    allowedRole: ["systemAdmin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: null,
      Comp,
      allowedRole: ["systemAdmin"],
      entityType: ["systemAdmin"],
      auth: ["authenticated"],
    },
    new: {
      element: <CreateForm />,
      allowedRole: ["systemAdmin"],
      entityType: ["systemAdmin"],
      auth: ["authenticated"],
    },
  },
};

export default invites;
