import { useEffect } from "react";
import { Stack } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { FormikField, FormikSelect } from "../../../../../component/formikForm";
import { entityType } from "../../../../../settings/formFieldEnum";

export default function Step1EntityBasic({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const { step, id } = values;

  // useEffect(() => {
  //   if (step !== 0) {
  //     console.log({ step });
  //     setFieldValue("step", 0);
  //   }
  //   setErrors({});
  // }, [setFieldValue, setErrors, step]);

  useEffect(() => {
    if (!id) {
      setFieldValue("id", uuidv4());
    }
  }, [id, setFieldValue]);

  return (
    <Stack width={1600} spacing={2}>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikSelect
          label="Business Type"
          name="businessType"
          labelKey="label"
          valueKey="value"
          multiple
          options={entityType}
        />
        <FormikField name="statusCode" label="StatusCode" />
      </Stack>
      <Stack direction="row" spacing={5} alignItems="flex-end">
        <FormikField name="name" label="Name" width={800} required />
        <FormikField name="alias" label="Alias" />
      </Stack>
    </Stack>
  );
}
