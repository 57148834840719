import React from "react";
import { useReactiveVar } from "@apollo/client";

import { Routes, Route } from "react-router-dom";
import { GlobalStyles, CssBaseline } from "@mui/material";
import { RequireAuth, UnAuthLayout } from "./layout";

import {
  ExternalPartnerLayout,
  EntityLayout,
  AdminLayout,
} from "./layout/subLayout";
import iterateRoute from "./function/getRoute";
import entityModules from "./modules/entityModules";
import privateModules from "./modules/privateModules";
import publicModules from "./modules/publicModules";
import systemAdminModules from "./modules/systemAdminModules";
import externalPartnerModules from "./modules/externalPartnerModules";
import { Dashboard } from "./pages";
import AuthPage from "./component/auth/AuthPage";
import useCurrentUser from "./function/hook/useCurrentUser";
import { userVar, userEntityRelationVar } from "./client/cache";

import "./globalCss.css";

function App() {
  useCurrentUser();

  const user = useReactiveVar(userVar);
  const userEntityRelation = useReactiveVar(userEntityRelationVar);

  if (user === "loading" || userEntityRelation === "loading") return null;

  const isAuthenticated = !!user?.sub;
  const { entityID, entityType, userRole } = userEntityRelation;

  const retrivedRoutesFromModules = (modules) => {
    const route = modules
      .filter((x) => x.route)
      ?.reduce((a, v) => {
        a[v.path] = v.route;
        return a;
      }, {});

    return iterateRoute({
      entityType,
      userRole,
      isAuthenticated,
      entityID,
      route,
    });
  };

  // NOT SURE WHAT GLOBALSYLES IS DOING HERE
  return (
    <>
      {/* <GlobalStyles styles={{ margin: 0, padding: 0, listStyle: "none" }} /> */}
      <CssBaseline />
      <Routes>
        <Route element={<UnAuthLayout />}>
          {retrivedRoutesFromModules(publicModules)}
        </Route>
        <Route path="auth" element={<AuthPage />} />
        <Route path="ebp/:entityID" element={<ExternalPartnerLayout />}>
          {retrivedRoutesFromModules(externalPartnerModules)}
        </Route>
        <Route path="/cloud" element={<RequireAuth />}>
          <Route index element={<Dashboard />} />
          {retrivedRoutesFromModules(privateModules)}
          <Route path="admin-console" element={<AdminLayout />}>
            {retrivedRoutesFromModules(systemAdminModules)}
          </Route>
          <Route path=":entityID" element={<EntityLayout />}>
            {retrivedRoutesFromModules(entityModules)}
          </Route>
        </Route>
        <Route path="*" element={<p>nothing</p>} />
      </Routes>
    </>
  );
}

export default App;
