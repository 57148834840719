import { useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { FormikField } from "../../../../../component/formikForm";

const MaterialEntityRelationsByDefiner = /* GraphQL */ `
  query MaterialEntityRelationsByDefiner(
    $definerID: ID!
    $entityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialEntityRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialEntityRelationsByDefiner(
      definerID: $definerID
      entityID: $entityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        materialID
        material {
          id
          mfr
          mpn
        }
        code
        statusCode
        desc
        category
        pdt
        inventoryAlignment
        groupToRead
        groupToEdit
        groupToDelete
        definerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const MaterialsByDefiner = /* GraphQL */ `
  query MaterialsByDefiner(
    $definerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMaterialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MaterialsByDefiner(
      definerID: $definerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        definerID
        mpn
        mfr
      }
      nextToken
      __typename
    }
  }
`;

export default function Form0SelectItem({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  setErrors,
  setStatus,
}) {
  const { step } = values;

  useEffect(() => {
    if (step !== 0) {
      // console.log({ step });
      setFieldValue("step", 0);
    }
    // setErrors({});
  }, [setFieldValue, setErrors, step]);

  // const { entityID } = useParams();

  // let { data: materialEntityRelationsByDefinerData } = useQuery(
  //   gql(MaterialEntityRelationsByDefiner),
  //   { variables: { definerID: entityID } }
  // );
  // const codes = useMemo(
  //   () =>
  //     materialEntityRelationsByDefinerData?.MaterialEntityRelationsByDefiner?.items?.map(
  //       (x) => ({
  //         id: x.materialID,
  //         code: x.code,
  //         relationID: x.id,
  //         materialID: x.materialID,
  //         mfr: x.material.mfr,
  //         mpn: x.material.mpn,
  //       })
  //     ),
  //   [
  //     materialEntityRelationsByDefinerData?.MaterialEntityRelationsByDefiner
  //       ?.items,
  //   ]
  // );

  // useEffect(() => {
  //   setFieldValue("codes", codes);
  // }, [codes, setFieldValue]);

  return (
    <Stack spacing={5}>
      <FormikField
        width={120}
        name="inquiryValidDays"
        label="Valid days"
        type="number"
        required
        InputProps={{ inputProps: { min: 1, max: 30 } }}
      />
    </Stack>
  );
}
