import { onUpdate } from "../../../function/onUpdate";
import { getOffer, updateOffer } from "./query";

export default async function onUpdateOffer({ values, client }) {
  const fieldsToUpdate = [
    { field: "currency", createUpdate: true },
    { field: "price", createUpdate: true },
    { field: "leadtime", createUpdate: true },
    { field: "statusCode", createUpdate: true },
  ];

  await onUpdate({
    type: "Offer",
    values,
    mutation: updateOffer,
    query: getOffer,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: ["GetOffer"],
  });
}
