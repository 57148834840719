import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { offerByNumber, relationsByEntity1 } from "../query";
import SimpleTable from "../../../../component/SimpleTable";
import sendQuotes from "../sendQuote";

const headers = [
  "itemNumber",
  "mfr",
  "gcas",
  "mpn",
  "price",
  "priceCur",
  "mpq",
  "moq",
  "unit",
  "quantity",
  "leadtime",
  "validThur",
  "note",
];

export default function SendQuoteToCTM({ number, definerID }) {
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery(gql(offerByNumber), { variables: { number } });

  const items = [...(data?.offerByNumber?.items || [])];

  const {
    buyer,
    date,
    paymentTerms,
    incoTerms,
    sales,
    vendor,
    vendorID,
    customerID,
    customer,
  } = items?.[0] || {};

  const { data: relationData } = useQuery(gql(relationsByEntity1), {
    variables: {
      entity1ID: customerID,
      entity2ID: { eq: vendorID },
      filter: {
        and: {
          definerID: { eq: definerID },
          relation: { eq: "CUSTOMER-SUPPLIER" },
        },
      },
    },
    skip: !vendorID || !data,
  });

  const vendorCode = relationData?.relationsByEntity1?.items?.[0]?.vendorCode;

  items.sort((a, b) => (a.itemNumber > b.itemNumber ? 1 : -1));

  if (!data) return null;

  const metaData = {
    date,
    quoteNumber: number,
    paymentTerm: paymentTerms.replace(/[_]/g, " "),
    incoTerm: incoTerms?.terms,
    vendorAddress: vendor?.address,
    vendorName: vendor?.name,
    vendorPhoneNumber: vendor?.phoneNumber,
    salesName: sales?.name,
    salesEmail: sales?.email,
    customerName: customer?.name,
    buyerName: buyer?.name,
    buyerEmail: buyer?.email,
    hknVendorCode: vendorCode,
    buyerFN: buyer?.fn,
    buyerLN: buyer?.ln,
  };

  const formmatedItems = items.map((x) => {
    const {
      itemNumber,
      price,
      moq,
      note,
      currency,
      leadtime,
      material,
      materialEntityRelation,
      quantity,
      validDays,
    } = x;
    const { mfr, mpn, desc, mpq, unit } = material || {};
    const { code: gcas } = materialEntityRelation || {};
    let validThur = "";
    if (validDays) {
      const date = new Date();
      date.setDate(date.getDate() + validDays);
      validThur = new Date(Date.now() + validDays * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
    }

    return {
      itemNumber,
      mfr,
      mpn,
      gcas,
      desc,
      leadtime,
      price,
      priceCur: currency,
      moq,
      mpq,
      unit,
      qty: quantity,
      validThur,
      note,
    };
  });

  return (
    <Stack
      width="100%"
      spacing={3}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Stack direction="row" spacing={2}>
        <Typography variant="p">Customer: {customer?.name}</Typography>
        <Typography variant="p">Buyer Name: {buyer?.name}</Typography>
        <Typography variant="p">Buyer Email: {buyer?.email}</Typography>
        <Typography variant="p">Vendor Code: {vendorCode}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="p">Vendor: {vendor?.name}</Typography>
        <Typography variant="p">Tel: {vendor?.phoneNumber}</Typography>
        <Typography variant="p">{vendor?.address}</Typography>
        <Typography variant="p">
          Sales: {sales?.name} {sales?.email}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="p">Date: {date}</Typography>
        <Typography variant="p">Number: {number}</Typography>
        <Typography variant="p">IncoTerms: {incoTerms?.terms}</Typography>
        <Typography variant="p">PaymentTerms: {paymentTerms}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await sendQuotes({
              metaData,
              items: formmatedItems,
              isPreview: true,
            });
            setIsLoading(false);
          }}
        >
          Send Quote (prev)
        </LoadingButton>
        <LoadingButton
                  loading={isLoading}

          variant="contained"
          color="secondary"
          onClick={async () => {
            setIsLoading(true);

            await sendQuotes({
              metaData,
              items: formmatedItems,
              isPreview: false,
            });
            setIsLoading(false);
          }}
        >
          Send Quote
        </LoadingButton>
      </Stack>
      <SimpleTable headers={headers} rows={formmatedItems} />
    </Stack>
  );
}
