import { useCallback } from "react";
import { Stack } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormikForm, { FormikField } from "../../../../component/formikForm";
import onCreate from "../onCreate";
import onCreateRelation from "../onCreateRelation";
import { CreateRelationFields } from "./relation/AddRelationship";

const validationSchemaForCreate = Yup.object().shape({
  // entity: Yup.object().required("Please select entity"),
  // email: Yup.string()
  //   .email("Invalid email")
  //   .required("Please enter a valid email"),
  // fn: Yup.string()
  //   .min(2, "At least 2 characters")
  //   .max(10, "Too Long")
  //   .required("Please enter first name"),
  // ln: Yup.string()
  //   .min(2, "At least 2 characters")
  //   .max(10, "Too Long")
  //   .required("Please enter last name"),
  // title: Yup.string().min(2, "At least 2 characters").nullable(),
  // statusCode: Yup.string().min(2, "At least 2 characters").nullable(),
  // role: Yup.array()
  //   .min(1, "Please assign at least one role")
  //   .required("Please select role(s)"),
});

export default function CreateForm() {
  const { entityID } = useParams();
  const navigate = useNavigate();

  const initialValues = { isEditing: true };

  const onSubmit = useCallback(
    async ({ values, client }) => {
      const createdUserID = await onCreate({
        values: { ...values, entityID },
        client,
      });

      await onCreateRelation({
        values: {
          ...values,
          entityID,
          definerID: entityID,
          userID: createdUserID,
        },
        client,
      });

      navigate(-1);
    },
    [entityID, navigate]
  );

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      validationSchema={validationSchemaForCreate}
      FormMainFields={CreateFields}
      onSubmit={onSubmit}
      FormArray={null}
      handleCancel={handleCancel}
    />
  );
}

const CreateFields = ({ values }) => {
  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={1} alignItems="center"></Stack>
      <Stack direction="row" spacing={5}>
        <FormikField name="fn" label="First Name" width={140} required />
        <FormikField name="ln" label="Last Name" width={140} />
        <FormikField name="email" label="Email" type="email" />
        <FormikField name="phoneNumber" label="PhoneNumber" />
        <FormikField name="address" label="Address" />
      </Stack>
      <CreateRelationFields />
    </Stack>
  );
};
