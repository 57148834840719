import CPOProcess from "./CPOProcess";

const cpo_import = {
  path: "cpo_import",
  sideNav: {
    group: 6,
    order: 20,
    label: "CPO Import",
    entityType: "all",
    allowedRole: ["admin"],
    auth: ["authenticated"],
  },
  route: {
    index: {
      element: <CPOProcess />,
      allowedRole: ["admin"],
      entityType: "all",
      auth: ["authenticated"],
    },
  },
};

export default cpo_import;
